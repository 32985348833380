import React, {
	Fragment,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import pathToRegexp from 'path-to-regexp'
import classNames from 'classnames'
import { useHistory, useParams } from 'react-router'
import each from 'lodash/each'
import { branch, mapProps, renameProp, renameProps, withProps } from 'recompose'
import styled from 'styled-components'
import { I } from '../common/v5/config'
import {
	ADMIN_CHATBOT_EDIT,
	ADMIN_CHATBOTS_INTENT
} from '../common/path'
import {
	changeMasksUpdater,
	hasPrefix,
	isNumberOrNumberStringEqual,
	trimPrefix
} from '../common/helpers'
import { emptyArray, emptyObject, idNameSmall } from '../common/constants'
import {
	AEO_ADD,
	AEO_DELETE,
	AEO_EDIT,
	AEO_MANUAL,
	EDIT_AGENT,
	EDIT_INTENT,
	FIELD_AGENT_DISPLAY_NAME,
	FIELD_AGENT_LIBRARIES,
	FIELD_INTENT_DISPLAY_NAME,
	FIELD_INTENT_MESSAGES,
	FIELD_INTENT_TRAINING_PHRASES,
	P_AMAZON_LEX,
	SUB_FIELD_INTENT_MESSAGES,
	TXT_ACTION,
	TXT_ADD,
	TXT_ADD_CUSTOM_RESPONSE,
	TXT_ADD_TEXT_RESPONSE,
	TXT_AGENT,
	TXT_CAN_NOT_SAVE,
	TXT_CHATBOT,
	TXT_CHATBOTS,
	TXT_INTENT,
	TXT_INTENTS,
	TXT_LIBRARIES,
	TXT_NAME,
	TXT_NO_CHANGE_SAVE,
	TXT_NO_RAW_DEBUG_INFO,
	TXT_OUTPUT_RESPONSE,
	TXT_OUTPUT_TEXT,
	TXT_RAW_DEBUG_INFO,
	TXT_TEST_PHRASE,
	TXT_TEST_RESPONSE_RESULT,
	TXT_TRAINING_PHRASE,
	initCustomResponse,
	initTextResponse,
	initTrainingPhrase,
	pathParams,
	toSelectChatbotPath,
	P_META_LLAMA,
	FIELD_AGENT_WELCOME_GREETING,
	FIELD_AGENT_PERSONALITY,
	FIELD_AGENT_ENABLE_EMOJI,
	FIELD_AGENT_LANGUAGES_SUPPORTED,
	FIELD_AGENT_ENABLE_CHAT_TRIGGER,
	FIELD_AGENT_ACTIONS_FLOW,
	FIELD_AGENT_ACTIONS_TOOLS_OPTION
} from '../common/v5/chatbotConstants'
import { useCallbackWithValue, useCallbackMultiValues } from '../hooks/callback'
import { useNoFalsyArray } from '../hooks/state'
import { useToggle } from '../hooks/toggle'
import {
	composeWithDisplayName,
	withUnmountWhenHidden
} from './hocs'
import { Body } from './AlertConfirm'
import Button from './Button'
import ButtonGroup from './ButtonGroup'
import { V5MultiSelectDropdown, MultiSelectDropdown } from './Dropdown'
import {
	ACTION_DELETE,
	ACTION_EDIT,
	EditorBox,
	EditorFooter,
	Hideable,
	ListContentWrapper,
	ListHeaderWrapper,
	SaveButton,
	StandardEditorHeader,
	TableHeader,
	Skeleton,
	CancelButton
} from './Admin'
import {
	FormInputWithLabelRow,
	TextInputRow,
	SelectInputRow,
	CheckboxInputRow
} from './Form'
import { EditableSelected, NormalizedSelect as Select } from './Select'
import Table, {
	CELL_ACTION,
	CELL_AGENT,
	CELL_ANY,
	CELL_CUSTOM,
	CELL_INPUT_TEXT,
	CELL_TEXTAREA,
	Input,
	NormalizedTable,
	Tr
} from './Table'
import { BTN_TXT_SAVE, BTN_TXT_SAVING, COL_COMPLETE_ERRAND_HISTORIES, M_CHATBOTS } from '../common/v5/constants'
import { centionBlue } from '../styles/_variables';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {default as SquareCheckbox}  from './SquareCheckbox';

const agentNameField = FIELD_AGENT_DISPLAY_NAME
const intentNameField = FIELD_INTENT_DISPLAY_NAME
const agentWelcomeGreeting = FIELD_AGENT_WELCOME_GREETING
const agentPersonality = FIELD_AGENT_PERSONALITY
const agentLanguagesSupported = FIELD_AGENT_LANGUAGES_SUPPORTED
const agentEnableEmoji = FIELD_AGENT_ENABLE_EMOJI
const agentEnableChatTrigger = FIELD_AGENT_ENABLE_CHAT_TRIGGER
const agentActionsFlow = FIELD_AGENT_ACTIONS_FLOW
const agentActionsToolsOption = FIELD_AGENT_ACTIONS_TOOLS_OPTION

const toEditChatbotPath = pathToRegexp.compile(ADMIN_CHATBOT_EDIT)
const toEditIntentPath = pathToRegexp.compile(ADMIN_CHATBOTS_INTENT)

const libraryIdFields = idNameSmall

const LabelBlock = renameProp(
	'className',
	'mainClassName'
)(FormInputWithLabelRow)

const StyledLabel = styled(LabelBlock)`
  .label-block {
    margin-bottom: 10px;
    label {
      color: #6d6d6d;
      font-size: 12px;
      font-weight: 500;
      margin-right: 10px;
      width: 100px;
    }
  }
`
const MultiDropdown = mapProps(({ className, ...props }) => ({
	className: classNames('popup-multi-select', className),
	flip: true,
	...props
}))(V5MultiSelectDropdown)

const StyledDropdown = styled(MultiDropdown)`
  &.popup-multi-select .dropdown.show .dropdown-menu .dropdown-item span {
    color: #6d6d6d;
  }
`
const LibrariesSelectBase = ({ data, onSelect, selected, title }) => {
	const [show, onToggle] = useToggle()
	return (
		<StyledLabel label={title}>
			<StyledDropdown
				data={data}
				groupSelect
				id='chatbot-libraries'
				idFields={libraryIdFields}
				multiSelect
				onSelect={onSelect}
				onToggle={onToggle}
				overrideTitle
				selectAll
				selectNone
				selected={selected}
				show={show}
				title={title}
			/>
		</StyledLabel>
	)
}

export const LibrariesSelect = composeWithDisplayName(
	'LibrariesSelect',
	withUnmountWhenHidden,
	memo
)(LibrariesSelectBase)

const SyncButton = props => <Button color='blue' text={I('Sync')} {...props} />

const useChatbot = ({
	onChangeChatbotSelection,
	onEditStart,
	onFinishEdit
}) => {
	const { eid, id, iid } = useParams()
	const edit = useMemo(() => {
		let which
		let isNew
		if (iid) {
			which = EDIT_INTENT
			isNew = iid === 'create'
		} else if (eid) {
			which = EDIT_AGENT
			isNew = eid === 'create'
		}
		if (typeof which === 'undefined') {
			return false
		}
		return { which, isNew, iid, eid }
	}, [eid, iid])
	const selected = id || ''
	const history = useHistory()
	const onSelect = useCallback(id => {
		if (edit) {
			const { eid, iid, which } = edit
			if (which === EDIT_AGENT) {
				history.push(toEditChatbotPath(pathParams(id, eid, iid)))
			} else if (which === EDIT_INTENT) {
				history.push(toEditIntentPath(pathParams(id, eid, iid)))
			}
		} else {
			history.push(toSelectChatbotPath(pathParams(id)))
		}
	}, [edit, history])
	const onAddAgent = useCallback(e => {
		history.push(toEditChatbotPath(pathParams(selected, 'create')))
	}, [history, selected])
	const onAddIntent = useCallback(e => {
		history.push(toEditIntentPath(pathParams(selected, selected, 'create')))
	}, [history, selected])
	const onClickAgent = useCallback(eid => {
		history.push(toEditChatbotPath(pathParams(selected, eid)))
	}, [history, selected])
	const onClickIntent = useCallback(iid => {
		history.push(toEditIntentPath(pathParams(selected, selected, iid)))
	}, [history, selected])
	const onEditClose = useCallback(() => {
		history.push(toSelectChatbotPath(pathParams(selected)))
	}, [history, selected])
	useEffect(() => {
		if (selected) {
			onChangeChatbotSelection(selected)
		}
	}, [onChangeChatbotSelection, selected])
	useEffect(() => {
		if (edit) {
			onEditStart(edit)
		}
		return () => {
			// must clean up the data as 'data' field share by two different
			// data-format component processor.
			onFinishEdit()
		}
	}, [edit, onEditStart, onFinishEdit])
	return {
		edit,
		onAddAgent,
		onAddIntent,
		onClickAgent,
		onClickIntent,
		onEditClose,
		onSelect,
		selected
	}
}

const identity = v => v

const useColumns = (title, textGetter) => useMemo(() => {
	let _textGetter
	if (typeof textGetter !== 'function') {
		_textGetter = identity
	} else {
		_textGetter = textGetter
	}
	return [
		{
			Header: title,
			accessor: _textGetter,
			id: CELL_INPUT_TEXT,
			type: CELL_INPUT_TEXT
		},
		{
			Header: TXT_ACTION,
			accessor: 'id',
			actions: [ACTION_DELETE],
			type: CELL_ACTION
		}
	]
}, [title, textGetter])

const Div = styled.div`
  margin-bottom: 8px;
  width: 100%;
`
const withDiv = Component => props => <Div><Component {...props} /></Div>

const ButtonBase = props => <Button color='blue' {...props} />

const DivButton = withDiv(ButtonBase)

const AddButton = props => <DivButton text={TXT_ADD} {...props} />

const Buttons = withDiv(ButtonGroup)

const EditableTexts = ({
	onChange,
	textGetter,
	texts,
	title,
	validator
}) => {
	const columns = useColumns(title, textGetter)
	const handleAdd = useCallbackWithValue(AEO_ADD, onChange)
	const handleDeleteBase = useCallbackWithValue(AEO_DELETE, onChange)
	const handleDelete = useCallback((type, value, { row: { index } }) => {
		handleDeleteBase(index)
	}, [handleDeleteBase])
	const handleEdit = useCallbackWithValue(AEO_EDIT, onChange)
	return (
		// eslint-disable-next-line react/jsx-fragments
		<Fragment>
			<Table
				columns={columns}
				data={texts}
				onInputChange={handleEdit}
				onClickAction={handleDelete}
			/>
			<AddButton onClick={handleAdd} />
		</Fragment>
	)
}

const MessageOutputs = ({ cell: { row: { index } }, onChange, texts }) => {
	const handleChange = useCallback((operand, _index, value) => {
		if (operand === AEO_EDIT) {
			value = { [_index]: { $set: value } }
		} else if (operand === AEO_ADD) {
			value = { $push: [''] }
		} else if (operand === AEO_DELETE) {
			value = { $splice: [[_index, 1]] }
		} else {
			// unknown operand
			return
		}
		onChange(index, value)
	}, [index, onChange])
	return (
		<EditableTexts
			onChange={handleChange}
			texts={texts}
			title={TXT_OUTPUT_TEXT}
		/>
	)
}

const Texts = renameProps({
	onInputChange: 'onChange',
	value: 'texts'
})(MessageOutputs)

const useDynamicColumns = (title, accessor, typeAccessor) => useMemo(() => [
	{
		Header: title,
		accessor,
		typeAccessor,
		Renderer: Texts,
		id: CELL_ANY,
		type: CELL_ANY
	},
	{
		Header: TXT_ACTION,
		accessor: 'id',
		actions: [ACTION_DELETE],
		type: CELL_ACTION
	}
], [title, accessor, typeAccessor])

const isCustomResponse = ({ type }) => type === 1

const cellTypeGetter = ({ original }) => {
	if (isCustomResponse(original)) {
		return CELL_TEXTAREA
	}
	return CELL_CUSTOM
}

const anyAccessor = data => {
	const { [SUB_FIELD_INTENT_MESSAGES]: messages } = data
	if (isCustomResponse(data)) {
		if (messages && messages.length > 0) {
			return messages[0]
		}
		return ''
	}
	return messages
}

const BTN_ADD_TEXT_RESPONSE = 1
const BTN_ADD_CUSTOM_RESPONSE = 2

const EditableTables = ({ data, onChange, title }) => {
	const columns = useDynamicColumns(title, anyAccessor, cellTypeGetter)
	const lastIndex = data && data.length ? data.length - 1 : 0
	const handleAddBase = useCallbackMultiValues(onChange, AEO_ADD, lastIndex)
	const addTextResponse = useCallbackWithValue(initTextResponse, handleAddBase)
	const addCustomResponse = useCallbackWithValue(
		initCustomResponse,
		handleAddBase
	)
	const handleDeleteBase = useCallbackWithValue(AEO_DELETE, onChange)
	const handleDelete = useCallback((type, value, { row: { index } }) => {
		handleDeleteBase(index)
	}, [handleDeleteBase])
	const handleEditBase = useCallbackWithValue(AEO_EDIT, onChange)
	const handleEdit = useCallback((index, value) => {
		handleEditBase(index, { messages: value })
	}, [handleEditBase])
	const handleCustomResponseChange = useCallback((index, value) => {
		handleEdit(index, { $set: [value] })
	}, [handleEdit])
	const condition = useMemo(() => ({
		buttons: {
			[BTN_ADD_TEXT_RESPONSE]: {
				color: 'blue',
				text: TXT_ADD_TEXT_RESPONSE
			},
			[BTN_ADD_CUSTOM_RESPONSE]: {
				color: 'blue',
				text: TXT_ADD_CUSTOM_RESPONSE
			}
		},
		order: [BTN_ADD_TEXT_RESPONSE, BTN_ADD_CUSTOM_RESPONSE]
	}), [])
	const handleButtonsClick = useCallback(which => {
		if (which === BTN_ADD_TEXT_RESPONSE) {
			return addTextResponse()
		}
		return addCustomResponse()
	}, [addTextResponse, addCustomResponse])
	return (
		// eslint-disable-next-line react/jsx-fragments
		<Fragment>
			<Table
				columns={columns}
				data={data}
				onClickAction={handleDelete}
				onInputChange={handleEdit}
				onTextareaChange={handleCustomResponseChange}
			/>
			<Buttons condition={condition} onClick={handleButtonsClick} />
		</Fragment>
	)
}

const actionEditOnly = [ACTION_EDIT]
const actionEditAndDelete = [ACTION_EDIT, ACTION_DELETE]

const agentTableColumns = (isUsingMeta) => [
	{
		Header: TXT_NAME,
		accessor: agentNameField
	},
	{
		Header: TXT_AGENT,
		accessor: 'user',
		type: CELL_AGENT
	},
	...(
		[
			{
				Header: TXT_ACTION,
				accessor: 'id',
				actions: actionEditOnly,
				disableActionsWhenRowActive: actionEditOnly,
				type: CELL_ACTION
			}
		]
	)
];

const agentData = ({ agent }) => agent

const ChatbotTable = ({ onClickAction, ...props }) => (
	<NormalizedTable
		columns={agentTableColumns(props.isMetaLLamaChatbot)}
		dataGetter={agentData}
		onClickAction={useCallbackWithValue(EDIT_AGENT, onClickAction)}
		{...props}
	/>
)

const intentTableColumns = restrictionGetter => [
	{
		Header: TXT_NAME,
		accessor: intentNameField
	},
	{
		Header: TXT_ACTION,
		accessor: 'id',
		actions: actionEditAndDelete,
		disableActionsCondition: (button, { original: { events } }) => {
			if (button === ACTION_DELETE) {
				return restrictionGetter(events).disableDelete
			}
		},
		disableActionsWhenRowActive: actionEditAndDelete,
		type: CELL_ACTION
	}
]

const useIntentColumns = restrictionGetter => useMemo(
	() => intentTableColumns(restrictionGetter),
	[restrictionGetter]
)

const intentDataGetter = ({ intent }) => intent

const TrComponent = ({
	isLex,
	onPrefetch,
	restrictionGetter,
	row,
	...props
}) => {
	const { original: { id, events } } = row
	const { isLibrary } = restrictionGetter(events)
	useEffect(() => {
		if (isLex && isLibrary) {
			onPrefetch(id)
		}
	}, [id, isLex, isLibrary, onPrefetch])
	return <Tr row={row} {...props} />
}

const IntentTable = ({
	isMetaLLamaChatbot,
	isLex,
	onClickAction,
	onPrefetch,
	restrictionGetter,
	...props
}) => (
	<NormalizedTable
		TrComponent={TrComponent}
		columns={useIntentColumns(restrictionGetter)}
		dataGetter={intentDataGetter}
		onClickAction={useCallbackWithValue(EDIT_INTENT, onClickAction)}
		rowProps={useMemo(
			() => ({ isLex, onPrefetch, restrictionGetter }),
			[isLex, onPrefetch, restrictionGetter]
		)}
		{...props}
	/>
)

const InputTextRow = withDiv(Input)

const ReadOnlyTextInput = props => <InputTextRow disabled {...props} />

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: "none ",
	padding: grid * 2,
	margin: `0 0 ${grid}px 0`,
	background: isDragging ? "lightgreen" : "grey",
	...draggableStyle
});

const getSubDroppableStyle = (isDraggingOver) => ({
	background: isDraggingOver ? "lightblue" : "",
	padding: grid,
	width: 'auto'
});

const SupportedChatbotLanguages = [
	{ id: 1, code: "af", name: "Afrikaans" },
	{ id: 38, code: "sq", name: "Albanian" },
	{ id: 2, code: "am", name: "Amharic" },
	{ id: 3, code: "ar", name: "Arabic" },
	{ id: 17, code: "hy", name: "Armenian" },
	{ id: 4, code: "az", name: "Azeri" },
	{ id: 5, code: "bn", name: "Bengali" },
	{ id: 29, code: "my", name: "Burmese" },
	{ id: 6, code: "zh", name: "Chinese" },
	{ id: 7, code: "da", name: "Danish" },
	{ id: 32, code: "nl", name: "Dutch" },
	{ id: 10, code: "en", name: "English" },
	{ id: 12, code: "fa", name: "Farsi" },
	{ id: 13, code: "fi", name: "Finnish" },
	{ id: 14, code: "fr", name: "French" },
	{ id: 8, code: "de", name: "German" },
	{ id: 23, code: "ka", name: "Georgian" },
	{ id: 9, code: "el", name: "Greek" },
	{ id: 15, code: "he", name: "Hebrew" },
	{ id: 41, code: "hi", name: "Hindi" },
	{ id: 16, code: "hu", name: "Hungarian" },
	{ id: 19, code: "is", name: "Icelandic" },
	{ id: 18, code: "id", name: "Indonesian" },
	{ id: 20, code: "it", name: "Italian" },
	{ id: 21, code: "ja", name: "Japanese" },
	{ id: 22, code: "jv", name: "Javanese" },
	{ id: 42, code: "kn", name: "Kannada" },
	{ id: 24, code: "km", name: "Khmer" },
	{ id: 25, code: "ko", name: "Korean" },
	{ id: 26, code: "lv", name: "Latvian" },
	{ id: 28, code: "ms", name: "Malay" },
	{ id: 43, code: "ml", name: "Malayalam" },
	{ id: 27, code: "mn", name: "Mongolian" },
	{ id: 30, code: "nb", name: "Norwegian Bokmål" },
	{ id: 31, code: "no", name: "Norwegian Nynorsk" },
	{ id: 33, code: "pl", name: "Polish" },
	{ id: 34, code: "pt", name: "Portuguese" },
	{ id: 35, code: "ro", name: "Romanian" },
	{ id: 36, code: "ru", name: "Russian" },
	{ id: 37, code: "sl", name: "Slovanian" },
	{ id: 11, code: "es", name: "Spanish" },
	{ id: 40, code: "sw", name: "Swahili" },
	{ id: 39, code: "sv", name: "Swedish" },
	{ id: 47, code: "tl", name: "Tagalog" },
	{ id: 44, code: "ta", name: "Tamil" },
	{ id: 45, code: "te", name: "Telugu" },
	{ id: 46, code: "th", name: "Thai" },
	{ id: 48, code: "tr", name: "Turkish" },
	{ id: 49, code: "ur", name: "Urdu" },
	{ id: 50, code: "vi", name: "Vietnamese" },
	{ id: 51, code: "cy", name: "Welsh" }
];


const withAgentEditForm = Component => ({
	data,
	disableSave,
	edit,
	hideSave,
	hideSync,
	hideTest,
	libraries,
	onChange,
	onClickDebugInfo,
	onCloseTestBench,
	onSave,
	onSync,
	onTestPhrase,
	original,
	queries,
	adminStatus,
	isMetaLLamaChatbot,
	actionsFlow,
	langSrc
}) => {

	const handleChangeCallback = useCallbackWithValue(EDIT_AGENT, onChange)
	const handleChangeData = (e) => {
		const { value, name } = e.target;
		handleChangeCallback(name, AEO_EDIT, value);
	}

	const handleEnableActionsTrigger = (toggle) => {
		handleChangeCallback(agentEnableChatTrigger, AEO_EDIT, toggle);
	}

	const handleEnableEmoji = (toggle) => {
		handleChangeCallback(agentEnableEmoji, AEO_EDIT, toggle);
	}

	const [forms, setForms] = useState([]);

	const handleActionsFlowChange = (index, updatedForm) => {
		const updatedForms = forms.map((form, idx) =>
			idx === index ? updatedForm : form
		);
		setForms(updatedForms);
		const actionJsonStr = JSON.stringify(updatedForms);
		handleChangeCallback("action_flow", AEO_EDIT, actionJsonStr);
	};

	const handleAddForm = () => {
		const id = forms.length + 1;
		const newForm = {
			id: id,
			actions: []
		};
		setForms([...forms, newForm]);
	};

	const handleRemoveForm = (index) => {
		const updatedForms = forms.filter((_, idx) => idx !== index);
		setForms(updatedForms);
		const actionJsonStr = JSON.stringify(updatedForms);
		handleChangeCallback("action_flow", AEO_EDIT, actionJsonStr);
	};

	const [optTools, setOptTools] = useState([]);

	useEffect(() => {
		if(data && data[agentActionsFlow]) {
			const actionFlowData = JSON.parse(data[agentActionsFlow]);
			if (actionFlowData.length > 0 ) {
				if (actionFlowData.length !== forms.length) {
					setForms(actionFlowData);
				}
			}
		}
		if(data && data[agentActionsToolsOption] && data[agentActionsToolsOption].buttons && data[agentActionsToolsOption].buttons.length > 0) {
			setOptTools(data[agentActionsToolsOption].buttons);
		}
	}, [data])

	const handleSelectChange = (value, name) => {
		handleChangeCallback(name, AEO_EDIT, value);
	};

	const repositionSubAction = (data, parentId, subId, targetIndex) => {
		const parent = data.find(item => item.id == parentId);
		if (!parent || !Array.isArray(parent.subActions)) {
			console.error("Parent ID not found or no subActions available.");
			return;
		}
		const currentIndex = parent.subActions.findIndex(sub => sub.id == subId);
		if (currentIndex === -1) {
			console.error("SubAction ID not found.");
			return;
		}
		const [subAction] = parent.subActions.splice(currentIndex, 1);
		parent.subActions.splice(targetIndex, 0, subAction);
	}

	const handleOnDragEnd = (result) => {
		if (!result.destination) {
			console.error("No valid destination");
			return;
		}
		const targetIndex = result.destination.index;
		let items = Array.from(forms);

		if (result.type !== "droppableSubItem") {
			const [reorderedItem] = items.splice(result.source.index, 1);
			items.splice(targetIndex, 0, reorderedItem);
			setForms(items);
			const actionJsonStr = JSON.stringify(items);
			handleChangeCallback("action_flow", AEO_EDIT, actionJsonStr);
		} else {
			const regex = /pId-(\d+)-subId-(\d+)/;
			const dragId = result.draggableId;
			const match = dragId.match(regex);
			if (match) {
				const pId = parseInt(match[1]);
				const subId = parseInt(match[2]);
				repositionSubAction(items, pId, subId, targetIndex);
				setForms(items);
				const actionJsonStr = JSON.stringify(items);
				handleChangeCallback("action_flow", AEO_EDIT, actionJsonStr);
			} else {
				console.error("No match found to re position");
			}
		}
	}

	const [expandAction, setExpandAction] = useState(false);
	const handleExpandArea = () => {
		setExpandAction(!expandAction);
	}

	const [selectedLang, setSelectedLang] = useState("");

	const onHandleSelectLanguages = (selected) => {
		let langStr = "";
		if(selected) {
			const selectedLangArr = selected.split(",").map(Number);
			let langCodes = [];
			if(selectedLangArr.length > 0) {
				selectedLangArr.map((id) => {
					const lang = SupportedChatbotLanguages.find((lang) => lang.id === id);
					if(lang) {
						langCodes.push(lang.code);
					}
				});
			}
			langStr = langCodes.join(",");
		}
		setSelectedLang(selected);
		handleChangeCallback(agentLanguagesSupported, AEO_EDIT, langStr);
	}
	useEffect(() => {
		if(data && data[agentLanguagesSupported]) {
			const langArr = data[agentLanguagesSupported].split(",");
			let langIds = [];
			if(langArr.length > 0) {
				langArr.map((code) => {
					const lang = SupportedChatbotLanguages.find((lang) => lang.code === code);
					langIds.push(lang.id);
				});
			}
			const langStr = langIds.join(",");
			setSelectedLang(langStr);
		}
	}, [data[agentLanguagesSupported]]);

	return (
		<Component
			disableSave={disableSave}
			hideSync={hideSync}
			hideSave={hideSave}
			hideTest={hideTest}
			onClickDebugInfo={onClickDebugInfo}
			onCloseTestBench={onCloseTestBench}
			onSave={onSave}
			onSync={useCallbackMultiValues(onSync, edit, data)}
			onTestPhrase={onTestPhrase}
			queries={queries}
			adminStatus={adminStatus}
			isMetaLLamaChatbot={isMetaLLamaChatbot}
		>
			<form id="chatBotAgent" className="admin-one-form edit-admin-form chatbot-manager">
				<div id="ChatbotHideableArea" hidden={expandAction}>
					<ReadOnlyTextInput label={TXT_NAME} value={data[agentNameField]} />
					{
						!isMetaLLamaChatbot &&
						<LibrariesSelect
							data={libraries}
							onSelect={useCallbackMultiValues(
								onChange,
								EDIT_AGENT,
								FIELD_AGENT_LIBRARIES,
								AEO_EDIT
							)}
							selected={useNoFalsyArray(data[FIELD_AGENT_LIBRARIES])}
							title={TXT_LIBRARIES}
						/>
					}
					{
						isMetaLLamaChatbot &&
						<div>
							<FormInputWithLabelRow
								label={I("Greeting")}
								mandatory={false}
								inlineLabel={true}
							>
								<TextInputRow
									id="welcome_greeting"
									name="welcome_greeting"
									className="agent-pref-input"
									value={data[agentWelcomeGreeting]}
									onChange={handleChangeData}
									disabled={false}
									textArea={true}
									rows={3}
									cols={100}
								/>
							</FormInputWithLabelRow>
							<FormInputWithLabelRow
								label={I('Languages Supported')}
							>
							<MultiSelectDropdown
								textSelectLabel={I("Select languages")}
								textNoItemFound={I("No language found")}
								options={SupportedChatbotLanguages}
								idKey="id"
								labelKey="name"
								defaultSelected={selectedLang}
								onChange={onHandleSelectLanguages}
							/>
							</FormInputWithLabelRow>
							<FormInputWithLabelRow
								label={I('Personality')}
								mandatory={false}
								inlineLabel={true}
							>
								<SelectInputRow
									id="personality"
									name="personality"
									className="admin-select-ivr-connect-to"
									option={CHATBOT_PERSONALITY}
									mandatory={true}
									value={data[agentPersonality]}
									textNoItemSelected={I("Please select")}
									onSelect={handleSelectChange}
								/>
								<div>
									<label htmlFor={"admin-chatbot-enable-emoji"}>{I("With emoji")}</label>
									<SquareCheckbox
										id={"admin-chatbot-enable-emoji"}
										name={"enable_emoji"}
										onClick={handleEnableEmoji}
										checked={data[agentEnableEmoji]}
										data-qa-id={"bot-enable-chat-trigger"}
										disabled={data[agentPersonality] != 1}
									/>
								</div>
							</FormInputWithLabelRow>
							<CheckboxInputRow
								label={I("Enable chat actions trigger")}
								id={"admin-chatbot-enable-chat-actions-trigger"}
								name={"enableActionsTrigger"}
								data-qa-id="bot-enable-chat-trigger"
								checked={data[agentEnableChatTrigger]}
								helperTxt={I("Enable this will show set of buttons upon started chat with this chatbot")}
								onChange={handleEnableActionsTrigger}
							/>
						</div>
					}
				</div>
				{ isMetaLLamaChatbot &&
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<div id="DragDropKanban" className="dragDrop-context single-drop" style={{ maxHeight: 'max-content' }}>
							<div className={"board__container"}>
								<div className="board__title" title={I("Actions")}>
									{I("Actions")}
									<span style={{ float: 'right', cursor: 'pointer' }} onClick={handleExpandArea}>
										<i className={expandAction ? 'icon-collapse' : 'icon-expand'}></i>
									</span>
								</div>
								<Droppable droppableId="boxes">
									{(provided, snapshot) => (
										<div
											className={classNames("board__body", { "isDraggingOver": snapshot.isDraggingOver })}
											style={getSubDroppableStyle(snapshot.isDraggingOver)}
											ref={provided.innerRef}
											{...provided.droppableProps}
										>
											<div className="board__body-inner-wrap">
												{forms.map((form, index) => (
													<Draggable key={"actionKey-" + index + form.id} draggableId={"actionTool-" + form.id + index + 1} index={index}>
														{(provided, snapshot) => (
															<div
																className={classNames("item", { "isDragging": (snapshot.isDragging && !snapshot.isDropAnimating) })}
																style={getItemStyle(
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
																ref={provided.innerRef}
																{...provided.dragHandleProps}
																{...provided.draggableProps}
															>
																<ActionFlowForm
																	key={"action-" + index}
																	id={index + 1}
																	actions={form}
																	optTools={optTools}
																	onChange={(updatedForm) => handleActionsFlowChange(index, updatedForm)}
																	onRemove={() => handleRemoveForm(index)}
																	onSave={() => {
																		forms[index].isSaved = true;
																		setForms([...forms]);
																	}}
																/>
															</div>
														)}
													</Draggable>
												))}
												{
													forms.length == 0 && <div style={{textAlign: 'center', color: '#6d6d6d', margin: 'auto', width: '100%', padding: '50px 40%'}}>{I("No buttons defined")}</div>
												}
												{provided.placeholder}
											</div>
										</div>
									)}
								</Droppable>
							</div>
						</div>
						<div style={{ marginTop: '10px' }}>
							<SaveButton onClick={handleAddForm} text={"Add action flow"} title={I(" Add New Action Flow")} />
						</div>
					</DragDropContext>
				}
			</form>
		</Component>
	)
}

const getPhrase = ({ phrase }) => phrase

const TrainingInputs = ({ onChange, texts }) => {
	const handleChangeBase = useCallbackWithValue(
		FIELD_INTENT_TRAINING_PHRASES,
		onChange
	)
	const handleChange = useCallback((operand, index, value) => {
		if (operand === AEO_EDIT) {
			value = { [index]: { phrase: { $set: value } } }
			operand = AEO_MANUAL
		} else if (operand === AEO_ADD) {
			value = initTrainingPhrase
		} else if (operand === AEO_DELETE) {
			value = index
		} else {
			// unknown operand
			return
		}
		handleChangeBase(operand, value)
	}, [handleChangeBase])
	return (
		<EditableTexts
			onChange={handleChange}
			textGetter={getPhrase}
			texts={texts}
			title={TXT_TRAINING_PHRASE}
		/>
	)
}

const ResponsesBase = ({ onChange, data }) => {
	const handleChangeBase = useCallbackWithValue(FIELD_INTENT_MESSAGES, onChange)
	const handleChange = useCallback((operand, index, value) => {
		if (operand === AEO_EDIT) {
			value = { [index]: value }
		} else if (operand === AEO_ADD) {
			value = { $push: [value] }
		} else if (operand === AEO_DELETE) {
			value = { $splice: [[index, 1]] }
		} else {
			// unknown operand
			return
		}
		handleChangeBase(AEO_MANUAL, value)
	}, [handleChangeBase])
	return (
		<EditableTables
			data={data}
			onChange={handleChange}
			title={TXT_OUTPUT_RESPONSE}
		/>
	)
}

const Responses = composeWithDisplayName(
	'Responses',
	memo,
	withUnmountWhenHidden
)(ResponsesBase)

const withIntentEditForm = Component => ({
	data,
	edit: { eid, isNew },
	disableSave,
	hideTest,
	reservedDisplayName,
	restrictionGetter,
	onChange,
	onClickDebugInfo,
	onCloseTestBench,
	onSave,
	onTestPhrase,
	original: { [FIELD_INTENT_DISPLAY_NAME]: originalDisplayName },
	queries,
	adminStatus
}) => {
	const {
		[FIELD_INTENT_DISPLAY_NAME]: displayName,
		[FIELD_INTENT_MESSAGES]: responses,
		[FIELD_INTENT_TRAINING_PHRASES]: trainingPhrases,
		events
	} = data
	const { disableResponse } = restrictionGetter(events)
	const handleChange = useCallbackWithValue(EDIT_INTENT, onChange)
	const displayNameChange = useCallbackMultiValues(
		handleChange,
		FIELD_INTENT_DISPLAY_NAME,
		AEO_EDIT
	)
	// special condition as the time of writing we use display name to indicating
	// link between library and intent.
	const disabledEditDisplayName = useMemo(() => {
		if (isNew) {
			return false
		} else if (originalDisplayName !== displayName) {
			return false
		}
		return hasPrefix(displayName, reservedDisplayName)
	}, [isNew, displayName, originalDisplayName, reservedDisplayName])
	useEffect(() => {
		if (!disabledEditDisplayName &&
			hasPrefix(displayName, reservedDisplayName)) {
			// do not allow name start with reserved word
			displayNameChange('')
		}
	}, [
		disabledEditDisplayName,
		displayName,
		displayNameChange,
		originalDisplayName,
		reservedDisplayName
	])
	return (
		<Component
			disableSave={disableSave}
			hideSync
			hideTest={hideTest}
			onClickDebugInfo={onClickDebugInfo}
			onCloseTestBench={onCloseTestBench}
			onSave={onSave}
			onTestPhrase={onTestPhrase}
			queries={queries}
			adminStatus={adminStatus}
		>
			<InputTextRow
				disabled={disabledEditDisplayName}
				label={TXT_NAME}
				onChange={displayNameChange}
				value={displayName}
			/>
			<TrainingInputs onChange={handleChange} texts={trainingPhrases} />
			<Responses
				data={responses}
				hidden={disableResponse}
				onChange={handleChange}
			/>
		</Component>
	)
}

const StyledTextarea = styled.textarea`
  max-height: 50vh;
  resize: both;
  width: 100%;
`
const RawDBGInfo = props => <DivButton text={TXT_RAW_DEBUG_INFO} {...props} />

const ENTER_KEY = 13

const EnterTextInput = ({ onEnter, ...props }) => {
	const handleKeyPress = useCallback(({ charCode }) => {
		if (charCode === ENTER_KEY) {
			onEnter()
		}
	}, [onEnter])
	return <InputTextRow onKeyPress={handleKeyPress} {...props} />
}

const TestBenchBase = ({
	onClickDebugInfo,
	onClose,
	onEnter,
	queries: { byId, list }
}) => {
	const { raw, text } = useMemo(() => {
		if (list.length && list.length > 0) {
			const answer = byId[list[list.length - 1]]
			if (answer && answer.answer) {
				return answer.answer
			}
		}
		return emptyObject
	}, [byId, list])
	const lastQueryAnswer = useMemo(() => {
		if (typeof text === 'string') {
			return text
		}
		return ''
	}, [text])
	const rawString = useMemo(() => {
		if (!raw) {
			return ''
		}
		return JSON.stringify(raw, null, '  ')
	}, [raw])
	const totalLines = useMemo(
		() => rawString.split('\n').length + 1,
		[rawString]
	)
	const [busy, setBusy] = useState(false)
	const [showRaw, setShowRaw] = useState(false)
	const handleClickRawDebugInfo = useCallback(() => {
		setShowRaw(true)
		onClickDebugInfo()
			.catch(err => console.log('error clicking raw debug info:', err))
			.then(() => setShowRaw(false))
	}, [onClickDebugInfo])
	const disabledRawInfo = useMemo(() => {
		if (busy || !raw) {
			return TXT_NO_RAW_DEBUG_INFO
		}
		return false
	}, [busy, raw])
	const [value, setValue] = useState('')
	const handleChange = useCallback(value => {
		setValue(value)
	}, [])
	const handleEnter = useCallback(() => {
		setBusy(true)
		Promise.resolve(onEnter(value)).then(() => {
			setBusy(false)
		})
	}, [onEnter, value])
	useEffect(() => {
		return () => {
			onClose()
		}
	}, [onClose])
	return (
		<div>
			<EnterTextInput
				disabled={busy}
				label={TXT_TEST_PHRASE}
				onChange={handleChange}
				onEnter={handleEnter}
				value={value}
			/>
			<ReadOnlyTextInput
				label={TXT_TEST_RESPONSE_RESULT}
				value={lastQueryAnswer}
			/>
			<Body hidden={!showRaw}>
				<StyledTextarea disabled rows={totalLines} value={rawString} />
			</Body>
			<RawDBGInfo
				disabled={disabledRawInfo}
				onClick={handleClickRawDebugInfo}
			/>
		</div>
	)
}

const TestBench = composeWithDisplayName(
	'TestBench',
	memo,
	withUnmountWhenHidden
)(TestBenchBase)

const EditFormWrapper = ({
	children,
	disableSave,
	hideSave,
	hideSync,
	hideTest,
	onClickDebugInfo,
	onCloseTestBench,
	onSave,
	onSync,
	onTestPhrase,
	queries,
	adminStatus: saveStatus,
	isMetaLLamaChatbot
}) => {
	let buttonSaveTxt = BTN_TXT_SAVE, disable = disableSave;
	if (saveStatus && saveStatus.status === 1) {
		buttonSaveTxt = BTN_TXT_SAVING;
		disable = true;
	}
	return (
		<EditorBox>
			{children}
			<Div>
				<EditorFooter>
					<SyncButton hide={hideSync || isMetaLLamaChatbot} onClick={onSync} />
					<SaveButton disabled={disable && !isMetaLLamaChatbot} hide={hideSave} onClick={onSave} text={buttonSaveTxt} title={buttonSaveTxt} />
				</EditorFooter>
			</Div>
			{
				!isMetaLLamaChatbot &&
				<TestBench
					hidden={hideTest}
					onClickDebugInfo={onClickDebugInfo}
					onClose={onCloseTestBench}
					onEnter={onTestPhrase}
					queries={queries}
				/>
			}
		</EditorBox>
	)
}

// props need to have:
//  data: input data that agent change.
//  original: original data that act as initial state of 'data'.
//  onSave: save handler.
//  edit: {
//    which,
//    id,
//    isNew
//  }
//  disableSave (optional) allow parent to control the disabled state.
export const useChangeDetectSave = (changeDetector, props) => {
	const { data, edit, onSave, original, disableSave } = props
	const changes = useMemo(
		() => changeDetector(original, data),
		[changeDetector, data, original]
	)
	const noChange = !changes
	const disabled = useMemo(() => {
		const disableTexts = []
		if (noChange) {
			disableTexts.push(TXT_NO_CHANGE_SAVE)
		}
		if (disableSave) {
			if (typeof disableSave === 'string') {
				disableTexts.push(disableSave)
			} else {
				disableTexts.push(TXT_CAN_NOT_SAVE)
			}
		}
		return disableTexts.join(' ')
	}, [noChange, disableSave])
	const handler = useCallbackMultiValues(
		onSave,
		edit,
		changes,
		data
	)
	return [disabled, handler]
}

const withHandleSave = Component => props => {
	const [disabled, handler] = useChangeDetectSave(changeMasksUpdater, props)
	return <Component {...props} disableSave={disabled} onSave={handler} />
}

const withSimpleHandlers = Component => ({ onCloseTestBench, ...props }) => {
	const { edit: { eid } } = props
	const handleCloseTestBench = useCallbackWithValue(eid, onCloseTestBench)
	return <Component onCloseTestBench={handleCloseTestBench} {...props} />
}

const isEditingChatbotAgent = ({ edit: { which } }) => which === EDIT_AGENT

const EditForm = composeWithDisplayName(
	'EditForm',
	memo,
	withProps(({ data, edit, adminStatus }) => {
		if (!edit || !data || edit.which !== data.which) {
			return { hidden: true }
		}
		const { isNew } = edit
		return {
			data: data.data,
			hideSync: isNew,
			hideTest: isNew,
			original: data.original,
			adminStatus
		}
	}),
	withUnmountWhenHidden,
	withHandleSave,
	withSimpleHandlers,
	branch(
		isEditingChatbotAgent,
		withAgentEditForm,
		withIntentEditForm
	)
)(EditFormWrapper)

const titleMap = {
	[EDIT_AGENT]: TXT_CHATBOT,
	[EDIT_INTENT]: TXT_INTENT
}

const EditFormHeader = withProps({ titleMap })(StandardEditorHeader)

const agentNameAsLabel = data => {
	if (!data || !data.agent) {
		return ''
	}
	const { agent: { [agentNameField]: name } } = data
	return name
}

const agentIdAsValue = ({ agent: { id } }) => id

const useMemoChabotsField = (byId, selected, field) => useMemo(() => {
	if (!selected || typeof byId[selected] !== 'object') {
		return
	}
	return byId[selected][field]
}, [byId, selected, field])

const emptyNormalized = { byId: emptyObject, list: emptyArray }

const useMemoChabotNormalizedField = (byId, selected, field) => {
	const chatbotData = useMemoChabotsField(byId, selected, field)
	return useMemo(() => {
		if (!chatbotData || !chatbotData.list.length) {
			return emptyNormalized
		}
		return chatbotData
	}, [chatbotData])
}

const useInternalIntentRestriction = reservedEvents => useCallback(
	events => {
		if (!events || !events.length) {
			return emptyObject
		}
		const {
			inquiryData,
			// eslint-disable-next-line camelcase
			inquiryData_yes,
			// eslint-disable-next-line camelcase
			inquiryData_no,
			// eslint-disable-next-line camelcase
			inquiryData_yes_yes,
			// eslint-disable-next-line camelcase
			inquiryData_yes_no,
			createErrand,
			// eslint-disable-next-line camelcase
			createErrand_yes,
			// eslint-disable-next-line camelcase
			createErrand_no,
			endOfConversation,
			fallbackAgent,
			// eslint-disable-next-line camelcase
			fallbackAgent_yes,
			// eslint-disable-next-line camelcase
			fallbackAgent_no,
			fallbackNoAgent,
			// eslint-disable-next-line camelcase
			fallbackNoAgent_yes,
			// eslint-disable-next-line camelcase
			fallbackNoAgent_no,
			fallbackSuggestion,
			generalGreeting,
			chatbotLibQ,
			speakToAgent,
			welcome,
			defaultFallback,
			defaultWelcome
		} = reservedEvents
		const hard = {
			[speakToAgent]: true,
			[createErrand_yes]: true,
			[inquiryData_no]: true,
			[inquiryData_yes_yes]: true,
			[inquiryData_yes_no]: true,
			[fallbackAgent_yes]: true,
			[fallbackNoAgent_yes]: true
		}
		const soft = {
			[createErrand]: true,
			[createErrand_no]: true,
			[inquiryData]: true,
			[inquiryData_yes]: true,
			[endOfConversation]: true,
			[fallbackAgent]: true,
			[fallbackAgent_no]: true,
			[fallbackNoAgent]: true,
			[fallbackNoAgent_no]: true,
			[fallbackSuggestion]: true,
			[generalGreeting]: true,
			[defaultFallback]: true,
			[defaultWelcome]: true,
			[welcome]: true
		}
		const result = {}
		each(events, v => {
			if (hard[v]) {
				result.disableDelete = true
				result.disableResponse = true
				return false
			} else if (soft[v]) {
				result.disableDelete = true
				return false
			} else if (hasPrefix(v, chatbotLibQ)) {
				result.disableResponse = true
				result.isLibrary = true
				result.library = trimPrefix(v, chatbotLibQ)
				return false
			}
		})
		return result
	},
	[reservedEvents]
)

const useIsLex = (byId, selected) => {
	const agent = useMemoChabotsField(byId, selected, 'agent')
	return agent && agent.provider === P_AMAZON_LEX
}

const useIsMetaLLamaChatbot = (byId, id) => {
	const agent = useMemoChabotsField(byId, id, 'agent')
	return agent && agent.provider === P_META_LLAMA
}

const IntentAll = 0;
const IntentUser = 1;
const IntentLibrary = 2;
const IntentSystem = 3;

const IntentTypes = [
	{ id: IntentAll, name: I('All') },
	{ id: IntentUser, name: I('User') },
	{ id: IntentLibrary, name: I('Library') },
	{ id: IntentSystem, name: I('System') }
]

const Chatbot = ({
	bots: { byId, list },
	className,
	editData,
	libraries,
	onClickDebugInfo,
	onCloseTestBench,
	onDeleteChatbot,
	onDeleteChatbotIntent,
	onEdit,
	onLoad,
	onPrefetchIntent,
	onQuestionChatbot,
	onSave,
	onSync,
	reservedEvents,
	...props
}) => {
	const restrictionGetter = useInternalIntentRestriction(reservedEvents)
	const {
		edit,
		onAddIntent,
		onClickAgent,
		onClickIntent,
		onEditClose,
		onSelect,
		selected
	} = useChatbot(props)
	const noAgentSelected = !selected
	const {
		byId: intentById,
		list: intentList
	} = useMemoChabotNormalizedField(byId, selected, 'intents')
	const filterText = props.ui.searchText;
	const filterType = props.ui.filterType;
	const welcomeGreetings = props.ui[FIELD_AGENT_WELCOME_GREETING];
	const enableChatActionsTrigger = props.ui[FIELD_AGENT_ENABLE_CHAT_TRIGGER];
	const actionsFlow = props.ui[FIELD_AGENT_ACTIONS_FLOW];
	const actionsToolsOpts = props.ui[FIELD_AGENT_ACTIONS_TOOLS_OPTION];
	const filteredIntentList = useMemo(() => {
		if (filterType == IntentAll && filterText == "") {
			return intentList;
		}
		const filterList = [];
		each(intentList, (v) => {
			var intent = intentById[v].intent;
			var intentType = IntentUser;
			if (intent.events && intent.events.length > 0) {
				intentType = (intent.events[0].indexOf("workflow_libraryquestion") !== -1) ?
					IntentLibrary : IntentSystem;
			}
			if (filterType == IntentAll || filterType == intentType) {
				if (filterText.length > 0) {
					if (intent.display_name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1) {
						filterList.push(v)
					}
				} else {
					filterList.push(v);
				}
			}
		})
		return filterList;
	}, [intentById, intentList, filterText, filterType]);
	const isLex = useIsLex(byId, selected)
	const firstKey = Object.keys(byId)[0];
	const isMetaLLamaChatbot = useIsMetaLLamaChatbot(byId, firstKey);
	const handlePrefetchIntent = useCallback(
		iid => {
			if (isLex && selected) {
				return onPrefetchIntent(selected, iid)
			}
		}, [isLex, onPrefetchIntent, selected])
	const editId = useMemo(() => {
		if (edit && edit.eid) {
			return edit.eid
		}
		return 0
	}, [edit])
	const queries = useMemoChabotNormalizedField(byId, editId, 'questions')
	const handleQuery = useCallbackWithValue(editId, onQuestionChatbot)
	const handleDeleteIntent = useCallback((iid, _, { original: { events } }) => {
		if (selected) {
			const { library } = restrictionGetter(events)
			onDeleteChatbotIntent(selected, iid, library)
		}
	}, [restrictionGetter, selected, onDeleteChatbotIntent])
	const actionMap = useMemo(
		() => ({
			[EDIT_AGENT]: {
				[ACTION_EDIT]: onClickAgent,
				[ACTION_DELETE]: onDeleteChatbot
			},
			[EDIT_INTENT]: {
				[ACTION_EDIT]: onClickIntent,
				[ACTION_DELETE]: handleDeleteIntent
			}
		}),
		[onClickAgent, onClickIntent, onDeleteChatbot, handleDeleteIntent]
	)
	const handleActions = useCallback(
		(which, action, id, ...args) => actionMap[which][action](id, ...args),
		[actionMap]
	)
	const chatbotTableActiveChecker = useCallback(({ values: { id } }) => {
		const { which, eid } = edit
		if (which === EDIT_AGENT) {
			return isNumberOrNumberStringEqual(eid, id)
		}
	}, [edit])
	const intentTableActiveChecker = useCallback(({ values: { id } }) => {
		const { which, eid, iid } = edit
		if (which === EDIT_INTENT && isNumberOrNumberStringEqual(eid, selected)) {
			return iid === id
		}
	}, [edit, selected])
	useEffect(() => {
		onLoad()
	}, [onLoad])
	return (
		<Skeleton
			className={className}
			view={M_CHATBOTS}
			lists={
				// eslint-disable-next-line react/jsx-fragments
				<Fragment>
					<ListHeaderWrapper className='bot-header'>
						<TableHeader
							hideAddIcon
							title={TXT_CHATBOTS}
						/>
					</ListHeaderWrapper>
					<ListContentWrapper className='bot-content'>
						<ChatbotTable
							activeChecker={chatbotTableActiveChecker}
							byId={byId}
							list={list}
							isMetaLLamaChatbot={isMetaLLamaChatbot}
							onClickAction={handleActions}
							{...props}
						/>
					</ListContentWrapper>
					{
						!isMetaLLamaChatbot ?
							<Select
								byId={byId}
								className='bot-select'
								data={list}
								getOptionLabel={agentNameAsLabel}
								getOptionValue={agentIdAsValue}
								onSelect={onSelect}
								selected={selected}
							/> : null
					}
					<Hideable hidden={noAgentSelected}>
						<ListHeaderWrapper className='intent-header'>
							<TableHeader
								addActive={!!edit}
								hideAddIcon={noAgentSelected}
								onClickAdd={onAddIntent}
								title={TXT_INTENTS}
							/>
						</ListHeaderWrapper>
						<div className={"adminFilter intentListFilter"}>
							<TextInputRow
								id={"searchIntent"}
								name={"searchIntent"}
								className={"admin-filter-select search-field"}
								icon="icon-search"
								placeholder={I("Search for...")}
								value={props.ui.searchText}
								onChange={(e) => props.onTextInputChange("searchText", e)}
							/>
							<SelectInputRow
								id={"filterIntentType"}
								name={"filterIntentType"}
								className={"admin-filter-select search-field"}
								label={I('Filter')}
								textNoItemSelected={"textNoItemSelected"}
								option={IntentTypes}
								value={props.ui.filterType}
								onSelect={props.onSelectFilter}
							/>
						</div>
						<ListContentWrapper className='intent-content'>
							<IntentTable
								activeChecker={intentTableActiveChecker}
								byId={intentById}
								onPrefetch={handlePrefetchIntent}
								isMetaLLamaChatbot={isMetaLLamaChatbot}
								isLex={isLex}
								list={filteredIntentList}
								onClickAction={handleActions}
								restrictionGetter={restrictionGetter}
								{...props}
							/>
						</ListContentWrapper>
					</Hideable>
				</Fragment>
			}
		>
			<EditFormHeader edit={edit} onClose={onEditClose} />
			<EditForm
				data={editData}
				edit={edit}
				libraries={libraries}
				onChange={onEdit}
				onClickDebugInfo={onClickDebugInfo}
				onCloseTestBench={onCloseTestBench}
				onSave={onSave}
				onSync={onSync}
				onTestPhrase={handleQuery}
				queries={queries}
				restrictionGetter={restrictionGetter}
				adminStatus={props.adminStatus}
				isMetaLLamaChatbot={isMetaLLamaChatbot}
				welcomeGreetings={welcomeGreetings}
				agentEnableChatTrigger={enableChatActionsTrigger}
				agentActionsFlow={actionsFlow}
				agentActionsToolsOption={actionsToolsOpts}
				{...props}
			/>
		</Skeleton>
	)
}

const StyledChatbot = styled(Chatbot)`
  ${Table} {
    width: 100%;
  }
  .bot-select {
    padding: 10px;
  }
`
export default StyledChatbot

const TrainPhraseSelection = props => (
	<div><EditableSelected {...props} /></div>
)

const getClientMessageText = ({ text }) => text
const createClientMessage = text => ({ text })
const validIfNotEmpty = text => !!text

export const ClientMessageSelection = ({
	clientMessages,
	onChangeSelection,
	selectedMessage,
	...props
}) => (
	<TrainPhraseSelection
		isValidNewOption={validIfNotEmpty}
		getNewOptionData={createClientMessage}
		getOptionLabel={getClientMessageText}
		getOptionValue={getClientMessageText}
		onChange={onChangeSelection}
		options={clientMessages}
		value={selectedMessage}
		{...props}
	/>
)

const CHATBOT_ACTION_OPTIONS = [
	{ id: 1, name: I("AI Agent"), actionType: "actionTools" }, //call an endpoint that setup in the action tools (AI Agent Builder)
	{ id: 2, name: I("URL"), actionType: "url" },  // a hyperlink
	{ id: 3, name: I("Text"), actionType: "text" }, //as a shortcut for a prompt
	{ id: 4, name: I("Sub actions"), actionType: "button" }, //to show another set of actions after click
]

const CHATBOT_PERSONALITY = [
	{ id: 1, name: I("Casual")},
	{ id: 2, name: I("Formal")},
]

const StyledTitleBullet = styled.span`
	font-size: 16px;
	color: #FFF;
	background: ${centionBlue};
	padding: 2px 6px;
	margin-right: 5px;
	border-radius: 4px;
`;

const ActionFlowForm = ({ id, actions, optTools, sub, noInputKeywords = true, onChange, onRemove, onSave }) => {
	const [formState, setFormState] = useState({
		id: 0,
		title: "",
		data: "",
		description: "",
		inputKeywords: "",
		chatbotActionType: 0,
		chatbotActionToolsOutputType: 0, //unique id of the action tool from the tool management (AI Agent Builder)
		chatbotActionOutputType: "", // no longer used, to be removed
		outputText: "", // For chatbotActionOutputType === 1
		subActions: [], // Store nested actions
		isSaved: false // Track saved state (not used for now)
	});

	const [collapsed , setCollapsed] = useState(true);
	const iconCollapse = collapsed ? 'icon-chevron-down' : 'icon-chevron-up';

	useEffect(() => {
		if (actions) {
			const updatedState = {
				...actions,
				subActions: actions.subActions ? actions.subActions : [],
			};
			setFormState(updatedState);
		}
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		const updatedState = {
			...formState,
			[name]: value,
			subActions: formState.subActions
		};
		setFormState(updatedState);
		onChange(updatedState);
	};

	const handleSelectChange = (value, name) => {
		const updatedState = {
			...formState,
			[name]: value,
			subActions: formState.subActions
		};
		setFormState(updatedState);
		onChange(updatedState);
	};

	const handleSubActionsChange = (index, updatedForm) => {
		const updatedForms = formState.subActions.map((form, idx) =>
			idx === index ? updatedForm : form
		);
		const updatedState = {
			...formState,
			subActions: updatedForms,
		};
		setFormState(updatedState);
		onChange(updatedState);
	};

	const handleAddNestedForm = () => {
		const newNestedForm = {
			id: formState.subActions.length + 1,
			title: "",
			data: "",
			description: "",
			inputKeywords: "",
			chatbotActionType: 0,
			chatbotActionToolsOutputType: 0,
			chatbotActionOutputType: "",
			outputText: "",
			subActions: [],
			isSaved: false
		};
		const updatedForms = [...formState.subActions, newNestedForm];
		const updatedState = {
			...formState,
			subActions: updatedForms,
		};
		setFormState(updatedState);
		onChange(updatedState);
	};

	//for partial save, not used for now
	const handleAdd = () => {
		setFormState((prev) => ({ ...prev, isSaved: true }));
		onChange({ ...formState, isSaved: true });
		onSave();
	};

	const renderSubActions = (parentId) => {
		if (formState && formState.subActions && formState.subActions.length === 0) {
			return null
		} else {
			if (formState && formState.subActions && formState.subActions.length > 0) {
				return <Droppable droppableId={"inner-droppable-" + parentId + "subId" + id} type={"droppableSubItem"}>
					{(provided) => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
							style={{
								marginTop: "10px",
								padding: "10px",
								overflow: 'hidden'
							}}
						>
							{formState.subActions.map((form, index) =>
								<Draggable key={"sub_actionKey-" + index + id + form.id} draggableId={"sub_actionTool-" + "pId-" + parentId + "-subId-" + form.id + "-" + id + index + 1} index={index}>
									{(provided, snapshot) => (
										<div className={classNames("item", { "isDragging": (snapshot.isDragging && !snapshot.isDropAnimating) })}
											style={getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											)}
											ref={provided.innerRef}
											{...provided.dragHandleProps}
											{...provided.draggableProps}
										>
											<ActionFlowForm
												key={"sub-action-" + index}
												id={index + 1}
												actions={form}
												optTools={optTools}
												sub={true}
												onChange={(updatedForm) => handleSubActionsChange(index, updatedForm)}
											/>
										</div>
									)}
								</Draggable>
							)}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			}
		}
	};
	let actionToolsLink = [];
	if(optTools && optTools.length > 0) {
		optTools.forEach((opt) => {
			actionToolsLink.push({ id: opt.id, name: opt.title });
		});
	}

	const collapsedIconStyle = {
		fontSize: '18px',
		marginLeft: 'auto',
		marginTop: '10px',
	};

	return (
		<div style={{display: "flex", border: '1px solid #eaeaea', marginBottom: '10px'}}>
			<form className="admin-one-form edit-admin-form ivr-admin-form admin-chatbot-single-action">
				<div>
					<div className={"admin-form-header-collapsible" + (sub ? ' sub' : '')} onClick={() => setCollapsed(!collapsed)}>
						<div className="admin-form-sub-title">
							<StyledTitleBullet>{id}</StyledTitleBullet>
							{ formState.title }
						</div>
						<div title={I("Hide action")} 
							style={collapsedIconStyle}>
								<i className="icon-grip"></i>
								<i className={iconCollapse}></i>
						</div>
					</div>
					<div hidden={collapsed} className="admin-form-content-collapsible">
						<FormInputWithLabelRow
							mandatory={false}
							inlineLabel={false}
						>
							<TextInputRow
								name="title"
								className="admin-text-input with-helper"
								label={I('Label')}
								value={formState.title}
								onChange={handleInputChange}
								inlineLabel={false}
								mandatory={true}
							/>
						</FormInputWithLabelRow>
						<FormInputWithLabelRow
							label={I('Description')}
							mandatory={false}
							inlineLabel={false}
						>
							<TextInputRow
								name="description"
								className="admin-text-input with-helper"
								value={formState.description}
								onChange={handleInputChange}
								inlineLabel={false}
							/>
						</FormInputWithLabelRow>
						{
							!noInputKeywords &&
							<FormInputWithLabelRow
								label={I('Input keywords')}
								mandatory={false}
								inlineLabel={false}
							>
								<TextInputRow
									name="inputKeywords"
									className="admin-text-input with-helper"
									value={formState.inputKeywords}
									onChange={handleInputChange}
									inlineLabel={false}
									textArea={true}
									rows={3}
									helperTxt={I('Keywords that will display this action. Separate by comma.')}
								/>
							</FormInputWithLabelRow>
						}
						<FormInputWithLabelRow
							label={I('Actions type')}
							mandatory={true}
							inlineLabel={false}
						>
							<SelectInputRow
								id="chatbotActionType"
								name="chatbotActionType"
								className="admin-select-ivr-connect-to"
								option={CHATBOT_ACTION_OPTIONS}
								mandatory={true}
								value={formState.chatbotActionType}
								textNoItemSelected={I("Please select")}
								onSelect={handleSelectChange}
							/>
						</FormInputWithLabelRow>
						{
							formState.chatbotActionType != 4 &&
							<FormInputWithLabelRow
								label={I('Text')}
								mandatory={true}
								inlineLabel={false}
							>
								<TextInputRow
									name="data"
									className="admin-text-input with-helper"
									value={formState.data}
									onChange={handleInputChange}
									inlineLabel={false}
									mandatory={true}
									helperTxt={I("Text that will send to the chatbot")}
								/>
							</FormInputWithLabelRow>
						}
					</div>
				</div>
				{
					<div hidden={collapsed} className="admin-form-content-collapsible" style={{overflow: 'hidden', height: 'auto'}}>
						<FormInputWithLabelRow
							mandatory={false}
							inlineLabel={false}
						>
						{renderSubActions(actions.id)}
						{
							(formState.chatbotActionType === 4 || formState.chatbotActionOutputType === 4) &&
							<div style={{ marginTop: '10px' }}>
								<SaveButton onClick={handleAddNestedForm} text={I("Add sub actions")} />
							</div>
						}
						</FormInputWithLabelRow>
					</div>
				}
				{formState.chatbotActionType === 1 && (
					<div hidden={collapsed} className="admin-form-content-collapsible">
						<FormInputWithLabelRow
							label={I('Select tools to display ?')}
							inlineLabel={false}
						>
							<SelectInputRow
								id="chatbotActionToolsOutputType"
								name="chatbotActionToolsOutputType"
								className="admin-select-ivr-connect-to"
								option={actionToolsLink}
								mandatory={true}
								value={formState.chatbotActionToolsOutputType}
								textNoItemSelected={I("Please select")}
								onSelect={handleSelectChange}
							/>
						</FormInputWithLabelRow>
					</div>
				)}
			{
				//no need to show partial save for now
				//<SaveButton onClick={handleAdd} text={I("Save action")} />
				<div hidden={collapsed} title={I("Remove action")} style={{margin: '10px', fontSize: '18px'}}><i className="icon-trash" style={{cursor: "pointer"}} onClick={onRemove} ></i></div>
			}
			</form>
		</div>
	);
};
