import React, {
    Fragment
    , PureComponent
    , useCallback
    , memo
    , useMemo
    , useRef
    , useEffect
    , useState
} from 'react';
import classNames from 'classnames';
import { renderFileActionBtns as renderActionButtons } from '../admin';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import { renderEditButton } from '../admin';
import { I, L, webRoot } from '../../../common/v5/config';
import {
    ListHeaderWrapper,
    ListContentWrapper,
    editorTitleByView,
    Delete as ActionDelete,
    Download as ActionDownload,
    Edit as ActionEdit,
    EditorHeader,
    AdminListAndEditLayout,
    withEditWrap,
} from '../../../reactcomponents/Admin';
import { TableIconicButton as Button, TextInputRow } from '../../../reactcomponents/Form';
import {
    ADMIN_TITLE,
    INPUT_EMPTY_WARNING,
    INPUT_SPACE_WARNING,
    M_GROUPS,
    FORM_EMPTY,
    FORM_EDIT,
    FORM_CREATEGROUP,
    FORM_MANAGEGROUP,
    FORM_LISTGROUP,
    FORM_UPLOAD
} from '../../../common/v5/constants';
import withGroups from './groupsCtnr';
import GroupTabs from './groupEdit';
import FileUploader from '../../../components/FileUploader';
import Helper from '../../../reactcomponents/Helper'
import { customPageTotal } from '../../../reactcomponents/Table';

const uploadHelperTxt = <div>
    <div>{I("The csv file should contain group name, area name, system group name separated by a comma, for example:")}</div>
    <br />
    <div>{I("Group name,Area name,Systemgroup name")}</div>
    <br />
    <div>{"Support,General Support,Cention"}</div>
</div>

const GroupUploadForm = ({ onFetchAdminList }) => {

    const [status, setStatus] = useState(I("Create new groups by uploading csv file."))

    const handleFileUpload = (rs) => {
        if (rs.status) {
            setTimeout(function () {
                getGroups();
            }, 1000);
            setStatus(I("Data successfully imported."))
        } else {
            setStatus(I("File uploading failed! Please contact support."))
        }
    }
    const getGroups = () => {
        $.get(webRoot + "group")
            .done(function (rs) {
                if (rs.length > 0) {
                    setStatus(I("Please wait while the system completes the upload process. Imported data will be available in the list shortly."));
                } else {
                    onFetchAdminList();
                }
            })
    }
    return (
        <div id="groupUpload">
            <div className='upload-input-wrap'>
                <h3 className='title mb-2'>{I("Upload file")}</h3>
                <div className='pull-right upload'>
                    <FileUploader
                        className="btn-file-upload-wrap"
                        uploadTo="group/upload"
                        multipleFile={false}
                        // onProgress={this.handleUploadProgress}
                        onFileupload={handleFileUpload}
                    />
                    <Helper>{uploadHelperTxt}</Helper>
                </div>
            </div>
            <span className='label'>{status}</span>
        </div>
    )
}

export const TableHeader = ({
    title,
    children,
    helperText
}) => {
    return (
        <div className='admin-top-header'>
            <div className='upper-content'>
                <div className='form-heading'>
                    <h2 className='title'>{L(title)}</h2>
                    <Helper>{helperText}</Helper>
                </div>
                <div className='tab-options-wrapper'>
                    {children}
                </div>
            </div>
        </div>
    )
};

const MEASURE_CLASS = "agent-header-col"
//Todo: This can be used to smartly fit agent names and group the remaining that doesnt fit
const MeasureElem = ({ setWidth }) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const element = document.querySelector(`.${MEASURE_CLASS}`);
        const observer = new ResizeObserver(([entry]) => {
            setDimensions(entry.contentRect);
            setWidth(entry.contentRect.width)
        });
        observer.observe(element);

        return () => {
            observer.unobserve(element);
        };
    }, []);

    return (
        <div id="myElement" className="my-element2">
            <p>Width: {dimensions.width}</p>
            {/* <p>Height: {dimensions.height}</p> */}
        </div>
    );
}

// const EditForm = withEditWrap(CORSAddForm);
const EditForm = withEditWrap(GroupTabs);
const UploadForm = withEditWrap(GroupUploadForm);

const GroupsBase = ({
    view
    , onLoad
    , showInline
    , list
    , onLoadToEdit
    , onLoadToEditFolder
    , onLoadToUpload
    , onDownloadFromList
    , onChangeAdminInput
    , onSaveAdmin
    , onSaveGroupFolder
    , onDeleteFolderFromList
    , onDeleteFromList
    , onCloseForm
    , activeId
    , input
    , formType
    , onFetchAdminList
    , onSetAdminColumnWidth
    , columnWidth
    , areaList
    , folderList
    , adminStatus
    , ...props
}) => {
    useEffect(() => {
        if (onLoad) {
            onLoad(M_GROUPS, false);
        }
    }, []);

    const handleDelete = (id) => {
        let confirmDelMsg = I('Are you sure you want to delete?');
        if (confirm(confirmDelMsg)) {
            onDeleteFromList({ id: id }, view);
        }
    }

    const actionDatas = {
        onDelete: handleDelete,
        onEdit: onLoadToEdit
    }

    const agentHeaderFormatter = (column, colIndex) => {
        return <MeasureElem setWidth={onSetAdminColumnWidth}/>
    }

    const agentFormatter = (cell, row, rowIndex, formatExtraData) => {
        let agents = [];
        let totalAgentsCount;
        let remainingCount = null;
        let remainingAgents = null;
        let totalAgentsArray = [];
        const firstThree = 3; // first 3 elements

        if(row.agents !== undefined) {
            totalAgentsArray = row.agents.members.concat(row.agents.teamLeaders)
            totalAgentsCount = totalAgentsArray.length

            // const names = totalAgentsArray.map( member => member.name);
            // agents = names.slice(0, firstThree).join(", ");

            // if(names.length > firstThree) {
            //     remainingAgents = names.slice(firstThree);
            //     remainingCount = totalAgentsCount - firstThree
            // }
        }

        const agentCount = <span className='badge badge-pill custom'>{totalAgentsCount}</span>

        return (
            //display first 3 agents name and count of remaining agents
            // <div>
            //     <span>{agents}</span>
            //     {remainingCount && <span title={remainingAgents.join(", ")}> + {remainingCount} more</span>}
            // </div>

            //display number or agents and show agent's name on hover
            <Helper
                className="group__agent--tooltip"
                triggerElement={agentCount}
                hideTooltip={totalAgentsCount === 0}
            >
                <ul style={{lineHeight: "2em", maxHeight: "50vh"}}>
                    {
                       totalAgentsArray.map((agent, index) => {
                        return <li key={agent.id}>{`${agent.username} (${agent.name})`}</li>
                       })
                    }
                </ul>
            </Helper>
        )
    }

    const folderFormatter = (cell, row, rowIndex, formatExtraData) => {
        let folderCount;
        if(row.folders !== undefined) {
            folderCount = row.folders.folderList.length
        }
        return <div>{folderCount}</div>
    }

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            hidden: true
        },
        {
            dataField: 'name',
            text: I('Name'),
            sort: true,
        },
        {
            dataField: 'agent',
            text: I('Agents'),
            sort: true,
        //     headerClasses: MEASURE_CLASS,
        //     headerFormatter: agentHeaderFormatter,
            headerAlign: 'center',
            align: 'center',
            formatter: agentFormatter,
        //     formatExtraData: columnWidth
        },
        {
            dataField: 'folder',
            text: I('Folder'),
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: folderFormatter
        },
        {
            isDummyField: true,
            dataField: 'action',
            text: I('Action'),
            classes: 'right',
            headerAlign: (column, colIndex) => 'right',
            align: (cell, row, rowIndex, colIndex) =>  'right',
            formatter: (cell, row) => {
                return renderActionButtons(cell, row, actionDatas);
            },
        }
    ]

    const defaultSorted = [{
        dataField: 'createtime',
        order: 'desc'
    }];

    const isNew = activeId <= 0;

    const options = {
        paginationSize: 3,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '<<',
        prePageText: '<',
        nextPageText: '>',
        lastPageText: '>>',
        showTotal: true,
        paginationTotalRenderer: customPageTotal,
        disablePageTitle: true,
        sizePerPageList: [10]
    };

    const isCreate = formType == FORM_EDIT && !activeId;
    const isUpload = formType == FORM_UPLOAD

    let listSection = <Fragment>
        <ListHeaderWrapper>
            <TableHeader
                title={ADMIN_TITLE[view]}
                type='button'
                iconClass='icon-add'
                hide={false}
            >
                <Button
                    type='button'
                    className={classNames("btn tab-btn bg-transparent btn-round", { "active": isUpload })}
                    iconClass='icon-upload'
                    title={I('Upload groups')}
                    onClick={onLoadToUpload}
                    value={FORM_CREATEGROUP}
                // disabled={isCreate}
                />
                <Button
                    type='button'
                    className={classNames("btn bg-transparent btn-round", { "active": isCreate })}
                    iconClass='icon-add'
                    title={I('Create New')}
                    onClick={e => onLoadToEdit(0)}
                    hide={false}
                    active={showInline}
                />
            </TableHeader>
        </ListHeaderWrapper>
        <ListContentWrapper className={"v5-table-style"}>
            <BootstrapTable
                keyField="id"
                data={list}
                noDataIndication={I("No data to display")}
                columns={columns}
                bordered={false}
                condensed={false}
                defaultSorted={defaultSorted}
                pagination={paginationFactory(options)}
                hover
            />
        </ListContentWrapper>
    </Fragment>;

    let editSection = <Fragment>
        <EditorHeader
            hidden={!showInline}
            isNew={isNew}
            onClickClose={onCloseForm}
            title={editorTitleByView(isNew, view)}
        />
        {formType === FORM_UPLOAD &&
            <UploadForm hidden={!showInline} onFetchAdminList={onFetchAdminList} />}
        {formType === FORM_EDIT &&
            <EditForm
                activeId={activeId}
                areaList={areaList}
                input={input}
                view={view}
                onChangeAdminInput={onChangeAdminInput}
                onSave={onSaveAdmin}
                onSaveGroupFolder={onSaveGroupFolder}
                onDeleteFolderFromList={onDeleteFolderFromList}
                onCancel={onCloseForm}
                onDelete={handleDelete}
                isNew={isNew}
                hidden={!showInline}
                folderList={folderList}
                onLoadToEditFolder={onLoadToEditFolder}
                adminStatus={adminStatus}
                {...props}
            />}
    </Fragment>;

    return (
        <AdminListAndEditLayout
            idClass={"AdminGroup"}
            listSection={listSection}
            editSection={editSection}
        />
    );
}

const AdminGroups = withGroups(GroupsBase);
export default AdminGroups;
