import React from 'react'
import each from 'lodash/each'
import update from 'immutability-helper'
import {
	AT_COL_ANS,
	AT_SAVED,
	COL_MASK_LIGHT_ON,
	COL_MASK_READ_ANSWER,
	COL_MASK_DELIVERY_ERR,
	MT_COLLABORATION
} from '../../common/v5/constants'
import {
	removePM,
	sanitizeHtml,
	stripHTML
} from '../../common/v5/helpers'
import ClickableTitle from '../../reactcomponents/ClickableTitle'
import { Message, PanelSingleMessage } from './Message'

class CollaborateQuery extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleActionClick = this.handleActionClick.bind(this);
	}
	handleActionClick(action) {
		this.props.onActionClick(action, sanitizeHtml(this.props.html),
			this.props.queryID, 0, this.props.threadID);
	}
	render() {
		const {attachments, writer, html, timestamp, notMine, to, recipients, saved, tagList} = this.props;
		return <PanelSingleMessage
			attachmentType={AT_SAVED}
			attachments={attachments}
			className={"collab-query"}
			clone={!saved}
			data-qa-id={"collab-query-"+this.props.queryID}
			queryId={this.props.queryID}
			html={html}
			isAgent={true}
			name={writer.name}
			onActionClick={this.handleActionClick}
			photo={writer.photo}
			print={!saved}
			recipients={recipients}
			reply={notMine}
			resend={!saved}
			showExactDayAndTime={this.props.showExactDayAndTime}
			showSize={0}
			time={timestamp}
			type={MT_COLLABORATION}
			edit={saved}
			saved={saved}
			tagList={tagList}
		/>
	}
}

class CollaborateAnswer extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleActionClick = this.handleActionClick.bind(this);
		this.handleAttachmentInsert = this.handleAttachmentInsert.bind(this);
	}
	handleActionClick(action) {
		this.props.onActionClick(action, sanitizeHtml(this.props.html),
			0, this.props.answerID, this.props.threadID);
	}
	handleAttachmentInsert(type, _useless1, attachmentID, _useless2, index) {
		const attachment = this.props.attachments[index];
		if(attachment) {
			this.props.onAttachmentInsert(attachment);
		}
	}
	render() {
		const {
				attachments,
				writer,
				html,
				timestamp,
				index,
				canTranslate,
				interfaceLang,
				onTranslation,
				recipients
			} = this.props;
		return <PanelSingleMessage
			className={"collab-answer"}
			notErrand={true}
			type={MT_COLLABORATION}
			data-qa-id={"collab-answer-"+this.props.answerID}
			queryId={''}
			photo={writer.photo}
			name={writer.name}
			// status={'Sent'}
			time={timestamp}
			showSize={0}
			isAgent={false}
			clone={true}
			reply={true}
			print={true}
			resend={this.props.resend}
			html={html}
			interfaceLang={interfaceLang}
			recipients={recipients}
			attachmentType={AT_COL_ANS}
			attachments={attachments}
			onAttachmentSelect={this.handleAttachmentInsert}
			onActionClick={this.handleActionClick}
			canTranslate={canTranslate}
			onTranslation={onTranslation}
			showExactDayAndTime={this.props.showExactDayAndTime}
		/>
	}
}

const CollaborateWaitFooter = ({title, targets}) => {
	let targetsName = [], names = "";
	if(targets && targets.length > 0) {
		for(let i = 0; i < targets.length; i++) {
			targetsName.push(targets[i].name);
		}
		names = targetsName.join(",");
	}
	if(names) {
		return <div className="waiting-on-message">
				<h3>{title}</h3>
				<span>{names}</span>
			</div>;
	}
	return <div className="waiting-on-message">
			<h3>{title}</h3>
		</div>;
};

class CollaborateThread extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			loading: false
		};
	}
	componentDidUpdate(prevProps) {
		const p = this.props
		//seems previous old UI the loading spinner is not stopped once there is content. "loading" only resets(by state) as the component get unmounted
		//on new UI adding this to stop the spinner
		if(p.isLoaded !== prevProps.isLoaded) {
			this.setState({loading: false});
		}
}
	handleClick(e) {
		const p = this.props;
		this.setState({loading: true});
		let read;
		if(!this.props.notMine) {
			read = p.data.read;
		}
		p.onLoad(p.me, p.eid, read);
	}
	handleCollapseCollabThread = (e) => {
		this.setState({loading: false});
		this.props.onToggleCollapse(this.props.me, this.props.expand);
	}
	render() {
		const p = this.props, data = p.data, eeThreadID = '#'+p.me;
		let icon, highlightClass, deliveryError;
		let errandSubject = removePM(data.subject);
		if (this.state.loading) {
			icon = <i className="fa fa-spinner fa-spin" aria-hidden="true" />;
		} else {
			icon = <label>
					<i
						data-qa-id={"icon-expand-ee-"+eeThreadID}
						className="icon-chevron-down"
						title={I('Expand this expert thread')}
						// TODO: NOTE1: this onClick trigger also in old UI and thus trigger
						// twice onClick events and two endpoints send out.
						onClick={this.handleClick}
					/>
				</label>;
		}

		let iconCollapse = <label onClick={this.handleCollapseCollabThread}>
							<i data-qa-id={"icon-collapse-ee-"+eeThreadID}
								className="icon-chevron-right"
								title={I('Collapse this expert thread')}
							/>
						</label>;

		if (!(data.flag & COL_MASK_LIGHT_ON) &&
			!(data.flag & COL_MASK_READ_ANSWER)) {
			highlightClass = 'no-highlight light-bulb-off';
		} else if (data.read) {
			highlightClass = 'no-highlight';
		}
		let emailCollab = false;
		if (data.to && data.to.indexOf('@') > -1) {
			emailCollab = true;
		}
		if (data.flag & COL_MASK_DELIVERY_ERR) {
			if(emailCollab) {
				deliveryError = <span title={I('Email delivery error')}>&nbsp;
					<span className="fa-stack fa-lg">
						<i className="fa fa-envelope-o fa-stack-1x" />
						<i className="fa fa-ban fa-stack-2x text-danger" />
					</span>
				&nbsp;</span>;
			}
		} else {
			deliveryError = null;
		}
		return <div className="waiting-on-message ee-thread">
				<div className="expand-ee-thread" title={I('Expand this expert thread')} data-qa-id={"expand-ee-thread-"+eeThreadID} onClick={this.handleClick}>
					{deliveryError}
					<h3 className={highlightClass}>{data.to}</h3>{' '}
					<span className={"thread-subject"}>{errandSubject}</span>{' '}
					<div className="icon-container">{p.onToggleCollapse ? iconCollapse : icon}</div>
				</div>
			</div>
	}
}

class CollaborateThreadHeader extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handlePrint = this.handlePrint.bind(this);
		this.handleCreateNew = this.handleCreateNew.bind(this);
		this.handleAnswerQuery = this.handleAnswerQuery.bind(this);
		this.handleCollapseCollabThread = this.handleCollapseCollabThread.bind(this);
	}
	handlePrint(e) {
		this.props.onPrint(this.props.me);
	}
	handleCreateNew(e) {
		let queryID, answerID;
		if(this.props.lastIsAnswer) {
			answerID = this.props.lastID;
			queryID = 0;
		} else {
			answerID = 0;
			queryID = this.props.lastID;
		}
		this.props.onCreateNew(this.props.me, queryID, answerID);
	}
	handleAnswerQuery(e) {
		this.props.onAnswerQuery(this.props.me, this.props.lastQuery);
	}
	handleCollapseCollabThread(e) {
		this.props.onToggle(this.props.me, this.props.expand);
	}
	render() {
		let canAnswerQuery;
		if(this.props.notMine) {
			canAnswerQuery = <i className="fa fa-reply" aria-hidden="true"
				title={I('Answer the last query.')}
				onClick={this.handleAnswerQuery} />;
		} else {
			canAnswerQuery = null;
		}

		const threadHeaderBar = <div className="thread-header">
			<div className="icon-wrapper">
				<i className="icon-printer" aria-hidden="true"
					data-qa-id={"ee-print-thread-#"+this.props.me}
					title={I('Print this collaboration conversation thread.')}
					onClick={this.handlePrint} />
				<i className="fa fa-file" aria-hidden="true"
					data-qa-id={"ee-new-query-#"+this.props.me}
					title={I('Create new query for this thread')}
					onClick={this.handleCreateNew} />
			</div>
		</div>;

		return threadHeaderBar;
	}
}

const collaborationStr = I('COLLABORATION')

const HRLine = ({ className, style }) => (
	<hr className={className} style={style} />
)

class MessageCollaboration extends React.Component {
	constructor(props) {
		super(props);
		this.handleShow = this.handleShow.bind(this);
		this.handleActionClick = this.handleActionClick.bind(this);
		this.handleCreateNewQuery = this.handleCreateNewQuery.bind(this);
		this.handleCollapseCollabThread = this.handleCollapseCollabThread.bind(this);
		this.handleLoadCollabThread = this.handleLoadCollabThread.bind(this);
	}
	handleShow(e) {
		this.props.onToggleShow()
	}
	componentDidUpdate(prevProps) {
		if(Object.keys(prevProps.expandCollabQueryThread).length == 0 &&
			Object.keys(this.props.expandCollabQueryThread).length > 0) {
			let keys = Object.keys(this.props.expandCollabQueryThread);
			let info = this.props.expandCollabQueryThread[keys[0]];
			if(info.expand == true) {
				let queryId = "collab-query-"+info.queryId;
				const element = document.getElementById(queryId);
				if(typeof element !== 'undefined' && element != null) {
					element.scrollIntoView({block: 'center'});
				}
			}
		}
	}
	handleActionClick(action, html, queryID, answerID, threadID) {
		switch(action) {
			case 'resend':
				this.props.onReplyAction(html, queryID, answerID, threadID,
					this.props.eid);
				break;
			case 'reply':
				if(queryID) {
					this.props.onAnswerQuery(threadID, queryID);
				} else {
					this.props.onReplyAction(html, queryID, answerID, threadID,
						this.props.eid);
				}
				break;
			case 'print':
				this.props.onPrintAction(html, queryID, answerID, threadID,
					this.props.eid);
				break;
			case 'clone':
				this.props.onCopyCollaborationMessage(this.props.errandInputsAnswer + html,
					stripHTML(this.props.errandInputsAnswer) + html);
				break;
			case 'edit':
				this.props.onEditAction(queryID, answerID, threadID, this.props.eid);
				break;
		}
	}
	handleCreateNewQuery(threadID, queryID, answerID) {
		console.log('dbg: onclick create new',
			{threadID, queryID, answerID, errand: this.props.eid});
		this.props.onReplyAction('', queryID, answerID, threadID,
			this.props.eid);
	}
	handleCollapseCollabThread(id){
		this.props.onExpandAction(id, false);
	}
	handleLoadCollabThread(id, eid, read) {
		this.props.onLoadThread(id, eid, read);
		this.props.onExpandAction(id, true);
	}
	render() {
		const p = this.props;	
		if(p.noEE) {
			return null; // allow client message on almost same par as note
		}
		if(p.showCollaborate) {
			let collaborationClass = 'msg errand-message-group collaboration';
			const ready = p.ready;
			let m;
			if(!ready || !p.listReady) {
				if(!p.listReady) {
					m = <div className="message-group">
							<i className="fa fa-spinner fa-spin fa-3x"
								aria-hidden="true"></i>;
						</div>;
				} else {
					m = null;
				}
				return <div className={collaborationClass} ref={p.innerRef}>
						<ClickableTitle title={collaborationStr} left={true}
							ready={false} />
						{m}
					</div>;
			}
			const list = p.eeList;
			if(!list || !list.length) {
				return null;
			}
			m = [];
			// check if waiting is true
			let waitTitle = null, ptitle = [];
			each(p.eeSummary.targets, ({ name }) => {
				ptitle.push(name);
			});
			ptitle = ptitle.join(', ') + ` (${p.eeSummary.answers} messages)`;
			if(p.showCollaborate) {
				collaborationClass += ' collapsed';
			}
			each(p.eeList, (v, i) => {
				const id = v.id;
				if(v.type == 0) {
					if (p.expandCollabQueryThread[id] &&
					typeof p.expandCollabQueryThread[id].expand !== 'undefined' && p.expandCollabQueryThread[id].expand) {
						// message type
						const msgs = v.data.message;
						let ms = [], lastQuery;
						each(msgs, (w, j) => {
							let writer = {photo: ""}, ts = w.dateHuman, content;
							let recipients = {};
							if (w.htmlBody) {
								content = w.htmlBody;
							} else {
								content = w.body;
							}
							if(w.answer) {
								if(w.fromInternal) {
									writer.name = w.fromInternal.name;
									writer.photo = w.fromInternal.avatar;
								} else if(w.from) {
									writer.name = w.from.name;
									writer.photo = w.from.avatar;
								}
								if(w.to) {
									recipients.to = w.to;
									recipients.cc = w.cc;
								} else if(w.toInternals){
									let internalAgent = [];
									for(let key in w.toInternals) {
										internalAgent.push(w.toInternals[key]);
									}
									if(internalAgent.length > 0)
										recipients.toAgents = internalAgent.join(",");
								}
								ms.unshift(<CollaborateAnswer key={'answer'+w.id}
									recipients={recipients}
									attachments={w.attachmentList}
									onAttachmentInsert={p.onAnswerAttachmentInsert}
									html={content} timestamp={ts} writer={writer}
									threadListIndex={i} messagesIndex={j}
									threadID={id} answerID={w.id} channel='email'
									onActionClick={this.handleActionClick}
									canTranslate={p.canTranslate}
									interfaceLang={p.interfaceLang}
									onTranslation={p.onTranslation}
									showExactDayAndTime={p.showExactDayAndTime} />);
							} else {
								if(!lastQuery) {
									lastQuery = w.id;
								}
								if(w.from) {
									writer.name = w.from.name;
									writer.photo = w.from.avatar;
								}
								if(w.to) {
									recipients.to = w.to;
									recipients.cc = w.cc;
									recipients.bcc = w.bcc;
								}
								if(w.toInternals){
									let internalAgent = [];
									for(let key in w.toInternals) {
										internalAgent.push(w.toInternals[key]);
									}
									if(internalAgent.length > 0)
										recipients.toAgents = internalAgent.join(",");
								}
								ms.unshift(<CollaborateQuery key={'query'+w.id}
									recipients={recipients}
									attachments={w.attachmentList}
									html={content} timestamp={ts} writer={writer}
									threadListIndex={i} messagesIndex={j}
									threadID={id} queryID={w.id} notMine={p.notMine}
									onActionClick={this.handleActionClick}
									showExactDayAndTime={p.showExactDayAndTime} saved={w.saved} tagList={p.tagList}/>);
							}
						});
						if(msgs.length) {
							const lastMsg = msgs[msgs.length-1];
							ms.unshift(<CollaborateThreadHeader key={'hdr'+id} me={id}
								lastIsAnswer={lastMsg.answer} lastID={lastMsg.id}
								lastQuery={lastQuery} notMine={p.notMine}
								onAnswerQuery={p.onAnswerQuery}
								onPrint={p.onPrintThread}
								onCreateNew={this.handleCreateNewQuery}
								onToggle={this.handleCollapseCollabThread} />);
						}
						m.push(<CollaborateThread key={'brief'+id} me={id}
						onPrint={p.onPrintThread}
						onCreateNew={this.handleCreateNewQuery}
						onToggleCollapse={this.handleCollapseCollabThread}
						expand={false}
						isLoaded={true}
						data={v.header} onLoad={this.handleCollapseCollabThread} eid={p.eid}
						notMine={p.notMine} />);

						m = m.concat(ms);

						m.push(<HRLine key={'thread-end'+id} className={"thread-end"}/>);
					} else {
						// brief type after expand
						m.push(<CollaborateThread key={'brief'+id} me={id}
						data={v.header} onLoad={this.handleLoadCollabThread} eid={p.eid}
						notMine={p.notMine} />);
					}
				} else {
					// brief type before expand
						m.push(<CollaborateThread key={'brief'+id} me={id}
						data={v.data} onLoad={this.handleLoadCollabThread} eid={p.eid}
						notMine={p.notMine} />);
				}
			});
			if(p.eeSummary.answers < p.eeSummary.queries) {
				// Recipients in footer now showing all the To/Agents for all threads combined
				const targets = p.eeSummary.targets;
				let title;
				if(p.eeSummary.status) {
					title = p.eeSummary.status;
				} else {
					const t = I('Waiting for reply');
					title = `${t} (${p.eeSummary.answers}/${p.eeSummary.queries})`;
				}
				waitTitle = <div className="waiting-on-title">{title}</div>;
				m.unshift(<CollaborateWaitFooter key='eewait' title={title}
				targets={targets} />);
			}
			return <div className="collab-container">
				<div className={collaborationClass} ref={p.innerRef}>
					<div className="participants" title={ptitle}>{ptitle}</div>
					<div className="message-group">{m}</div>
				</div>
			</div>
		}else{
			return null;
		}
	}
}

export default MessageCollaboration;


export class DataCRMSystems extends  React.Component {
	constructor(props) {
		super(props);
	}
	IsJsonString = (str)=> {
		try {
				JSON.parse(str);
		} catch (e) {
				return false;
		}
		return true;
}
	render =()=>{
		const { crmDataSource } = this.props;
		let crmData = [];
		if(this.IsJsonString(JSON.stringify(crmDataSource))){
			Object.keys(crmDataSource).forEach( (key, i)=>{
				crmData.push(<div key={i}>
					<div><b>{key}:</b> {crmDataSource[key]}</div>
				</div>);
			});
		}else {
			//html
			crmData = `${crmDataSource}`;
		}
		return(<div>
						<span>List of CRM data</span>
						{crmData}
		</div>);
	}
};
