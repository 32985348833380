import React from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import {
	BackToList
	, HeaderActions
	, StandardAction
} from '../../reactcomponents/HeaderActions';
import Ckeditor from '../../components/Ckeditor';
import {
	ReplyFormCtnr,
	ScrollToBottomCtnr
} from './internalMsgCtnrs';
import {
	RPLY_INTERNAL_MESSAGE,
	BTN_SEND,
	BTN_CLEAR,
	BTN_SAVE,
	PF_TIMESTAMP,
	// UNKNOWN_DATE,
	ST_ACQUIRED,
	ST_ANSWERED
	// PF_HISTORY_CREATED
} from '../../common/v5/constants';
import { getChevronIcon } from '../../common/v5/helpers';
import { I, webRoot } from '../../common/v5/config';
import { Message } from '../../components/v5/Message';
import OneReplyNav from '../../reactcomponents/OneReplyNav';
import ScrollToBottom from '../errand.js';
import { WorkflowBreadcrumbsCtnr, WorkflowInnerContainerCtnr } from './workflowCtnr';

const DeleteAction = props => (
	<StandardAction {...props}
		text={I("Delete message")}
		icon="icon-trash"
		overflow={36}
	/>
);

const RestoreAction = props => (
	<StandardAction {...props}
		text={I("Restore message")}
		icon="icon-return"
		overflow={36}
	/>
);

class InternalMessageHeader extends React.Component {
	constructor(props) {
		super(props);
		// this.expandErrandMobileHeader = this.expandErrandMobileHeader.bind(this);
		this.openMoreActions = this.openMoreActions.bind(this);
		this.onDeleteMessages = this.onDeleteMessages.bind(this);
		this.onRestoreMessages = this.onRestoreMessages.bind(this);
		this.state = {
			moreNavActions: false,
			alreadyOpenExternal: false
		};
	}
	// expandErrandMobileHeader(){
	// 	const m = this.props.mobile;
	// 	if(m && m.viewErrand) {
	// 		this.props.onToggleMobileErrandHeader(!m.viewErrandHeader);
	// 	}
	// }
	openMoreActions(){
		this.setState({moreNavActions: !this.state.moreNavActions});
	}
	onDeleteMessages(){
		let ids = this.props.selectedMessages ? this.props.selectedMessages.join(",") : "";
		let selectedFoler = this.props.selectedFoler;
		this.props.onHandleDeleteMessage(ids, selectedFoler);
	}
	onRestoreMessages(){
		let ids = this.props.selectedMessages ? this.props.selectedMessages.join(",") : "";
		let selectedFoler = this.props.selectedFoler;
		this.props.onHandleRestoreMessage(ids, selectedFoler);
	}
	render() {
		const {
			// mobile,
			state,
			hide,
			subject,
			onCloseMessageView,
			mobile,
			collapseSideBar,
			showSideBar
		} = this.props;

		let customStyledAppHeader = { borderRadius: "initial"}

		if(state !== ST_ACQUIRED && state !== ST_ANSWERED) {
			if(hide){
				return <div className="app-header" style={customStyledAppHeader}>&nbsp;</div>;
			}
		}
		// let mobileClass = '';
		// if(mobile && mobile.viewErrandHeader) {
		// 	mobileClass = 'open';
		// }
		let actionButtons = [], actionRestoreButtons = [], k = 0;
		if (this.props.showBackToList) {
			actionButtons.push(
				<BackToList
					key={k++}
					className="pull-left"
					data-qa-id={this.props["data-qa-id"]+"back-to-list"}
					onClick={this.props.onBackToListView}
				/>
			);
		}
		//if in trash folder and message selected, show restore icon
		if (this.props.selectedFolder == 3) {
			actionButtons.push(
				<RestoreAction
					key={k++}
					data-qa-id={this.props["data-qa-id"]+"restore-msg"}
					onClick={this.onRestoreMessages}
					hide={mobile}
				/>
			);
		}
		if(mobile){
			actionButtons.push(
				<BackToList
					key={k++}
					className="pull-left transparent"
					data-qa-id={this.props["data-qa-id"]+"back-to-IM-list"}
					onClick={onCloseMessageView}
				/>
			)
		} else {
			actionButtons.push(
				<DeleteAction
					key={k++}
					data-qa-id={this.props["data-qa-id"]+"del-msg"}
					onClick={this.onDeleteMessages}
					className="transparent delete-im"
				/>
			);
		}
		return (
			<div className="app-header" style={customStyledAppHeader}>
				{/* <div id="mobile-errand-nav" onClick={() => {this.expandErrandMobileHeader()}}>
					<h3 className={mobileClass}>
						{subject}
					</h3>
				</div> */}
				{(collapseSideBar || !showSideBar) && <WorkflowBreadcrumbsCtnr /> }
				<HeaderActions
					className={classNames(
						"errand-nav"
						, {"more-open": this.state.moreNavActions}
					)}
				>
					{actionButtons}
				</HeaderActions>
			</div>
		);
	}
}

class InternalMessageBoxMessages extends React.Component {
	render() {
		const p = this.props;
		let htmlBody = p.message.htmlBody;
		if(htmlBody.length == 0){
			htmlBody = p.message.body;
		}
		let props = {
			type: "IM",
			status: I("Sent"),
			parseFormat: PF_TIMESTAMP,
			time: p.message.createdAt,
			html: "<div><b>" + p.message.subject + "</b></div>" + htmlBody,
			notErrand: true,
			photo: p.message.avatar,
			isAgent: false,
			showSize: 0,
			showAll: false,
			name: p.message.origin,
			time: p.message.createdAt,
			};

		//get receiver
		const selectedFolder = p.selectedFolder;
		const imIDSelected = p.mid;
		const agentList = p.agentList;
		const groupList = p.groupList;
		const msgListData = p.data;
		let agentNameText = ""
		let groupNameText = ""
		let toTextTo = ""
		if ((selectedFolder != 1) && (msgListData != null) && (imIDSelected!=0) && (msgListData[imIDSelected]!=undefined)) {
			const targets = msgListData[imIDSelected].targets;
			const groups = msgListData[imIDSelected].groups;
			let agentName = "", agentId = 0;
			let groupName = "", groupId = 0;

			//get each agent id from targets state
			$.each(targets, function(k,v) {
				agentId = v.id;
				//need to get agent name by id
				agentName = "";
				$.each(agentList, (i,v) => {
					if (v.Id == agentId) {
						agentName = v.Name;
						return false;
					}
				});
				if(agentName != ""){
					if (agentNameText  != "") {
						agentNameText += ","
					}
					agentNameText+=agentName;
				}
			});

			//get each group id from groups state
			$.each(groups, function(k,v) {
				groupId = v.id;
				//need to get group name by id
				groupName = "";
				$.each(groupList, (i,v) => {
					if (v.Id == groupId) {
						// groupId = id;
						groupName = v.Name;
						return false;
					}
				});
				if(groupName != ""){
					//to seperate agent and group
					if (agentNameText != "" && groupNameText == "") {
						groupNameText += ","
					} else {
						if (groupNameText  != "") {
							groupNameText += ","
						}
					}
					groupNameText+=groupName;
				}
			});
			agentNameText+=groupNameText
			toTextTo = " To:"
		}

		return (
			<div className="errand-box-messages" id="errand-box-messages" data-custom-scrollbar>
				<Message toText={toTextTo} toAgent={agentNameText} {...props} />
			</div>
		);
	}
}

const IMMessageBlock = ({
	children
	, className
	, innerRef
	, onClick
	, onScroll
	, atTop
}) => (
		<div
			ref={innerRef} className={className} onScroll={onScroll} onClick={onClick}>
				{children}
				<ScrollToBottomCtnr atTop={atTop}>
					<ScrollToBottom/>
				</ScrollToBottomCtnr>
		</div>
);


// const IMDataInProgress = () =>
// 	<h3>{I('Fetching current internal message data ...')}</h3>;

// function replaceEmptyArray(data) {
// 	if (!data || !data.length) {
// 		return emptyArray;
// 	}
// 	return data;
// }

function withScrollIntoQuestion(Component) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.innerRef = this.innerRef.bind(this);
			this.questionRef = this.questionRef.bind(this);
			this.handleScroll = this.handleScroll.bind(this);
		}
		executeAutoScroll() {
			let self = this;
			$('.'+this.props.className).imagesLoaded().always( function( instance ) {
				if(self.cRef != null){
					self.cRef.scrollTop = self.qref.offsetTop;
				}
			});
		}
		checkIfNeedScroll() {
			if (this.props.scrollToQuestion
				&& this.cRef
				&& this.qref
				&& this.cRef.scrollTop !== this.qref.offsetTop) {
					this.executeAutoScroll();
			}
		}
		componentDidMount() {
			this.checkIfNeedScroll();
		}
		componentDidUpdate(prevProps, prevState) {
			this.checkIfNeedScroll();
		}
		innerRef(ref) {
			this.cRef = ref;
		}
		questionRef(ref) {
			this.qref = ref;
		}
		handleScroll(e) {
			const {
					scrollToQuestion
					, onCancelScrollToQuestion
					, onScroll
					, ...props
				} = this.props
				;
			if (scrollToQuestion) {
				onCancelScrollToQuestion(scrollToQuestion);
			}
			if (typeof onScroll === "function") {
				onScroll(e);
			}
		}
		render() {
			const {
					scrollToQuestion
					, onCancelScrollToQuestion
					, onScroll
					, ...props
				} = this.props
				;
			return (
				<Component {...props}
					innerRef={this.innerRef}
					onScroll={this.handleScroll}
					questionRef={this.questionRef}
				/>
			);
		}
	}
}

const IMAnswerBase = ({
	photo
	, from
	, edit
	, print
	, clone
	, html
	, timestamp
	, truncateMessageByDefault
	, onSetMessageTruncatePref
	, ...props
}) => {
return (
	<Message
		photo={photo}
		isAgent={true}
		status={status}
		truncateText={true}
		name={(from ? from : from)}
		edit={edit}
		translate={false}
		time={timestamp}
		showSize={0}
		html={html}
		truncateErrandMessageByDefault={truncateMessageByDefault}
		onSetErrandMessageTruncatePref={onSetMessageTruncatePref}
		type={'CHAT'}
		{...props}
	/>
);
};

const IMAnswer = withPrintClone(IMAnswerBase);

function withPrintClone(Component) {
	return props => <Component {...props} clone={true} print={true} />;
}

const IMQuestionBase = ({
	photo
	, timestamp
	, canEditQuestion
	, innerRef
	, tags
	, myMessageId
	, html
	, hid
	, from
	, breakLine
	, truncateMessageByDefault
	, onSetMessageTruncatePref
	, myErrandId
	, print
	, clone
	, ...props
}) => {
	return (
		<Message
			photo={photo}
			innerRef={innerRef}
			isAgent={false}
			status={I('Sent')}
			truncateText={true}
			name={(from ? from : from)}
			tags={tags} hid={hid}
			translate={false}
			edit={canEditQuestion}
			myErrandId={myErrandId}
			time={timestamp}
			showSize={0}
			html={html}
			breakLine={breakLine}
			// breakLine={true}
			truncateErrandMessageByDefault={truncateMessageByDefault}
			onSetErrandMessageTruncatePref={onSetMessageTruncatePref}
			// onClickAgentAssistTrigger={onClickAgentAssistTrigger}
			// onSend={onSend}
			{...props}
		/>
	);
};

const IMQuestion = withPrintClone(IMQuestionBase);

const HistoryDataInProgress = () => <h3>{I('Fetching history data ...')}</h3>;

function withHistoriesBase(Component, QuestionComponent, AnswerComponent) {
	return ({
		historyReady
		, ...props
	}) => {
		const {
				canTranslate
				, truncateMessageByDefault
				, data
				, eid
				, interfaceLang
				, onTranslation
				, onSetMessageTruncatePref
				, fetchHistory
				, activeUserId
				, message
			} = props
			;
		let m = [];
		if (historyReady) {
			// if (!histories) {
			// 	return null;
			// }
			if (!fetchHistory || !fetchHistory.data || !fetchHistory.data.norm) {
				return null;
			}
			//for old record. doesn't have thread.
			if (Object.keys(fetchHistory.data.norm).length == 0) {
				let msg;
				if (message.htmlBody) {  // `v` is not defined here.
					msg = message.htmlBody;
				} else {
					msg = message.body;
				}
				if (activeUserId==message.originId) {
					m.push(<AnswerComponent
						key={'a'+message.mid}
						from={message.origin}
						photo={message.avatar}
						html={msg}
						parseFormat={PF_TIMESTAMP}
						timestamp={message.createdAt}
						delete={false} //history can't be deleted
						edit={false}
						print={false}
						clone={false}
						myErrandId={message.mid}
						eid={message.mid}
					/>);
				}
				else {
					m.push(<QuestionComponent
						key={'q'+message.mid}
						from={message.origin}
						photo={message.avatar}
						html={msg}
						parseFormat={PF_TIMESTAMP}
						timestamp={message.createdAt}
						myErrandId={message.mid}
						eid={message.mid}
						interfaceLang={interfaceLang}
						canTranslate={canTranslate}
						truncateMessageByDefault={truncateMessageByDefault}
						onSetMessageTruncatePref={onSetMessageTruncatePref}
						hid={message.mid}
						delete={false} //history can't be deleted
						edit={false}
						print={false}
						clone={false}
						onTranslation={onTranslation}
					/>);
				}
			}

			const { norm } = fetchHistory.data;
			$.each(norm, (i, v) => {
				let msg, from = v.origin;
				if (v.htmlBody) {
					msg = v.htmlBody;
				} else {
					msg = v.body;
				}
				if (activeUserId==v.originId) {
					m.push(<AnswerComponent
						key={'a'+v.mid}
						from={from}
						photo={v.avatar}
						html={msg}
						parseFormat={PF_TIMESTAMP}
						timestamp={v.createdAt}
						delete={false} //history can't be deleted
						edit={false}
						print={false}
						clone={false}
						myErrandId={v.mid}
						eid={eid}
					/>);
				}
				else {
					m.push(<QuestionComponent
						key={'q'+v.mid}
						from={from}
						photo={v.avatar}
						html={msg}
						parseFormat={PF_TIMESTAMP}
						timestamp={v.createdAt}
						myErrandId={v.mid}
						eid={eid}
						interfaceLang={interfaceLang}
						canTranslate={canTranslate}
						truncateMessageByDefault={truncateMessageByDefault}
						onSetMessageTruncatePref={onSetMessageTruncatePref}
						hid={v.displayId}
						delete={false} //history can't be deleted
						edit={false}
						print={false}
						clone={false}
						onTranslation={onTranslation}
					/>);
				}
			});
		} else {
			m.push(<HistoryDataInProgress key="fetching-history" />);
		}
		return <Component {...props}>{m}</Component>;
	};
}

function withHistories(Component) {
	return withHistoriesBase(Component, IMQuestion, IMAnswer);
}

function currentIMDataProp(Component) {
	return ({ data, ...props }) => <Component {...props} data={data.my} />;
}

const IMBoxMessagesBase = compose(
	withHistories
	, currentIMDataProp
	, withScrollIntoQuestion
)(IMMessageBlock);

function withIMMessageHandlers(Component) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleScroll = this.handleScroll.bind(this);
			this.state = {
				atTop: false
			}
		}
		handleScroll(e) {
			let scrollDown, scrollTop = e.target.scrollTop;
			if (this.props.isClosed) {
				this.props.onActivateView();
			}
			if (this.previousScroll) {
				scrollDown = scrollTop > this.previousScroll;
			} else {
				scrollDown = true;
			}
			this.previousScroll = scrollTop;
			// simple change 'scrollDown' below to 'scrollTop > 0' if only want
			// scroll to top to show attachment panel.
			// if (scrollTop > 0) {
			// 	this.props.onToggleAttachmentPanel(false, true);
			// } else {
			// 	this.props.onToggleAttachmentPanel(true);
			// }
			if(scrollTop) {
				let maxHeight = (e.target.scrollHeight-e.target.clientHeight-40)
				if(scrollTop <= maxHeight) {
					this.setState({atTop: true})
				}
				if(scrollTop >= maxHeight) {
					this.setState({atTop: false})
				}
			}
		}
		render() {
			const {
					extraClass
					, ...props
				} = this.props
				;
			return (
				<Component {...props}
					className={classNames("errand-box-messages", extraClass)}
					onScroll={this.handleScroll}
					atTop={this.state.atTop}
				/>
			);
		}
	}
}

const IMBoxOptionalMessages = withIMMessageHandlers(IMBoxMessagesBase);

const IMBoxMessages = props => (
	<IMBoxOptionalMessages {...props}
	/>
);

class ReplyOneButton extends React.Component {
	constructor(props) {
		super(props);
		this.handleButtonClick = this.handleButtonClick.bind(this);
	}
	handleButtonClick(e) {
		const p = this.props;
		p.onButtonClick(p.type, e);
	}
	render() {
		const p = this.props;
		let busy = null;
		if(p.sendBusy || p.saveBusy) {
			busy = <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>;
		}
		return <a data-qa-id={"im-reply-btn-"+p.text} href="#" onClick={this.handleButtonClick}
			 disabled={p.sendBusy} className={p.className}>{busy}{p.text}</a>;
	}
}

class ReplyButtons extends React.Component {
	render() {
		const p = this.props;
		let sendTxt, saveTxt;
		if(p.sendBusy) {
			sendTxt = I('Sending...');
		} else {
			sendTxt = I('Send');
		}
		if(p.saveBusy) {
			saveTxt = I('Saving...');
		} else {
			saveTxt = I('Save');
		}
		return <div className="button-row">
				<ReplyOneButton type={BTN_CLEAR} onButtonClick={p.onButtonClick}
					text={I('Clear')} className="btn-grey" />
				<ReplyOneButton type={BTN_SEND} onButtonClick={p.onButtonClick}
					text={sendTxt} sendBusy={p.sendBusy} className="btn-blue" />
				<ReplyOneButton type={BTN_SAVE} onButtonClick={p.onButtonClick}
					text={saveTxt} saveBusy={p.saveBusy} className="btn-blue" />
			</div>;
	}
}

const replyOptions = {
		id: RPLY_INTERNAL_MESSAGE,
		name: I('REPLY')
	};

export class ReplyForm extends React.Component {
	constructor(props) {
		super(props);
		this.handleAnswerSubject = this.handleAnswerSubject.bind(this);
		this.handleCkeditorChange = this.handleCkeditorChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleToggleRecipients = this.handleToggleRecipients.bind(this);
	}
	handleAnswerSubject(e) {
		this.props.onAnswerSubjectChange(e.target.value);
	}
	handleCkeditorChange(e) {
		const cke = e.editor;
		this.props.onAnswerChange(cke.getData(),
			cke.document.getBody().getText());
	}
	handleKeyDown() {
	}
	handleToggleRecipients() {
		this.props.onToggleRecipients(!this.props.showRecipients);
	}
	render() {
		const p = this.props, toolbarID = 'ckeditorimrTop',
			id = replyOptions.id;
		let fontFamily = '', fontSize = '12px', height = 120,
			areaArchive = [], replyFormClass,
			isComment, hide, showTextarea,
			subjectDOM = null, ckeClass, bgColor;
		let langs = (p.wfSettings.answerWysiwyg ? p.wfSettings.answerWysiwyg.languageSrc : []);
		if(!isComment) {
			hide = true;
		}
		if(p.show) {
			replyFormClass = 'reply-form opened';
		} else {
			replyFormClass = 'reply-form';
		}

		return <div className="errand-box-reply-forms">
				<div className={replyFormClass} id={id}>
					<div className="reply-editors">
						<div className="reply-editor active-editor">
							<textarea onChange={p.onInternalCommentChange}
									hidden={hide} value={p.comment}></textarea>
								<Ckeditor id={'ckeditorvimr5'}
									bgColor={bgColor}
									myClass={ckeClass}
									defaultFontFamily={fontFamily}
									toolbarID={toolbarID}
									defaultFontSize={fontSize}
									defaultContent={p.answer}
									spellLanguages={langs}
									height={height}
									simpleToolbar={false}
									simpleEditor={true}
									fileArchiveImages={areaArchive}
									onKeydown={this.handleKeyDown}
									onChange={this.handleCkeditorChange}
									hideToolbar={p.wfSettings.showReplyToolbar}
								/>
								<ReplyButtons onButtonClick={p.onButtonClick}
									sendBusy={p.sendBusy}
									saveBusy={p.saveBusy}
								/>
						</div>
					</div>
				</div>
			</div>;
	}
};

class InternalMessageBoxReply extends React.Component {
	render() {
		const p = this.props, {currentReply, showReply, showIMReply, 'data-qa-id': dataQAId } = p;
		return (
			<div className="errand-box-reply" hidden={!showIMReply}>
				<button
					data-qa-id={dataQAId}
					className="errand-box-reply-switcher"
					onClick={p.onToggleShow}
				>
					<i className={getChevronIcon(!showReply)} />
				</button>
				<OneReplyNav key={"one-reply-nav-im"}
					id={"im-one-reply"}
					data-qa-id={"im-one-reply-nav"}
					tag='a'
					name={replyOptions.name}
					onSelectReply={p.onSelectReply}
					disabled={false}
				/>
				<ReplyFormCtnr show={showReply} currentChecked={currentReply} />
			</div>
		);
	}
}

class InternalMessageContent extends React.Component {
	render() {
		const p = this.props, state = p.state.state;
		if(	p.mid===0) {
			let name;
			name = <h1>{p.name}</h1>;
			return <WorkflowInnerContainerCtnr className="app-inner-content">
					<div className="im-ready-text">{name}
						<h3 className="book">{I('No messages selected.')}</h3>
					</div>
				</WorkflowInnerContainerCtnr>;
		}
		// let mobileReply = null, mobileOption = null;

		let msgExtraClass = ""; //??? for what
		let isClosed = false;

		return <WorkflowInnerContainerCtnr className="app-inner-content">
			<div className="errand-box" hidden={p.hide}>
				{/* <InternalMessageBoxMessages message={p.message} mid={p.mid} data={p.data}
					historyReady={p.historyReady} constants={p.constants}
					agentList={p.agentList}
					groupList={p.groupList}
					selectedFolder={p.selectedFolder}
					imReady={p.imReady}/> */}
				<IMBoxMessages
					eid={p.mid}
					data={p.data}
					filterPopup={p.filterPopup}
					extraClass={msgExtraClass}//?
					associatedErrands={p.associatedErrands}
					historyReady={p.historyReady}
					constants={p.constants}
					imReady={p.imReady}
					truncateErrandMessageByDefault={p.truncateErrandMessageByDefault}
					isClosed={isClosed}//?
					onActivateView={this.props.onActivateView}
					onActionClick={this.handleMessageAction}
					onCancelScrollToQuestion={this.props.onCancelScrollToQuestion}
					scrollToQuestion={this.props.scrollToQuestion}
					forwardSelection={p.forwardSelection}
					onToggleAttachmentPanel={p.onToggleAttachmentPanel}
					onSelectForward={p.onSelectForward}
					agentCanDeleteFeature={p.agentCanDeleteFeature}
					agentCanEditQuestionFeature={p.agentCanEditQuestionFeature}
					onSetErrandMessageTruncatePref={p.onSetErrandMessageTruncatePref}
					// onClickAgentAssistTrigger={p.onClickAgentAssistTrigger}
					// onSend={p.onSend}
					interfaceLang={p.interfaceLang}
					canTranslate={p.canTranslate}
					onTranslation={p.onTranslation}
					// displayId={displayId}
					fetchHistory={p.fetchHistory}
					activeUserId={p.activeUserId}
					message={p.message}
				/>
				{/* {mobileReply}
				{mobileOption} */}
				<InternalMessageBoxReply data-qa-id={p["data-qa-id"]} currentReply={p.currentReply}
					showReply={p.showReply} onToggleShow={p.onToggleReply} showIMReply={p.showIMReply}
					onSelectReply={p.onSelectReply} state={state} />
			</div>
		</WorkflowInnerContainerCtnr>;
	}
}

export class InternalMessage extends React.Component {
	render() {
		const {
			eui,
			mobile,
			// visible,
			// fullView,
			filter,
			messageId,
			message,
			wfSettings,
			currentState,
			data,
			constants,
			onToggleReply,
			onSelectReply,
			showIMReply,
			// onToggleErrandOptionMobile,
			selectedMessages,
			totalSelected,
			onDeleteMessages,
			onRestoreMessages,
			agentList,
			groupList,
			historyReady,
			imReady,
			fetchHistory,
			collapseSideBar,
			showSideBar,
			handleCloseMessageView
		} = this.props;

		let hide, hideToolbar= true, myClass = 'app-content internal-message', subj = "";
		// if(mobile) {
		// 	if(!mobile.viewErrand) {
		// 		hide = true;
		// 	} else {
		// 		myClass += ' mobile';
		// 	}
		// }
		// if(!visible) {
		// 	hide = true;
		// }
		if(totalSelected > 0){
			hideToolbar = false;
		}
		// if(fullView) {
		// 	myClass += ' width-100';
		// }
		if(collapseSideBar) {
			myClass += ' collapsed';
		}
		if(typeof message !== "undefined"){
			subj = message.subject;
		}
		if(mobile) {
			if(totalSelected === 1 && messageId !== 0){
				hide = false;
				myClass += ' mobile show';
			} else {
				hide = true;
			}
		}
		return (
			<div className={myClass} hidden={hide}>
				<InternalMessageHeader
					data-qa-id={"im-msg-header"}
					subject={subj}
					hide={hideToolbar}
					state={currentState.state}
					selectedMessages={selectedMessages}
					selectedFolder={filter.selectedFolder}
					onHandleDeleteMessage={onDeleteMessages}
					onHandleRestoreMessage={onRestoreMessages}
					onCloseMessageView={handleCloseMessageView}
					mobile={mobile}
					collapseSideBar={collapseSideBar}
					showSideBar={showSideBar}
				/>
				<InternalMessageContent state={currentState}
					mid={messageId}
					message={message}
					data-qa-id={"im-msg-content"}
					name={wfSettings.activeUserName}
					activeUserId={wfSettings.activeUserId}
					showReply={eui.reply.show}
					currentReply={eui.reply.current}
					// mobile={mobile}
					data={data} constants={constants}
					onToggleReply={onToggleReply}
					onSelectReply={onSelectReply}
					showIMReply={showIMReply}
					agentList={agentList}
					groupList={groupList}
					selectedFolder={filter.selectedFolder}
					// onToggleErrandOptionMobile={onToggleErrandOptionMobile}
					historyReady={historyReady}
					imReady={imReady}
					fetchHistory={fetchHistory}
				/>
			</div>
		);
	}
}
