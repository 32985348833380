import React, {
    Fragment
    , PureComponent
    , useCallback
    , memo
    , useMemo
    , useEffect
    , useState
} from 'react';
import classNames from 'classnames';
import { renderFileActionBtns as renderActionButtons } from '../admin';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import { renderEditButton } from '../admin';
import { I, L, webRoot } from '../../../common/v5/config';
import {
    ListHeaderWrapper,
    ListContentWrapper,
    editorTitleByView,
    Delete as ActionDelete,
    Download as ActionDownload,
    Edit as ActionEdit,
    EditorHeader,
    AdminListAndEditLayout,
    withEditWrap,
} from '../../../reactcomponents/Admin';
import { TableIconicButton as Button, TextInputRow } from '../../../reactcomponents/Form';
import {
    ADMIN_TITLE,
    BTN_TXT_SAVE,
    BTN_TXT_SAVING,
    INPUT_EMPTY_WARNING,
    INPUT_SPACE_WARNING,
    M_CORS_WHITELIST,
} from '../../../common/v5/constants';
import withAccessibility from './accessibilityCtnr';
import { customPageTotal } from '../../../reactcomponents/Table';

const CORSAddForm = ({
	hidden
	, input
	, onChangeAdminInput
	, onSave
    , view
    , adminStatus
}) => {
    let buttonSaveTxt = BTN_TXT_SAVE, disableSave = false;
    if (adminStatus && adminStatus.status === 1) {
        buttonSaveTxt = BTN_TXT_SAVING;
        disableSave = true;
    }

    const handleChangeData = (e) =>{
		const { value, name } = e.target
		onChangeAdminInput(name, value);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let corsURL = input.corsUrl;
        if (input.corsUrl && input.corsUrl.endsWith("/")) {
            corsURL = input.corsUrl.slice(0, -1);
        }
        let param = { url: corsURL };
        onSave(view, param);
	}

	return (
		<form id="corsWhitelistEdit" onSubmit={handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
			<div className='input-section'>
                <TextInputRow
					id={"corsUrl"}
					name={"corsUrl"}
					className="admin-text-input"
					label={I("CORS whitelist URL")}
					value={input.corsUrl || ""}
					onChange={handleChangeData}
					mandatory={true}
					inlineLabel={false}
				/>
			</div>
            {/* {baseButtons} */}
            <div className='action-wrapper' style={{display:"flex", float:"right"}} >
                {/* <Button type="button" onClick={onCancel} className="btn-grey" title="Cancel" data-qa-id="btn-cancel-account" label={I("Cancel")}/> */}
                <Button type="submit" disabled={!input.corsUrl || disableSave} className="btn-blue save" title={buttonSaveTxt} data-qa-id="btn-add-account" label={buttonSaveTxt}/>
            </div>
		</form>
	)
}

const TableHeader = ({
	onHandleOpenNew,
	showInline,
	title,
}) => {
	return (
		<div className='admin-top-header'>
			<div className='upper-content'>
				<h2 className='title'>{L(title)}</h2>
				<div className='tab-options-wrapper'>
					<Button
						type='button'
						className={classNames("btn bg-transparent btn-round", { "active": showInline })}
						iconClass='icon-add'
						title={I('Create New')}
						onClick={onHandleOpenNew}
						hide={false}
						active={showInline}
					/>
				</div>
			</div>
		</div>
	)
};

const EditForm = withEditWrap(CORSAddForm);

const CorsBase = ({
    view
    , onLoad
    , showInline
    , list
    , onLoadToEdit
    , onChangeAdminInput
    , onSaveAdmin
    , onDeleteFromList
    , onCloseForm
    , activeId
    , input
    , adminStatus
}) => {
    useEffect(() => {
        if (onLoad) {
            onLoad(M_CORS_WHITELIST, false);
        }
    }, []);

    const handleDelete = (id) => {
        let confirmDelMsg = I('Are you sure you want to delete?');
        if (confirm(confirmDelMsg)) {
            onDeleteFromList(id, view);
        }
    }

    const actionDatas = {
        onDelete: handleDelete,
    }

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            hidden: true
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            isDummyField: true,
            dataField: 'action',
            text: 'Action',
            headerAlign: (column, colIndex) => 'right',
            align: (cell, row, rowIndex, colIndex) =>  'right',
            classes: 'right',
            formatter: (cell, row) => {
                return renderActionButtons(cell, row, actionDatas);
            },
        }
    ]

    const defaultSorted = [{
        dataField: 'createtime',
        order: 'desc'
    }];

    const isNew = activeId <= 0;

    const options = {
        paginationSize: 3,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '<<',
        prePageText: '<',
        nextPageText: '>',
        lastPageText: '>>',
        showTotal: true,
        paginationTotalRenderer: customPageTotal,
        disablePageTitle: true,
        sizePerPageList: [10]
    };

    let listSection = <Fragment>
        <ListHeaderWrapper>
             <TableHeader
                title={ADMIN_TITLE[view]}
                type='button'
                iconClass='icon-add'
                onHandleOpenNew={e => onLoadToEdit(0)}
                hide={false}
                showInline={showInline}
            />
        </ListHeaderWrapper>
        <ListContentWrapper className={"v5-table-style"}>
            <BootstrapTable
                keyField="id"
                data={list}
                noDataIndication={I("No data to display")}
                columns={columns}
                bordered={false}
                condensed={false}
                defaultSorted={defaultSorted}
                pagination={paginationFactory(options)}
                hover
            />
        </ListContentWrapper>
    </Fragment>;

    let editSection = <Fragment>
        <EditorHeader
            hidden={!showInline}
            isNew={isNew}
            onClickClose={onCloseForm}
            title={editorTitleByView(true, view)}
        />
        <EditForm
            activeId={activeId}
            input={input}
            view={view}
            onChangeAdminInput={onChangeAdminInput}
            onSave={onSaveAdmin}
            onCancel={onCloseForm}
            onDelete={handleDelete}
            isNew={true}
            hidden={!showInline}
            adminStatus={adminStatus}
        />
    </Fragment>;

    return (
        <AdminListAndEditLayout
            className={"cors-whitelist-page"}
            listSection={listSection}
            editSection={editSection}
        />
    );
}

const CORSwhiteList = withAccessibility(CorsBase);
export default CORSwhiteList;
