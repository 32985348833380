import shortid from 'shortid'
import update from 'immutability-helper'
import { $ } from '../../../common/globals'
import {
  deleteAdminChatbots,
  deleteAdminChatbotsIntents,
  getAdminChatbots,
  getAdminChatbotsIntents,
  getAdminOneChatbot,
  postAdminChatbots,
  postAdminChatbotsIntents,
  postAdminChatbotsQuery,
  postAdminChatbotsSync,
  postAdminChatbotsTrainingphrases
} from './ajax'
import {
  keyAdminChatbots,
  keyAdminChatbotsQuery,
  keyAdminDeleteChatbots,
  keyAdminDeleteChatbotsIntents,
  keyAdminEditChatbots,
  keyAdminEditChatbotsIntents,
  keyAdminGetChatbotsIntents,
  keyAdminGetOneChatbot,
  keyAdminSyncChatbot,
  keyEditChatbotsTrainingphrases
} from '../../constants/keys'
import {
  async,
  createBroadcaster,
  createDispatchLimiter,
  loadOnceCreator,
  multiAsync
} from '../../util'
import {
  EDIT_AGENT,
  EDIT_INTENT,
  TXT_DELETING,
  TXT_FETCHING_DATA,
  TXT_SAVING,
  TXT_SYNCING,
  TXT_TRAIN_CHATBOTS_PHRASE,
  createIntentTrainingPhrase,
  initAgent,
  initIntent,
  pathParams,
  toSelectChatbotPath
} from '../../../common/v5/chatbotConstants'
import { push } from '../../../common/v5/utils'
import { lastClientMessageMemo } from '../../selectors/chat'
import {
  adminAgentsState,
  adminAsyncMap,
  adminStateByKey,
  matchParamMemo
} from '../../selectors/chatbot'
import {
  addQuestion,
  updateAdminStartEdit,
  updateChatbotUIField
} from '../chatbot'
import { popError, wrapPopWait } from '../hmf'
import { YES_NO_BUTTONS, customConfirm } from './hmf'
import { onceAgents, onceKnowledgeBaseList } from './workflow'
import { adminActionStatus } from '../admin'

export const addPhrasesToChatbots = (
  libraryQuestionId,
  question,
  chatbots
) => dispatch => {
  if (libraryQuestionId <= 0 || !question) {
    console.log(
      'invalid condition to train chatbots:',
      libraryQuestionId,
      question,
      chatbots
    )
    return
  }
  const data = createIntentTrainingPhrase(question)
  data.libraryQuestionId = libraryQuestionId
  if (chatbots && chatbots.length) {
    data.chatbots = chatbots
  }
  return dispatch(multiAsync(
    postAdminChatbotsTrainingphrases(data),
    adminAsyncMap[keyEditChatbotsTrainingphrases]
  ))
}

const questionChatbotBase = (id, question) => dispatch => {
  if (!question) {
    question = question + ''
    const err = new Error(`invalid question for chatbot: ${id} ${question}`)
    console.log(err)
    return Promise.reject(err)
  }
  const data = { text: question }
  const qid = shortid.generate()
  dispatch(addQuestion({ id, qid, question }))
  return dispatch(multiAsync(
    postAdminChatbotsQuery(id, data),
    adminAsyncMap[keyAdminChatbotsQuery],
    { id, qid }
  ))
}

export const questionChatbot = (
  id,
  question
) => dispatch => dispatch(questionChatbotBase(id, question))
  .catch(err => dispatch(popError(err)))

const loadOnce = (key, ajax) => loadOnceCreator(
  adminAsyncMap,
  key,
  ajax,
  adminStateByKey
)

export const onceAdminChatbots = loadOnce(keyAdminChatbots, getAdminChatbots)

const onceOnloadBase = () => dispatch => {
  const dispatches = [
    dispatch(onceAgents()),
    dispatch(onceAdminChatbots()),
    dispatch(onceKnowledgeBaseList())
  ]
  return $.when(...dispatches)
}

export const onceOnload = createBroadcaster(onceOnloadBase)

const getUrlIds = (chatbotId, intentId) => {
  const urlIds = { id: chatbotId }
  if (typeof intentId !== 'undefined') {
    urlIds.iid = intentId
  }
  return urlIds
}

const chatbotIntentParam = (urlIds, keepPreviousFullView) => update(
  urlIds,
  { $merge: { keepPreviousFullView } }
)

const multiOrNotAsync = multi => multi ? multiAsync : async

const oneChatbotBase = (multi, forceReload, chatbotId) => (
  dispatch,
  getState
) => {
  // check before request
  const { byId } = adminAgentsState(getState())
  const agent = byId[chatbotId]
  if (!agent) {
    const err = new Error(`no valid agent data for ${chatbotId} when get agent`)
    console.log(err)
    return Promise.reject(err)
  }
  const { fullView } = agent
  if (!forceReload && fullView) {
    return Promise.resolve(fullView)
  }
  // can not find full view chatbot cache, so trigger the request
  return dispatch(multiOrNotAsync(multi)(
    getAdminOneChatbot(chatbotId),
    adminAsyncMap[keyAdminGetOneChatbot]
  ))
}

const oneChatbot = createBroadcaster(oneChatbotBase)

const oneIntentOrIntents = (multi, forceReload, chatbotId, intentId) => (
  dispatch,
  getState
) => {
  // check before request
  const { byId } = adminAgentsState(getState())
  const agent = byId[chatbotId]
  if (!agent) {
    const err = new Error(`no valid agent data for ${chatbotId} ${intentId}`)
    console.log(err)
    return Promise.reject(err)
  }
  const { intents } = agent
  if (!forceReload && intents) {
    if (typeof intentId === 'undefined') {
      return Promise.resolve(intents)
    }
    const intent = intents.byId[intentId]
    if (intent && intent.fullView) {
      return Promise.resolve(intent.fullView)
    }
  }
  if (typeof intentId === "undefined") {
    dispatch(updateChatbotUIField("filterType", 0))
    dispatch(updateChatbotUIField("searchText", ""))
  }
  // can not find cache, so trigger the request
  const urlIds = getUrlIds(chatbotId, intentId)
  return dispatch(multiOrNotAsync(multi)(
    getAdminChatbotsIntents(urlIds),
    adminAsyncMap[keyAdminGetChatbotsIntents],
    // NOTE: at the moment only delete use forceReload which can directly be
    // used to determine if full view should be kept or not. This may not be the
    // case when other code need force reload.
    chatbotIntentParam(urlIds, forceReload)
  ))
}

const noCancelIntent = (...args) => oneIntentOrIntents(true, false, ...args)

const fetchIntentBase = (chatbotId, intentId) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('dbg: fetch intent:', chatbotId, intentId)
  }
  return noCancelIntent(chatbotId, intentId)
}

export const fetchIntentDirect = (chatbotId, intentId) => (dispatch) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('dbg: fetch direct intent, no cache:', chatbotId, intentId)
  }
  const forceReload = false;
  const multi = false;
  const urlIds = getUrlIds(chatbotId, intentId)
  return dispatch(multiOrNotAsync(multi)(
    getAdminChatbotsIntents(urlIds),
    adminAsyncMap[keyAdminGetChatbotsIntents],
    chatbotIntentParam(urlIds, forceReload)
  ))
}


export const fetchIntent = createDispatchLimiter(fetchIntentBase, 3)

const oneChatbotIntentBase = (
  forceReload,
  chatbotId,
  ...args
) => oneIntentOrIntents(false, forceReload, chatbotId, ...args)

const intentsOrOneIntent = createBroadcaster(oneChatbotIntentBase)

const chatbotIntent = (
  forceReload,
  chatbotId,
  intentId
) => dispatch => dispatch(intentsOrOneIntent(forceReload, chatbotId))
  .then(data => {
    if (typeof intentId === 'undefined') {
      return data
    }
    return dispatch(intentsOrOneIntent(forceReload, chatbotId, intentId))
  })

const oneChatbotIntent = (
  chatbotId,
  intentId
) => dispatch => dispatch(onceOnload())
  .then(() => dispatch(chatbotIntent(false, chatbotId, intentId)))

export const chatbotIntents = wrapPopWait(TXT_FETCHING_DATA, oneChatbotIntent)

export const updateChatbotIntent = (chatbotId, intentId, data, completeData) => {
  const urlIds = getUrlIds(chatbotId, intentId)
  const param = { ...urlIds }
  if (completeData) {
    param.data = completeData
  }
  return async(
    postAdminChatbotsIntents(urlIds, data),
    adminAsyncMap[keyAdminEditChatbotsIntents],
    param
  )
}

const mutateIntent = ({ eid, iid, isNew }, changes, data) => {
  const completeData = data
  let intentId
  if (!isNew) {
    intentId = iid
    if (changes) {
      data = { data: changes.change, masks: changes.masks }
    }
  }
  return updateChatbotIntent(eid, intentId, data, completeData)
}

const updateChatbot = (id, data, updater) => {
  const param = { id, data }
  if (updater) {
    param.updater = updater
  }
  return async(
    postAdminChatbots(id, data),
    adminAsyncMap[keyAdminEditChatbots],
    param
  )
}

const mutateChatbot = ({ eid, isNew }, changes, data) => {
  let chatbotId
  let updater
  if (!isNew) {
    chatbotId = eid
    if (changes) {
      updater = changes.updater
      if (changes && !changes.welcome_greeting) {
        updater = { ...updater, welcome_greeting: data.welcome_greeting }
        changes = { ...changes, change: { ...changes.change, welcome_greeting: data.welcome_greeting } }
      }
      if (changes && !changes.enable_chat_trigger) {
        updater = { ...updater, enable_chat_trigger: data.enable_chat_trigger }
        changes = { ...changes, change: { ...changes.change, enable_chat_trigger: data.enable_chat_trigger } }
      }
      if (changes && !changes.enable_emoji) {
        updater = { ...updater, enable_emoji: data.enable_emoji }
        changes = { ...changes, change: { ...changes.change, enable_emoji: data.enable_emoji } }
      }
      if (changes && !changes.languages_supported) {
        updater = { ...updater, languages_supported: data.languages_supported }
        changes = { ...changes, change: { ...changes.change, languages_supported: data.languages_supported } }
      }
      data = changes.change
    }
  }
  return updateChatbot(chatbotId, data, updater)
}

const deleteChatbotBase = id => async(
  deleteAdminChatbots(id),
  adminAsyncMap[keyAdminDeleteChatbots],
  { id }
)

export const deleteChatbot = wrapPopWait(TXT_DELETING, deleteChatbotBase)

const deleteIntentPath = (id, iid, libraryQuestionId) => {
  const path = { id, iid }
  if (libraryQuestionId) {
    path.library = ['libraries', libraryQuestionId]
  }
  return path
}

const deleteIntentBase = (id, iid, libraryQuestionId) => async(
  deleteAdminChatbotsIntents(deleteIntentPath(id, iid, libraryQuestionId)),
  adminAsyncMap[keyAdminDeleteChatbotsIntents],
  { id, iid }
)

const deletingIntent = (
  id,
  iid,
  libraryQuestionId
) => dispatch => dispatch(deleteIntentBase(id, iid, libraryQuestionId))
  .then(() => dispatch(chatbotIntent(true, id)))

export const deleteIntent = wrapPopWait(TXT_DELETING, deletingIntent)

const adminEditBase = ({
  eid,
  iid,
  isNew,
  which
}) => dispatch => dispatch(onceOnload()).then(() => {
  if (isNew) {
    return
  } else if (which === EDIT_INTENT) {
    return dispatch(chatbotIntent(false, eid, iid))
  }
  return dispatch(oneChatbot(false, false, eid))
})

const adminEditing = info => (
  dispatch,
  getState
) => dispatch(adminEditBase(info)).then(() => {
  const { eid, iid, isNew, which } = info
  const { byId } = adminAgentsState(getState())
  let data
  if (which === EDIT_AGENT) {
    if (isNew) {
      data = initAgent
    } else {
      data = byId[eid].fullView
    }
  } else if (which === EDIT_INTENT) {
    if (isNew) {
      data = initIntent
    } else {
      data = byId[eid].intents.byId[iid].fullView
    }
  }
  if (typeof data === 'undefined') {
    return
  }
  return dispatch(updateAdminStartEdit({ data, which }))
})

export const adminEdit = wrapPopWait(TXT_FETCHING_DATA, adminEditing)

const saveBase = (info, changes, data) => {
  const { which } = info
  let mutator
  if (which === EDIT_AGENT) {
    mutator = mutateChatbot
  } else {
    mutator = mutateIntent
  }
  return mutator(info, changes, data)
}

const saving = (...args) => (
  dispatch,
  getState
) => {
  dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
  return dispatch(saveBase(...args)).then(() => {
      const match = matchParamMemo(getState());
      let selected;
      if (match && match.params) {
          selected = match.params.id;
      }
      dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
      return dispatch(push(toSelectChatbotPath(pathParams(selected))));
  });
};

export const save = wrapPopWait(TXT_SAVING, saving)

export const askTrainChatbotsWithLibraryQuestion = (
  libraryQuestionId,
  chatbots
) => (
  dispatch,
  getState
) => dispatch(customConfirm(
  TXT_TRAIN_CHATBOTS_PHRASE,
  YES_NO_BUTTONS,
  {
    selected: lastClientMessageMemo(getState()),
    libraryQuestionId
  }
)).then(({ data: { selected: { text }, libraryQuestionId } }) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`dbg: train chatbots lib-q-id: (${libraryQuestionId})`, text)
  }
  dispatch(addPhrasesToChatbots(libraryQuestionId, text, chatbots))
})

const syncChatbot = ({ eid }, data) => async(
  postAdminChatbotsSync(eid, data),
  adminAsyncMap[keyAdminSyncChatbot]
)

const saveChatbotOrSyncBase = (isSync, ...args) => {
  let mutator
  if (isSync) {
    mutator = syncChatbot
  } else {
    mutator = mutateChatbot
  }
  return mutator(...args)
}

const saveChatbotOrSync = (...args) => (
  dispatch,
  getState
) => {
  dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
  return dispatch(saveChatbotOrSyncBase(...args)).then(({ id, questions }) => {
    const match = matchParamMemo(getState());
    let selected;
    if (match && match.params && match.params.id) {
      if (!questions || id.toString() !== match.params.id.toString()) {
        selected = match.params.id;
      }
    }
    dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
    return dispatch(push(toSelectChatbotPath(pathParams(selected))));
  });
};


const savingChatbot = (...args) => saveChatbotOrSync(false, ...args)

export const saveChatbot = wrapPopWait(TXT_SAVING, savingChatbot)

const syncing = (...args) => saveChatbotOrSync(true, ...args)

export const sync = wrapPopWait(TXT_SYNCING, syncing)
