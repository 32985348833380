import pathToRegexp from 'path-to-regexp'
import { I } from './config'
import {
  AEO_ADD as _ADD,
  AEO_DELETE as _DELETE,
  AEO_EDIT as _EDIT,
  AEO_MANUAL as _MANUAL
} from '../constants'
import {
  ADMIN_CHATBOT_EDIT,
  ADMIN_CHATBOT_SELECT,
  ADMIN_CHATBOTS_INTENT
} from '../path'

export const PC_CHATBOT = true

export const P_AMAZON_LEX = 2
export const P_META_LLAMA = 4

export const EDIT_AGENT = 1
export const EDIT_INTENT = 2

// Admin Edit Operand
export const AEO_ADD = _ADD
export const AEO_DELETE = _DELETE
export const AEO_EDIT = _EDIT
export const AEO_MANUAL = _MANUAL

// i18n texts ------------------------------------------------------------------
export const TXT_ACTION = I('Action')
export const TXT_ADD = I('Add')
export const TXT_ADD_CUSTOM_RESPONSE = I('Add custom response')
export const TXT_ADD_TEXT_RESPONSE = I('Add text response')
export const TXT_AGENT = I('Agent')
export const TXT_CAN_NOT_SAVE = I("Can't save")
export const TXT_CHATBOT = I('Chat bot')
export const TXT_CHATBOTS = I('Chatbots')
export const TXT_CREATED_ON = I('Created on')
export const TXT_DELETING = I('deleting')
export const TXT_FETCHING_DATA = I('fetching data')
export const TXT_INTENT = I('Intent')
export const TXT_INTENTS = I('Intents')
export const TXT_LIBRARIES = I('Libraries')
export const TXT_NAME = I('Name')
export const TXT_NO_CHANGE_SAVE = I('No changes to save')
export const TXT_NO_RAW_DEBUG_INFO = I('No raw debug info')
export const TXT_OUTPUT_RESPONSE = I('Output response')
export const TXT_OUTPUT_TEXT = I('Output text')
export const TXT_RAW_DEBUG_INFO = I('Raw debug info')
export const TXT_SAVING = I('saving')
export const TXT_SYNCING = I('synchronizing')
export const TXT_TEST_PHRASE = I('Test phrase')
export const TXT_TEST_RESPONSE_RESULT = I('Test response result')
export const TXT_TRAINING_PHRASE = I('Training phrase')
export const TXT_TRAIN_CHATBOTS_PHRASE = I('Train chatbots with this phrase?')

export const CHATBOT_ROUTES = [
  ADMIN_CHATBOT_EDIT,
  ADMIN_CHATBOT_SELECT,
  ADMIN_CHATBOTS_INTENT
]

export const CHATBOT_ROUTES_MATCH = {
  exact: true,
  path: CHATBOT_ROUTES
}

export const FIELD_AGENT_DISPLAY_NAME = 'display_name'
export const FIELD_AGENT_LIBRARIES = 'libraries'
export const FIELD_AGENT_WELCOME_GREETING = 'welcome_greeting'
export const FIELD_AGENT_PERSONALITY = 'personality'
export const FIELD_AGENT_ENABLE_EMOJI = 'enable_emoji'
export const FIELD_AGENT_LANGUAGES_SUPPORTED = 'languages_supported'
export const FIELD_AGENT_ENABLE_CHAT_TRIGGER = 'enable_chat_trigger'
export const FIELD_AGENT_ACTIONS_FLOW = 'action_flow'
export const FIELD_AGENT_ACTIONS_TOOLS_OPTION = 'action_tools_option'
export const FIELD_INTENT_DISPLAY_NAME = FIELD_AGENT_DISPLAY_NAME
export const FIELD_INTENT_TRAINING_PHRASES = 'training_phrases'
export const FIELD_INTENT_MESSAGES = 'messages'
export const SUB_FIELD_INTENT_MESSAGES = FIELD_INTENT_MESSAGES

export const initAgent = { name: '' }

export const initTrainingPhrase = { name: '', phrase: '' }

export const initCustomResponse = { type: 1, [SUB_FIELD_INTENT_MESSAGES]: [''] }

export const initTextResponse = { [SUB_FIELD_INTENT_MESSAGES]: [''] }

export const initIntent = {
  [FIELD_INTENT_DISPLAY_NAME]: 'New intent',
  name: '',
  [FIELD_INTENT_TRAINING_PHRASES]: [initTrainingPhrase],
  [FIELD_INTENT_MESSAGES]: [initTextResponse]
}

export const createIntentTrainingPhrase = phrase => ({
  [FIELD_INTENT_TRAINING_PHRASES]: [{ phrase }]
})

export const pathParams = (id, eid, iid) => {
  const result = {}
  if (id) {
    result.id = id
  }
  if (iid) {
    result.iid = iid
    result.eid = eid
  } else if (eid) {
    result.eid = eid
  }
  return result
}

export const toSelectChatbotPath = pathToRegexp.compile(ADMIN_CHATBOT_SELECT)
