import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { I, L } from '../common/v5/config'
import { withMountWhenShow, withUnmountWhenHidden } from './hocs'

const SidepanelTriggerButton = ({
  className,
  onClick,
  icon,
  title,
  text
}) => (
  <button
    className={classNames('side-button', className)}
    onClick={onClick}
    title={L(title)}
  >
    <i className={icon} />
    <span>{L(text)}</span>
  </button>
)

const SidepanelTriggerButtonWithPin = ({
  className,
  onClick,
  icon,
  title,
  text,
  isShow = false,
  pinIcon = "",
  pinTitle = "",
  pinned = false,
  onPinned
}) => (
  <button
    className={classNames('side-button', className)}
  >
    <i className={icon} onClick={onClick} title={L(title)}/>
    <span onClick={onClick} title={L(title)}>{L(text)}</span>
    {
      isShow && pinIcon &&
      <i className={pinIcon + (pinned ? ' active': "")} onClick={() => onPinned(!pinned)} title={pinTitle} />
    }
  </button>
)

export const SidepanelTriggerBtn = withUnmountWhenHidden(SidepanelTriggerButton)

export const InternalCommentTriggerButton = ({ hidden, onClick }) => (
  <SidepanelTriggerBtn
    className='internal-comment'
    hidden={hidden}
    onClick={onClick}
    title={I('Toggle Internal Comment sidepanel')}
    icon='icon-internal-comment-fill'
    text={I('Internal Comment')}
  />
)

export const CollaborationTriggerButton = ({ hidden, onClick }) => (
  <SidepanelTriggerBtn
    className='collaboration'
    hidden={hidden}
    onClick={onClick}
    title={I('Toggle Collaboration sidepanel')}
    icon={'icon-collaboration-fill'}
    text={I('Collaboration')}
  />
)
export const CRMIntegrationTriggerButton = ({ hidden, onClick }) => (
  <SidepanelTriggerBtn
    className='crmEmailTrigger'
    hidden={hidden}
    onClick={onClick}
    title={I('Toggle CRM System sidepanel')}
    icon={'icon-webform'}
    text={I('CRM System')}
  />
)

export const AgentAssistTriggerButton = ({ isShow, onClick, pinned, onPinned }) => (
  <SidepanelTriggerButtonWithPin
    className='agent-assist'
    onClick={onClick}
    title={I('Toggle Agent Assist sidepanel')}
    icon={'icon-chatbot'}
    text={I('Agent Assist')}
    isShow={isShow}
    pinIcon={'icon-pin'}
    pinTitle={I("Always show Agent Assist panel")}
    pinned={pinned}
    onPinned={onPinned}
  />
)

const rightActionDiv = {
  position: 'absolute',
  right: '0px'
}

export const RightSidePanelHeader = ({
  headingTitle,
  showActions = true,
  showMaximize, showMinimize,
  onMaximizePanel,
  onMinimizePanel,
  onClosePanel,
  showExpand,
  isExpanded,
  onExpandPanel,
  showBackToButton,
  onClickBack,
}) => {
  const panelHeaderStyle = {
    width: '100%',
    padding: '14px 16px',
    gap: '16px',
  }
  return (
    <div className='panel-header' style={panelHeaderStyle}>
      {
        showBackToButton &&
        <span className='icon icon-chevron-left' style={{ margin: '5px', cursor: 'pointer' }} onClick={onClickBack}></span>
      }
      <span className='heading' style={{ margin: '10px' }}>{headingTitle}</span>
      {
        showActions &&
        <div className='panel-actions right' style={rightActionDiv}>
          <span className='panel-act-maximize' hidden={!showMaximize} style={{ margin: '5px' }} onClick={onMaximizePanel}>
            <i className='icon icon-fullscreen' />
            </span>
            <span className='panel-act-maximize' hidden={!showMinimize} style={{ margin: '5px' }} onClick={onMinimizePanel}>
              <i className='icon icon-fullscreen-end' />
            </span>
            {
              showExpand &&
              <Fragment>
                <span className='panel-act-expand' hidden={isExpanded} style={{ margin: '5px' }} onClick={() => onExpandPanel(true)}>
                  <i className='icon icon-expand' />
                </span>
                <span className='panel-act-expand' hidden={!isExpanded} style={{ margin: '5px' }} onClick={() => onExpandPanel(false)}>
                  <i className='icon icon-collapse' />
                </span>
              </Fragment>
            }
            <span className='panel-act-close' style={{ margin: '5px' }} onClick={onClosePanel}>
              <i className='icon icon-times' />
          </span>
        </div>
      }
    </div>
  )
}

export const RightSidepanelBase = ({
  className,
  children,
  headingTitle,
  hideDefaultHeader = false,
  showMaximize = false,
  showExpand = false,
  onClosePanel,
  onMaximizePanel,
  onExpandPanel,
  isExpanded,
  showBackToButton,
  onClickBack
}) => (
  <div className={classNames('right-sidepanel', className)}>
    {
      !hideDefaultHeader &&
      <RightSidePanelHeader
        headingTitle={headingTitle}
        showMaximize={showMaximize}
        onClosePanel={onClosePanel}
        onMaximizePanel={onMaximizePanel}
        showExpand={showExpand}
        onExpandPanel={onExpandPanel}
        isExpanded={isExpanded}
        showBackToButton={showBackToButton}
        onClickBack={onClickBack}
      />
    }
    <div className='panel-body'>{children}</div>
  </div>
)

const RIGHTSIDE_PANEL_WIDTH = '340px';

const StyledRightSidepanel = styled(RightSidepanelBase)`
  &.right-sidepanel {
    height: 100%;
    width: ${RIGHTSIDE_PANEL_WIDTH};
    position: relative;
    // background-color: white;
    border-left: 1px solid #ccc;
    // SECTION: header
    .panel-header {
      height: 40px;
      background-color: #f2f2f2;
      display: flex;
      align-items: center;
      color: #6d6d6d;
      font-weight: 700;
      border-bottom: 1px solid #e5e5e5;
      .panel-actions {
        cursor: pointer;
        > i {
          margin: 8px 12px;
        }
      }
    }
    // SECTION: body
    .panel-body {
      height: calc(100% - 40px);
      padding-bottom: 60px;
    }
  }
`

export const RightSidepanel = withMountWhenShow(StyledRightSidepanel)

export const CollaborationRightSidepanelBase = ({
  children,
  className,
  onClosePanel,
  show,
  onExpandCollaboration
}) => (
  <RightSidepanel
    className={classNames('collaboration', className)}
    onClosePanel={onClosePanel}
    headingTitle={I('Collaboration')}
    show={show}
  >
    <span id="expand-collaboration" className="icon icon-chevron-left collaboration-left" onClick={onExpandCollaboration}></span>
    {children}
  </RightSidepanel>
)

export const CollaborationRightSidepanel = styled(CollaborationRightSidepanelBase)`
  &.collaboration.right-sidepanel {
    &.active{
      // padding: 0 18px;
      width: 95% !important;
      position: absolute;
      right: 0;
      // background-color: #f1f1f1;
      &>.panel-body .collab-container .errand-message-group.collaboration .message-group .icPanelMsg.collab-query{
        .truncate-text{
          overflow-x: auto;
        }
        .icPanelInfo .icPanelPhoto{
          width: 45px;
        }
      }
      .collaboration-left{
        right: 101%;
      }
    }
    transition: width 0.4s ease-out;
    &>.panel-body .collab-container .errand-message-group.collaboration .message-group .icPanelMsg.collab-query .truncate-text{
      overflow-x: hidden;
    }
    .collaboration-left{
      position: absolute;
      top: 50%;
      right: 350px;
      border: 1px solid;
      border-radius: 15px;
      font-size: 12px;
      padding: 2px;
      background: #359cf8;
      color: white;
      text-align: center;
      cursor: pointer;
      transition: right 0.3s ease-out;
      &:before{
        margin-right: 1px;
      }
    }
    .panel-header {
      // background-color: #e0f3fe
    }
    .panel-body {
      .msg.errand-message-group {
        width: 100%;
        margin: 0;
      }
      .collab-container {
        padding-bottom: 80px;
        .errand-message-group.collaboration {
          width: 100%;
          margin: 0;
          padding: 0;
          .participants {
            color: #909395;
            font-weight: 600;
            overflow: hidden;
            vertical-align: bottom;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            // background-color: #eaf8fe;
            font-size: 11px;
            padding: 5px 10px;
            margin: 15px 0;
          }
          .message-group {
            padding:0;
            // background-color: white;
            .waiting-on-message {
              margin: 10px 0;
              &.ee-thread {
                display: flex;
                padding: 0 10px;
                margin: 8px 0 0 0;
                .expand-ee-thread {
                  width: 100%;
                  // width: calc(100% - 50px);
                  display: flex;
                  align-items: center;
                  height: 30px;
                  justify-content: flex-start;
                  position: relative;
                  // padding: 0 10px;
                  .thread-id {
                    max-width: 25%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: flex;
                    span {
                      background-color: #0c87f7;
                      padding: 2px 8px;
                      color: white;
                      border-radius: 9px;
                      max-width: unset;
                    }
                  }
                  h3 {
                    line-height: unset;
                    max-width: 25%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    padding-left: 5px;
                    font-size: 12px;
                  }
                  .thread-subject {
                    // max-width: 45%;
                    max-width: unset;
                    width: auto;
                    margin-right: 18px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-left: 5px;
                    font-size: 12px;
                  }
                  .icon-container {
                    position: absolute;
                    right: 0;
                    width: 12px;
                  }
                }
              }
              .thread-actions {
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                > label {
                  margin-right: 5px;
                }
              }
            }
            .thread-header {
              width: 100%;
              padding: 0 10px;
              .icon-wrapper {
                display: flex;
                justify-content: flex-end;
                padding: 4px 16px;
                i {
                  margin: 0 4px ;
                  color: #b4bcc0;
                  cursor: pointer;
                }
              }
            }
            .block_line_collaborate-thread-header {
              span {
                &.icon-wrapper {
                }
              }
            }
            .icPanelMsg {
              &.collab-query {
                margin: 10px 15px;
              }
              &.collab-answer {
                // background-color: #e5ecf8;
                margin: 10px 15px;
              }
              .meta-wrapper {
                display: flex;
                .errand-message-tags {
                  margin-left: 10px;
                  > i {
                    font-size: 11px;
                  }
                  .tooltip-content {
                    transform: translate(-150px, -20px);
                    z-index: 100;
                    &:before {
                      left: unset;
                      right: 14px;
                      // border-left: 6px solid transparent;
                      // border-right: 6px solid transparent;
                      border: 0;
                    }
                  }
                }
               .errand-message-tag {
                  margin-left: 10px;
                  margin-right: 10px;
                  > i {
                    font-size: 11px;
                  }

                  .errand-message-tags-counter {
                    font-size: 7px;
                    font-weight: bold;
                    color:rgb(6, 119, 224);
                    position: absolute;
                  }

                  .tooltip-content {
                    transform: translate(-100px, -20px);
                    z-index: 100;
                    &:before {
                      left: unset;
                      right: 14px;
                      // border-left: 6px solid transparent;
                      // border-right: 6px solid transparent;
                      border: 0;
                    }

                    ul {
                      list-style: none;
                      padding: 0;
                      margin: 0;

                      li {
                        font-size: 12px;
                        color: #555;
                        padding: 2px 0;
                        display: flex;
                        align-items: center;

                        &:before {
                          content: '•';
                          font-size: 12px;
                          color: #999;
                          margin-right: 5px;
                        }
                      }
                    }
                  }

                  &:hover .tooltip-content {
                    display: block;
                  }
                }

            }
            .thread-end {
              border-width: 3px;
            }
          }
        }
      }
    }
  }
`
export const CRMRightSidepanelBase = ({
  children,
  className,
  onClosePanel,
  show,
  onExpandCRM
}) => (
  <RightSidepanel
    className={classNames('crmEmailTrigger', className)}
    onClosePanel={onClosePanel}
    headingTitle={I('CRM System data')}
    show={show}
  >
    <span id="expand-crmEmailTrigger" className="icon icon-chevron-left crmEmailTrigger-left" onClick={onExpandCRM}></span>
    {children}
  </RightSidepanel>
)

export const CRMIntegrationRightSidepanel = styled(CRMRightSidepanelBase)`
  &.crmEmailTrigger.right-sidepanel {
    &.active{
      // padding: 0 18px;
      width: 95% !important;
      position: absolute;
      right: 0;
      // background-color: #f1f1f1;
      &>.panel-body .collab-container .errand-message-group.crmEmailTrigger .message-group .icPanelMsg.collab-query{
        .truncate-text{
          overflow-x: auto;
        }
        .icPanelInfo .icPanelPhoto{
          width: 45px;
        }
      }
      .crmEmailTrigger-left{
        right: 101%;
      }
    }
    transition: width 0.4s ease-out;
    &>.panel-body .collab-container .errand-message-group.crmEmailTrigger .message-group .icPanelMsg.collab-query .truncate-text{
      overflow-x: hidden;
    }
    .crmEmailTrigger-left{
      position: absolute;
      top: 50%;
      right: 350px;
      border: 1px solid;
      border-radius: 15px;
      font-size: 12px;
      padding: 2px;
      background: #359cf8;
      color: white;
      text-align: center;
      cursor: pointer;
      transition: right 0.3s ease-out;
      &:before{
        margin-right: 1px;
      }
    }
    .panel-header {
      background-color: #e0f3fe
    }
    .panel-body {
      .msg.errand-message-group {
        width: 100%;
        margin: 0;
      }
      .collab-container {
        padding-bottom: 80px;
        .errand-message-group.crmEmailTrigger {
          width: 100%;
          margin: 0;
          padding: 0;
          .participants {
            color: #909395;
            font-weight: 600;
            overflow: hidden;
            vertical-align: bottom;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            // background-color: #eaf8fe;
            font-size: 11px;
            padding: 5px 10px;
            margin: 15px 0;
          }
          .message-group {
            padding:0;
            // background-color: white;
            .waiting-on-message {
              margin: 10px 0;
              &.ee-thread {
                display: flex;
                padding: 0 10px;
                margin: 8px 0 0 0;
                .expand-ee-thread {
                  width: 100%;
                  // width: calc(100% - 50px);
                  display: flex;
                  align-items: center;
                  height: 30px;
                  justify-content: flex-start;
                  position: relative;
                  // padding: 0 10px;
                  .thread-id {
                    max-width: 25%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: flex;
                    span {
                      background-color: #0c87f7;
                      padding: 2px 8px;
                      color: white;
                      border-radius: 9px;
                      max-width: unset;
                    }
                  }
                  h3 {
                    line-height: unset;
                    max-width: 25%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    padding-left: 5px;
                    font-size: 12px;
                  }
                  .thread-subject {
                    // max-width: 45%;
                    max-width: unset;
                    width: auto;
                    margin-right: 18px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-left: 5px;
                    font-size: 12px;
                  }
                  .icon-container {
                    position: absolute;
                    right: 0;
                    width: 12px;
                  }
                }
              }
              .thread-actions {
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                > label {
                  margin-right: 5px;
                }
              }
            }
            .thread-header {
              width: 100%;
              padding: 0 10px;
              .icon-wrapper {
                display: flex;
                justify-content: flex-end;
                padding: 4px 16px;
                i {
                  margin: 0 4px ;
                  color: #b4bcc0;
                  cursor: pointer;
                }
              }
            }
            .block_line_collaborate-thread-header {
              span {
                &.icon-wrapper {
                }
              }
            }
            .icPanelMsg {
              &.collab-query {
                background-color: #e0f3fe;
                margin: 10px 15px;
              }
              &.collab-answer {
                // background-color: #e5ecf8;
                background-color: #e5ecf8;
                margin: 10px 15px;
              }
              .meta-wrapper {
                display: flex;
                .errand-message-tags {
                  margin-left: 10px;
                  > i {
                    font-size: 11px;
                  }
                  .tooltip-content {
                    transform: translate(-150px, -20px);
                    z-index: 100;
                    &:before {
                      left: unset;
                      right: 14px;
                      // border-left: 6px solid transparent;
                      // border-right: 6px solid transparent;
                      border: 0;
                    }
                  }
                }
              }
            }
            .thread-end {
              border-width: 3px;
            }
          }
        }
      }
    }
  }
`
const StyledDiv = styled.div`
  .icPanel {
    float: right;
    width: $icPanelWidth;
    background-color: #fff;
    position: absolute;
    right: 0px;
    box-shadow: -2px 3px 11px -5px #ccc;
    z-index: 2;
    &.chat {
      position: fixed;
      right: 30px;
    }
    .icPanelHeader {
      background-color: #fff9ec;
      padding: 15px;
      font-size: 12px;
      font-weight: 500;
      .icPanelHeaderClose {
        margin-right: 10px;
        cursor: pointer;
        i {
          font-weight: 800;
          font-size: 8px;
        }
      }
    }
    .icPanelBody {
      overflow: auto;
      padding-bottom: 10px;
      .icPanelMsg {
        margin: 10px;
        padding: 10px;
        box-shadow: 2px 3px 5px 1px #ccc;
        border-radius: 3px;
        .icPanelInfo {
          display: inline-flex;
          font-size: 12px;
          width: 100%;
          .icPanelPhoto {
            width: 13%;
            .icon-agent {
              width: 30px;
            }
            figure {
              width: 30px;
              img {
                padding-top: 10px;
              }
            }
          }
          .icPanelNameWrapper {
            width: 67%;
            display: block;
            padding-top: 10px;
          }
          .icPanelName {
            font-weight: 500;
          }
          .icPanelEdit {
            float: right;
            width: 20%;
            font-size: 10px;
            color: #eaeaea;
            padding-top: 10px;
            .icBtnEdit {
              border: 1px solid #eaeaea;
              padding: 3px 5px;
              border-radius: 5px;
              text-transform: uppercase;
              cursor: pointer;
              i {
                margin-right: 5px;
                font-size: 13px;
                vertical-align: top;
              }
              &.active {
                border: 1px solid #0c87f7;
                background: #e0f3ff;
                color: #0c87f7;
                font-weight: 500;
              }
            }
          }
          .icPanelTime {

          }
        }
        .text {
          div {
            font-size: 10px;
          }
        }
        .errand-message-actions {
          margin-left: 0px;
          width: 100%;
          text-align: right;
          &-links {
            right: 0px;
          }
        }
        &:last-child {
          .errand-message-actions {
            &-links {
              right: 0px;
              top: -65px;
            }
          }
        }
        .errand-message-attachment {
          font-size: 11px;
          .errand-attachment-list-tag {
            max-width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
`

export const AIAnswerRightSidepanelBase = ({
  children,
  className,
  onClosePanel,
  show,
  onExpandAIAnswer,
  onMaximizePanel
}) => (
  <RightSidepanel
    className={classNames('ai-answer', className)}
    onClosePanel={onClosePanel}
    headingTitle={I('Agent assist')}
    show={show}
    showMaximize={true}
    onMaximizePanel={onMaximizePanel}
  >
    <span id="expand-ai-answer" hidden={true} className="icon icon-chevron-left ai-answer-left" onClick={onExpandAIAnswer}></span>
    {children}
  </RightSidepanel>
)

export const AIAnswerRightSidepanel = styled(AIAnswerRightSidepanelBase)`
  &.ai-answer.right-sidepanel {
    z-index: 100;
    &.active{
      width: 95% !important;
      position: absolute;
      right: 0;
      &>.panel-body .collab-container .errand-message-group.collaboration .message-group .icPanelMsg.collab-query{
        .truncate-text{
          overflow-x: auto;
        }
        .icPanelInfo .icPanelPhoto{
          width: 45px;
        }
      }
      .collaboration-left{
        right: 101%;
      }
    }
    transition: width 0.4s ease-out;
    &>.panel-body .collab-container .errand-message-group.collaboration .message-group .icPanelMsg.collab-query .truncate-text{
      overflow-x: hidden;
    }
    .ai-answer-left{
      position: absolute;
      top: 50%;
      right: 350px;
      border: 1px solid;
      border-radius: 15px;
      font-size: 12px;
      padding: 2px;
      background: #359cf8;
      color: white;
      text-align: center;
      cursor: pointer;
      transition: right 0.3s ease-out;
      &:before{
        margin-right: 1px;
      }
    }
    .panel-header {
      background-color: #EC6A52;
      color: #FFFFFF;
    }
    .panel-body {
      .msg.errand-message-group {
        width: 100%;
        margin: 0;
      }
      .collab-container {
        padding-bottom: 80px;
        .errand-message-group.collaboration {
          width: 100%;
          margin: 0;
          padding: 0;
          .participants {
            color: #909395;
            font-weight: 600;
            overflow: hidden;
            vertical-align: bottom;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            // background-color: #eaf8fe;
            font-size: 11px;
            padding: 5px 10px;
            margin: 15px 0;
          }
          .message-group {
            padding:0;
            // background-color: white;
            .waiting-on-message {
              margin: 10px 0;
              &.ee-thread {
                display: flex;
                padding: 0 10px;
                margin: 8px 0 0 0;
                .expand-ee-thread {
                  width: 100%;
                  // width: calc(100% - 50px);
                  display: flex;
                  align-items: center;
                  height: 30px;
                  justify-content: flex-start;
                  position: relative;
                  // padding: 0 10px;
                  .thread-id {
                    max-width: 25%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: flex;
                    span {
                      background-color: #0c87f7;
                      padding: 2px 8px;
                      color: white;
                      border-radius: 9px;
                      max-width: unset;
                    }
                  }
                  h3 {
                    line-height: unset;
                    max-width: 25%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    padding-left: 5px;
                    font-size: 12px;
                  }
                  .thread-subject {
                    // max-width: 45%;
                    max-width: unset;
                    width: auto;
                    margin-right: 18px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-left: 5px;
                    font-size: 12px;
                  }
                  .icon-container {
                    position: absolute;
                    right: 0;
                    width: 12px;
                  }
                }
              }
              .thread-actions {
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                > label {
                  margin-right: 5px;
                }
              }
            }
            .thread-header {
              width: 100%;
              padding: 0 10px;
              .icon-wrapper {
                display: flex;
                justify-content: flex-end;
                padding: 4px 16px;
                i {
                  margin: 0 4px ;
                  color: #b4bcc0;
                  cursor: pointer;
                }
              }
            }
            .block_line_collaborate-thread-header {
              span {
                &.icon-wrapper {
                }
              }
            }
            .icPanelMsg {
              &.collab-query {
                margin: 10px 15px;
              }
              &.collab-answer {
                // background-color: #e5ecf8;
                margin: 10px 15px;
              }
              .meta-wrapper {
                display: flex;
                .errand-message-tags {
                  margin-left: 10px;
                  > i {
                    font-size: 11px;
                  }
                  .tooltip-content {
                    transform: translate(-150px, -20px);
                    z-index: 100;
                    &:before {
                      left: unset;
                      right: 14px;
                      // border-left: 6px solid transparent;
                      // border-right: 6px solid transparent;
                      border: 0;
                    }
                  }
                }
              }
            }
            .thread-end {
              border-width: 3px;
            }
          }
        }
      }
    }
  }
`

const HideableDiv = withUnmountWhenHidden(StyledDiv)

export const PanelWrapper = ({ children, className, hidden }) => (
  <HideableDiv className={classNames('icPanel', className)} hidden={hidden}>
    {children}
  </HideableDiv>
)

export const ChatPanelWrapper = props => (
  <PanelWrapper className='chat' {...props} />
)

const KBLibraryRightSidepanelBase = ({
  children,
  className,
  onClosePanel,
  show,
  onExpandKBLibrary,
  showBackToButton,
  onBackToMainKBList,
  isExpanded,
}) => (
  <RightSidepanel
    className={classNames('kb-library', className)}
    onClosePanel={onClosePanel}
    headingTitle={I('Knowledge Bases')}
    showBackToButton={showBackToButton}
    onClickBack={onBackToMainKBList}
    hideDefaultHeader={false}
    show={show}
    showExpand={true}
    onExpandPanel={onExpandKBLibrary}
    isExpanded={isExpanded}
  >
    {children}
  </RightSidepanel>
)

export const KBLibraryRightSidepanel = styled(KBLibraryRightSidepanelBase)`
  &.kb-library.right-sidepanel {
    &.active{
      width: 95% !important;
      position: absolute;
      right: 0;
      &>.panel-body .collab-container .errand-message-group.kb-library .message-group .icPanelMsg.collab-query{
        .truncate-text{
          overflow-x: auto;
        }
        .icPanelInfo .icPanelPhoto{
          width: 45px;
        }
      }
      .kb-library-left{
        right: 101%;
      }
    }
    transition: width 0.4s ease-out;
    &>.panel-body .collab-container .errand-message-group.kb-library .message-group .icPanelMsg.collab-query .truncate-text{
      overflow-x: hidden;
    }
    .kb-library-left{
      position: absolute;
      top: 50%;
      right: 350px;
      border: 1px solid;
      border-radius: 15px;
      font-size: 12px;
      padding: 2px;
      background: #359cf8;
      color: white;
      text-align: center;
      cursor: pointer;
      transition: right 0.3s ease-out;
      &:before{
        margin-right: 1px;
      }
    }
    .panel-header {
      background-color: #EAEAEA
    }
    .panel-body {
      .msg.errand-message-group {
        width: 100%;
        margin: 0;
      }
    }
  }
`