import each from 'lodash/each';
import keys from 'lodash/keys';
import { createSelector } from 'reselect';
import update from 'immutability-helper';
import moment from 'moment';
import { I, webRoot } from '../../common/v5/config.js';
import {
	ACQ_ERD_OTHER,
	ACQ_ERD_SELECTED_TOP,
	AET_ACQUIRE,
	AET_HISTORIES,
	ALL_Q_AT_STR,
	AT_SAVED,
	AT_UPLOADED,
	BTN_CALL,
	BTN_CLEAR,
	BTN_PREVIEW,
	BTN_RESEND,
	BTN_RESET,
	BTN_SAVE,
	BTN_SAVE_AS_EML,
	BTN_SEND,
	BTN_SEND_ALL,
	COL_COMPLETE_ERRAND_HISTORIES,
	COL_NEW_THREAD,
	COL_SAVE_RECIPIENT,
	CTX_REVIEW,
	D_AREAS,
	D_BASIC_ERRANDS,
	D_EXTENDED,
	D_ERRAND_NOTES,
	D_EE_LIST,
	D_HISTORY,
	DEF_AREA_DATA,
	DEF_ERRAND_BUTTONS_ORDER,
	E_A_SAVING,
	E_A_SENDING,
	E_A_UNKNOWN,
	EXPERT_ANS_IDLE,
	ECB_ALL_CURR_Q_AT,
	ECB_FWD_ALL_HISTORIES,
	ECB_INC_QUESTION,
	ECB_INC_HISTORIES,
	ECB_INC_COLLAB_HISTORY,
	ECB_PARTIAL_ANSWER,
	ECB_SUGGEST_TO_LIBRARY,
	EOPR_ANSWERED,
	EST_ACQUIRED,
	EST_HANDLED,
	EST_OWNED,
	EST_UNKNOWN,
	EXPERT_ANS_SENDING,
	EXPERT_ANS_SAVING,
	EXT_EXPERT_EDIT_ATTACHMENT_TYPE,
	FULL_HISTORY_STR,
	FULL_COLLAB_HISTORY_STR,
	HKA_SAVE_AS_EML,
	ICCB_NEW,
	ICST_NO_DATA,
	INC_ALL_HISTORIES_STR,
	INPUTS_MANUAL_ERRAND,
	INPUTS_OPEN_ERRAND,
	LSSS_VALID,
	NEW_MANUAL_ERRAND_ID,
	NOT_MINE_INVALID,
	NOT_MINE_ERROR,
	NOT_MINE_OTHER_AGENT,
	OPEN_STR,
	OPT_CHANNELS,
	PREVIEW,
	RC_EMAIL,
	RC_SMS,
	RC_VOICE,
	RC_FORM,
	RC_CHATBOT,
	RPLY_CHECKBOX_OPTS,
	RPLY_CHECKBOXES_ORDER,
	RPLY_COLLABORATE,
	RPLY_COMMENT,
	RPLY_ERRAND,
	RPLY_EXT_FWD,
	RPLY_CHAT_SUMMARY,
	RPLY_GRP_CHAT_COL,
	RPLY_QUESTION,
	SAVE_AS_EML_STR,
	SHARE_STATISTICS,
	STATISTICS_PAGE,
	ST_ACQUIRED,
	ST_ACQUIRING,
	ST_ANSWERED,
	ST_JUST_LOGIN,
	ST_UNSELECT,
	TELAVOX,
	UNKNOWN_PHOTO_LINK,
	UNSELECT_STR,
	ACT_CLOSED,
	ACT_ANSWERED,
	ACT_FORWARDED,
	ACT_SAVE,
	ACT_UNKNOWN,
	ERRAND_HOTKEYS,
	CLEAR_ANSWER,
	SAVE,
	RESET,
	SEND,
	SEND_TO_ALL,
	RPLY_MANUAL,
	emptyObject,
	emptyArray,
	CB_ALL_CONTACT,
	CB_EXPERT_IN_AREA,
	CB_CONTACT_CARD,
	CB_AGENTS,
	CB_ADDRESS_BOOK,
	RECIPIENT_INTERNAL_COLLABORATE,
	ADD_TO_CONTACT_CARD,
	INPUTS_MANUAL_CALL,
	MEDIA_ACTION,
	VIEW_REPLY_PANEL,
	BTN_TXT_SAVING,
	BTN_TXT_SAVE
} from '../../common/v5/constants';
import {
	callStatusIdle,
	sipCallShowAgentList,
	sipCallGetAgentList,
	sipCallTransferIsExternal
} from './call';
import {
	isErrandViewOnly,
	noSelector
} from './common';
import {
	contextMemo
	, onlyActiveAgentsSelector
	, allConnectedAreasSelector
	, workflowSettingsSelector
} from './workflow';
import {
	isManualPopup,
	isManualCallPopup,
	getManualErrandInputs,
	manualErrandSelectedAreaData,
	manualErrandSelectedAreaSelector,
	manualErrandSelectedReplyChannel,
	addressBookSelector,
	isCallMemoize,
	showBulkSendSelector
} from './manual';
import {
	actionsLinkMemo,
	canInternalGroupChat,
	isTwilioCallEnabled,
	serviceTypes
} from './server';
import { openQuestionIdSelector } from './library';
import { getAppState, getUniqueData } from '../util';
import {
	canAcquire,
	errandHotkeyChar,
	isEmptyHTML,
	isValidEmail,
	isValidIntegration,
	isValidPhoneNo,
	getChatArea,
	hasPrefix,
	mergeTwoSorted,
	findEmailRegex,
	isTelavoxEnabled,
	notChatOrChatInCollborate,
	filePathPrefix,
} from '../../common/v5/helpers';

import {
	isSipNumber,
} from '../../common/helpers.js';

export const getAppErrand = state => getAppState(state, "errand");

export const getCurrentErrandAreaId = state => getAppErrand(state).inputs.area;

const getUI = state => getAppErrand(state).ui;

const getPreviewOrSaveEmlBusy = state => getUI(state).previewOrSaveEmlBusy;

const getUIReply = state => getUI(state).reply;

const makeUIReplySelector = field => state => getUIReply(state)[field]

export const showReactionPopupMemo = makeUIReplySelector('showReactionPopup')

const currentErrand = state => getAppErrand(state).currentErrand;

export const getCurrentErrand = state => currentErrand(state).id;

const currentErrandType = state => currentErrand(state).type;

export const isErrandAnswered = state => currentErrandType(state) === EOPR_ANSWERED;

const isChat = state => getAppErrand(state).chat;

export const currentChatErrand = state => isChat(state);

// this should be more effective as selector function than isChat because it
// should return only boolean and any selector check its return value will not
// trigger the memoized function even the chat object internal field change.
function isChatErrand(state) {
	return !!isChat(state);
}

export const getAppAdmin = state => getAppState(state, "admin");

const basicErrand = state => state.app.errand.basic;

const acquireErrand = state => state.app.errand.acquireErrand;

const acquireErrandParam = state => acquireErrand(state).param;

export const reviewData = noSelector(
	acquireErrand
	, ({ data }) => {
		if (!data || !data.acquire || !data.acquire.data) {
			return;
		}
		return data.acquire.data.review;
	}
);

export const getMyId = state => workflowSettingsSelector(state).activeUserId;

const eeeDomain = state => state.app.errand.externalExpertEdit.domain;

const fetchHistoryState = state => getAppErrand(state).fetchHistory;

const isFetchHistoryWip = state => fetchHistoryState(state).wip;

export const getDomainBasicErrands = state => state.domain[D_BASIC_ERRANDS];

const getDomainBasicErrandById = state => getDomainBasicErrands(state).byId;

const getDomainHistory = state => state.domain[D_HISTORY];

const getDomainHistoryById = state => getDomainHistory(state).byId;

export const getDomainNotes = state => state.domain[D_ERRAND_NOTES];

const basicErrandData = noSelector(
	basicErrand
	, basic => {
		if (!basic || !basic.data || !basic.data.data) {
			return emptyObject;
		}
		return basic.data.data;
	}
);

const serviceTypeMemoize = noSelector(
	basicErrandData
	, ({ service }) => {
		if (service > 0) {
			return service;
		}
		return 0;
	}
);

export const currentCipherKeyMemoize = noSelector(
	basicErrandData
	, ({ cipherKey }) => {
		if (cipherKey) {
			return cipherKey;
		}
		return "";
	}
);

function cleanPhoneNumber(num) {
	if (num && isValidPhoneNo(num)) {
		return num.replace(" ", "").replace("(", "").replace(")", "");
	}
	return "";
}

// const fromMemoize = noSelector(
// 	basicErrandData
// 	, ({ fromAddress }) => cleanPhoneNumber(fromAddress)
// );

const toMemoize = noSelector(
	basicErrandData
	, ({ to }) => cleanPhoneNumber(to)
);

export const dialBackCallerIdPhoneNumberMemoize = noSelector(
	toMemoize
	, serviceTypeMemoize
	, serviceTypes
	, (to, serviceType, serviceTypeConstants) => {
		if (serviceType === serviceTypeConstants.SERVICE_VOICE
			|| serviceType === serviceTypeConstants.SERVICE_MANUAL_VOICE) {
			return to;
		}
		return "";
	}
);

const errandStatusSelector = createSelector(
	isChatErrand
	, basicErrand
	, acquireErrand
	, (chat, {wip: wipBasic, data: dataBasic}, {wip: wipAcquire, data: dataAcquire}) => {
		if (chat) {
			return EST_OWNED; // TODO: assume chat errand always owned, correct?
		} else if (!wipBasic && !wipAcquire && dataBasic && dataAcquire &&
			dataAcquire.acquire) {
			if (dataBasic.data.closed || dataBasic.data.deleted) {
				return EST_HANDLED;
			} else if (dataAcquire.acquire.acquired) {
				return EST_OWNED;
			}
			return EST_ACQUIRED;
		}
		return EST_UNKNOWN;
	}
);

const pendingReviewMemo = noSelector(
	basicErrandData
	, ({ pendingReview }) => !!pendingReview
);

export const isWA24hourWindow = noSelector(
	basicErrandData
	, ({ service, canWaSendAnswer }) => {
		if(service === Workflow.Errand.SERVICE_WHATSAPP){
			return canWaSendAnswer;
		}
		return true
	}
)

export const inputsReplyCheckboxes = createSelector(
	getAppErrand,
	appErrand => appErrand.inputs.checkboxes
);

export const collaborationInputs = createSelector(
	getAppErrand,
	appErrand => appErrand.collaborationInputs
);

function collaborationRole(state, props) {
	return collaborationInputs(state, props).replyQuery;
}

const defCheckboxes = {
	checkboxes: RPLY_CHECKBOX_OPTS,
	order: RPLY_CHECKBOXES_ORDER
};

const showIncludeQuestion = state => {
	const wfData = state.app.workflow.fetchWfSettings.data;
	if (wfData) {
		return wfData.toggleIncludeQuestion;
	}
	return false;
}

const showIncludeHistories = state => {
	const wfData = state.app.workflow.fetchWfSettings.data;
	if (wfData) {
		return wfData.showIncludeAllHistories;
	}
	return false;
}

const createCounterWithCondition = (objectById, condition) => {
	let count = 0;
	$.each(objectById, (k, v) => {
		if (k && k !== "undefined") {
			if (condition(v, k)) {
				count++;
			}
		}
	});
	return count;
}

const showCheckboxQuestionAttachmentsAll = (state, props) => {
	const { selected_externalforward_attachment } = getAppErrand(state, props).inputs;
	if (!selected_externalforward_attachment) {
		return 0;
	}
	return createCounterWithCondition(
		selected_externalforward_attachment
		, v => v
	);
}

function countSelectedForwardHistory(state, props) {
	const { opr } = historyDataSelector(state);
	if (!opr) {
		return 0;
	}
	return createCounterWithCondition(opr, ({ selected }, k) => {
		if (k === "all" || k === "allSelected") {
			return false;
		}
		return selected;
	});
}

export const getHistoryIdSelector = createSelector (
	getDomainHistory,
	initialThreadIDSelector,
	(history, threadId) => {
		if (!history || !history.byId) {
			return [];
		}
		if (!threadId) {
			return [];
		}
		const historyIds = [];
		const byId = history.byId[threadId];
		if (!byId) {
			return [];
		}
		if (byId.data) {
			byId.data.forEach(data => {
				historyIds.push(data.displayId);
			});
		}
		return historyIds;
	}
)

export const replyCheckboxesSelector = createSelector(
	collaborationRole
	, showIncludeQuestion
	, showIncludeHistories
	, showCheckboxQuestionAttachmentsAll
	, countSelectedForwardHistory
	, isChatErrand
	, (
		replyQuery
		, displayIncludeQuestion
		, displayIncludeHistories
		, count
		, forwardHistoryCount
		, isChat
	) => {
		let checkboxes,label;
		if (!replyQuery) {
			if (displayIncludeQuestion && !displayIncludeHistories) {
				return defCheckboxes;
			}
			if(FULL_HISTORY_STR === 'Full history'){
				label = I('Full history');
			} else {
			    label = FULL_HISTORY_STR;
		    }	
			if (!isChat) {
				label += " (" + forwardHistoryCount + ")";
			}
			const hisotryCheckbox = { [ECB_FWD_ALL_HISTORIES]: { $set: { label } } };
			checkboxes = update(RPLY_CHECKBOX_OPTS, {
				[RPLY_EXT_FWD]: hisotryCheckbox
				, [RPLY_COLLABORATE]: hisotryCheckbox
			});
			if (displayIncludeHistories) {
				const INC_ALL_HISTORIES_STR = I('Include full history')
				checkboxes = update(checkboxes, {
					[RPLY_ERRAND]: {[ECB_INC_HISTORIES]: {$set: {label: INC_ALL_HISTORIES_STR + (" (" + forwardHistoryCount + ")")}}},
				});
			}
			if (!displayIncludeQuestion) {
				checkboxes = update(checkboxes, {
					[RPLY_ERRAND]: {$unset: [ECB_INC_QUESTION]}
				});
			}
			if (typeof count !== "undefined") {
				const ALL_Q_AT_STR = I('All current question attachments ({COUNT})')
				const label = ALL_Q_AT_STR.replace("{COUNT}", count);
				checkboxes = update(checkboxes, {
					[RPLY_EXT_FWD]: {[ECB_ALL_CURR_Q_AT]: {$set: {label}}}
				});
			}
			const collabHistoryCheckbox = {[ECB_INC_COLLAB_HISTORY]: {
				$set: {label: FULL_COLLAB_HISTORY_STR}
			}};
			checkboxes = update(checkboxes, {
				[RPLY_COLLABORATE]: collabHistoryCheckbox
			});
		} else {
			checkboxes = update(RPLY_CHECKBOX_OPTS, {
				[RPLY_COLLABORATE]: {
					$unset: [COL_SAVE_RECIPIENT]
				}
			});
		}
		return update(defCheckboxes, {checkboxes: {$set: checkboxes}});
	}
);

export const getCurrentReply = createSelector(
	getAppErrand,
	appErrand => appErrand.ui.reply.current
);

export const socialActionPerChannel = createSelector(
	basicErrand
	, basic => {
		if (!basic || !basic.data || !basic.data.data) {
			return false;
		}
		const { service, fbPms, twitterPm } = basic.data.data;
		if(service === Workflow.Errand.SERVICE_WHATSAPP){
			return false;
		}else if (service === Workflow.Errand.SERVICE_FACEBOOK && fbPms){
			return false;
		}else if (service === Workflow.Errand.SERVICE_TWITTER && twitterPm){
			return false;
		}else if (service === Workflow.Errand.SERVICE_TELEGRAM){
				return false;
		}
		else{
			return true;
		}
	}
)

export const getCurrentType = createSelector(
	isCallMemoize
	, getAppErrand,
	(isCall, appErrand) => {
		if(isCall) {
			return appErrand.ui.manualCall.tab;
		}
		return appErrand.ui.manual.tab;
	}
);

// Like getCurrentReply but with awareness of manual errand popup.
export const currentActiveReply = noSelector(
	getCurrentReply
	, isManualPopup
	, isManualCallPopup
	, showBulkSendSelector
	, (currentReply, isManualActive, isManualCallActive, isBulkSend) => {
		if (isManualActive || isManualCallActive || isBulkSend) {
			return RPLY_MANUAL;
		}
		return currentReply;
	}
);

export const isCollaborationSelector = createSelector(
	getCurrentReply,
	reply => reply === RPLY_COLLABORATE
);

export const shouldHideQuickReply = createSelector(
	getCurrentReply,
	basicErrand,
	isChatErrand,
	(reply, basic, isChat) => {
		if(reply === RPLY_COLLABORATE ) return false;
		if (!basic || !basic.data || !basic.data.data) {
			return false;
		}
		let shouldShowQR = false;
		const { service, fbPms, sourceService } = basic.data.data;
		if( isChat ){
			if(sourceService === Workflow.Errand.SERVICE_FACEBOOK){
				shouldShowQR = true;
			}
		}else{
			//e.chat.errand.data.sourceId
			if (service === Workflow.Errand.SERVICE_FACEBOOK && fbPms){
				shouldShowQR = true;
			}
		}
		return shouldShowQR;
	}
);

function currentEditingNote(state, props) {
	return getAppErrand(state, props).inputs.current_edit_note.note;
}

function getHistoryDataOprAll(state, props) {
	const { opr } = historyDataSelector(state);
	if (!opr) {
		return false;
	}
	return !!opr.all;
}

function currentQuestionAttachmentsAllSelected(state, props) {
	const { selected_externalforward_attachment } = getAppErrand(state, props).inputs;
	if (!selected_externalforward_attachment) {
		return false;
	}
	let allSelected = true;
	$.each(selected_externalforward_attachment, (k, v) => {
		if (!v) {
			allSelected = false;
			return false;
		}
	});
	return allSelected;
}

const appErrandCollaborationInputsSelectedAttachment = state => getAppErrand(state).collaborationInputs.selectedAttachment;
const appErrandInputsQuestionAttachment = state => getAppErrand(state).inputs.question_attachments;

function currentErrandAttachmentsAllSelected(state, props) {
	const currReply = getAppErrand(state, props).ui.reply.current;
	let files = {};
	if(currReply === RPLY_ERRAND){
		files = appErrandInputsQuestionAttachment(state)
	}else if(currReply === RPLY_COLLABORATE) {
		files = appErrandCollaborationInputsSelectedAttachment(state);
	}else{
		return;
	}
	if (!files) {
		return false;
	}
	let allSelected = false;
	$.each(files, (k, v) => {
		if (!v) {
			allSelected = false;
			return false;
		}
		allSelected = true;
	});
	return allSelected;
}

export const isAllCurrentFilesAttached = createSelector(
	currentErrandAttachmentsAllSelected
	, (includesAll) => {
		if(includesAll) {
			return true;
		}
		return false;
	}
);

export const currentCollabChannel = state => state.app.errand.inputs.collab_channel;

const getServicesByName = state => getServerServices(state).byName;

const getServicesByType = state => getServerServices(state).byType;

const getFilteredChannelsByType = state => getFilteredChannels(state).byType;

const getErrandBasic = state => {
	let chat = isChat(state);
	if (chat) {
		return chat.errand;
	}
	return basicErrand(state).data;
};

export const getCurrentChannel = createSelector(
	[
		getErrandBasic,
		getServicesByName
	],
	(basic, services) => {
		if(!basic || !basic.data) {
			return services['email'];
		}
		return basic.data.service;
	}
);

export const getSourceService = createSelector(
	[
		getErrandBasic
	], basic => {
		if(!basic || !basic.data) {
			return 0;
		}
		return basic.data.sourceService;
	}
);

const currentErrandServiceReplyChannel = createSelector(
	getCurrentChannel
	, getServicesByType
	, (channel, services) => {
		return services[channel].channel
	}
);

export const replyCheckboxStatesSelector = createSelector(
	inputsReplyCheckboxes
	, currentEditingNote
	, getCurrentReply
	, collaborationInputs
	, getHistoryDataOprAll
	, currentQuestionAttachmentsAllSelected
	, currentErrandServiceReplyChannel
	, currentCollabChannel
	, (
		checkboxes
		, note
		, reply
		, inputs
		, allSelected
		, qAttachmentsAllSelected
		, serviceReplyChannel
		, collab_channel
	) => {
		if (reply === RPLY_COMMENT) {
			return {[ICCB_NEW]: note === 0};
		} else if (reply === RPLY_EXT_FWD) {
			return update(checkboxes, {
				[ECB_FWD_ALL_HISTORIES]: {$set: allSelected}
				, [ECB_ALL_CURR_Q_AT]: {$set: qAttachmentsAllSelected}
			});
		} else if (reply === RPLY_ERRAND) {
			return update(checkboxes, {
				[ECB_INC_HISTORIES]: {$set: serviceReplyChannel == RC_EMAIL ? allSelected : false}
			});
		} else if (reply !== RPLY_COLLABORATE) {
			return checkboxes;
		}
		if (inputs.replyQuery) {
			return {[COL_NEW_THREAD]: false};
		}
		let newThread;
		if (inputs.queryID || inputs.answerID) {
			newThread = false;
		} else {
			newThread = true;
		}
		return {
			[ECB_FWD_ALL_HISTORIES]: collab_channel == RC_EMAIL ? allSelected : false // TODO: NOTE1: we should hide checkbox instead of giving static unchange-able state
			, [COL_NEW_THREAD]: newThread
			, [COL_SAVE_RECIPIENT]: inputs.saveRecipient
			, [ECB_INC_COLLAB_HISTORY]: inputs.threadID != 0 ? inputs.includeCollabHistory : false // TODO: see NOTE1
		};
	}
);

export const currentErrandDisplayIdMemo = noSelector(
	getErrandBasic,
	data => {
		if (!data || !data.data || !data.data.displayId) {
			return ''
		}
		return data.data.displayId
	}
)

export const hideSubjectbarSelector = createSelector(
	[
		collaborationRole,
		isCollaborationSelector
	],
	(replyQuery, isCollaboration) => replyQuery && isCollaboration
);

const defCollaborateAttachmentList = {
	list: {},
	size: 0,
	uploadedSize: 0,
	archiveSize: 0,
	excludeIncomingSize: 0,
	totalSize: 0
};

function externalExpertEditAttachmentLink(a, eid) {
	let url = filePathPrefix()
	if(a.outgoingAttachment) {
		if(a.isArchive) {
			url += "file/" + a.archiveId + "/" + encodeURI(a.fileName);
		} else {
			url += "errand/fetchErrandAnswerAttachment?errand=" + eid +
				"&attachment=" + a.attachmentId;
		}
	} else {
		url += "errand/fetchErrandAttachment?errand=" + eid + "&attachment=" +
			a.attachmentId;
	}
	return url;
}

const collaborateAttachmentListSelector = createSelector(
	[
		isCollaborationSelector,
		getAppErrand
	],
	(collaborate, appErrand) => {
		if(!collaborate) {
			return defCollaborateAttachmentList;
		} else {
			if(appErrand.externalExpertEdit.wip) {
				return update(defCollaborateAttachmentList, {$merge: {
					notReady: true}});
			} else if(!appErrand.externalExpertEdit.domain) {
				return defCollaborateAttachmentList;
			}
		}
		const eee = appErrand.externalExpertEdit.domain.list,
			inpts = appErrand.collaborationInputs;
		let list = {}, size = 0, uploadedSize, archiveSize, excludeIncomingSize, savedSize;
		let librarySize = 0;
		let attachments = [];
		$.each(eee.oldAttachments, (k,v) => {
			const url = externalExpertEditAttachmentLink(v, v.errandId);
			attachments.push({
				id: v.attachmentId,
				estSize: v.attachmentSize,
				fileName: v.fileName,
				embeddedImage: v.embeddedImage,
				src: url,
				download: url
			});
		});
		list["oldAttachments"] = attachments;
		size += list["oldAttachments"].length;

		$.each(eee.errandAttachments, (i,e) => {
			let attachments = [];
			$.each(e.attachments, (k,v) => {
				const url = externalExpertEditAttachmentLink(v, e.errandId);
				attachments.push({
					id: v.attachmentId,
					estSize: v.attachmentSize,
					fileName: v.fileName,
					embeddedImage: v.embeddedImage,
					src: url,
					download: url,
					outgoingAttachment: v.outgoingAttachment ? true : false
				});
			});
			list[e.displayID] = attachments;
			size += list[e.displayID].length;
		});
		if(inpts.uploaded_attachments.length) {
			uploadedSize = inpts.uploaded_attachments.length;
		} else {
			uploadedSize = 0;
		}
		if(inpts.archive_attachments.length) {
			archiveSize = inpts.archive_attachments.length;
		} else {
			archiveSize = 0;
		}
		if(inpts.library_attachments.length) {
			librarySize = inpts.library_attachments.length;
		} else {
			librarySize = 0;
		}

		if(inpts.saved_attachments.length) {
			savedSize = inpts.saved_attachments.length;
		} else {
			savedSize = 0;
		}
		excludeIncomingSize = uploadedSize + archiveSize + librarySize + savedSize;
		return {
			list,
			size,
			uploadedSize,
			archiveSize,
			excludeIncomingSize,
			totalSize: size + excludeIncomingSize
		};
	}
);

const appErrandInputsSelectedExternalForwardAttachment = state => getAppErrand(state).inputs.selected_externalforward_attachment;

export const selectedAttachmentList = createSelector(
	[
		getCurrentReply,
		appErrandCollaborationInputsSelectedAttachment,
		appErrandInputsSelectedExternalForwardAttachment,
		appErrandInputsQuestionAttachment
	],
	(currentReply, selectedAttachment, selectedExternalForwardAttachment, questionAttachment) => {
		let attachments;
		if (currentReply === RPLY_COLLABORATE) {
			attachments = selectedAttachment;
		} else if ( currentReply === RPLY_EXT_FWD) {
			attachments = selectedExternalForwardAttachment;
		} else {
			attachments = questionAttachment;
		}
		return attachments;
	}
);

const appErrandInputsSelectedHistoryAttachment = state => getAppErrand(state).inputs.selected_history_attachments;

export const selectedHistoryAttachmentList = createSelector(
	[
		getCurrentReply,
		appErrandInputsSelectedHistoryAttachment,
		inputsReplyCheckboxes,
		replyCheckboxStatesSelector
	],
	(currentReply, selectedHistoryAttachment, inputsCheckbox, checkBoxState) => {
		//currentReply === RPLY_EXT_FWD
		if((currentReply === RPLY_ERRAND && inputsCheckbox[ECB_INC_HISTORIES]) ||
			(currentReply === RPLY_EXT_FWD && checkBoxState[ECB_FWD_ALL_HISTORIES])) {
				return selectedHistoryAttachment;
		}
		return [];
	}
);

// const currentChannel = state => state.app.errand.ui.currentChannel;

export const currentErrandReplyChannel = state => state.app.errand.inputs.reply_channel;

const getValidReplyInputs = createSelector(
	[
		getCurrentReply,
		getAppErrand
	],
	(replyType, errand) => {
		if(replyType === RPLY_COLLABORATE) {
			return errand.collaborationInputs;
		}
		return errand.inputs;
	}
);

const btnClassBlue = 'btn-blue'
	, btnClassYellow = 'btn-yellow'
	, btnClassGrey = 'btn-grey'
	;
const CALL_STR = I('Call')
	, CLEAR_STR = I('Clear')
	, PREVIEW_STR = I('Preview')
	, SAVE_STR = BTN_TXT_SAVE
	, SEND_STR = I('Send')
	, RESEND_STR = I('Resend')
	, SAVING_STR = BTN_TXT_SAVING
	, SEND_ALL_STR = I('Send to all')
	, SENDING_STR = I('Sending...')
	, RESET_STR = I('Reset')
	, ANS_STR = I('Answer')
	, ANSWERING_STR = I('Answering...')
	, defCallState = {text: CALL_STR, class: btnClassBlue}
	, disCallState = update(defCallState, {disabled: {$set: true}})
	, defResendState = {text: RESEND_STR, class: btnClassBlue}
	, defSendState = {
		text: SEND_STR
		, class: btnClassBlue
		, hotkey: errandHotkeyChar(SEND)
	}
	, defSendAllState = {
		text: SEND_ALL_STR
		, class: btnClassBlue
		, hotkey: errandHotkeyChar(SEND_TO_ALL)
	}
	, defSave = {
		text: SAVE_STR
		, class: btnClassYellow
		, hotkey: errandHotkeyChar(SAVE)
	}
	, defClear = {
		text: CLEAR_STR
		, class: btnClassGrey
		, hotkey: errandHotkeyChar(CLEAR_ANSWER)
	}
	, defReset = {
		text: RESET_STR
		, class: btnClassBlue
		, hotkey: errandHotkeyChar(RESET)
	}
	, defPreview = {
		text: PREVIEW_STR
		, class: btnClassBlue
		, hotkey: errandHotkeyChar(PREVIEW)
		, hidden: true // TODO: remove preview button completely
	}
	, defSaveAsEml = {
		text: SAVE_AS_EML_STR
		, class: btnClassBlue
		, hotkey: errandHotkeyChar(HKA_SAVE_AS_EML)
		, hidden: true // TODO: remove save as eml button completely
	}
	, defEditQuestionButtonState = {
		[BTN_RESET]: defReset
		, [BTN_SAVE]: defSave
	}
	, defHandledErrandButtonState = {
		[BTN_SAVE]: defSave
		, [BTN_RESEND]: defResendState
		, [BTN_SAVE_AS_EML]: defSaveAsEml
	}
	, defHandledExternalForwardButtonState = {
		[BTN_RESEND]: defResendState
		, [BTN_SAVE_AS_EML]: defSaveAsEml
	}
	, defInternalCommentButtonState = {
		[BTN_CLEAR]: defClear
		, [BTN_SAVE]: defSave
	}
	, defCollaborationButtonState = {
		[BTN_CLEAR]: defClear
		, [BTN_SEND]: defSendState
	}
	, defButtonState = {
		[BTN_CLEAR]: defClear
		, [BTN_SEND]: defSendState
		, [BTN_PREVIEW]: defPreview
		, [BTN_SAVE_AS_EML]: defSaveAsEml
	}
	;
const disableSendToAllFeature = state => state.app.workflow.fetchWfSettings.data['disable-send-to-all'];

const getInputsAnswerState = (state, props) => getValidReplyInputs(state, props).answer_state;

export const isIdleAnswer = state => getAppErrand(state).inputs.answer_state === E_A_UNKNOWN;
export const isEEIdleAnswer = state => getAppErrand(state).collaborationInputs.answer_state === EXPERT_ANS_IDLE;

function getUpdateErrandWip(state) {
	return getAppErrand(state).updateAnswer.wip;
}

export const isSaving = state => false; //getUpdateErrandWip(state);

const getSmsEnabled = state => {
	return state.app.workflow.fetchWfSettings.data['enableSMS'];
}

const getIsErrandClosed = state =>{
	if(state.app.errand.basic.data){
		return state.app.errand.basic.data.data.closed;
	}
	return false
}

const getInputsReplyQuery = (state, props) => getValidReplyInputs(state, props).replyQuery;

const getAcquireData = state => {
	const e = state.app.errand;
	if(!e.currentErrand.acquire) {
		return;
	}
	const a = e.acquireErrand.data;
	if(a) {
		return a.acquire;
	}
};

const inCommonFolder = noSelector(
	getAcquireData
	, acquire => {
		if (!acquire || !acquire.data) {
			return false;
		}
		return !!acquire.data.errand_in_group_folder;
	}
);

const isOwnedOrAbleHandle = noSelector(
	errandStatusSelector
	, inCommonFolder
	, (status, isInCommonFolder) => status !== EST_OWNED && !isInCommonFolder
);

const isHandled = noSelector(
	errandStatusSelector
	, status => status === EST_HANDLED
);

const getServerServices = state => state.server.services;

const getFilteredChannels = state => {
	const wfData = state.app.workflow.fetchWfSettings.data;
	if(typeof wfData !== "undefined" && wfData){
		return wfData.channels;
	}
	return emptyData;
}

const serviceSelectorByField = field => {
	return (channels, filteredChannels) => {
		if (filteredChannels[field] !== null){
			$.each(channels[field], (k, v) => {
				$.each(filteredChannels[field], (i, j) => {
					if (v.name == j.name){
						j.channel = v.channel;
					}
				});
			});
		}
		return filteredChannels[field];
	};
}

const createServerChannelsSelector = field => {
	return createSelector(getServerServices, getFilteredChannels, serviceSelectorByField(field));
}

const getFilteredChannelsSelector = createServerChannelsSelector("byType");

const isEmailReply = (state, props) => currentErrandReplyChannel(state, props) === RC_EMAIL;

function addOrCreateUpdateObject(updateObj, key, value) {
	if (!updateObj) {
		return {[key]: value};
	}
	updateObj[key] = value;
	return updateObj;
}

const isVoiceChannel = noSelector(
	currentErrandReplyChannel
	, channel => channel === RC_VOICE
);

const isChannelSaveOnly = noSelector(
	currentErrandReplyChannel
	, channel => channel === RC_FORM || channel === RC_CHATBOT
);

export const isVoiceChannelLike = noSelector(
	isErrandViewOnly
	, isVoiceChannel
	, isChannelSaveOnly
	, (errandViewOnlyForAmeyo, voiceChannel, saveOnly) =>
		errandViewOnlyForAmeyo || voiceChannel || saveOnly
);

function updateSendButton(updated, sendButton) {
	if (sendButton.hidden) {
		updated[BTN_SEND].hidden = {$set: true};
	} else if (sendButton.sending) {
		updated[BTN_SEND].text = {$set: SENDING_STR};
	}
	return updated;
}

export const isValidAcquireErrandReviewContext = noSelector(
	acquireErrand
	, contextMemo
	, getCurrentErrand
	, ({ param }, context, errandId) => {
		if (!param) {
			return false;
		}
		const { errand, source } = param;
		return errand === errandId
			&& source === CTX_REVIEW
			&& context === CTX_REVIEW;
	}
);

const constantTextBusy = {disabled: {$set: true}, busy: {$set: true}}
	, setToHidden = {hidden: {$set: true}}
	;
const getButtons = createSelector(
	isErrandViewOnly
	, errandStatusSelector
	, getCurrentReply
	, getInputsAnswerState
	, getInputsReplyQuery
	, disableSendToAllFeature
	, isValidIntegration
	, inCommonFolder
	, isEmailReply
	, getPreviewOrSaveEmlBusy
	, getSmsEnabled
	, getIsErrandClosed
	, isVoiceChannel
	, isTwilioCallEnabled
	, callStatusIdle
	, isChannelSaveOnly
	, isValidAcquireErrandReviewContext
	, contextMemo
	, pendingReviewMemo
	, isWA24hourWindow
	, (
		errandViewOnlyForAmeyo
		, errandStatus
		, reply
		, answerState
		, replyQuery
		, disableSendToAll
		, isIntegration
		, isInCommonFolder
		, emailReply
		, previewOrSaveEmlBusy
		, smsEnabled
		, errandIsClosed
		, voiceChannel
		, twilioEnabled
		, callStatusIsIdle
		, channelSaveOnly
		, acquireReviewContext
		, context
		, pendingReview
		, waCanSendAnswer
	) => {
		let defButton;
		if (!emailReply || errandViewOnlyForAmeyo) {
			defButton = update(defButtonState, {
				[BTN_PREVIEW]: setToHidden
				, [BTN_SAVE_AS_EML]: setToHidden
			});
		} else if (previewOrSaveEmlBusy) {
			defButton = update(defButtonState, {
				[BTN_PREVIEW]: constantTextBusy
				, [BTN_SAVE_AS_EML]: constantTextBusy
			});
		} else {
			defButton = defButtonState;
		}
		if (errandViewOnlyForAmeyo) {
			if (reply === RPLY_COLLABORATE) {
				return defCollaborationButtonState;
			} else if (reply === RPLY_EXT_FWD) {
				return defButton;
			} else if (reply === RPLY_QUESTION) {
				return defEditQuestionButtonState;
			}
			let sendBtnState = setToHidden;
			if (errandStatus == EST_OWNED
				&& errandIsClosed == false
				&& smsEnabled == true) {
				sendBtnState = {$set: defSendState}
			}
			return update(defButton, {
				[BTN_CLEAR]: {class: {$set: btnClassBlue}}
				// TODO: do you want to close errand view also? And you might
				// want to update the busy code part further down.
				, [BTN_SAVE]: {$set: defSave}
				, [BTN_SEND]: sendBtnState
			});
		} else if (reply === RPLY_COMMENT) {
			return defInternalCommentButtonState;
		} else if (reply === RPLY_QUESTION) {
			return defEditQuestionButtonState;
		} else if (reply === RPLY_COLLABORATE) {
			if (answerState !== EXPERT_ANS_SENDING && answerState !== EXPERT_ANS_SAVING) {
				if (!replyQuery) {
					return defCollaborationButtonState;
				} else {
					return update(defCollaborationButtonState, {
						[BTN_SEND]: {text: {$set: ANS_STR}, disabled: {$set: false}}
					});
				}
			}
			let sendUpdate = {disabled: {$set: true}, busy: {$set: true}};
			if (replyQuery) {
				sendUpdate.text = {$set: ANSWERING_STR};
			}
			return update(defCollaborationButtonState, {
				[BTN_CLEAR]: constantTextBusy
				, [BTN_SEND]: sendUpdate
			});
		} else {
			// errand or external forward reply
			if (answerState !== E_A_SENDING && answerState !== E_A_SAVING) {
				if (pendingReview) {
					if (context === CTX_REVIEW && acquireReviewContext) {
						return defButton;
					} else {
						return emptyObject;
					}
				} else if (errandStatus === EST_HANDLED
					&& (reply === RPLY_ERRAND || reply === RPLY_EXT_FWD)) {
					let btnState;
					if (reply === RPLY_ERRAND) {
						if (voiceChannel) {
							btnState = update(defHandledErrandButtonState, {
								[BTN_RESEND]: setToHidden
							});
						} else if(!waCanSendAnswer){
							btnState = update(defHandledErrandButtonState, {
								[BTN_RESEND]: setToHidden
							});
						} else {
							btnState = defHandledErrandButtonState;
						}
					} else {
						btnState = defHandledExternalForwardButtonState;
					}
					if (!emailReply) {
						btnState = update(btnState, {
							[BTN_SAVE_AS_EML]: setToHidden
						});
					}
					return btnState;
				} else if (errandStatus !== EST_OWNED && !isInCommonFolder) {
					return emptyObject;
				} else if (reply === RPLY_ERRAND) {
					let updateButtons;
					if (!disableSendToAll && !voiceChannel &&
						!channelSaveOnly) {
						updateButtons = addOrCreateUpdateObject(
							updateButtons
							, BTN_SEND_ALL
							, {$set: defSendAllState}
						);
					}
					if (isIntegration || voiceChannel || channelSaveOnly||
						isTelavoxEnabled()) {
						updateButtons = addOrCreateUpdateObject(
							updateButtons
							, BTN_SAVE
							, {$set: defSave}
						);
					}
					if (voiceChannel) {
						if (!smsEnabled) {
							updateButtons = addOrCreateUpdateObject(
								updateButtons
								, BTN_SEND
								, setToHidden
							);
						}
						if (twilioEnabled) {
							let callState;
							if (callStatusIsIdle) {
								callState = defCallState;
							} else {
								callState = disCallState;
							}
							updateButtons = addOrCreateUpdateObject(
								updateButtons
								, BTN_CALL
								, {$set: callState}
							);
						}
						if( isTelavoxEnabled()) {
							updateButtons = addOrCreateUpdateObject(
								updateButtons
								, BTN_SEND
								, setToHidden
							);
						}
					} else if (channelSaveOnly){
						updateButtons = addOrCreateUpdateObject(
							updateButtons
							, BTN_SEND
							, setToHidden
						);
					}else if( !waCanSendAnswer ){
						updateButtons = addOrCreateUpdateObject(
							updateButtons
							, BTN_SEND
							, setToHidden
						);
					}
					if (!updateButtons) {
						return defButton;
					}
					return update(defButton, updateButtons);
				}
				// external forward reply and errand is still open and owned.
				return defButton;
			}
			let updated = {
					[BTN_CLEAR]: constantTextBusy
					, [BTN_SEND]: {disabled: {$set: true}, busy: {$set: true}}
					, [BTN_PREVIEW]: constantTextBusy
					, [BTN_SAVE_AS_EML]: constantTextBusy
				}
				, sendButton = {}
				;
			if (reply === RPLY_ERRAND) {
				let updateBzSendAll = {
					disabled: {$set: true}
					, busy: {$set: true}
				};
				if (answerState === E_A_SENDING) {
					sendButton.sending = true;
					updateBzSendAll.text = {$set: SENDING_STR};
				}
				if (!disableSendToAll && !voiceChannel) {
					const bzDisAll = update(defSendAllState, updateBzSendAll);
					updated[BTN_SEND_ALL] = {$set: bzDisAll};
				}
				if (isIntegration || voiceChannel) {
					let updateBzSave = {
						disabled: {$set: true}
						, busy: {$set: true}
					};
					if (answerState === E_A_SAVING) {
						updateBzSave.text = {$set: SAVING_STR};
					}
					const bzDisSave = update(defSave, updateBzSave);
					updated[BTN_SAVE] = {$set: bzDisSave};
				}
				if (voiceChannel) {
					if (!smsEnabled) {
						sendButton.hidden = true;
					}
					if (twilioEnabled) {
						updated[BTN_CALL] = {
							$set: update(disCallState, {busy: {$set: true}})
						};
					}
				}
			} else {
				// external forward
				if (answerState === E_A_SENDING) {
					sendButton.sending = true;
				}
			}
			return update(defButton, updateSendButton(updated, sendButton));
		}
	}
);

export const buttonStateSelector = createSelector(
	getButtons,
	buttons => ({buttons, order: DEF_ERRAND_BUTTONS_ORDER})
);

function convertAddrObjToStr(addresses) {
	let addrs = [];
	if(addresses && addresses.length > 0) {
		$.each(addresses, (i,v) => {
			addrs.push(v.value);
		});
	}
	return addrs;
}

const getUpdateTo = createSelector(
	getValidReplyInputs,
	inputs => inputs.update_to
);

export const firstUpdateToMemoize = noSelector(
	getUpdateTo
	, updateTos => {
		if (!updateTos || !updateTos.length) {
			return "";
		}
		return updateTos[0].id;
	}
);

const getUpdateCc = createSelector(
	getValidReplyInputs,
	inputs => inputs.update_cc
);

const getUpdateBcc = createSelector(
	getValidReplyInputs,
	inputs => inputs.update_bcc
);

const getUpdateForward = createSelector(
	getValidReplyInputs,
	inputs => inputs.update_forward
);

const getUpdateToOrUpdateForward = createSelector(
	[
		getCurrentReply,
		getUpdateTo,
		getUpdateForward
	],
	(replyType, to, forward) => {
		if(replyType === RPLY_EXT_FWD) {
			return forward
		}
		return to;
	}
);

export const getEmailRecipients = createSelector(
	getUpdateTo,
	getUpdateCc,
	getUpdateBcc,
	getUpdateForward,
	(to,cc,bcc,fwdTo) => {
		return {
			to: convertAddrObjToStr(to),
			cc: convertAddrObjToStr(cc),
			bcc: convertAddrObjToStr(bcc),
			fwdTo: convertAddrObjToStr(fwdTo),
		}
	}
)

function addressValidation(items, seen, validation, addresses) {
	if(addresses && addresses.length) {
		$.each(addresses, (i,v) => {
			if(!seen[v.id] && validation(v.id)) {
				items.push({id: v.id, value: v.value});
				seen[v.id] = true;
			}
		});
	}
}

export const replyAnswerSelector = createSelector(
	getValidReplyInputs,
	inputs => inputs.update_answer
);

export const replyPlainAnswerSelector = createSelector(
	getValidReplyInputs,
	inputs => inputs.plain_answer
);

export const replySubjectSelector = createSelector(
	getValidReplyInputs,
	inputs => inputs.update_subject
);

export const errandQuestionSelector = createSelector(
	getValidReplyInputs,
	inputs => inputs.update_question
);

const emptyErrand = {
	acquired: false,
	data: {
		id: 0,
		displayId: '',
		status: '',
		service: 0,
		serviceName: '',
		organisationId: 0,
		organisationName: '',
		area: 0,
		areaName: '',
		priority: '',
		agentId: 0,
		agent: '',
		frm: '',
		from: '',
		fromAddress: '',
		fromName: '',
		to: '',
		toAddresses: [],
		copy: '',
		copyAddresses: null,
		subject: '',
		iconicSubject: '',
		body: '',
		date: '',
		answeredDate: '',
		donedate: '',
		postponedate: 0,
		attachments: '0',
		returnPath: '',
		closed: false,
		deleted: false,
		answered: false,
		wasForwardedToExternal: false,
		wasClonedAndForwardedExternal: false,
		wasCloned: false,
		feedbackAnswer: '',
		feedbackLink: '',
		fbPms: true,
		fbLikes: '',
		fbAction: '',
		sendPm: false,
		hasAnswer: false,
		answerDeleted: false,
		retweet: false,
		twitterPm: false,
		endTimer:0,
		secureUserId: ''
	},
	groupWith: 0,
	hasChild: false,
	id: 0,
	style: {},
	threadId: 0
};

const emptyProfile = {
	photo: UNKNOWN_PHOTO_LINK
	, name: ""
};

const emptyErrandData = {
	errand: emptyErrand // TODO: this is wrong as it should be emptyErrand.data
	, client: emptyProfile
	, extendedData: emptyObject
};

const emptyErrandAndClient = update(emptyErrandData, {history: {$set: emptyObject}});

const getFrom = data => {
	let frm;
	if(data.secureUserId && data.secureUserId.length > 0) {
		frm = data.secureUserId;
	} else if(data.fromName) {
		frm = data.fromName;
	} else if(data.fromAddress) {
		frm = data.fromAddress;
	} else {
		frm = data.from;
	}
	return frm;
};

export function initialThreadIDSelector(state) {
	return getAppErrand(state).acquireOpr.initialThreadId;
}

export const getErrandBasicMemoize = getErrandBasic;

const emptyData = { data: emptyArray };

const fetchHistoryData = state => state.app.errand.fetchHistory.data

const historyDataSelector = createSelector(
	isChatErrand,
	fetchHistoryData,
	(isChat, historyData) => {
		if (isChat) {
			return update(historyData, { $merge: emptyData })
		} else if (historyData && historyData.data) {
			return historyData
		}
		return emptyData
	}
);

export const getExtendedDataSelector = (state, props) => {
	if (isChat(state)) {
		return emptyData; // TODO include extended data in ChatErrand
	}
	let exData = state.app.errand.fetchExtendedData.data;
	if (!exData) {
		return emptyData;
	}
	return exData;
};

const defAttachmentList = {
	list: [],
	size: 0,
	insertedQuestionSize: 0,
	savedSize: 0,
	uploadedSize: 0,
	archiveSize: 0,
	collaborateAnswerSize: 0,
	excludeIncomingSize: 0,
	totalSize: 0
};

const createSelectedQuestionAttachmentsSelector = attachmentsSelector => createSelector(
	attachmentsSelector
	, getExtendedDataSelector
	, (attachments, extend) => {
		if (!extend
			|| !extend.data
			|| !extend.data.errand_attachment_list
			|| !extend.data.errand_attachment_list.length
			|| !attachments) {
			return emptyArray;
		}
		const list = extend.data.errand_attachment_list;
		let result = [];
		$.each(list, (i, v) => {
			if (attachments[v.id] && (!cflag.IsActive("2024-03-26.CEN-2280.exclude.unselected.inline.image") || !v.embeddedImage)) {
				result.push(v);
			}
		});
		return result;
	}
);

const questionAttachments = state => getValidReplyInputs(state).question_attachments;

export const questionAttachmentsSelector = createSelectedQuestionAttachmentsSelector(questionAttachments);

const externalForwardQuestionAttachments = state => getValidReplyInputs(state).selected_externalforward_attachment;

export const selectedExtFwdQuestionAttachmentsSelector = createSelectedQuestionAttachmentsSelector(externalForwardQuestionAttachments);

const savedAttachments = state => getValidReplyInputs(state).saved_attachments;

export const uploadedAttachmentsSelector = state => getValidReplyInputs(state).uploaded_attachments;

export const archiveAttachmentsSelector = state => getValidReplyInputs(state).archive_attachments;

export const libraryAttachmentsSelector = state => getValidReplyInputs(state).library_attachments;

export const collaborateAnsAttachmentsSelector = state => getValidReplyInputs(state).update_external_expert_Answer_attachments;

export const attachmentListSelector = createSelector(
	isCollaborationSelector
	, getExtendedDataSelector
	, questionAttachments
	, savedAttachments
	, uploadedAttachmentsSelector
	, archiveAttachmentsSelector
	, libraryAttachmentsSelector
	, collaborateAnsAttachmentsSelector
	, collaborateAttachmentListSelector
	, (collaborate, extend, question, saved, uploaded, archive, library, colAns, collaborateList) => {
		if(!collaborate) {
			if(!extend || extend.wip) {
				return update(defAttachmentList, {notReady: {$set: true}});
			}
			let savedSize, uploadedSize, archiveSize, collaborateAnswerSize,
				librarySize, excludeIncomingSize;
			if(saved.length) {
				savedSize = saved.length;
			} else {
				savedSize = 0;
			}
			if(uploaded.length) {
				uploadedSize = uploaded.length;
			} else {
				uploadedSize = 0;
			}
			if(archive.length) {
				archiveSize = archive.length;
			} else {
				archiveSize = 0;
			}
			if(library && library.length){
				librarySize = library.length;
			} else {
				librarySize = 0;
			}
			if(colAns.length) {
				collaborateAnswerSize = colAns.length;
			} else {
				collaborateAnswerSize = 0;
			}
			excludeIncomingSize = savedSize + uploadedSize + archiveSize +
				+ librarySize + collaborateAnswerSize;
			if(!excludeIncomingSize && (!extend.data ||
				!extend.data.errand_attachment_list ||
				!extend.data.errand_attachment_list.length)) {
				return defAttachmentList;
			}
			const list = extend.data.errand_attachment_list;
			let size, insertedQuestionSize = 0;
			if(list && list.length) {
				size = list.length;
				$.each(question, (k,v) => {
					if(v) {
						insertedQuestionSize++
					}
				});
			} else {
				size = 0;
			}
			return {list, size, insertedQuestionSize, savedSize, uploadedSize,
				archiveSize, collaborateAnswerSize, excludeIncomingSize,
				totalSize: size+excludeIncomingSize};
		}
		return collaborateList;
	}
);

export const historyAttachmentListSelector = createSelector(
	inputsReplyCheckboxes
	, historyDataSelector
	, getCurrentErrand
	, getCurrentReply
	, replyCheckboxStatesSelector
	, (inputsCheckbox, history, errand, currentReply, checkboxState) => {
		if(history && (currentReply === RPLY_ERRAND && inputsCheckbox[ECB_INC_HISTORIES])
			|| (currentReply === RPLY_EXT_FWD && checkboxState[ECB_FWD_ALL_HISTORIES])) {
			let list = {}, size = 0;
			$.each(history.data, (i, e) => {
				if(errand !== e.eid) {
					list[e.displayId] = [...e.incoming, ...e.outgoing];
					size += list[e.displayId].length;
				}
			});
			return {list, size};
		}
		return defAttachmentList;
	}
);

const chatAndNotCollaborateMemo = createSelector(
	isChat,
	isCollaborationSelector,
	(chat, collaborate) => chat && !collaborate
)

export const attachmentNotReady = createSelector(
	chatAndNotCollaborateMemo,
	attachmentListSelector,
	(chat, { notReady }) => {
		if (chat) {
			return false;
		}
		return !!notReady;
	}
)

export const incomingAttachmentList = (state, props) => {
	if (chatAndNotCollaborateMemo(state)) {
		return emptyArray;
	}
	return attachmentListSelector(state, props).list;
}

export const incomingAttachmentsSize = (state, props) => {
	if (chatAndNotCollaborateMemo(state)) {
		return 0;
	}
	return attachmentListSelector(state, props).size;
}

export const totalAttachmentsSize = (state, props) => {
	if (chatAndNotCollaborateMemo(state)) {
		return 0;
	}
	return attachmentListSelector(state, props).totalSize;
}

const defNotesAttachmentList = {list: [], size: 0};

const currentNotesSelector = createSelector(
	[
		getCurrentErrand,
		getDomainNotes
	],
	(errandID, notes) => notes.byId[errandID]
);

function convertInternalCommentAttachments(as) {
	let attachments = [];
	if(!as) {
		return attachments;
	}
	$.each(as, (i,v) => {
		attachments.push({
			id: v.id,
			estSize: v.estSize,
			fileName: v.fileName,
			src: v.downloadURL,
			download: v.downloadURL
		});
	});
	return attachments;
}

export const internalCommentAttachments = createSelector(
	currentNotesSelector,
	currentNotes => {
		if(currentNotes && currentNotes.notes && currentNotes.notes.length) {
			let attachmentsById = {};
			$.each(currentNotes.notes, (i,v) => {
				attachmentsById[v.id] = convertInternalCommentAttachments(
					v.attachments);
			});
			return attachmentsById;
		}
	}
);

export function getCompList(state) {
	const { data } = getAppAdmin(state).companyList;
	if(data && data.length > 0) {
		return data;
	}
	return [];
}

export const companyListSelector = createSelector(
	getCompList,
	compList => {
		return compList;
	}
);

export function customerNoteAttachments(state) {
	const contactCard = state.app.errand.ui.contactCard;
	if(contactCard) {
		let attachments = [];
		$.each(contactCard.noteAttachment, (i,v) => {
			attachments.push({
				id: v.id,
				estSize: v.estSize,
				fileName: v.value,
				download: v.download
			});
		});
		return [{list: attachments}];
	}
	return;
}

export function announcementAttachmentSelector(state) {
	const announcement = state.app.notification.uiData.announcement;
	if(announcement) {
		let attachments = [];
		$.each(announcement.announcementAttachment, (i,v) => {
			attachments.push({
				id: v.id,
				estSize: v.estSize,
				fileName: v.fileName,
				download: v.download
			});
		});
		return [{list: attachments}];
	}
	return;
}

export function allowInternalCommentMemoize(state) {
	const wfData = state.app.workflow.fetchWfSettings.data;
	if (!wfData) {
		return false;
	}
	return wfData['notes.errand'];
}

export function allowAgentEditQuestionMemoize(state) {
	const wfData = state.app.workflow.fetchWfSettings.data;
	if (!wfData) {
		return false;
	}
	return wfData['edit-question'];
}

function getCurrentErrandICState(state) {
	return currentErrand(state).internalComment;
}

export const internalCommentStateSelector = createSelector(
	[
		allowInternalCommentMemoize,
		getCurrentErrandICState
	],
	(canInternalComment, icState) => {
		if (!canInternalComment) {
			return ICST_NO_DATA;
		}
		return icState;
	}
);

export const notesAttachmentsSelector = createSelector(
	[
		getCurrentReply,
		internalCommentAttachments
	],
	(reply, attachmentsObj) => {
		if(reply !== RPLY_COMMENT || !attachmentsObj) {
			return defNotesAttachmentList;
		}
		let attachments = [];
		$.each(attachmentsObj, (k,v) => {
			attachments = attachments.concat(v);
		});
		return attachments;
	}
);

export const getInternalCommentsSelector = createSelector(
	currentNotesSelector,
	currentNotes => {
		if(currentNotes && currentNotes.notes && currentNotes.notes.length) {
			return currentNotes.notes;
		}
	}
);

export const savedAttachmentsSelector = createSelector(
	[
		isCollaborationSelector,
		savedAttachments
	],
	(isCollaborate, saved) => {
		return saved;
	}
);

function insertFrm(errand) {
	if (!errand) {
		return emptyErrand;
	}
	return update(errand, {data: {$merge: {frm: getFrom(errand.data)}}});
}

export const getCurrentBasicErrand = createSelector(getErrandBasic, insertFrm);

const getDomainClientAvatar = state => getDomain(state)["clientAvatar"].byId;

export const getClientAvatarByErrand = createSelector(
	[
		getErrandBasic
	],
	(errand) => {
		let photo = "";
		if(errand && errand.data && errand.data.fromAvatar){
			photo = errand.data.fromAvatar;
		}
		return photo;
	}
);

const getClientAvatarInfoByErrand = createSelector(
	[
		getCurrentBasicErrand,
		getDomainClientAvatar
	],
	(errand, avatar) => {
		if(errand && avatar){
			let ob = avatar[errand.data.fromId];
			if(ob) {
				return ob;
			}
		}
		return emptyObject;
	}
);

const getCurrentErrandData = createSelector(
	getCurrentBasicErrand
	, getExtendedDataSelector
	, (errand, exData) => {
		if (!errand) {
			return emptyErrandData;
		}
		return {
			errand: errand.data
			, extendedData: exData
			, client: {
				photo: errand.data? errand.data.fromAvatar : ""
				, name: errand.data? errand.data.fromName : ""
			}
			, style: errand.style
		};
	}
);

export const isCurrentErrandWasExternalForwarded = noSelector(
	getCurrentErrandData
	, ({ errand }) => errand.wasForwardedToExternal
);

export const getErrandDataAndClientById = createSelector(
	[
		getCurrentBasicErrand,
		getExtendedDataSelector,
		historyDataSelector,
		getClientAvatarByErrand
	],
	(errand, exData, history, photo) => {
		if(!errand) {
			return emptyErrandAndClient;
		}
		return {
			errand: errand.data,
			extendedData: exData,
			client: {
				photo: photo,
				name: errand.data.frm,
				fromAddress: errand.data.fromAddress
			},
			history: history,
			style: errand.style
		};
	}
);

function getOtherContactsData(state, props) {
	return getOtherContacts(state).data;
}

function getHistoryContactsData(state, props) {
	return getHistoryContacts(state).data;
}

function getCompanyHistoryData(state, props) {
	return getCompanyHistoryContacts(state).data;
}

function getCompanyOtherContactData(state, props) {
	return getCompanyOtherContacts(state).data;
}

function getCompanyMode(state, props) {
	return getUI(state).contactCard.showCompanyInfo;
}

const emptyOtherContact = {
	order: emptyArray
	, norm: emptyObject
};

export const contactCardOtherContactSelector = createSelector(
	getOtherContactsData
	, getHistoryContactsData
	, getCompanyMode
	, getCompanyOtherContactData
	, getCompanyHistoryData
	, (open, history, company, companyOpen, companyHistory) => {
		let order, norm;
		let errandList =[];
		if(company) {
			if (!companyOpen && !companyHistory) {
				return emptyOtherContact;
			} else if (!companyOpen) {
				return companyHistory;
			} else if (!companyHistory) {
				return companyOpen;
			}
			order = mergeTwoSorted(companyOpen.order, companyHistory.order, "reverse")
			, norm = update(companyOpen.norm, {$merge: companyHistory.norm})
			;
		} else{
			if (!open && !history) {
				return emptyOtherContact;
			} else if (!open) {
				return history;
			} else if (!history) {
				return open;
			}
			order = mergeTwoSorted(open.order, history.order, "reverse")
			, norm = update(open.norm, {$merge: history.norm})
			;
		}
		errandList = Object.values(norm).map(obj => ({ ...obj.data, threadId: obj.threadId }));
		return {norm, order, errandList}
	}
);

const getErrandHistoryAgents = (histories, agentId) => {
	let totalAgent = 1
		, uniqueness = {[agentId]: true}
		;
	$.each(histories, (i, v) => {
		if (!uniqueness[v.userId]) {
			totalAgent++;
			uniqueness[v.userId] = true;
		}
	});
	return totalAgent;
};

function countHistoryAgents(errand, histories) {
	if (!errand || !errand.data || !histories || !histories.length) {
		return 0;
	}
	return getErrandHistoryAgents(histories, errand.data.agentId);
}

export const errandHistoryAgentsSelector = createSelector(
	getCurrentBasicErrand
	, historyDataSelector
	, countHistoryAgents
);

const emptyMyErrand = update(emptyErrandData, {$merge: {
	agent: emptyProfile
	, question: ""
	, answer: ""
	, isMine: false
}});

const defaultNotMine = {
	id: 0,
	name: '',
	type: NOT_MINE_INVALID,
	status: ''
};

export const notMineSelector = createSelector(
	[
		getAcquireData,
		getMyId
	],
	(acquire, myId) => {
		if(!acquire) {
			return update(defaultNotMine, {status: {
				$set: 'Invalid acquire data.'}});
		} else if(acquire.error) {
			return update(defaultNotMine, {
				type: {$set: NOT_MINE_ERROR},
				status: {$set: acquire.error}
			});
		} else if(acquire.acquired) {
			if(acquire.owner && acquire.owner.id !== myId) {
				return update(defaultNotMine, {
					status: {
						$set: I('Inconsistent owner ID: {MY_ID} {OWNER_ID}.')
							.replace('{MY_ID}', myId)
							.replace('{OWNER_ID}', acquire.owner.id)
					}
				});
			}
			return false;
		}
		const data = acquire.data;
		let status;
		if(data.closed || data.deleted) {
			status = I('This errand has already been handled by {0}.')
				.format(acquire.owner.name);
		} else {
			status = I('This errand is owned by {OWNER_NAME}.')
				.replace('{OWNER_NAME}', acquire.owner.name);
		}
		return update(defaultNotMine, {
			type: {$set: NOT_MINE_OTHER_AGENT},
			status: {$set: status}
		});
	}
);

const getMyErrand = createSelector(
	getCurrentErrandData
	, getAcquireData
	, getMyId
	, (errand, acquire, myId) => {
		if (!errand || !acquire || acquire.error || !acquire.data) {
			return emptyMyErrand;
		}
		let { answer_body: answer, question_body: question } = acquire.data;
		if (!question) {
			question = errand.errand.body;
		  }
		if(acquire.errand == errand.errand.id && typeof errand !== "undefined"
			&& answer != errand.errand.answer){
				answer = errand.errand.answer;
		}
		return update(errand, {$merge: {
			agent: {
				photo: acquire.owner.photo
				, name: acquire.owner.name
			}
			, question
			, answer
			, isMine:  acquire.owner.id === myId
		}});
	}
);

function _transformAttachments(a) {
	let as = [];
	$.each(a, (i,v) => {
		as.push({id: v.id, download: v.download, fileName: v.fileName, src: v.src});
	});
	return as;
}

function transformAttachments(h) {
	if(h.incoming && h.incoming.length) {
		h = update(h, {incoming: {$set: _transformAttachments(h.incoming)}});
	}
	if(h.outgoing && h.outgoing.length) {
		h = update(h, {outgoing: {$set: _transformAttachments(h.outgoing)}});
	}
	return h;
}

// NOTE: do NOT remove below commented code as it provides alternative for
// getting the answer action which in future can be useful if we need to
// seperate between close with answer send and without send answer.
// const reAction = /^(Errand|Chat)[ ]+(.*?) by (agent|client).*?$/
// 	, reChatExpired = /^Chat[ ]+expired due to inactivity.*?$/
// 	, actionVerbs = {
// 		answered: 'was answered'
// 		, closed: 'was closed'
// 		, closedWithoutSend: 'was closed without sending an answer'
// 		, forwarded: 'was forwarded'
// 		, replied: 'was replied'
// 		, saved: 'was saved'
// 	}
// 	;
// function infoFromActionNote(actions, h) {
// 	let foundLastSaved
// 		, foundLastAction
// 		, actionType
// 		;
// 	$.each(actions, (j, { datetime, notes, origin, ts }) => {
// 		const matches = reAction.exec(notes);
// 		if (matches) {
// 			const match = matches[2];
// 			if (hasPrefix(match, actionVerbs.saved)) {
// 				foundLastSaved = {datetime, origin, ts};
// 				if (foundLastAction) {
// 					return false;
// 				}
// 			} else {
// 				if (hasPrefix(match, actionVerbs.closedWithoutSend)) {
// 					actionType = ACT_CLOSED;
// 				} else if (hasPrefix(match, actionVerbs.closed)) {
// 					actionType = ACT_CLOSED;
// 				} else if (hasPrefix(match, actionVerbs.answered)) {
// 					actionType = ACT_ANSWERED;
// 				} else if (hasPrefix(match, actionVerbs.forwarded)) {
// 					actionType = ACT_FORWARDED;
// 				}
// 			}
// 		} else if (reChatExpired.test(notes)) {
// 			actionType = ACT_CLOSED;
// 		}
// 		if (actionType) {
// 			foundLastAction = {datetime, origin, actionType, ts};
// 			if (foundLastSaved) {
// 				return false;
// 			}
// 		}
// 	});
// 	return [foundLastSaved, foundLastAction];
// }

function infoFromActions(actions, h, actionsLink) {
	let foundLastSaved
		, foundLastAction
		;
	$.each(actions, (j, { type, datetime, origin, ts }) => {
		const act = actionsLink[type];
		if (act) {
			if (act === ACT_SAVE) {
				foundLastSaved = {datetime, origin, ts};
				if (foundLastAction) {
					return false;
				}
			} else if (act === ACT_CLOSED
				|| act === ACT_ANSWERED
				|| act === ACT_FORWARDED) {
				foundLastAction = {datetime, origin, actionType: act, ts};
				if (foundLastSaved) {
					return false;
				}
			}
		}
	});
	return [foundLastSaved, foundLastAction];
}

function getInfoFromActions(h, actionsLink) {
	const reversedActions = h.actions.slice().reverse()
		, [
			foundLastSaved
			, foundLastAction
		] = infoFromActions(reversedActions, h, actionsLink)
		;
	if (foundLastSaved) {
		const lastSaved = {
			dateTime: foundLastSaved.ts
			, agent: {
				photo: UNKNOWN_PHOTO_LINK
				, name: foundLastSaved.origin
			}
		};
		h = update(h, {$merge: {lastSaved}});
	}
	if (foundLastAction) {
		const lastAction = {
			dateTime: foundLastAction.ts
			, agent: {
				photo: UNKNOWN_PHOTO_LINK
				, name: foundLastAction.origin
			},
			actionType: foundLastAction.actionType
		};
		h = update(h, {$merge: {lastAction}});
	}
	return h;
}

const transformAttachmentsAndActions = (
	history,
	actionsLink
) => transformAttachments(getInfoFromActions(history, actionsLink))

function historiesTransforms(histories, actionsLink) {
	let updatedHistories = histories;
	each(histories, (v, i) => {
		const newHistory = transformAttachmentsAndActions(v, actionsLink)
		if (newHistory !== v) {
			updatedHistories = update(
				updatedHistories
				, { $splice: [[i, 1, newHistory]] }
			)
		}
	})
	if (updatedHistories != histories) {
		return updatedHistories
	}
	return histories
}

const getHistoriesData = state => {
	const e = state.app.errand;
	if(isChat(state) || !e.currentErrand.history) {
		return;
	}
	const h = e.fetchHistory;
	if(h && h.data && h.data.data && h.data.data.length) {
		return h.data.data;
	}
};

const getHistoriesOpr = state => {
	const e = state.app.errand;
	if(!e.currentErrand.history) {
		return;
	}
	const h = e.fetchHistory;
	if(h && h.data && h.data.data && h.data.data.length) {
		return h.data.opr;
	}
};

const getHistories = createSelector(
	getHistoriesData
	, actionsLinkMemo
	, (historiesData, actionsLink) => {
		if (!historiesData) {
			return;
		}
		return historiesTransforms(historiesData, actionsLink);
	}
);

const normalizeHistories = histories => {
	const byId = {}
	const allIds = []
	const threads = {}
	each(histories, v => {
		const { eid, threadId } = v
		byId[eid] = v
		threads[eid] = threadId
		allIds.push(eid)
	})
	return { allIds, byId, threads }
}

const errandFromHistories = (historiesByThreadId, errandId, threadId) => {
	const histories = historiesByThreadId[threadId]
	if (histories && histories.data && histories.data.length) {
		let _history
		each(histories.data, history => {
			const { eid } = history
			if (eid === errandId) {
				_history = history
				return false
			}
		})
		return _history
	}
}

const currentErrandThreadIdMemo = noSelector(
	getErrandBasic,
	basic => {
		if (basic) {
			return basic.threadId
		}
	}
)

const getHistoryFromErrandIds = (
	allIds,
	byId,
	threads,
	historiesByThread,
	actionsLink,
	errands,
	currentThreadId,
	errandIds
) => {
	each(errandIds, id => {
		const { threadId } = errands[id]
		if (threadId === currentThreadId) {
			return
		}
		const errand = errandFromHistories(historiesByThread, id, threadId)
		allIds.push(id)
		threads[id] = threadId
		if (errand) {
			byId[id] = transformAttachmentsAndActions(errand, actionsLink)
		}
	})
}

export const getCurrentErrandId = state => {
	const e = state.app.errand;
	if(e && e.currentErrand && e.currentErrand.id) {
		return e.currentErrand.id;
	}
};

export const getPreviousErrandId = state => {
	const e = state.app.errand;
	if(e && e.ui ){
		return e.ui.previousErrandId;
	}
	return 0;
};

export const getCurrentErrandOpening = state => {
	const e = state.app.errand;
	if(e && e.ui ){
		return e.ui.currentErrandOpening;
	}
	return 0;
};

const getSortHistory = state => {
	const wfData = state.app.workflow.fetchWfSettings.data;
	if(typeof wfData !== "undefined" && wfData){
		return wfData.sortHistoryAscending;
	}
	return true;
};

// comparison sort undefined value last.
const comparison = (ascending, a, b) => {
	if (!ascending) {
		const tmp = b
		b = a
		a = tmp
	}
	const typeOfA = typeof a
	const typeOfB = typeof b
	if (typeOfA === 'undefined' && typeOfB === 'undefined') {
		return 0
	} else if (typeOfA === 'undefined') {
		return 1
	} else if (typeOfB === 'undefined') {
		return -1
	}
	if (a > b) {
		return 1
	} else if (a < b) {
		return -1
	}
	return 0
}

// TODO: refactor to use 'comparison'.
function sortHistory(ascending, histories){
	histories.sort(function(a, b) {
		if(ascending){
			if ( a.created > b.created )
				return 1;
			if ( a.created < b.created )
				return -1;
			return 0;
		} else {
			if ( a.created < b.created )
				return 1;
			if ( a.created > b.created )
				return -1;
			return 0;
		}
	});

	return histories
}

const emptyMyErrandAndMyHistory = update(
	emptyMyErrand,
	{ history: { $set: emptyObject } }
)
const emptyHistoryAndMy = {
	histories: emptyArray,
	my: emptyMyErrandAndMyHistory,
	avatars: emptyObject
}
const emptyHistoriesAndOthers = {
	byId: emptyObject,
	allIds: emptyArray,
	threads: emptyObject
}
const emptyHistoryOrOtherAndMy = {
	historiesAndOthers: emptyHistoriesAndOthers,
	my: emptyMyErrandAndMyHistory,
	avatars: emptyObject
}

const getClientAvatars = createSelector(
	getDomainClientAvatar
	,	(avatar) => {
		if(avatar){
			return avatar;
		}
		return emptyObject;
	}
);

const includeCurrentErrandInHistories = state => {
	const wfData = state.app.workflow.fetchWfSettings.data;
	if(wfData){
		return wfData["include-current-errand-in-history"];
	}
	return false;
};

const addCurrentErrandForSequentialHistoriesThread = (total, include) => {
	if (total <= 2 && include) {
		return false
	}
	return include
}

const getEid = (byId, id) => {
	const value = byId[id]
	if (value) {
		return value.eid
	}
}

const sortNormalizedHistory = (ascending, histories) => {
	const { byId, allIds } = histories
	const _allIds = allIds.slice()
	_allIds.sort((a, b) => comparison(
		ascending,
		getEid(byId, a),
		getEid(byId, b)
	))
	return update(histories, { allIds: { $set: _allIds } })
}

// TODO: remove this
const oldGetHistoriesAndMy = createSelector(
	getCurrentErrandId,
	getMyErrand,
	getHistories,
	getSortHistory,
	getClientAvatars,
	includeCurrentErrandInHistories,
	(
		errandId,
		my,
		histories,
		sortHistoryAscending,
		avatars,
		includeCurrentErrand
	) => {
		if (!errandId) {
			return emptyHistoryAndMy;
		} else if (!histories) {
			histories = emptyArray;
			my = update(my, {history: {$set: emptyObject}});
		} else {
			let found, index;
			if(histories.length <= 2 && includeCurrentErrand){
				includeCurrentErrand = false;
			}
			$.each(histories, (i,v) => {
				if(v.eid === errandId) {
					found = true;
					index = i;
					return false;
				}
			});
			if (found) {
				const history = histories[index]
					, { lastAction, lastSaved } = history
					;
				my = update(my, {$merge: {lastAction, lastSaved, history}});
				if(!includeCurrentErrand) {
					histories = update(histories, {$splice: [[index, 1]]});
				}
				// TODO: this is wrong as sorting mutating histories array which is
				// something should not be done in selector. But it unlikely causing any
				// problem for time being as long as no code use un-sort data but no one
				// can predict the future code, a code should always follow proper rule.
				histories = sortHistory(sortHistoryAscending, histories);
			} else {
				// weird case: history data do not contain current errand.
				// TODO: this seem can happen because during opening an errand,
				// history is not being fetched first and there is chances that
				// previous fetching history (which already outdated) will
				// arrived and may be temporary used as current history data.
				// This only happen during opening errand while another errand
				// still being opened. Still need a better solution where
				// opening an errand need to cancel any previous on-going data
				// fetching or invalidate them when found outdated.
				my = update(my, {history: {$set: emptyObject}});
			}
		}
		return {histories, my, avatars};
	}
);

const emptyOtherContactMy = {client: emptyProfile}
	, emptyOtherContactErrand = {
		histories: emptyArray
		, my: emptyOtherContactMy
		, avatars: emptyObject
	}
	;
const getOtherContactErrandId = state => getAcquireOpr(state).showId;

const getOtherContactThreadId = state => getAcquireOpr(state).showThreadId;

const emptyHistoryData = {data: emptyArray};

export const otherContactErrandHistorySelector = createSelector(
	getDomainHistoryById
	, getOtherContactThreadId
	, actionsLinkMemo
	, getSortHistory
	, (byId, threadId, actionsLink, sortHistoryAscending) => {
		let histories = byId[threadId];
		if (!histories || !histories.data || !histories.data.length) {
			return emptyHistoryData;
		}
		histories = histories.data;
		histories = historiesTransforms(histories, actionsLink);
		if (!histories || !histories.length) {
			return emptyHistoryData;
		}
		histories = sortHistory(sortHistoryAscending, histories);
		return {data: histories};
	}
);

const otherContactErrandHistoriesData = createSelector(
	otherContactErrandHistorySelector
	, histories => {
		if (!histories.data.length) {
			return emptyArray;
		}
		return histories.data;
	}
);

const emptySeperatedOtherContactHistories = [emptyArray, emptyObject];

const seperateOtherContactErrandHistories = createSelector(
	otherContactErrandHistoriesData
	, getOtherContactErrandId
	, (histories, errandId) => {
		if (!histories.length) {
			return emptySeperatedOtherContactHistories;
		}
		let myHistory;
		$.each(histories, (i, v) => {
			if (v.eid === errandId) {
				myHistory = v;
				histories = update(histories, {$splice: [[i, 1]]});
				return false;
			}
		});
		if (!myHistory) {
			myHistory = emptyObject;
		}
		return [histories, myHistory];
	}
);

const otherContactErrandHistoryDataSelector = createSelector(
	seperateOtherContactErrandHistories
	, ([h, data]) => data
);

export const otherContactErrandTagsSelector = createSelector(
	otherContactErrandHistoryDataSelector
	, history => {
		if (!history.tagsList) {
			return emptyArray;
		}
		return history.tagsList;
	}
);

export const otherContactErrandIncomingAttachmentSizeSelector = createSelector(
	otherContactErrandHistoryDataSelector
	, history => {
		if (!history.incoming) {
			return 0;
		}
		return history.incoming.length;
	}
);

export const otherContactErrandAttachmentSizeSelector = createSelector(
	otherContactErrandHistoryDataSelector
	, otherContactErrandIncomingAttachmentSizeSelector
	, (history, incomingSize) => {
		let outgoingSize;
		if (!history.outgoing || !history.outgoing.length) {
			outgoingSize = 0
		} else {
			outgoingSize = history.outgoing.length;
		}
		if (!incomingSize && !outgoingSize) {
			return 0;
		}
		return incomingSize + outgoingSize;
	}
);

const otherContactBasicErrand = createSelector(
	getDomainBasicErrandById
	, getOtherContactErrandId
	, (byId, errandId) => byId[errandId]
);

export const otherContactBasicErrandSelector = createSelector(
	otherContactBasicErrand
	, errand => insertFrm(errand).data
);

export const canAcquireOtherContactErrandSelector = createSelector(
	otherContactBasicErrandSelector
	, getMyId
	, (errand, myId) => canAcquire(myId, errand.agentId, errand)
);

const emptyOwner = {
		id: 0
		, name: ""
		, photo: ""
	}
	, emptyAcquireData = {
		acquired: false
		, data: {
			answer_body: ""
			, answer_subject: ""
			, deleted: false
			, question_body: ""
			, related_errands: emptyArray
		}
		, displayId: ""
		, errand: 0
		, error: false
		, owner: emptyOwner
	}
	;
export const otherContactErrandOwnerSelector = createSelector(
	otherContactBasicErrandSelector
	, ({ agentId, agent, agentAvatar }) => {
		if (!agentId) {
			return emptyOwner;
		}
		return update(
			emptyOwner
			, {$merge: {id: agentId, name: agent, photo: agentAvatar}}
		);
	}
);

export const otherContactErrandAcquireDataSelector = createSelector(
	otherContactErrandOwnerSelector
	, otherContactBasicErrand
	, (errandOwner, errand) => {
		if (!errand && !errandOwner.id) {
			return emptyAcquireData;
		}
		let acquired;
		if (errand) {
			acquired = errand.acquired;
		} else {
			acquired = false;
		}
		return update(
			emptyAcquireData
			, {acquired: {$set: acquired}, owner: {$set: errandOwner}}
		);
	}
);

export function otherContactHistoryReady(state) {
	const { wip, err } = getAppErrand(state).otherContactHistory;
	return !wip && !err;
}

export const otherContactErrandClientSelector = createSelector(
	otherContactBasicErrand
	, getDomainClientAvatar
	, (errand, avatars) => {
		if (!errand || !errand.data) {
			return emptyProfile;
		}
		const { data } = errand
			, { fromId } = data
			, ob = avatars[fromId]
			;
		let photo
			, name
			;
		if (ob && ob.avatar) {
			photo = ob.avatar.url;
		} else {
			photo = "";
		}
		name = getFrom(data);
		if (!name) {
			name = "";
		}
		if (!name && !photo) {
			return emptyProfile;
		}
		return {photo, name};
	}
);

export const otherContactErrandSelector = createSelector(
	otherContactErrandHistoriesData
	, otherContactErrandClientSelector
	, otherContactHistoryReady
	, getClientAvatars
	, (histories, client, ready, avatars) => {
		if (!ready) {
			return emptyOtherContactErrand
		}
		return {histories, my: {client}, avatars};
	}
);

export const otherContactErrandHistoryAgentsSelector = createSelector(
	otherContactBasicErrand
	, otherContactErrandHistoriesData
	, countHistoryAgents
);

const getMessageData = (state,props) =>{
	const messages = props;
	if(messages !== null){
		return messages
	}
	return [];
}

const getMessageId = state =>{ //this will return only the messageId
	return state
}

export const getInternalMessageData = createSelector(
	getMessageData,
	getMessageId,
	(data, stateMID) =>{
		if(data === null){
			return{};
		}
		let message = {};
		$.each(data, (k,v) => {
			if(v.mid === stateMID){
				message = data[k];
			}
		});
		return message;
	}
);

export const getHistoriesSelection = createSelector(
	getHistoriesOpr,
	opr => {
		if(!opr) {
			return {};
		}
		let selections = {}
		$.each(opr, (k,v) => {
			if(v.selected) {
				selections[k] = true;
			} else {
				selections[k] = false;
			}
		});
		return selections;
	}
);

const getFetchClientsAddressListReady = state => {
	const e = state.app.errand;
	if(e && e.currentErrand && e.currentErrand.otherAddresses) {
		return true;
	}
};

const getFetchClientsAddressList = state => {
	const f = state.app.errand.fetchClientsAddressList.data;
	if(f && f.clientAddress && f.clientAddress.length) {
		return f.clientAddress;
	}
};

const getClientFromOtherContact = state => {
	const f = state.app.errand.fetchClientsAddressList.data;
	if(f && f.clientName) {
		return f.clientName;
	}
};

export const getClientContactCardName = state => {
	const ui = state.app.errand.ui;
	if(ui && ui.contactCard.name) {
		return ui.contactCard.name;
	} else {
		return getClientFromOtherContact(state);
	}
};

const getFetchClientNotesCount = state => {
	const f = state.app.errand.fetchClientsAddressList.data;
	if(f && f.clientNotesCount) {
		return f.clientNotesCount;
	}
};

const getFetchSuggestAddTo = state => {
	const f = state.app.errand.fetchClientsAddressList.data;
	if(f && f.suggestAddTo) {
		return f.suggestAddTo;
	}
};

export const getContactCardStatus = createSelector(
	[
		getFetchClientsAddressListReady,
		getFetchClientsAddressList
	],
	(ready, addresses) => {
		if (!ready || !addresses) {
			return false;
		}
		if(addresses.length === 0){
			return false;
		}
		return true;
	}
);

export const getClientNotesStatus = createSelector(
	[
		getFetchClientsAddressListReady,
		getFetchClientNotesCount
	],
	(ready, notesCount) => {
		if (!ready || !notesCount) {
			return false;
		}
		if(notesCount === 0){
			return false;
		}
		return true;
	}
);

export const getSuggestAddTo = createSelector(
	[
		getFetchClientsAddressListReady,
		getFetchSuggestAddTo
	],
	(ready, suggestAddTo) => {
		if (!ready || !suggestAddTo) {
			return 0;
		}
		return suggestAddTo;
	}
);

export const getChannelsSelector = createSelector(
	[
		getFetchClientsAddressListReady
		, getFetchClientsAddressList
		, getServicesByType
		, getFilteredChannelsSelector
		, getCurrentChannel
		, getSourceService
	],
	(ready, addresses, services, filteredChannels, current, sourceService) => {
		// TODO: CCC-2826 8 Jan 2019 still not working as backend no update_channel
		// remove the 'true' below once working.
		// TODO: CCC-2826 need to allow reply to different channel BUT every
		// different may have multiple options to choose from. For example I can
		// reply with email channel, but they can be 2 or 3 emails option to
		// choose from. Or I can reply with Facebook private messagge but there
		// can be more than one Facebook account to choose from.
		// ALSO think about reply-channel and service-channel may not be same
		// thing because we can reply to Facebook channel which may be only mean
		// Facebook messenger as no point to reply to wall-post as does not
		// really make sense.
		let r = {[services[current].channel]: true};
		let result = [];
		if (ready && addresses){
			$.each(addresses, (i,v) => {
				$.each(filteredChannels, (j,w) => {
					if (v.serviceName === w.name){
						r[w.channel] = true;
						return false;
					}
				});
			});
		}
		$.each(filteredChannels, (j,w) => {
			// all errand can send through these chanel
			if (w.channel === RC_EMAIL){
				r[RC_EMAIL] = true;
			} else if (w.channel === RC_VOICE){
				r[RC_VOICE] = true;
			} else if (w.channel === RC_SMS){
				r[RC_SMS] = true;
			}
		});
		if (sourceService > 0 && sourceService != current){
			r["origin"] = true;
		}
		$.each(r, (k,v) => {
			result.push(k);
		});
		return result;
	}
);

const getCollabChannels = state =>{
	const d = state.app.workflow.fetchWfSettings.data;
	if(d && d.collaborationChannels){
		return d.collaborationChannels;
	}
}

export const getCollabChannelsSelector = createSelector(
	[
		getServicesByType
		, getCollabChannels
	],
	(services, collabChannels) => {
		let result = [];
		$.each(collabChannels, (i,v) => {
			$.each(services, (j,w) => {
				if(v.name.toLowerCase() === w.name.toLowerCase()){
					//Mujibur: Added the lowecase function here
					//reason behind is: lets a service name `Google Chat`
					//in any case of the letter should be supported
					//instead of case sensitivity
					result.push(w.channel);
				}
			});
		});
		return result;
	}
);

export const getCollabChannelsListSelector = createSelector(
	[
		getServicesByType
		, getCollabChannels
	],
	(services, collabChannels) => {
		let result = [];
		$.each(collabChannels, (i,v) => {
			$.each(services, (j,w) => {
				if(v.name === w.name){
					result.push(v);
				}
			});
		});
		return result;
	}
);

const getTimezoneOffset = state => {
	const d = state.app.workflow.fetchWfSettings.data;
	if(d && d.agentTimezoneOffset) {
		return d.agentTimezoneOffset;
	}
};

export const agentTimezoneOffsetSelector = createSelector(
	getTimezoneOffset,
	timezoneOffset => {
		if(!timezoneOffset) {
			return "+0000";
		}
		return timezoneOffset;
	}
);

export const agentTimezoneMemoize = state => agentTimezoneOffsetSelector(state);

const getInputsClassification = state => state.app.errand.inputs.classification;

export const isMultiSelectionErrandList = state => {
	const classification = getInputsClassification(state);
	if (classification && classification.option && classification.option.multiple) {
		return true;
	}
	return false;
};

export const isManualErrandClassification = createSelector(
	getInputsClassification,
	classification => {
		if(classification && classification.option && classification.option.manual) {
			return true;
		}
		return false;
	}
);

const isManualOrMulti = state => isMultiSelectionErrandList(state) ||
	isManualErrandClassification(state);

const getCurrentTaggingErrandData = createSelector(
	getInputsClassification
	, classification => {
		if (!classification) {
			return emptyErrand.data;
		}
		return classification.errands[classification.index];
	}
);

export const getCurrentTaggingErrand = createSelector(
	[
		isManualErrandClassification,
		getCurrentTaggingErrandData
	],
	(manual, errandData) => {
		if(manual) {
			return NEW_MANUAL_ERRAND_ID;
		}
		return errandData.id;
	}
);

const getDomain = state => state.domain;

const getDomainAreaDataById = state => getDomain(state)[D_AREAS].byId;

const getCurrentTaggingErrandAreaId = state => getCurrentTaggingErrandData(state).area;

const getCurrentTaggingAreaData = createSelector(
	[
		getCurrentTaggingErrandAreaId,
		getDomainAreaDataById
	],
	(id, areas) => areas[id]
);

const getCurrentTaggingExtendedData = createSelector(
	[
		getCurrentTaggingErrand,
		getDomain
	],
	(eId, domain) => {
		return {data: {errand_tags: []}}; // TODO: detect extended data too
	}
);

export const getCurrentOpenErrandID = createSelector(
	[
		isManualErrandClassification,
		getCurrentErrand
	],
	(manual, openedErrandID) => {
		if(manual) {
			return NEW_MANUAL_ERRAND_ID;
		}
		return openedErrandID;
	}
);

// NOTE: for time being manual consider is current errand meanwhile multi
// selection do not affect this and multi selection still can have current
// errand selected or no current errand.
export const getIsCurrentErrandSelector = createSelector(
	[
		isManualErrandClassification,
		getCurrentErrand,
		getCurrentTaggingErrand
	],
	(manual, currentErrand, taggingErrand) => {
		if (manual) {
			return true;
		}
		return currentErrand === taggingErrand;
	}
);

export const getClassificationReadinessSelector = createSelector(
	[
		getIsCurrentErrandSelector,
		isManualOrMulti,
		getCurrentTaggingAreaData,
		getCurrentTaggingExtendedData,
	],
	(isCurrent, manualOrMulti, areaData, extendedData) => {
		if(isCurrent || manualOrMulti) {
			return true;
		}
		if(!areaData) {
			return false;
		}
		if(!extendedData) {
			return false;
		}
		return true;
	}
);

function isClassificationActive(state) {
	return !!getInputsClassification(state);
}

function getFetchAreaTagWip(state) {
	return getAppErrand(state).fetchAreaTags.wip;
}

function getFetchAreaTagError(state) {
	return getAppErrand(state).fetchAreaTags.err;
}

const multiTaggingFetchAreaTagNotReadyBecauseNoTagging = {reason: I("No classification popup")}
	, multiTaggingFetchAreaTagNotReadyBecauseStillFetching = {reason: I("Still fetching area tags")}
	;
export const isClassificationFetchMultiAreasTagNotReady = createSelector(
	isClassificationActive
	, isMultiSelectionErrandList
	, getFetchAreaTagWip
	, getFetchAreaTagError
	, (active, isMulti, wip, err) => {
		if (!active) {
			return multiTaggingFetchAreaTagNotReadyBecauseNoTagging;
		} else if (!isMulti) {
			return false;
		} else if (wip) {
			return multiTaggingFetchAreaTagNotReadyBecauseStillFetching
		} else if (err) {
			return {
				reason: I("Error when fetching area tags: {ERROR}")
					.replace("{ERROR}", err)
			};
		}
		return false;
	}
);

export const isClassificationFetchMultiAreasTagReady = (state, props) =>
	!isClassificationFetchMultiAreasTagNotReady(state, props);

export const getCurrentErrandAreaDataDomain = state => state.app.errand.errandAreaData.domain;
const getCurrentErrandAreaDataErrand = state => state.app.errand.errandAreaData.data;

export const openErrandAreaLibraryMemo = createSelector(
	getCurrentErrandAreaDataErrand,
	areaData => {
		if (!areaData || !areaData.library) {
			return 0;
		}
		return areaData.library;
	}
);

const defaultAreaData = DEF_AREA_DATA;

export const getCurrentErrandAreaData = createSelector(
	getCurrentErrandAreaDataDomain,
	areaData => {
		if(!areaData) {
			return defaultAreaData;
		}
		return areaData;
	}
);

export const getAreaReWriteAnswerSetupMemo = createSelector(
	getCurrentErrandAreaDataDomain,
	areaData => {
		if (!areaData) {
			return emptyObject;
		}
		const { customAIInstructions, customAIResponse } = areaData;
		return {
			customAIInstructions,
			customAIResponse
		};
	}
)

const getRewriteAnswerBase = state => state.app.errand.ui.rewriteAnswerBase;
export const getRewriteAnswerBaseQuestionMemo = createSelector(
	getRewriteAnswerBase,
	rewriteAnswerBase => {
		if (!rewriteAnswerBase) {
			return false;
		}
		if(rewriteAnswerBase.currentQues != "") {
			return true;
		}
		return false;
	}
)

const getSuggestedAnswerData = state => state.app.errand.suggestedAnswer.data;
export const getSuggestedAnswer = createSelector(
	getSuggestedAnswerData,
	data => {
		if(data && data.suggested_answer) {
			return data.suggested_answer;
		}
		return [];
	}
);

const getCurrentChatOpen = state => state.app.errand.currentChatErrandId;
const getCurrentErrandOpen = state => currentErrand(state) ? currentErrand(state).id : 0;

export const getCurrentOpenErrandSelector = createSelector(
	[
		getCurrentChatOpen,
		getCurrentErrandOpen
	],
	(chatOpened, errandOpen) => {
		let currOpenId = 0;
		if(chatOpened !== -1) {
			currOpenId = chatOpened;
		}else{
			currOpenId = errandOpen;
		}
		return currOpenId;
	}
);

const getCurrentAreaAddressBook = createSelector(
	getCurrentErrandAreaData,
	areaData => {
		let items = areaData.addressbook_addresses;
		return items.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
	}
);

const validation = isValidEmail; // TODO: temporary only email, add more later

export const collaborationRecipientOption = createSelector(
	[
		isCollaborationSelector,
		eeeDomain
	],
	(isCollaborate, domain) => {
		if(!isCollaborate || !domain || !domain.list) {
			return [];
		}
		let seen = {}, items = [];
		addressValidation(items, seen, validation, domain.list.toAddressList);
		// NOTE: backend EE seem to use the same value as To to generate CC and
		// BCC option, so only check and add one enough.
		// addressValidation(items, seen, validation, domain.list.ccAddressList);
		// addressValidation(items, seen, validation, domain.list.bccAddressList);
		return items;
	}
);

const alreadyInputAddressesSelector = createSelector(
	getUpdateToOrUpdateForward
	, getUpdateCc
	, getUpdateBcc
	, (toOrForward, cc, bcc) => {
		let result;
		if (toOrForward && toOrForward.length) {
			result = toOrForward.concat(cc).concat(bcc);
		} else if (cc && cc.length) {
			result = cc.concat(bcc);
		} else if (bcc && bcc.length) {
			result = bcc;
		} else {
			return emptyObject;
		}
		let seen = {};
		$.each(result, (i, v) => {
			seen[v.id] = true;
		});
		return seen;
	}
);

function validateArrayAddress(items, seen, validation, addresses) {
	if (addresses && addresses.length) {
		let addrs = [];
		$.each(addresses, (i, value) => {
			addrs.push({id: value, value});
		});
		addressValidation(items, seen, validation, addrs);
	}
}

function extractEmailAddresses(body) {
	if(typeof body !== 'undefined'){
		var r = body.match(findEmailRegex);
		if(r) {
			return r;
		}
	}
	return [];
}

export const recipientsOptionsSelector = createSelector(
	[
		alreadyInputAddressesSelector,
		getCurrentAreaAddressBook,
		getCurrentBasicErrand,
		collaborationRecipientOption
	],
	(alreadyInput, addressBook, basic, collaborateOption) => {
		const from = basic.data.fromAddress
			, ccAddresses = basic.data.copyAddresses
			;
		let items = [], seen = {...alreadyInput};
		if(from && validation(from) && !seen[from]) {
			items.push({id: from, value: from});
			seen[from] = true;
		}
		validateArrayAddress(items, seen, validation, basic.data.toAddresses);
		validateArrayAddress(items, seen, validation, basic.data.copyAddresses);
		if (ccAddresses && ccAddresses.length) {
			let addrs = [];
			$.each(ccAddresses, (i, value) => {
				addrs.push({id: value, value});
			});
			addressValidation(items, seen, validation, addrs);
		}
		if (basic.data.body) {
			$.each(extractEmailAddresses(basic.data.body), (i, v) => {
				let email = v.toLowerCase();
				if(!seen[email]) {
					items.push({id: email, value: email});
					seen[email] = true;
				}
			});
		}
		if(addressBook && addressBook.length) {
			$.each(addressBook, (i,v) => {
				if(seen[v.id]) {
					return;
				}
				if(v.value === v.id) {
					items.push({id: v.id, value: v.id});
				} else {
					items.push({id: v.id, value: v.value + " ("+v.id+")"});
				}
				seen[v.id] = true;
			});
		}
		addressValidation(items, seen, validation, collaborateOption);
		return items.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
	}
);

const getCurrentAreaTags = createSelector(
	getCurrentErrandAreaData,
	areaData => areaData.normal_tags
);

const getCurrentAreaDeleteTags = createSelector(
	getCurrentErrandAreaData,
	areaData => areaData.delete_tags
);

const getMultipleErrand = createSelector(
	[
		getCurrentTaggingErrandData,
		getDomainBasicErrandById
	],
	(errandData, errands) => {
		let e = emptyErrand;
		if (errands && errandData && errandData.id && errands[errandData.id] && errands[errandData.id].data) {
			e = errands[errandData.id].data;
		}
		return e;
	}
);

const getChatAreaTags = chat => {
	let area = getChatArea(chat);
	if (area) {
		return area.normal_tags;
	}
	return [];
};

const getChatAllAreaTags = chat => {
	let area = getChatArea(chat);
	if (area) {
		if (!area.delete_tags || !area.delete_tags.length) {
			return area.normal_tags;
		} else if (!area.normal_tags || !area.normal_tags.length) {
			return area.delete_tags;
		}
		return area.normal_tags.concat(area.delete_tags);
	}
	return [];
};

export const getBothAreaTags = createSelector(
	[
		isChat,
		getCurrentAreaTags,
		getCurrentAreaDeleteTags
	],
	(chat, tags, deleteTags) => {
		let tagList = [];
		if (chat) {
			tagList = getChatAllAreaTags(chat)
		} else {
			if (!deleteTags || !deleteTags.length) {
				tagList = tags;
			} else if (!tags || !tags.length) {
				tagList = deleteTags;
			} else {
				tagList = tags.concat(deleteTags);
			}
		}
		return tagList;
	}
);

export const getAreaTags = createSelector(
	[
		isChat,
		getCurrentAreaTags,
	],
	(chat, tags) => {
		let tagList = [];
		if (chat) {
			tagList = getChatAreaTags(chat)
		} else {
			tagList = tags;
		}
		return tagList;
	}
);

export const getManualErrandAreaTags = createSelector(
	manualErrandSelectedAreaData,
	areaData => {
		if(!areaData) {
			return;
		}
		return areaData.normal_tags;
	}
);

// similar to get area tags but include check for manual errand too. And return
// manual errand tag if manual errand in operation else return the open errand
// area tag.
const getErrandAreaTags = createSelector(
	[
		isManualErrandClassification,
		getManualErrandAreaTags,
		getCurrentAreaTags
	],
	(manual, manualTags, tags) => {
		if(manual) {
			return manualTags;
		}
		return tags;
	}
);

const isDeletingClassification = state => {
	let classification = getInputsClassification(state);
	if (classification && classification.isDeleting) {
		return true
	}
	return false
};

const getAllAreaTags = state => state.app.errand.fetchAreaTags.data;

export const getMultiClassificationAreaTags = createSelector(
	[
		isDeletingClassification,
		getAllAreaTags,
		getCurrentTaggingErrandAreaId
	],
	(isDeleting, areaData, areaId) => {
		let tags = [];
		if (areaData && areaData[areaId]) {
			if (isDeleting) {
				tags = areaData[areaId].delete_tags;
			} else {
				tags = areaData[areaId].normal_tags;
			}
		}
		return tags;
	}
);

const getClassificationAreaTags = createSelector(
	[
		getCurrentTaggingAreaData,
		isDeletingClassification,
	],
	(areaData, isDeleting) => {
		let tags = [];
		if (areaData) {
			if (isDeleting) {
				tags = areaData.delete_tags;;
			} else {
				tags = areaData.normal_tags;
			}
		}
		return tags;
	}
);

export const getClassificationTagsSelector = createSelector(
	[
		isChat,
		getIsCurrentErrandSelector,
		isManualErrandClassification,
		isMultiSelectionErrandList,
		isDeletingClassification,
		getErrandAreaTags,
		getCurrentAreaDeleteTags,
		getClassificationAreaTags,
		getMultiClassificationAreaTags
	],
	(
		chat,
		isSameCurrent,
		manual,
		multi,
		deleteClassification,
		currentAreaTags,
		currentErrandDeleteTags,
		classficationAreaTags,
		multiClassificationTags
	) => {
		if (chat) {
			return getChatAreaTags(chat);
		}
		if (multi) {
			return multiClassificationTags;
		}
		if (isSameCurrent || manual) {
			// NOTE: there is not delete tag for manual errand as it always
			// creating.
			if (manual || !deleteClassification) {
				return currentAreaTags;
			}
			return currentErrandDeleteTags;
		}
		return classficationAreaTags;
	}
);

export const getClassificationTagsMemoize = getClassificationTagsSelector;

export const getClassificationAreaDataMemoize = createSelector(
	[
		getIsCurrentErrandSelector,
		isManualErrandClassification,
		getCurrentErrandAreaData,
		getCurrentErrandAreaId,
		getCurrentTaggingAreaData,
		getCurrentTaggingErrandAreaId,
		manualErrandSelectedAreaSelector,
		manualErrandSelectedAreaData
	],
	(
		isSameCurrent,
		manual,
		currentArea,
		currentAreaId,
		currentTaggingArea,
		currentTaggingAreaId,
		manualArea,
		manualAreaId
	) => {
		if (isSameCurrent) {
			return {id: currentAreaId, data: currentArea, useCurrent: true};
		} else if (manual) {
			return {id: manualAreaId, data: manualArea, useCurrent: false};
		}
		return {id: currentTaggingAreaId, data: currentTaggingArea, useCurrent: false};
	}
);

const getSelectedTags = createSelector(
	[
		isCallMemoize,
		isManualErrandClassification,
		getAppErrand
	],
	(isCall, manual, errand) => {
		let inpt = (manual) ? (isCall ? INPUTS_MANUAL_CALL : INPUTS_MANUAL_ERRAND) : INPUTS_OPEN_ERRAND;
		return errand[inpt].tags;
	}
);

const getClassificationSelectedTags = createSelector(
	getCurrentTaggingErrand
	, getInputsClassification
	, (eId, classification) => {
		if (!classification) {
			return emptyArray;
		}
		return classification.tags[eId];
	}
);

export const getClassificationSelectedTagsSelector = createSelector(
	[
		getIsCurrentErrandSelector,
		getSelectedTags,
		getClassificationSelectedTags,
	],
	(isSameCurrent, selectedTags, classficationSelectedTags) => {
		if (isSameCurrent) {
			return selectedTags;
		}
		return classficationSelectedTags;
	}
);

function getSelectedOprFromOprOrder(
	currentID
	, { order, opr }
	, basicErrands
	, errands
) {
	let selected = [];
	each(order, v => {
		if (v !== currentID && opr[v].selected) {
			const e = basicErrands[v];
			if (!e) {
				// need more protective here as some components need the whole
				// basic errand data and this data may not be available yet as
				// it may be asynchronously fetched but related errand ID only
				// had been retrieved.
				return;
			}
			errands[v] = basicErrands[v];
			selected.push(v);
		}
	});
	if (!selected.length) {
		return emptyArray;
	}
	return selected;
}

const emptyAssociatedObject = {
	errands: emptyObject
	, grouped: emptyArray
	, related: emptyArray
	, contacts: emptyArray
};

const getAcquireOpr = state => state.app.errand.acquireOpr;

export const getMemoizeAssociatedObject = createSelector(
	getCurrentErrand
	, getAcquireOpr
	, getDomainBasicErrandById
	, (currentID, opr, basicErrands) => {
		let errands = {};
		const related = getSelectedOprFromOprOrder(
				currentID
				, opr.related
				, basicErrands
				, errands
			)
			, contacts = getSelectedOprFromOprOrder(
				currentID
				, opr.oc
				, basicErrands
				, errands
			)
			;
		if (!related.length && !contacts.length) {
			return emptyAssociatedObject;
		}
		return {errands, related, grouped: emptyArray, contacts};
	}
);

export const getMemoizeAssociatedList = createSelector(
	getMemoizeAssociatedObject
	, ({ errands }) => errands
);

export const getMemoizeAssociatedArray = createSelector(
	getMemoizeAssociatedList
	, associatedList => {
		let list = [];
		$.each(associatedList, (k, v) => {
			list.push(k);
		});
		return list;
	}
);

export const getGroupedErrandListString = createSelector(
	getMemoizeAssociatedList
	, getCurrentErrand
	, (associatedList, currentID) => {
		let list = [currentID];
		$.each(associatedList, (k, v) => {
			list.push(k);
		});
		return list.join(",");
	}
);

export const getGroupedErrandIDsWithCipherKey = createSelector(
	getMemoizeAssociatedList
	, getCurrentErrand
	, getDomainBasicErrandById
	, (associatedList, currentID, domainBasicErrand) => {
		let list = []
		, currentErrand = domainBasicErrand[currentID]
		;
		if (currentErrand && currentErrand.data) {
			list.push({id: currentErrand.data.id, cipherKey: currentErrand.data.cipherKey})
		}
		$.each(associatedList, (k, v) => {
			list.push({
				id: v.data.id,
				cipherKey: v.data.cipherKey
			});
		});
		return list;
	}
);

const getClassificationBasic = createSelector(
	[
		getIsCurrentErrandSelector,
		isMultiSelectionErrandList,
		getErrandBasic,
		getCurrentTaggingErrand,
		getMemoizeAssociatedList,
		getMultipleErrand
	],
	(isCurrentErrand, multi, basic, taggingErrandId, associatedList, currentErrand) => {
		if (isCurrentErrand) {
			return basic;
		} else if (multi) {
			let errandBasic = Object.assign({}, emptyErrand);
			errandBasic.data = currentErrand;
			return errandBasic;
		}
		return associatedList[taggingErrandId];
	}
);

export const getClassificationAreaNameSelector = createSelector(
	[
		isManualErrandClassification,
		manualErrandSelectedAreaData,
		getClassificationBasic
	],
	(manual, manualAreaData, basic) => {
		if (manual) {
			if (!manualAreaData) {
				return I('Invalid manual errand area data.');
			}
			return manualAreaData.name;
		}
		if (basic && basic.data && basic.data.areaName) {
			return basic.data.areaName;
		} else {
			return "";
		}
	}
);

export const getClassificationDisplayIdSelector = createSelector(
	[
		isManualErrandClassification,
		getClassificationBasic
	],
	(manual, basic) => {
		if (manual) {
			return I('New');
		}
		if (basic && basic.data && basic.data.displayId) {
			return basic.data.displayId;
		} else {
			return "";
		}
	}
);

const displayIdList = (array, getter) => {
	const es = [];
	each(array, v => {
		es.push('#'+getter(v));
	});
	return es.sort();
};

const basicAssociatedErrandsMemo = noSelector(
	getErrandBasic,
	basic => {
		if (!basic ||
			!basic.data ||
			!basic.data.associates ||
			!basic.data.associates.length) {
			return emptyArray
		}
		return basic.data.associates
	}
)

export const associatedErrandsMemo = createSelector(
	basicAssociatedErrandsMemo,
	associates => displayIdList(associates, v => v.displayId)
)


const basicLinkedErrandsMemo = noSelector(
	getErrandBasic,
	basic => {
		if (!basic ||
			!basic.data ||
			!basic.data.linkedErrands ||
			!basic.data.linkedErrands.length) {
			return emptyArray
		}
		return basic.data.linkedErrands
	}
)

export const linkedErrandsMemo = createSelector(
	basicLinkedErrandsMemo,
	linkedErrands => displayIdList(linkedErrands, v => v.displayId)
)

export const associatedErrandListString = createSelector(
	getErrandBasic,
	getMemoizeAssociatedList,
	(basic, errands) => {
		if (!basic ||
			!basic.data ||
			!basic.data.associates ||
			// NOTE: if we want consistent front-end behaviour where 'closed' errand
			// will not show the 'live' associated errands then remove the 'length'
			// condition. At the moment, 'live' associate errands will be showned if
			// the errand has no associated errands previously.
			!basic.data.associates.length) {
			return displayIdList(errands, v => v.data.displayId);
		}
		return displayIdList(basic.data.associates, v => v.displayId);
	}
);

export const linkedErrandsListString = createSelector(
	getErrandBasic,
	getMemoizeAssociatedList,
	(basic, errands) => {
		if (!basic ||
			!basic.data ||
			!basic.data.linkedErrands ||
			!basic.data.linkedErrands.length) {
			return displayIdList(errands, v => v.data.displayId);
		}
		return displayIdList(basic.data.linkedErrands, v => v.displayId);
	}
);

const getHistoriesOrOthersAndMy = createSelector(
	getCurrentErrandId,
	getMyErrand,
	getHistories,
	getDomainHistoryById,
	actionsLinkMemo,
	currentErrandThreadIdMemo,
	getMemoizeAssociatedObject,
	getSortHistory,
	getClientAvatars,
	includeCurrentErrandInHistories,
	(
		errandId,
		my,
		histories,
		historiesByThreadId,
		actionsLink,
		currentThreadId,
		{ errands, related, contacts },
		sortHistoryAscending,
		avatars,
		includeCurrentErrand
	) => {
		if (!errandId) {
			return emptyHistoryOrOtherAndMy
		} else {
			let normalizedHistories
			if (!histories) {
				my = update(my, { history: { $set: emptyObject } })
			} else {
				let found, index
				each(histories, ({ eid }, i) => {
					if (eid === errandId) {
						found = true
						index = i
						return false
					}
				})
				if (found) {
					const history = histories[index]
					const { lastAction, lastSaved } = history
					my = update(my, { $merge: { lastAction, lastSaved, history } })
					if (!addCurrentErrandForSequentialHistoriesThread(
						histories.length,
						includeCurrentErrand
					)) {
						histories = update(histories, { $splice: [[index, 1]] })
					}
				} else {
					// weird case: history data do not contain current errand.
					// TODO: this seem can happen because during opening an errand,
					// history is not being fetched first and there is chances that
					// previous fetching history (which already outdated) will
					// arrived and may be temporary used as current history data.
					// This only happen during opening errand while another errand
					// still being opened. Still need a better solution where
					// opening an errand need to cancel any previous on-going data
					// fetching or invalidate them when found outdated.
					my = update(my, { history: { $set: emptyObject } });
				}
				normalizedHistories = normalizeHistories(histories)
			}
			let allIds
			let byId
			let threads
			if (normalizedHistories) {
				allIds = normalizedHistories.allIds
				byId = normalizedHistories.byId
				threads = normalizedHistories.threads
			} else {
				allIds = []
				byId = {}
				threads = {}
			}
			getHistoryFromErrandIds(
				allIds,
				byId,
				threads,
				historiesByThreadId,
				actionsLink,
				errands,
				currentThreadId,
				contacts
			)
			getHistoryFromErrandIds(
				allIds,
				byId,
				threads,
				historiesByThreadId,
				actionsLink,
				errands,
				currentThreadId,
				related
			)
			if (allIds.length) {
				histories = sortNormalizedHistory(
					sortHistoryAscending,
					{ allIds, byId, threads }
				)
			} else {
				histories = emptyHistoriesAndOthers;
			}
		}
		return { historiesAndOthers: histories, my, avatars };
	}
);

export const getHistoriesAndMy = (...args) => getHistoriesOrOthersAndMy(...args)

const collaborationInputsQueryId = state => collaborationInputs(state).queryID;
const collaborationInputsAnswerId = state => collaborationInputs(state).answerID;
const collaborationInputsThreadId = state => collaborationInputs(state).threadID;

const collaborationEditInfo = createSelector(
	collaborationInputsQueryId
	, collaborationInputsAnswerId
	, collaborationInputsThreadId
	, collaborationRole
	, (query, answer, thread, replyQuery) => {
		if (!thread) {
			return I("Creating new collaboration thread");
		} else if (replyQuery) {
			return I("Reply in collaboration #{QUERY_ID}")
				.replace("{QUERY_ID}", query);
		} else if (!answer) {
			return I("Creating new query (last query ID #{QUERY_ID})")
				.replace("{QUERY_ID}", query);
		}
		return I("Reply in collaboration #{ANSWER_ID}")
			.replace("{ANSWER_ID}", answer);
	}
);

export const replyNavInfoSelector = createSelector(
	isChat
	, getCurrentReply
	, currentEditingNote
	, getMemoizeAssociatedObject
	, collaborationEditInfo
	, (chat, currentReply, note, errands, collaboration) => {
		let info = '';
		switch (currentReply) {
			case RPLY_ERRAND:
				info = errands
				break;
			case RPLY_COMMENT:
				if (note === 0) {
					info = I('Creating new');
				} else {
					info = I('Editing comment {NOTE_ID}')
						.replace('{NOTE_ID}', note);
				}
				break;
			case RPLY_COLLABORATE:
				info = collaboration;
				break;
		}
		return info;
	}
);

function getExternalExpertEditWIP(state) {
	return state.app.errand.externalExpertEdit.wip;
}

function allowCollaboration(state) {
	const wfData = state.app.workflow.fetchWfSettings.data;
	if (!wfData) {
		return false;
	}
	return wfData['external-experts'];
}

function appendOpt(opts, opt) {
	return update(opts, {$push: [opt]});
}

const replyNavOptions = [
	{key: RPLY_ERRAND}
];

export const checkIfReviewExternalForward = reviewData => {
	if (!reviewData) {
		return false;
	}
	return !!reviewData.update_forward;
};

const isReviewExternalForward = noSelector(
	reviewData
	, checkIfReviewExternalForward
);

const getExternalForwardFeature = state => state.app.workflow.fetchWfSettings.data?.["forward-external.any"] || false;

export const replyNavOptionsSelector = createSelector(
	isChatErrand
	, errandStatusSelector
	, allowInternalCommentMemoize
	, allowCollaboration
	, getExternalExpertEditWIP
	// NOTE: purposely get the current errand service instead of agent's current
	// reply channel because external-forward should follow original errand
	// service.
	, currentErrandServiceReplyChannel
	, acquireErrand
	, allowAgentEditQuestionMemoize
	, isFetchHistoryWip
	, isValidAcquireErrandReviewContext
	, isReviewExternalForward
	, canInternalGroupChat
	, getExternalForwardFeature
	, (
		chat
		, errandStatus
		, canInternalComment
		, canCollaboration
		, eeeWip
		, serviceReplyChannel
		, { wip: wipAcquire, data: dataAcquire }
		, canEditQuestion
		, historyWip
		, reviewContext
		, reviewExternalForward
		, allowInternalGroupChat
		, externalForwardFeature
	) => {
		let options, noExternalForward;
		if(!externalForwardFeature) {
			noExternalForward = true;
		}
		if (errandStatus === EST_OWNED || errandStatus === EST_HANDLED) {
			options = replyNavOptions;
			if (serviceReplyChannel !== RC_EMAIL) {
				noExternalForward = true;
			}
		} else {
			if (!wipAcquire
				&& dataAcquire
				&& (dataAcquire.acquire
					&& dataAcquire.acquire.data.errand_in_group_folder)
				|| reviewContext) {
				if (reviewContext) {
					if (reviewExternalForward) {
						options = emptyArray;
					} else {
						options = replyNavOptions;
						noExternalForward = true;
					}
				} else {
					options = replyNavOptions;
					if (serviceReplyChannel !== RC_EMAIL) {
						noExternalForward = true;
					}
				}
			} else {
				noExternalForward = true;
				options = emptyArray;
			}
		}
		if (canInternalComment) {
			options = appendOpt(options, {key: RPLY_COMMENT});
		}
		const chatHasInternalGroupChat = chat && allowInternalGroupChat;
		if (chatHasInternalGroupChat) {
			options = appendOpt(options, { key: RPLY_GRP_CHAT_COL });
		}
		if (canCollaboration) {
			const collaboration = { key: RPLY_COLLABORATE };
			if (chatHasInternalGroupChat) {
				collaboration.name = I('EXTERNAL COLLABORATE')
			}
			if (eeeWip || historyWip) {
				collaboration.busy = true;
				collaboration.disabled = true;
			}
			options = appendOpt(options, collaboration);
		}
		if (!chat && !noExternalForward) {
			options =  appendOpt(options, {
				key: RPLY_EXT_FWD
				, busy: historyWip
				, disabled: historyWip
			});
		}
		if (!chat && canEditQuestion) {
			options = appendOpt(options, {key: RPLY_QUESTION});
		}
		return options;
	}
);

export const replyOptionsMemoize = replyNavOptionsSelector;

const getManualErrandSubject = createSelector(
	getManualErrandInputs,
	inputs => {
		if(!inputs) {
			return;
		}
		return inputs.update_subject;
	}
);

const defSubjectClassificationMultiSelection = {subject: '', iconic: false};

export const getClassificationSubjectSelector = createSelector(
	[
		isManualErrandClassification,
		getManualErrandSubject,
		getClassificationBasic
	],
	(manual, manualErrandSubject, basic) => {
		if (manual) {
			return {subject: manualErrandSubject, iconic: false};
		}
		if(basic && basic.data){
			if (basic.data.iconicSubject) {
				return {subject: basic.data.subject, iconic: basic.data.iconicSubject};
			}
			return {subject: basic.data.subject, iconic: false};
		}
		return {subject: "", iconic: false};
	}
);

const signaturePreviewContainer = state => state.app.workflow.fetchWfSettings.data.signaturePreviewContainer;

const salutationPreviewContainer = state => state.app.workflow.fetchWfSettings.data.salutationPreviewContainer;

const getSelectedSignature = state => state.app.errand.inputs.update_signature;

const getSelectedSalutation = state => state.app.errand.inputs.update_salutation;

const getSelectedCollaborateSignature = state => state.app.errand.collaborationInputs.update_signature;

const getAreaSignatureOrSalutationData = (areaSigSalData, agentSigSalData, adminOnly) => {
	var data = [];
	if(areaSigSalData != null && areaSigSalData.length > 0) {
		data = areaSigSalData;
	}
	if( agentSigSalData != null && agentSigSalData.length > 0) {
		if( !adminOnly ){
			if( data.length > 0 )
				data = data.concat(agentSigSalData);
			else
				data = agentSigSalData;
		}
	}
	return data;
};

export const getAreaSignatures = createSelector(getCurrentErrandAreaData, (areaData) => {
	return getAreaSignatureOrSalutationData(
		areaData.signatures, areaData.user_signatures, areaData.area_admin_tick_sign);
});

export const getAreaSalutations = createSelector(getCurrentErrandAreaData, (areaData) => {
	return getAreaSignatureOrSalutationData(
		areaData.salutations, areaData.user_salutations, areaData.area_admin_tick_sal);
});

export const getAreaQuickReplies = createSelector(getCurrentErrandAreaData, (areaData) => {
	return getAreaQuickRepliesData( areaData.quickreply_contents );
});

const getAreaQuickRepliesData = (qrData) => {
	var data = [];
	if(qrData != null && qrData.length > 0) {
		data = qrData;
	}
	return data;
};

export const previewPersonalization = createSelector(
	[
		getCurrentErrandAreaData,
		signaturePreviewContainer,
		salutationPreviewContainer,
		isCollaborationSelector,
		getSelectedSignature,
		getSelectedSalutation,
		getSelectedCollaborateSignature
	],
	(eArea, sigPreview, salPreview, isCollaborate, selectedSig, selectedSal, collaborateSig) => {
		let sigContent='', salContent='';
		if(sigPreview) {
			let signature = (isCollaborate ? collaborateSig : selectedSig)
			, sigContents = getAreaSignatureOrSalutationData(
				eArea.signature_contents, eArea.user_signatures_content, eArea.area_admin_tick_sign)
			;
			if (sigContents) {
				sigContent = $.map(sigContents, function(v, i) {
					if(typeof v[signature] != 'undefined') {
						return v[signature]
					}
				});
			}
		}
		if(salPreview){
			let salContents = getAreaSignatureOrSalutationData(
				eArea.salutation_contents, eArea.user_salutations_content, eArea.area_admin_tick_sal);
			if (salContents) {
				salContent = $.map(salContents, function(v, i) {
					if(typeof v[selectedSal] != 'undefined') {
						return v[selectedSal]
					}
				});
			}
		}
		return {
			sigContent: sigContent,
			salContent: salContent
		};
	}
);

export const howOpenExtSystem = state => {
	const wfData = state.app.workflow.fetchWfSettings.data;
	if(wfData) {
		return wfData.howToOpenExternalSystemForAgent;
	}
	return 0;
}

export const autoOpenExtSystemSelector = createSelector(
	[
		getCurrentErrandAreaData,
		howOpenExtSystem
	],
	(area, openExtSys) => {
		if(area && area.external_system_url_array) {
			if(area.external_system_url_array.length > 0 && (openExtSys === 2 || openExtSys === 3)){
				return true;
			}
		}
		return false;
	}
)

export const fileArchivesSelector = createSelector(
	[
		isChat,
		getCurrentErrandAreaData,
		getExtendedDataSelector
	],
	(chat, area, errand)=>{
		let fa;
		if (chat) {
			fa = [];
			$.each(initialChatData.chatAreas, (i, area) => {
				if (chat.errand.data.area == area.Id) {
					fa = area.FileArchive;
					return false;
				}
			});
			return fa;
		}

		fa = area.file_archive_images;
		if(fa && fa.length > 0){
			return fa;
		}else{
			let exdFileArchive = (errand != null ? errand.area_file_archive : []);
			return exdFileArchive;
		}
	}
);

export const getAgentsWithoutMeSelector = createSelector(
	[
		onlyActiveAgentsSelector,
		getMyId
	],
	(agents, myID) => {
		let found, index;
		$.each(agents, (i,v) => {
			if(v.Id === myID) {
				found = true;
				index = i;
				return false;
			}
		});
		if(!found) {
			return agents;
		}
		return update(agents, {$splice: [[index, 1]]});
	}
);


export const areaAgentDataSelector = createSelector(
	[
		onlyActiveAgentsSelector,
		getCurrentErrandAreaData
	],
	(agentData, area) => {
		let list = [];
		$.each(area.agents, (i,v) => {
			$.each(agentData, (j, u) => {
				if(v.id === u.Id) {
					list.push(u);
				}
			});
		});
		return list;
	}
);

const defSerialAttachment = {
	lists: null, // purposely null
	size: 0
};

const serialAttachments = createSelector(
	[
		getCurrentReply,
		getValidReplyInputs
	],
	(reply, inputs) => {
		if(reply !== RPLY_COMMENT) {
			return defSerialAttachment;
		} else if(!inputs.internal_comment_saved_attachments.length &&
			!inputs.internal_comment_uploaded_attachments.length) {
			return defSerialAttachment;
		}
		const size = inputs.internal_comment_saved_attachments.length +
			inputs.internal_comment_uploaded_attachments.length;
		return {
			lists: {
				[AT_SAVED]: {list: convertInternalCommentAttachments(
					inputs.internal_comment_saved_attachments)},
				[AT_UPLOADED]: {list: inputs.internal_comment_uploaded_attachments}
			},
			size
		};
	}
);

// NOTE: Shuold return null when no attachments.
export const serializedAttachmentsSelector = (state, props) => serialAttachments(state, props).lists;

export const serializedAttachmentSizeSelector = (state, props) => serialAttachments(state, props).size;

function getOtherContacts(state, props) {
	return getAppErrand(state).contacts;
}

function getHistoryContacts(state, props) {
	return getAppErrand(state).historyContacts;
}

function getCompanyHistoryContacts(state, props) {
	return getAppErrand(state).companyHistoryContacts;
}

function getCompanyOtherContacts(state, props) {
	return getAppErrand(state).companyOtherContacts;
}

function getMyErrands(state, props) {
	return getAppErrand(state).myErrands
}

function getLinkedErrands(state, props) {
	return getAppErrand(state).linkedErrands
}

const defOtherContacts = {
	ready: false
	, wip: false
	, list: emptyArray
	, filter: false
	, filterSize: 0
	, opr: emptyObject
	, allSelected: false
};

function isEmptyAcquireList(obj) {
	if (obj.list && obj.list.length) {
		return false;
	}
	return true;
}

function isMovedToAssociate(opr, id) {
	const op = opr[id];
	if (op && op.moved) {
		return true;
	}
	return false;
}

function processOtherContacts(
	{ wip, data }
	, filterSrcSize
	, filterSrc
	, associate
	, threadID
	, grouped
) {
	const { order, norm, allSelected } = data;
	let index
		, list
		, filter = false
		, filterSize = 0
		, opr = data.opr
		;
	$.each(order, (i, v) => {
		let movedToAssociated;
		if (associate && isMovedToAssociate(opr, v)) {
			movedToAssociated = true;
		}
		if (filterSrcSize && filterSrc[v] || movedToAssociated) {
			if (!filter) {
				filter = {};
			}
			filter[i] = true;
			filterSize++;
			if (movedToAssociated) {
				let storeTo;
				if (threadID && (norm[v] && norm[v].threadId === threadID)) {
					storeTo = grouped;
				} else {
					storeTo = associate;
				}
				storeTo.list.push(v);
				storeTo.opr[v] = opr[v];
			}
		} else {
			if (!list) {
				list = [v];
			} else {
				list.push(v);
			}
		}
	});
	if (!opr) {
		opr = emptyObject;
	}
	if (!list) {
		list = emptyArray;
	}
	return {
		ready: true
		, wip
		, list
		, filter
		, filterSize
		, opr
		, allSelected
	};
}

function processLinkedErrands(
	{ wip, data }
	, currentErrandID
	, currentThreadID
) {
	const { order, norm, allSelected } = data;
	let list
		, filter = false
		, filterSize = 0
		, opr = {}
		;
		$.each(order, (i, v) => {
			if (norm[v].threadId == currentThreadID) {
				if (!filter) {
					filter = {};
				}
				filter[i] = true;
				filterSize++;
			} else {
				if (!list) {
					list = [v];
				} else {
					list.push(v);
				}
			}
		});

	if (list) {
		$.each(data.linkedOpr, (i, v) => {
			if (list.includes(parseInt(i))) {
				opr[parseInt(i)] = v
			}
		} );
	}
	if (!opr) {
		opr = emptyObject;
	}
	if (!list) {
		list = emptyArray;
	}
	return {
		ready: true
		, wip: wip
		, list
		, filter
		, filterSize
		, opr
		, allSelected
	};
}


const defAcquireHistories = defOtherContacts
	, defEmptyAcquire = {
		ready: true
		, list: emptyArray
		, filter: false
		, filterSize: 0
		, opr: emptyObject
		, allSelected: false
	}
	, defOpenAssociateWip = {
		open: defOtherContacts
		, associated: defEmptyAcquire
		, grouped: defEmptyAcquire
	}
	, defOpenAssociate = {
		open: defEmptyAcquire
		, associated: defEmptyAcquire
		, grouped: defEmptyAcquire
	}
	, defChatRelateds = defEmptyAcquire
	;
function checkContactData(isChatRelated, contactData, currentId, storeKey) {
	if (isChatRelated) {
		// chat no related errands
		return defChatRelateds;
	} else if (contactData.wip
		&& (!contactData.data || !contactData.data[storeKey])) {
		// TODO: need a way to detect fresh state and not endpoint triggering
		// as this part of code will likely never be trigger because data not
		// null for state with 'opr' field.
		return defAcquireHistories;
	} else if (!contactData.data
		|| !contactData.data.order
		|| !contactData.data.order.length) {
		return defEmptyAcquire;
	}
	return processOtherContacts(contactData, 1, {[currentId]: true});
}

export const historyContactsSelector = createSelector(
	getHistoryContacts
	, getCurrentErrand
	, (history, currentId) => checkContactData(
		false
		, history
		, currentId
		, "list"
	)
);

const getRelatedAcquireOpr = state => getAcquireOpr(state).related;

const relatedErrandSelector = createSelector(
	isChat
	, getRelatedAcquireOpr
	, acquireErrand
	, getCurrentErrand
	, basicErrand
	, (chat, { order, opr }, acquire, currentId, errands) => {
		if (false && chat) {
			return defChatRelateds; // chat no related errands
		} else if (!order || !order.length) {
			if (acquire.wip && (!acquire.data || !acquire.data.acquire)) {
				// TODO: need a way to detect fresh state and not endpoint
				// triggering as this part of code will likely never be trigger
				// because data not null for state with 'opr' field.
				return defAcquireHistories;
			}
			return defEmptyAcquire;
		}
		let allSelected = true
			, norm = {}
			;
		$.each(order, (i, v) => {
			if (allSelected && !opr[v].selected) {
				allSelected = false;
			}
			norm[v] = errands[v];
		});
		return processOtherContacts({
			wip: acquire.wip
			, data: {opr, order, allSelected, norm}
		});
	}
);

const getOtherContactAcquireOpr = state => getAcquireOpr(state).oc;

const getOtherContactErrands = createSelector(
	getOtherContacts
	, getOtherContactAcquireOpr
	, (oc, { order, opr }) => {
		if (!order || !order.length) {
			if (oc.wip && (!oc.data || !oc.data.list)) {
				return defOtherContacts;
			}
			return defEmptyAcquire;
		}
		return {
			ready: true
			, wip: oc.wip
			, list: order
			, filter: false
			, filterSize: 0
			, opr
		};
	}
);

export const getCurrentWorkingErrands = createSelector(
	isChat
	, getRelatedAcquireOpr
	, getOtherContactAcquireOpr
	, (chat, related, oc) => {
		let errands = [];
		if (!chat && related.order) {
			$.each(related.order, (i, v) => {
				if (related.opr[v]) {
					errands.push(v);
				}
			});
		}
		if (oc.order) {
			$.each(oc.order, (i, v) => {
				if (oc.opr[v]) {
					errands.push(v);
				}
			});
		}
		return errands;
	}
);

function filterListCheck(list, unique, size) {
	if (list && list.length) {
		$.each(list, (i, v) => {
			if (!unique[v]) {
				unique[v] = true;
				size++;
			}
		});
	}
	return size;
}

const getFilterListSelector = createSelector(
	relatedErrandSelector
	, getOtherContactErrands
	, getCurrentErrand
	, ({ list }, { list: otherList }, currentErrandID) => {
		let data = {[currentErrandID]: true}
			, size = filterListCheck(list, data, 1)
			;
		size = filterListCheck(otherList, data, size)
		return {data, size};
	}
);

function constAcquireListIfEmpty(obj) {
	if (!obj.list.length) {
		obj.list = emptyArray;
		obj.opr = emptyObject;
	}
}

const openAndAssociateErrandSelector = createSelector(
	getOtherContacts
	, initialThreadIDSelector
	, getFilterListSelector
	, (oc, threadID, { size: filterSourceSize, data: filterSource }) => {
		if (oc.wip && (!oc.data || !oc.data.list)) {
			return defOpenAssociateWip;
		} else if (!oc.data || !oc.data.order || !oc.data.order.length) {
			return defOpenAssociate;
		}
		let associated = {
				ready: true
				, list: []
				, filter: false
				, filterSize: 0
				, opr: {}
			}
			, grouped = {
				ready: true
				, list: []
				, filter: false
				, filterSize: 0
				, opr: {}
			}
			;
		const open = processOtherContacts(
			oc
			, filterSourceSize
			, filterSource
			, associated
			, threadID
			, grouped
		);
		constAcquireListIfEmpty(associated);
		constAcquireListIfEmpty(grouped);
		return {open, associated, grouped};
	}
);

const linkedErrandsCreateSelector = createSelector(
	getLinkedErrands
	, getCurrentErrand
	, currentErrandThreadIdMemo,
	(linkedE, currentErrandID, currentThreadID) => {
		const linkedErrands = processLinkedErrands( linkedE, currentErrandID, currentThreadID);
		return {linkedErrands};
	}
);
export const openErrandsOtherContactsSelector = state =>
	openAndAssociateErrandSelector(state).open;

export const otherContactsSelector = openErrandsOtherContactsSelector;

export const myErrandsWithoutLinked = createSelector(
	[getMyErrands, getCurrentErrand, currentErrandThreadIdMemo],
	(myE, currentErrandID, currentThreadID)	=> {
		const { wip, data } = myE
		const { order, norm, allSelected } = data;

		let list = []
			, filter = {}
			, filterSize = 0
			, opr = {}
			, prvThreadId = 0
			;
		$.each(order, (i, v) => {
			let isFilter
	
			if (norm[v].threadId == currentThreadID || norm[v].threadId == prvThreadId) {
				isFilter = true
			} else {
				if (norm[v].linkedId > 0 ) {
					isFilter = true
				} else {
					isFilter = false
				}
			}
	
			if (isFilter) {
				filter[i] = true;
				filterSize++;
			} else {
				list.push(v);
				prvThreadId = norm[v].threadId
			}
		});
		if (list.length > 0) {
			$.each(data.opr, (i, v) => {
				if (list.includes(parseInt(i))) {
					opr[parseInt(i)] = v
				}
			} );
		}
		return {
			ready: true
			, wip: wip
			, list
			, filter
			, filterSize
			, opr
			, allSelected
		};
	}
)

export const getSelectedLinkedErrandsAddToReply = createSelector(
	[getMyErrands, getLinkedErrands, currentErrandThreadIdMemo],
	(myE, linkedE, currentThreadID) => {
		const { data:myEData } = myE
		const { order:myEOrder, norm:myENorm } = myEData;

		const { data:linkedEData } = linkedE
		const { linkedOpr , norm:linkedENorm} = linkedEData

		let addToReplyId = [] ;
		let addToReplyDisplayId = [] ;
		let threadId = 0
		// base on errand that selected at "linked errand", get threadID,
		// lookup myErrands to get all errands that under those thread
		if (linkedOpr) {
			$.each(linkedOpr, function(k, v) {
				if (v.selected) {
					if (linkedENorm[k]) {
						threadId = linkedENorm[k].threadId
						$.each(myENorm, (i, v) => {
							if (v.threadId == threadId) {
								addToReplyId.push(v.id.toString())
								addToReplyDisplayId.push(v.displayId)
							}
						});
					}
				}
			});
		}

		return {addToReplyId : addToReplyId,
			addToReplyDisplayId : addToReplyDisplayId} ;
	}
)
const linkedListSelector = createSelector(
	basicErrand,
	basic => {
		let list = {};
		if (basic && basic.data && basic.data && basic.data.data.linkedErrands) {
			$.each(basic.data.data.linkedErrands, (i, v) => {
				list[v.id] = v;
			})
		}
		return list;
	}
)
	
export const linkedErrandsSelector = state =>
	linkedErrandsCreateSelector(state).linkedErrands;

function setupAcquireListType(typeStore, list, whatType) {
	$.each(list, (i, v) => {
		typeStore[v] = whatType;
	});
}

const threadedErrandSelector = relatedErrandSelector;

function onlyHasCurrentErrandID(obj, id) {
	if (!obj.list || obj.list.length !== 1) {
		return false;
	}
	return obj.list[0] === id;
}

export const hasOtherErrandsSelector = createSelector(
	historyContactsSelector
	, threadedErrandSelector
	, otherContactsSelector
	, getCurrentErrand
	, (histories, threaded, contacts, currentErrandID) => {
		if (isEmptyAcquireList(histories)
			&& (isEmptyAcquireList(threaded)
				|| onlyHasCurrentErrandID(threaded, currentErrandID))
			&& isEmptyAcquireList(contacts)) {
			return false;
		}
		return true;
	}
);

export const hasOtherErrandsNoHistorySelector = createSelector(
	threadedErrandSelector
	, otherContactsSelector
	, getCurrentErrand
	, (threaded, contacts, currentErrandID) => {
		if ((isEmptyAcquireList(threaded)
				|| onlyHasCurrentErrandID(threaded, currentErrandID))
			&& isEmptyAcquireList(contacts)) {
			return false;
		}
		return true;
	}
);

const emptyAcquireErrands = {
		top: defEmptyAcquire
		, bottom: defEmptyAcquire
	}
	;
export const acquireErrandsSelector = createSelector(
	threadedErrandSelector
	, getOtherContactErrands
	, (top, bottom) => {
		if (isEmptyAcquireList(top) && isEmptyAcquireList(bottom)) {
			return emptyAcquireErrands;
		}
		return {top, bottom};
	}
);

function acquireRelatedErrandsOpr(state) {
	const d = acquireErrand(state).data;
	if (!d) {
		return emptyObject;
	}
	return d.opr;
}

function getUnclosedFromObj(errands, unique, basic, currentErrandID, obj) {
	$.each(obj, (k, v) => {
		const e = basic[k];
		if (!e) {
			return;
		}
		const d = e.data;
		if (e.id !== currentErrandID && (!unique || !unique[k])
			&& !d.closed) {
			if (unique) {
				unique[k] = true;
			}
			errands.push(k);
		}
	});
	return errands;
}

// only retrieve errands that is not currently opened errand and is still open
// and can be closed.
function getOtherOpen(sources, basic, currentErrandID) {
	let errands = []
		, uniqueErrands = {}
		;
	$.each(sources, (i, v) => {
		getUnclosedFromObj(
			errands
			, i !== 0 ? uniqueErrands : false
			, basic
			, currentErrandID
			, v
		);
	});
	return errands;
}

export const ocHasOpenErrandMemoize = createSelector(
	getContactsOpr
	, getDomainBasicErrandById
	, getCurrentErrand
	, (contactsOpr, basic, currentErrandID) => {
		const errands = getOtherOpen([contactsOpr], basic, currentErrandID);
		if (!errands || !errands.length) {
			return false;
		}
		return true;
	}
);

function getOtherErrandsFromSource(
	{ opr, order }
	, ids
	, errands
	, unique
	, basic
	, all
	, doNotCheckAcquired
	, checkUnique
	, noMoved
) {
	$.each(order, (i, v) => {
		const e = basic[v];
		if (!e) {
			return;
		}
		const o = opr[v];
		if ((!noMoved || !o.moved)
			&& (all || o.selected)
			&& (doNotCheckAcquired || !e.acquired)
			&& (!checkUnique || !unique[v])
			&& (!all || doNotCheckAcquired || e.data.agentId === 0)) {
			unique[v] = true;
			ids.push(v);
			errands[v] = e;
		}
	});
}

function getOpenErrands(sources, basic, all, doNotCheckAcquired) {
	let ids = []
		, errands = {}
		, unique = {}
		;
	$.each(sources, (i, v) => {
		getOtherErrandsFromSource(
			v
			, ids
			, errands
			, unique
			, basic
			, all
			, doNotCheckAcquired
			, i !== 0
			, true
		);
	});
	return {ids, errands};
}

const defOprOrder = {opr: emptyObject, order: emptyArray};

function getContactsOpr(state) {
	const { data } = getOtherContacts(state);
	if (!data) {
		return emptyObject;
	}
	return data.opr;
}

function getContactsOrder(state) {
	const { data } = getOtherContacts(state);
	if (!data) {
		return emptyArray;
	}
	return data.order;
}

const getContactsOprOrder = createSelector(
	getContactsOpr
	, getContactsOrder
	, (opr, order) => ({opr, order})
);

export const getSelectedOpenErrandsMemoize = createSelector(
	getContactsOprOrder
	, getDomainBasicErrandById
	, (oprOrder, basic) => getOpenErrands([oprOrder], basic, false, true)
);

export const getAllUnacquiredErrandsMemoize = createSelector(
	getContactsOprOrder
	, getDomainBasicErrandById
	, (oprOrder, basic) => getOpenErrands([oprOrder], basic, true, true)
);

export const currentAcquireTabSelector = createSelector(
	getAppErrand
	, appErrand => appErrand.ui.reply.acquireTab
);

export const currentAcquireOpenTypeSelector = createSelector(
	getAppErrand
	, appErrand => appErrand.ui.reply.openType
);

const defAcquireButtons = [
	{
		type: 'select'
		, className: 'btn-blue'
		, text: I('Acquire selected errands')
		, hidden: false
	}
	, {
		type: 'all'
		, className: 'btn-blue'
		, text: I('Acquire all')
		, hidden: true
	}
	, {
		type: 'link'
		, className: 'btn-blue'
		, text: I('Link errands')
		, hidden: true
	}
	, {
		type: 'unlink'
		, className: 'btn-blue'
		, text: I('Unlink')
		, hidden: true
	}
	, {
		type: 'addtoreply'
		, className: 'btn-blue'
		, text: I('Add to Reply')
		, hidden: true
	}
];

function getAcquiringProgress(state) {
	return state.app.errand.contacts.data.acquiringWip;
}

export const acquireButtonsSelector = createSelector(
	currentAcquireTabSelector
	, currentAcquireOpenTypeSelector
	, getSelectedOpenErrandsMemoize
	, getAcquiringProgress
	, (currentTab, openType, { ids }, wip) => {
		if (currentTab === AET_HISTORIES || currentTab === AET_ACQUIRE) {
			return null;
		} else if (ids && ids.length && !wip) {
			return defAcquireButtons;
		}
		let reason, allReason;
		if (ids && ids.length) {
			reason = I('Acquiring errands...');
		} else {
			reason = I('You must select at least one errand.');
		}
		if(wip) {
			allReason = {reason};
		}
		let selectHidden = false, linkHidden = true, unlinkHidden = true

		if (currentTab == "open" && openType == "myErrands") {
			selectHidden = true
			linkHidden = false
		} else {
			if (currentTab == "open" && openType == "owned") {
				selectHidden = false
				linkHidden = true
			}
		}
		if (currentTab == "linked") {
			unlinkHidden = false
			selectHidden = true
		}


		return update(defAcquireButtons, {
			0: {disabled: {$set: {reason}}, busy: {$set: wip}, hidden: {$set : selectHidden}}
			, 1: {disabled: {$set: allReason}, busy: {$set: wip}}
			, 2: {busy: {$set: wip} , hidden: {$set : linkHidden}}
			, 3: {busy: {$set: wip} , hidden: {$set : unlinkHidden}}
			, 4: {busy: {$set: wip} , hidden: {$set : unlinkHidden}}
		});
	}
);

export const needAutoSaveSelector = createSelector(
	isChatErrand
	, errandStatusSelector
	, (chat, status) => {
		if (chat || status !== EST_OWNED) {
			return false;
		}
		return true;
	}
);

function isStopScrollToQuestion(state) {
	return getUIReply(state).stopScrollToQuestion;
}

export function isHistoryReady(state) {
	return currentErrand(state).history;
}

export function isAcquireReady(state) {
	return currentErrand(state).acquire;
}

export function isAreaReady(state) {
	return currentErrand(state).area;
}

export function isBasicReady(state) {
	return currentErrand(state).basic;
}

const isCurrentOpenErrandClosed = createSelector(
	getCurrentErrandData
	, (ce) => {
		return ce.errand.closed;
	}
);

export const scrollToQuestionSelector = createSelector(
	isStopScrollToQuestion
	, isHistoryReady
	, isAcquireReady
	, isBasicReady
	, (stopScrollToQuestion, historyReady, acquireReady, basicReady) => {
		return !stopScrollToQuestion && historyReady && acquireReady && basicReady;
	}
);

function getLastSave(state) {
	return getUI(state).lastSave;
}

function getLastSaveTriggered(state) {
	return getLastSave(state).triggered;
}

function getErrandInputs(state) {
	return getAppErrand(state).inputs;
}

function getErrandUploadedAttachments(state) {
	return getErrandInputs(state).uploaded_attachments;
}

function tagsChanged(previous, current) {
	if(previous === current) {
		return false;
	}
	let pre = [], curr = [];
	$.each(previous, (i,v) => {
		const sorted = v.slice().sort();
		sorted.join('');
		pre.push(sorted);
	});
	$.each(current, (i,v) => {
		const sorted = v.slice().sort();
		sorted.join('');
		curr.push(sorted);
	});
	if(pre.join('') === curr.join('')) {
		return false;
	}
	return true;
}

// NOTE: make the order of array do not consider as changes.
function sortAndSerial(recipients) {
	let newSlice = recipients.slice();
	newSlice.sort((a, b) => {
		if(a.id < b.id) {
			return -1;
		}
		if(a.id > b.id) {
			return 1;
		}
		return 0;
	});
	let combined = '';
	$.each(newSlice, (i,v) => {
		combined += v.id;
	});
	return combined;
}

const recipientsChanged = (previous, current) => sortAndSerial(previous) !== sortAndSerial(current);

const questionAttachmentsChange = (previous, current) => {
	if (previous.question_attachments === current.question_attachments) {
		return false;
	} else {
		let changed = false;
		$.each(current.question_attachments, (k, v) => {
			if (v) {
				changed = true;
				return false;
			}
		});
		return changed;
	}
};

function sortByID(i, j) {
	return i.id > j.id;
}

const uploadedAttachmentsChange = (previous, current) => {
	let preUpload = previous.uploaded_attachments
		, currUpload = current.uploaded_attachments
		;
	if (preUpload === currUpload || (!preUpload && !currUpload)) {
		return false;
	} else if (!!preUpload !== !!currUpload
		|| preUpload.length !== currUpload.length) {
		return true;
	}
	preUpload = preUpload.slice().sort(sortByID).join(",");
	currUpload = currUpload.slice().sort(sortByID).join(",");
	return preUpload !== currUpload;
};

function checkboxesChanged(previous, current) {
	if(previous === current) {
		return false;
	} else if(previous[ECB_INC_QUESTION] !== current[ECB_INC_QUESTION]) {
		return true;
	} else if(previous[ECB_PARTIAL_ANSWER] !== current[ECB_PARTIAL_ANSWER]) {
		return true;
	} else if(false && previous[ECB_SUGGEST_TO_LIBRARY] !== current[ECB_SUGGEST_TO_LIBRARY]) {
		// TODO: suggest to library can not be saved as no backend storage.
		return true;
	}
	return false;
}

function isCkeditorChanged(previous, current) {
	if(previous.update_answer &&
		previous.update_answer !== current.update_answer) {
		return true;
	} else if(!previous.update_answer && !isEmptyHTML(current.update_answer)) {
		return true;
	}
	return false;
}

function errandInputsChanged(previous, current, checkCkeditor, debug) {
	if (previous === current) {
		return false;
	} else if(tagsChanged(previous.tags, current.tags)) {
		return true;
	} else if(false && previous.area !== current.area) {
		// NOTE: update area is directly update backend server so no need to be
		// detected by auto save if it is changed.
		return true;
	} else if(previous.done_date !== current.done_date) {
		return true;
	} else if(previous.update_subject !== current.update_subject) {
		return true;
	} else if(previous.update_lock !== current.update_lock) {
		return true;
	} else if(previous.update_salutation !== current.update_salutation) {
		return true;
	} else if(previous.update_signature !== current.update_signature) {
		return true;
	} else if(checkboxesChanged(previous.checkboxes, current.checkboxes)) {
		return true;
	} else if(recipientsChanged(previous.update_to, current.update_to)) {
		return true;
	} else if(recipientsChanged(previous.update_cc, current.update_cc)) {
		return true;
	} else if(recipientsChanged(previous.update_bcc, current.update_bcc)) {
		return true;
	} else if(false && previous.update_priority !== current.update_priority) {
		// NOTE: update priority is directly update backend server so no need to
		// be detected by auto save if it is changed.
		return true;
	} else if(checkCkeditor && isCkeditorChanged(previous, current)) {
		if (debug) {
			console.log && console.log(
				"dbg unsaved:"
				, {checkCkeditor, previous, current}
			);
		}
		return true;
	} else if(previous.update_question_subject !== current.update_question_subject) {
		if (debug) {
			console.log && console.log("dbg question subject changed");
		}
		return true;
	} else if(questionAttachmentsChange(previous, current)) {
		if (debug) {
			console.log && console.log("dbg question attachment changed");
		}
		return true;
	} else if (uploadedAttachmentsChange(previous, current)) {
		return true;
	} else if(current.archive_attachments && current.archive_attachments.length) {
		return true;
	} else if(current.library_attachments && current.library_attachments.length) {
		return true;
	} else if(previous.update_external_expert_Answer_attachments.length !== current.update_external_expert_Answer_attachments.length) {
		return true;
	} else if(previous.saved_attachments.length !== current.saved_attachments.length) {
		return true;
	} else if(previous.update_question !== current.update_question) {
		return true;
	}
	return false;
}

export const isInputsChangedMemoize = createSelector(
	getLastSave
	, getErrandInputs
	, ({ ckeditor, dirty, previous, start }, inputs) => {
		if (start !== LSSS_VALID || !dirty) {
			return false;
		}
		const checkCkeditor = !ckeditor;
		return errandInputsChanged(previous, inputs, checkCkeditor);
	}
);

export const needFullSaveMemoize = createSelector(
	getLastSaveTriggered
	, getErrandUploadedAttachments
	, (triggered, uploaded) => {
		if (!triggered) {
			return false;
		}
		// NOTE: 'full' save will only be triggerred whenever there is any
		// temporary uploaded attachment which will not be saved by auto-save
		// because they can potentially change HTML. These temporary uploaded
		// attachments will only be saved after errand closed view. Other
		// condition such [signature name] change will not be working in 5.0
		// because auto-save never change its content and also the [signature
		// name] seem to be a wrong option.
		// Refer to: https://jira.cention.se:8443/browse/CCC-158?focusedCommentId=27239&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-27239
		if (!uploaded || !uploaded.length) {
			return false;
		}
		return true;
	}
);

export const needSaveMemoize = state => !isErrandAnswered(state) && isInputsChangedMemoize(state) || needFullSaveMemoize(state);

function getLastSaveEE(state) {
	return getUI(state).lastSaveEE;
}

function collabInputsChanged(previous, current, checkCkeditor, debug) {
	if (previous === current) {
		return false;
	} else if(previous.update_subject !== current.update_subject) {
		return true;
	} else if(previous.update_signature !== current.update_signature) {
		return true;
	} else if(previous.includeCollabHistory !== current.includeCollabHistory) {
		return true;
	} else if(previous.includeErrandHistory !== current.includeErrandHistory) {
		return true;
	} else if(previous.canSendCollabToMedia !== current.canSendCollabToMedia) {
		return true;
	} else if(previous.selectedAgents !== current.selectedAgents) {
		return true;
	} else if(recipientsChanged(previous.update_to, current.update_to)) {
		return true;
	} else if(recipientsChanged(previous.update_cc, current.update_cc)) {
		return true;
	} else if(recipientsChanged(previous.update_bcc, current.update_bcc)) {
		return true;
	} else if(checkCkeditor && isCkeditorChanged(previous, current)) {
		if (debug) {
			console.log && console.log(
				"dbg unsaved:"
				, {checkCkeditor, previous, current}
			);
		}
		return true;
	} else if (uploadedAttachmentsChange(previous, current)) {
		return true;
	} else if(current.archive_attachments && current.archive_attachments.length) {
		return true;
	} else if(current.library_attachments && current.library_attachments.length) {
		return true;
	}
	return false;
}

export const getEEUploadedAttachments = state => {
	let unsavedAttachments = false;
	let colInputs = state.app.errand.collaborationInputs;

	// In collaboration auto-save, we don't save uploaded attachment, archive
	// attachment or library attachment until force save. So we need to check 
	// if there is any unsave attachments
	if (colInputs.uploaded_attachments.length>0 || colInputs.archive_attachments.length>0 || colInputs.library_attachments.length>0) {
		unsavedAttachments = true;
	}
	return unsavedAttachments;
}

export const isCollabInputsChangedMemoize = createSelector(
	getLastSaveEE
	, collaborationInputs
	, ({ ckeditor, dirty, previous, start }, inputs) => {
		if (start !== LSSS_VALID || !dirty) {
			return false;
		}
		const checkCkeditor = !ckeditor;
		return collabInputsChanged(previous, inputs, checkCkeditor);
	}
);

const getContactCardUI = state => state.app.errand.ui.contactCard;

export const contactCardValidation = createSelector(
	[
		getContactCardUI
	],
	(cc) => {
		let disabled = true;
		let type = cc.channelType, value = cc.contactInput.replace('\t','').trim();
		if((type === Workflow.Errand.SERVICE_VOICE || type === Workflow.Errand.SERVICE_SMS )&& (isValidPhoneNo(value)|| isSipNumber(value))){
			disabled = false;
		}else if(type === Workflow.Errand.SERVICE_TWITTER && hasPrefix(value, "twitter-")){
			disabled = false;
		}else if(type === Workflow.Errand.SERVICE_FACEBOOK && (hasPrefix(value, "facebook-")
			|| value.includes("facebook.com"))){
				disabled = false;
		}else if(type === Workflow.Errand.SERVICE_INSTAGRAM && hasPrefix(value, "instagram-")){
			disabled =false;
		}else if(type === Workflow.Errand.SERVICE_LINKEDIN && hasPrefix(value, "linkedin-")){
			disabled = false;
		}else if(type === Workflow.Errand.SERVICE_VKONTAKTE && hasPrefix(value, "centionvk-")){
			disabled = false;
		}else if(type === Workflow.Errand.SERVICE_LINE && hasPrefix(value, "centionline-")){
			disabled = false;
		}else if(type === Workflow.Errand.SERVICE_WHATSAPP && hasPrefix(value, "whatsapp-")){
			disabled = false;
		}else if((type === Workflow.Errand.SERVICE_EMAIL || type === Workflow.Errand.SERVICE_CHAT) && isValidEmail(value)){
			disabled = false;
		}else if(type === 0 && value != "" && cc.customLabel != ""){
			disabled = false;
		}
		return disabled;
	}
);

const getContactCard = state => currentErrand(state).contactCard;

const getAllContactCard = state => currentErrand(state).contactBook;

const getContactBookUI = state => state.app.errand.ui.contactBook;

export const currentCollaborationAddress = createSelector(
	[
		isCollaborationSelector,
		eeeDomain
	],
	(isCollaborate, domain) => {
		if(!isCollaborate || !domain || !domain.list) {
			return [];
		}
		return domain.list.toAddressList;
	}
);

export const isAgentsSelector = createSelector(
	getContactBookUI
	, (cb) => {
		if(cb.replyType === RECIPIENT_INTERNAL_COLLABORATE
			&& cb.context === RPLY_COLLABORATE) {
			 return true;
		}
		return false;
	}
);

export const isContactCardSelector = createSelector(
	getContactBookUI
	, (cb) => {
		if(cb.context === RPLY_EXT_FWD
			|| cb.context === RPLY_MANUAL
			|| cb.context === RPLY_ERRAND) {
			 return true;
		}
		return false;
	}
);

export const isAddToContactCard = createSelector(
	getContactBookUI
	, (cb) => {
		if(cb.context === ADD_TO_CONTACT_CARD) {
			 return true;
		}
		return false;
	}
);

export const isCollaboratorSelector = createSelector(
	getContactBookUI
	, (cb) => {
		if(cb.replyType != RECIPIENT_INTERNAL_COLLABORATE
			&& cb.context === RPLY_COLLABORATE) {
			 return true;
		}
		return false;
	}
);

export const isShareStatistic = createSelector(
	getContactBookUI
	, (cb) => {
		if(cb.replyType == SHARE_STATISTICS
			&& cb.context === STATISTICS_PAGE) { // TODO: can remove STATISTICS_PAGE dependecy to use activeMainMenu
			 return true;
		}
		return false;
	}
);

const whichAddressbook = createSelector(
	currentActiveReply
	, addressBookSelector
	, getCurrentAreaAddressBook
	, (currentReply, manual, errand) => {
		if(currentReply === RPLY_MANUAL) {
			return manual;
		} else {
			return errand;
		}
	}
);

export const contactBookPersonListSelector = createSelector(
	getContactBookUI
	, getAllContactCard
	, onlyActiveAgentsSelector
	, areaAgentDataSelector
	, currentCollaborationAddress
	, whichAddressbook
	, isAgentsSelector
	, isContactCardSelector
	, isCollaboratorSelector
	, isAddToContactCard
	, isShareStatistic
	, sipCallShowAgentList
	, sipCallGetAgentList
	, (cb, ccList, acAgents, agents, collaboration, addressbook
		, isAgent, isCc, isColla, isAddToCc, isShare, isSipTransfer, avSipAgents) => {
		const arr = [];
		if((isColla || isCc || isAddToCc || isSipTransfer) &&
			cb.currentTab === CB_CONTACT_CARD) {
				$.each(ccList, (i,v) => {
					arr.push({
						id: v.id,
						name: v.name,
						searchTerm: v.searchTerm
					});
					return
				});
			let query = cb.searchText.toLowerCase();
			return arr.filter(item => item.searchTerm.toLowerCase().indexOf(query) >= 0);
		}
		if(isAgent && (cb.currentTab === CB_ALL_CONTACT ||
			cb.currentTab === CB_AGENTS)) {
				$.each(agents, (i,v) => {
					arr.push({
					id: v.Id,
					name: v.Name
				});
			});
		}
		if((isColla || isSipTransfer) && (cb.currentTab === CB_ALL_CONTACT ||
			cb.currentTab === CB_EXPERT_IN_AREA)) {
			$.each(collaboration, (i,v) => {
				if(v.collaboration) {
					arr.push({
						id: v.id,
						name: (v.name === "" ? v.id : v.name)
					});
				}
			});
		}
		if((isColla || isCc || isSipTransfer) && (cb.currentTab === CB_ALL_CONTACT ||
			cb.currentTab === CB_ADDRESS_BOOK)) {
			$.each(addressbook, (i,v) => {
				arr.push({
					id: v.id,
					name: v.value
				});
			});
		}
		if((isShare || isSipTransfer) && (cb.currentTab === CB_ALL_CONTACT ||
			cb.currentTab === CB_AGENTS)) {
				if(isSipTransfer) {
					$.each(avSipAgents, (i,v) => {
						arr.push({
							id: v.id,
							name: v.username,
							sipid: v.sipid
						});
					});
				} else {
					$.each(acAgents, (i,v) => {
						arr.push({
							id: v.Id,
							name: v.Name
						});
					});
				}
		}
		return filterItems(cb.searchText, arr);
	}
);

const filterItems = (text, arr) => {
	let query = text.toLowerCase();
	return arr.filter(item => item.name.toLowerCase().indexOf(query) >= 0);
}

export const currentPersonDetailsSelector = createSelector(
	getContactBookUI
	, getServicesByType
	, getContactCard
	, onlyActiveAgentsSelector
	, areaAgentDataSelector
	, currentCollaborationAddress
	, whichAddressbook
	, isAgentsSelector
	, isContactCardSelector
	, isCollaboratorSelector
	, isAddToContactCard
	, isShareStatistic
	, sipCallShowAgentList
	, sipCallGetAgentList
	, (cb, service, cc, acAgents, agents, collaboration
		, addressbook, isAgent, isCc, isColla, isAddToCc, isShare, isSipTransfer, avSipAgents) => {
		let person;
		if((isCc || isAddToCc || isSipTransfer) &&
			cb.currentTab === CB_CONTACT_CARD) {
				let c = cc.customer, cid = c.id;
				if(isSipTransfer) {
					if(typeof cid === "number") {
						cid = cid.toString();
					}
				}
				if(c && cid === cb.selectedPerson) {
					person = {
						id: c.id
						, name: c.name
						, photo: c.avatar
						, date: c.date
						, externalId: c.externalId
						, companyId: c.companyId
						, companyName: c.companyName
					};
				}
		}
		if((isAgent) && (cb.currentTab === CB_AGENTS
			|| cb.currentTab === CB_ALL_CONTACT)) {
			$.each(agents, (i,v) => {
				if(v.Id === cb.selectedPerson) {
					person = {
						id: v.Id
						, name: v.Name
						, photo: v.Avatar
						, service: service[1].name
						, address: v.Email
					};
					return false;
				}
			});
		}
		if((isShare || isSipTransfer) && (cb.currentTab === CB_AGENTS
			|| cb.currentTab === CB_ALL_CONTACT)) {
			if(isSipTransfer) {
				$.each(avSipAgents, (i,v) => {
					if(v.id === cb.selectedPerson) {
						person = {
							id: v.id
							, name: v.name
							, photo: v.avatar
							, service: I("Voice")
							, address: v.sipid
							, sipid: v.sipid
						};
						return false;
					}
				});
			} else {
				$.each(acAgents, (i,v) => {
					if(v.Id === cb.selectedPerson) {
						person = {
							id: v.Id
							, name: v.Name
							, photo: v.Avatar
							, service: service[1].name
							, address: v.Email
						};
						return false;
					}
				});
			}
		}
		if((isColla || isSipTransfer) && (cb.currentTab === CB_ALL_CONTACT
			|| cb.currentTab === CB_EXPERT_IN_AREA)) {
			$.each(collaboration, (i,v) => {
				if(v.id === cb.selectedPerson) {
					person = {
						id: v.id
						, name: (v.name === "" ? v.id : v.name)
						, photo: v.avatar
						, service: service[v.service].name
						, address: v.id
					};
					return false;
				}
			});
		}
		if((isCc || isSipTransfer) && (cb.currentTab === CB_ALL_CONTACT ||
			cb.currentTab === CB_ADDRESS_BOOK)) {
				$.each(addressbook, (i,v) => {
					if(v.id === cb.selectedPerson) {
						person = {
							id: v.id
							, name: v.value
							, photo: ""
							, service: service[v.service].name
							, address: v.id
						};
						return false;
					}
				});
		}
		return person;
	}
);

export const errandServiceTypeSelector = createSelector(
	manualErrandSelectedReplyChannel
	, currentErrandReplyChannel
	, getContactBookUI
	, getServerServices
	, (manualRC, errandRC, cb, service) => {
		let reply_channel = (cb.context === RPLY_MANUAL) ?
			manualRC : (cb.context === RPLY_COLLABORATE) ?
			RC_EMAIL : errandRC;
		return service.byName[reply_channel];
	}
);

export const getErrandOutgoingDialplanSelector = createSelector(
	allConnectedAreasSelector
	, getCurrentErrandAreaId
	, manualErrandSelectedAreaSelector
	, (orgs, areaId, mAreaId) => {
		let callPlanId = "";
		let id = (areaId === 0) ? mAreaId : areaId;
		if (id === 0) {
			return callPlanId;
		}
		for(let index=0; index < orgs.length; index++) {
			$.each(orgs[index].Areas, (i, area) => {
				if(area.Id === id) {
					callPlanId =  area.SipOutgoingDialPlanId;
					return false
				}
			});
			if(callPlanId != "") {
				break;
			}
		}
		return callPlanId;
	}
);

export const getCurrentContactSelector = createSelector(
	getServicesByType
	, getContactBookUI
	, getContactCard
	, errandServiceTypeSelector
	, currentPersonDetailsSelector
	, isAgentsSelector
	, isContactCardSelector
	, isCollaboratorSelector
	, isShareStatistic
	, sipCallShowAgentList
	,(service, cb, cc, sType, details
		, isAgent, isCc, isColla, isShare, isSipTransfer) => {
		let item = {
			id: 0,
			address: ""
		};
		if(cb.currentTab === CB_CONTACT_CARD) {
			$.each(cc.contactList, (i,v) => {
				if((cb.selectedContact != 0 &&
					v.id === cb.selectedContact &&
					v.serviceType === sType) ||
					(cb.selectedContact === 0 &&
					v.serviceType === sType)) {
						item = {
							id: v.id,
							address: v.address
						};
						return false;
					}
			});
		} else {
			if(details && (isCc || isSipTransfer) &&
				details.service === service[sType].name) {
					item = {
						id: details.id,
						address: details.address
					};
			} else if(details && (isAgent || isColla || isShare || isSipTransfer)) {
				item = {
					id: details.id,
					address: details.address
				};
			}
		}
		return item;
	}
);

export const contactCardContactSelector = createSelector(
	getContactCard
	, (cc) => {
		if(!cc) {
			return [];
		}
		return cc.contactList;
	}
);

export const contactCardCustomerSelector = createSelector(
	[
		getClientAvatarInfoByErrand,
		getFetchClientsAddressListReady,
		getClientFromOtherContact
	],
	(avatarInfo, ready, clientName) => {
		if(avatarInfo && avatarInfo !== emptyObject) {
			return avatarInfo.nameOnContactCard;
		}else{
			if (!ready) {
				return "";
			}
			return clientName;
		}
		return clientName;
	}
);

function processLibraryItem(item, answerWithShortcut, currentDepth, maxDepth) {
	if (currentDepth > maxDepth) {
		return;
	}

	if (item.keyboardShortcut != "" && item.active) {
		let mod = [];
		if (item.keyboardShortcutUseAlt) mod.push("Alt");
		if (item.keyboardShortcutUseCtrl) mod.push("Ctrl");
		if (item.keyboardShortcutUseMeta) mod.push("Meta");
		if (item.keyboardShortcutUseShift) mod.push("Shift");

		let mods = mod.join(" + ");
		let shortcuts = "(" + mods + " + " + item.keyboardShortcut + ")";

		let shortcutItem = {
			Id: item.id,
			Name: item.name + " " + shortcuts,
			Answer: item.answer,
			Attachments: item.attachments,
			Key: item.keyboardShortcut.toUpperCase().charCodeAt(0),
			UseAlt: item.keyboardShortcutUseAlt,
			UseCtrl: item.keyboardShortcutUseCtrl,
			UseMeta: item.keyboardShortcutUseMeta,
			UseShift: item.keyboardShortcutUseShift
		}

		let found = answerWithShortcut.some(v => v.Id === shortcutItem.Id);
		if (!found) {
			answerWithShortcut.push(shortcutItem);
		}
	}

	if (item.list) {
		item.list.forEach(child => processLibraryItem(child, answerWithShortcut, currentDepth + 1, maxDepth));
	}
}

const knowledgeBase = state => state.domain.errandKnowledgeBase;
export const libraryShortcutsSelector = createSelector(
	[
		knowledgeBase
	],
	(kb) => {
		let answerWithShortcut = [];
		if(kb && typeof kb != "undefined") {
			// Notes: Change 'maxDepth' to control the maximum depth for knowledge base tree to trace for shortcuts
			const maxDepth = 5;
			kb.allIds.forEach(id => {
				let item = kb.byId[id];
				if (item && typeof item.list !== "undefined") {
					if(item.list && item.list.length > 0){
						item.list.forEach(lib => {
							if (lib) {
								lib.list.forEach(category => {
									if (category) {
										processLibraryItem(category, answerWithShortcut, 1, maxDepth);
									}
								});
							}
						});
					}
				}
			});
		}
		return answerWithShortcut;
	}
);

const knowledgeBaseUI = state => state.app.errand.ui.knowledgeBase;
export const knowledgeBaseDataSelector = createSelector(
	[
		knowledgeBase,
		knowledgeBaseUI,
		getCurrentErrandAreaData,
		manualErrandSelectedAreaData,
	],
	(kb, kbUI, errandArea, manualArea) => {
		let item, library;
		if(kbUI.selectedLibrary > 0){
			library = kbUI.selectedLibrary;
		} else if(kbUI.reply === RPLY_ERRAND && errandArea) {
			library = errandArea.library;
		} else if(kbUI.reply === RPLY_MANUAL && manualArea) {
			library = manualArea.library;
		}
		$.each(kb.allIds, (i,v) => {
			if(v === library){
				if(kb.byId[library]){
					item = kb.byId[library].list;
				}
			}
		});
		return item;
	}
);

const knowledgeBaseList = state => state.app.workflow.knowledgeBaseList;
export const knowledgeBaseListSelector = createSelector(
	[
		knowledgeBaseList
	],
	({data}) => {
		if(data != null){
			return data.list;
		}
		return [];
	}
);

const rewriteAnswerBaseList = state => state.app.errand.ui.rewriteAnswerBase;
export const rewriteAnsBaseDataSelector = createSelector(
	[
		getCurrentErrand
		, openQuestionIdSelector
		, rewriteAnswerBaseList
	],
	(eid, qId, { dataSrc }) => {
		let id = eid ? eid : qId ? 'qLibrary-'+qId : 'qLibrary-0';
		if(dataSrc != null){
			const data = dataSrc[id];
			if(data != null){
				return data;
			}
		}
		return emptyObject;
	}
);

export const getLlmSessionIdSelector = createSelector(
	[
		getCurrentErrand
		, openQuestionIdSelector
		, rewriteAnswerBaseList
	],
	(eid, qId, { llmSessionId }) => {
		let id = eid ? eid : qId ? 'qLibrary-'+qId : 'qLibrary-0';
		if(llmSessionId != null){
			const data = llmSessionId[id];
			if(data != null){
				return data;
			}
		}
		return "";
	}
);

export const getOriginalReplyChannel = createSelector(
	basicErrand
	, getServerServices
	, (basic, { byType, constants }) => {
		if (!basic || !basic.data || !basic.data.data) {
			return;
		}
		const { service, fbPms, twitterPm } = basic.data.data
			, { Errand } = constants.Workflow
			;
		let channel = byType[service];
		if (service === Errand.SERVICE_FACEBOOK && fbPms) {
			// channel = RC_FB_MESSENGER;
		} else if (service === Errand.SERVICE_TWITTER && twitterPm) {
			// channel = RC_TW_MESSENGER;
		} else if (service === Errand.SERVICE_VKONTAKTE && fbPms) {
			// channel = RC_VK_MESSENGER;
		}
		return channel;
	}
);

function getWorkflowFilterResetErrandView(state) {
	return state.app.workflow.filter.resetErrandView;
}

const defJustLogin = {state: ST_JUST_LOGIN}
	, defUnselect = {state: ST_UNSELECT}
	, defAnswered = {state: ST_ANSWERED}
	;
export const currentStateMemoize = createSelector(
	currentErrand
	, getWorkflowFilterResetErrandView
	, ({type, ready, area, acquire, extend, basic}, reset) => {
		switch (type) {
			case UNSELECT_STR:
				return defUnselect;
			case OPEN_STR:
				if (reset) {
					return defJustLogin;
				}
				let res = {};
				if (!ready) {
					res.state = ST_ACQUIRING;
					let totalTrue = 0;
					if (area) {
						totalTrue++;
					}
					if (acquire) {
						totalTrue++;
					}
					if (extend) {
						totalTrue++;
					}
					if (basic) {
						totalTrue++;
					}
					res.progress = 100*totalTrue/4;
				} else {
					res.state = ST_ACQUIRED;
				}
				return res;
			case EOPR_ANSWERED:
				return defAnswered;
			default:
				return defJustLogin;
		}
	}
);

export const isOpenedNormalErrand = createSelector(
	getCurrentErrand
	, isChatErrand
	, (id, chat) => {
		if (chat || id <= 0) {
			return false;
		}
		return true;
	}
);

export const getErrandHistoryIdsSelector = createSelector(
	getCurrentErrand
	, isChatErrand
	, getDomainHistoryById
	, initialThreadIDSelector
	, (eid, chat, byId, threadId) => {
		let ids = [];
		let histories = byId[threadId];
		if(chat){
			return eid;
		}
		if (!histories || !histories.data || !histories.data.length) {
			return eid;
		}
		histories = histories.data;
		if (!histories || !histories.length) {
			return eid;
		}
		$.each(histories, (i, hist) => {
			ids.push(hist.eid);
		});

		return ids.join(",");
	}
);

export const showPreviewSelector = noSelector(
	isChatErrand
	, isErrandViewOnly
	, isEmailReply
	, getCurrentReply
	, (thisIsChat, viewErrandOnly, emailReply, reply) => {
		if ((reply !== RPLY_ERRAND && reply !== RPLY_EXT_FWD)
			|| thisIsChat
			|| viewErrandOnly
			|| !emailReply) {
			return false;
		}
		return true;
	}
);

function createManualPreviewDisabledSelector(showSelector){
	return noSelector(
		showSelector
		, manualErrandSelectedAreaData
		, manualErrandSelectedReplyChannel
		, (show, areaData, channel) => {
			if (!show || !areaData || channel != RC_EMAIL) {
				return true;
			}
			return false;
		}
	);
}

function createPreviewOrSaveAsEmlDisabledSelector(showSelector) {
	return noSelector(
		showSelector
		, getPreviewOrSaveEmlBusy
		, (show, previewBusy) => {
			if (!show) {
				return true;
			}
			return previewBusy;
		}
	);
}

export const isPreviewDisabledSelector = createPreviewOrSaveAsEmlDisabledSelector(showPreviewSelector);

export const isManualPreviewDisabledSelector = createManualPreviewDisabledSelector(createPreviewOrSaveAsEmlDisabledSelector);

export const showSaveAsEmlSelector = noSelector(
	showPreviewSelector
	, isHandled
	, isOwnedOrAbleHandle
	, (show, handled, ownedOrAbleHandle) => {
		if (!show || (!isHandled && !isOwnedOrAbleHandle)) {
			return false;
		}
		return true;
	}
);

export const isSaveAsEmlDisabledSelector = createPreviewOrSaveAsEmlDisabledSelector(showSaveAsEmlSelector);

function getAppWorkflow(state) {
	return state.app.workflow;
}

const wipUpdateEE = state => getAppErrand(state).updateAnswerEE.wip;
const wipSendEE = state => getAppErrand(state).sendEE.wip;

export const isEEActionInProgressSelector = noSelector(
	wipUpdateEE, wipSendEE,
	(updateEE, sendEE) => (updateEE || sendEE)
);

const wipUpdateAnswer = state => getAppErrand(state).updateAnswer.wip;
const wipSendAnswer = state => getAppErrand(state).sendAnswer.wip;

// TODO: move redux state creator 'workflow' to selector for better common code
// usage and proper dependecy import.
const wipCloseErrand = state => getAppWorkflow(state).closeErrand.wip;
const wipDeleteErrands = state => getAppWorkflow(state).deleteErrands.wip;
const wipForwardToAgent = state => getAppWorkflow(state).forwardToAgent.wip;
const wipForwardToArea = state => getAppWorkflow(state).forwardToArea.wip;
const wipMoveToFolder = state => getAppWorkflow(state).moveToFolder.wip;
const wipReturnToInbox = state => getAppWorkflow(state).returnToInbox.wip;

export const isErrandActionInProgressSelector = noSelector(
	wipUpdateAnswer
	, wipSendAnswer
	, wipCloseErrand
	, wipDeleteErrands
	, wipForwardToAgent
	, wipForwardToArea
	, wipMoveToFolder
	, wipReturnToInbox
	, (
		updateAnswerWip
		, sendAnswerWip
		, closeErrandWip
		, deleteErrandsWip
		, forwardToAgentWip
		, forwardToAreaWip
		, moveToFolderWip
		, returnToInboxWip
	) => (
		updateAnswerWip
		|| sendAnswerWip
		|| closeErrandWip
		|| deleteErrandsWip
		|| forwardToAgentWip
		|| forwardToAreaWip
		|| moveToFolderWip
		|| returnToInboxWip
	)
);

export const openErrandFromContextMemoize = noSelector(
	acquireErrandParam
	, param => {
		if (!param) {
			return;
		}
		return param.source;
	}
);

export const getAgentWorking = noSelector(getAppErrand, (errand) => {
	if (errand.workingOnErrand.data) {
		return errand.workingOnErrand.data.agent_working_on_errand;
	}
	return false;
});

function genChannelActions(ch, opts, mediaConfig) {
	const ca = {}
	if(opts && opts[ch] && opts[ch].actions){
		for (var property in opts[ch].actions) {
			if (opts[ch].actions.hasOwnProperty(property)) {
				let active = false;
				if(mediaConfig[property] !== 'undefined'){
					active = mediaConfig[property];
				}
				ca[property] = update(opts[ch].actions[property], {active: {
					$set: active}});
			}
		}
		return update(opts[ch], {actions: {$set: ca}});
	}else{
		if(opts && opts[ch] && opts[ch].name){
			return opts[ch];
		}
		return {};
	}
}

const currentErrandCRMData = state => currentErrand(state).crmData;

export const getCRMEmailTrigger = createSelector(
	currentErrandCRMData,
	(crm) =>{
		if(typeof crm !== 'undefined' && crm !== null){
			return crm
		}
		return {};
	}
)

const currentErrandData = state => currentErrand(state).smData;
const getMediaConfig = createSelector(
	getChannelsSelector,
	currentErrandData,
	(channel, errand) => {
		let mediaActions = {};
		for(let props in MEDIA_ACTION){
			if(MEDIA_ACTION.hasOwnProperty(props)) {
				let v = MEDIA_ACTION[props];
				if(v === "fb_profile" || v === "twt_profile" || v === "vk_profile")
					mediaActions[v] = true;
				else
					mediaActions[v] = false;
			}
		}
		if(errand === null){
			return mediaActions;
		}
		if( ( (channel[0] == "facebook" || channel[0] == "vkontakte") && !errand.isFBVKpm) ||
			(channel[0] == "twitter" && !errand.isTwtPm) || channel[0] === "instagram"){
			mediaActions.fb_history = true;
			//CCC-3323 Added under history, so not used at the moment
			//mediaActions.fb_emotion_history = true;
			mediaActions.twt_history = true;
			mediaActions.vk_history = true;
			if(errand.like === "" || errand.like === "unliked"){
				mediaActions.fb_like = true;
				mediaActions.twt_like = true;
				mediaActions.vk_like = true;
				//mediaActions.ins_like = true;
			}else{
				mediaActions.fb_unlike = true;
				mediaActions.twt_unlike = true;
				mediaActions.vk_unlike = true;
				//mediaActions.ins_unlike = true;
			}
			if(!errand.retweet){
				mediaActions.twt_retweet = true;
			}else{
				mediaActions.twt_unretweet = true;
			}
			if(errand.action === ""){
				mediaActions.fb_hide = true;
				mediaActions.ins_hide = true;
				mediaActions.fb_deleteq = true;
				mediaActions.twt_deleteq = true;
				mediaActions.vk_deleteq = true;
				mediaActions.ins_deleteq = true;
			}else{
				if(errand.action !== "deleted"){
					if(errand.action === "hidden"){
						mediaActions.fb_unhide = true;
						mediaActions.ins_unhide = true;
					}else{
						mediaActions.fb_hide = true;
						mediaActions.ins_hide = true;
					}
				}else{
					/*CCC-3323 added in errand history so disabled it in errand actions: "fb_emotion_history",*/
					["fb_hide","fb_deleteq","vk_deleteq","vk_deleteq","fb_unhide","fb_like","twt_like","ins_unhide",
					"fb_history","twt_history","vk_history","twt_retweet","twt_unretweet","vk_like", "ins_hide", "ins_deleteq"
					].forEach(k =>{
						mediaActions[k] = false;
					});
				}
			}
			if(errand.service !== Workflow.Errand.SERVICE_MANUAL_FACEBOOK){
				mediaActions.fb_sendpm = true;
				mediaActions.fb_rating = true;
			}
			if(errand.service !== Workflow.Errand.SERVICE_MANUAL_TWITTER){
				mediaActions.twt_sendpm = true;
			}
			if(errand.service !== Workflow.Errand.SERVICE_MANUAL_VKONTAKTE){
				mediaActions.vk_sendpm = true;
			}
			if(errand.closed){
				if(!errand.answerDeleted){
					mediaActions.fb_update = true;
					mediaActions.vk_update = true;
					if(errand.hasAnswer){
						switch(errand.service){
							case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
								mediaActions.fb_deleteAns = true;
							case Workflow.Errand.SERVICE_MANUAL_TWITTER:
								mediaActions.twt_deleteAns = true;
							case Workflow.Errand.SERVICE_MANUAL_VKONTAKTE:
								mediaActions.vk_deleteAns = true;
						}
					}
				}
			}
		}
		return mediaActions;
	}
)

export const getChannelOpts = createSelector(
	getChannelsSelector,
	currentErrandReplyChannel,
	getMediaConfig,
	(channels, currentChannel, mediaConfig) => {
		let opts = {};
		$.each(channels, (i,v) => {
			if(currentChannel === v){
				let channelInfo = genChannelActions(v, OPT_CHANNELS, mediaConfig);
				if(Object.keys(channelInfo).length === 0 ) {
					channelInfo = OPT_CHANNELS[v];
				}
				opts[currentChannel] = channelInfo;
			}else{
				opts[v] = OPT_CHANNELS[v];
			}
		});
		return opts;
	}
);

export const getCollabOpts = createSelector(
	getCollabChannelsSelector,
	(collabChannels) => {
		let collabOpts = {};
		$.each(collabChannels, (i,v) => {
			collabOpts[v] = OPT_CHANNELS[v];
		});
		return collabOpts;
	}
);

export const getCollabAttachments = createSelector(
	[getDomain, getCurrentErrand],
	(domain, currentErrand) => {
		if (domain[D_EE_LIST] && domain[D_EE_LIST].byId && domain[D_EE_LIST].byId[currentErrand]) {
			return domain[D_EE_LIST].byId[currentErrand].attachments;
		}
		return [];
	}
);

const appErrandRatingsData = state => getAppErrand(state).ratings.data;
export const ratingsDataList = createSelector(
	appErrandRatingsData,
	(ratingsData) => {
		return  ratingsData != null ? ratingsData.list : []
	}
)

const appErrandReactionsData = state => getAppErrand(state).reactions.data;
export const reactionsDataList = createSelector(
	appErrandReactionsData,
	(reactionsData) => {
		return  reactionsData != null ? reactionsData.list : []
	}
)

export const checkCollabHistory = createSelector(
	collaborationInputsThreadId,
	(threadID) => {
		return  threadID != 0 ? {incCollabHis : true} : {incCollabHis : false}
	}
)

const appErrandUIReplyShowRecipients = state => getAppErrand(state).ui.reply.showRecipients;
const appErrandUIShowReplyShow = state => getAppErrand(state).ui.reply.show;

export const getAvailableReplyPanel = createSelector(
	isChat,
	getCurrentReply,
	currentErrandReplyChannel,
	appErrandUIReplyShowRecipients,
	appErrandUIShowReplyShow,
	(chat, currentReply, currentChannel, showRecipients, replyShow) => {
		let availableReplyPanel = [];
		let isErrand, isQuestion, isExtFwd, isCollaborate, isComment, isVoice;
		switch(currentReply) {
			case RPLY_ERRAND:
				isErrand = true;
				break;
			case RPLY_QUESTION:
				isQuestion = true;
				break;
			case RPLY_EXT_FWD:
				isExtFwd = true;
				break;
			case RPLY_COLLABORATE:
				isCollaborate = true;
				break;
			default:
				isComment = true;
		}
		const notChatOrChatCollaborating = notChatOrChatInCollborate(chat, currentReply);
		const emailType = notChatOrChatCollaborating &&
			(isErrand || isQuestion || isExtFwd || isCollaborate || isComment);

		if (emailType) {
			if (!isComment && !isQuestion) {
				availableReplyPanel.push(VIEW_REPLY_PANEL.KnowledgeBase);
				availableReplyPanel.push(VIEW_REPLY_PANEL.Subject);
			}
			availableReplyPanel.push(VIEW_REPLY_PANEL.Toolbar);
		}
		if(currentChannel === 'voice'){
			isVoice = true;
		}
		const isEmailCh = isErrand &&
			(currentChannel === 'email' || currentChannel === 'sms');
		let showSubjectDOM = false;
		if (notChatOrChatCollaborating && replyShow &&
			(isEmailCh || isExtFwd || isCollaborate || isVoice)) {
			showSubjectDOM = true;
		}
		if (showSubjectDOM) {
			availableReplyPanel.splice(1, 0, VIEW_REPLY_PANEL.Recipient);
		}else {
			availableReplyPanel = availableReplyPanel.filter(function(value, index, arr){
				return value !=  VIEW_REPLY_PANEL.Subject;
			});
		}
		let showRecipientPanel = false;
		if(showRecipients && !isVoice){
			showRecipientPanel = true;
		} else {
			if(isVoice) {
				if(isCollaborate) {
					showRecipientPanel = showRecipients;
				}
			}
		}
		if(isVoice) {
			if(!showRecipientPanel){
				availableReplyPanel = availableReplyPanel.filter(function(value, index, arr){
					return value !=  VIEW_REPLY_PANEL.Recipient;
				});
			}
		}
		return availableReplyPanel;
	}
)

const appErrandUIReplyShowReplyAssist = state => getAppErrand(state).ui.reply.showReplyAssist;
const appErrandUIReplyShowReplyToolbar = state => getAppErrand(state).ui.reply.showReplyToolbar;
const appErrandUIReplyShowSubject = state => getAppErrand(state).ui.reply.showSubject;

export const getSelectedReplyPanel = createSelector(
	getAvailableReplyPanel,
	appErrandUIReplyShowReplyAssist,
	appErrandUIReplyShowRecipients,
	appErrandUIReplyShowReplyToolbar,
	appErrandUIReplyShowSubject,
	(availableReplyPanel, showReplyAssist, showRecipients, showReplyToolbar, showSubject) => {
		let selectedReplyPanel = [];
		if (availableReplyPanel.length > 0){
			if(showReplyAssist) {
				selectedReplyPanel.push(VIEW_REPLY_PANEL.KnowledgeBase.id);
			}
			if(showRecipients) {
				selectedReplyPanel.push(VIEW_REPLY_PANEL.Recipient.id);
			}
			if(showReplyToolbar) {
				selectedReplyPanel.push(VIEW_REPLY_PANEL.Toolbar.id);
			}
			if(showSubject) {
				selectedReplyPanel.push(VIEW_REPLY_PANEL.Subject.id);
			}
		}
		return selectedReplyPanel;
	}
)

export const chatHasCallbackSelector = noSelector(isChat, (chat) => {
	if(chat && chat.enableCallbackRequest) {
		return true;
	}
	return false;
});
