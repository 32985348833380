import React, {
    Fragment
    , PureComponent
    , useCallback
    , memo
    , useMemo
    , useEffect
    , useState
} from 'react';
import classNames from 'classnames';
import { renderFileActionBtns as renderActionButtons } from '../admin';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { I, L, webRoot } from '../../../common/v5/config';
import {
    ListHeaderWrapper,
    ListContentWrapper,
    editorTitleByView,
    Delete as ActionDelete,
    Download as ActionDownload,
    Edit as ActionEdit,
    EditorHeader,
    AdminListAndEditLayout,
    withEditWrap,
} from '../../../reactcomponents/Admin';
import { TableIconicButton as Button, TextInputRow } from '../../../reactcomponents/Form';
import {
    ADMIN_TITLE,
    INPUT_EMPTY_WARNING,
    INPUT_SPACE_WARNING,
    M_CHAT_IP_BLACKLIST,
} from '../../../common/v5/constants';
import withAccessibility from './accessibilityCtnr';
import Helper from '../../../reactcomponents/Helper';
import { customPageTotal } from '../../../reactcomponents/Table';

const TableHeader = ({
    onHandleOpenNew,
    showInline,
    title,
}) => {
    return (
        <div className='admin-top-header'>
            <div className='upper-content'>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                    <h2 className='title'>{L(title)}</h2>
                    <Helper>{I("IP Address that is blocked through the chat errand")}</Helper>
                </div>
                <div className='tab-options-wrapper'>
                    <Button
                        type='button'
                        className={classNames("btn bg-transparent btn-round", { "active": showInline })}
                        iconClass='icon-add'
                        title={I('Create New')}
                        onClick={onHandleOpenNew}
                        hide={true}
                        active={showInline}
                    />
                </div>
            </div>
        </div>
    )
};

const ChatIPBlacklistBase = ({
    view
    , onLoad
    , showInline
    , list
    , onLoadToEdit
    , onDeleteFromList
    , onCloseForm
    , activeId
    , input
}) => {
    useEffect(() => {
        if (onLoad) {
            onLoad(M_CHAT_IP_BLACKLIST, false);
        }
    }, []);

    const handleDelete = (id) => {
        let confirmDelMsg = I('Are you sure you want to delete?');
        let params = {id: id}
        if (confirm(confirmDelMsg)) {
            onDeleteFromList(params, view);
        }
    }

    const actionDatas = {
        onDelete: handleDelete,
    }

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            hidden: true
        },
        {
            dataField: 'ipaddress',
            text: I('IP Address'),
            sort: true,
        },
        {
            dataField: 'agent',
            text: I('Agent'),
            sort: true,
        },
        {
            dataField: 'organisation',
            text: I('Organisation'),
            sort: true,
        },
        {
            dataField: 'area',
            text: I('Area'),
            sort: true,
        },
        {
            dataField: 'email',
            text: I('Email'),
            sort: true,
        },
        {
            dataField: 'time',
            text: I('Time'),
            sort: true,
        },
        {
            isDummyField: true,
            dataField: 'action',
            text: I('Action'),
            headerClasses: 'center',
            formatter: (cell, row) => {
                return renderActionButtons(cell, row, actionDatas);
            },
        }
    ]

    const defaultSorted = [{
        dataField: 'createtime',
        order: 'desc'
    }];

    const isNew = activeId <= 0;

    const options = {
        paginationSize: 3,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '<<',
        prePageText: '<',
        nextPageText: '>',
        lastPageText: '>>',
        showTotal: true,
        paginationTotalRenderer: customPageTotal,
        disablePageTitle: true,
        sizePerPageList: [10]
    };

    let listSection = <Fragment>
        <ListHeaderWrapper>
            <TableHeader
                title={ADMIN_TITLE[view]}
                type='button'
                iconClass='icon-add'
                onHandleOpenNew={e => onLoadToEdit(0)}
                hide={false}
                showInline={showInline}
            />
        </ListHeaderWrapper>
        <ListContentWrapper className={"v5-table-style"}>
            <BootstrapTable
                keyField="id"
                data={list}
                noDataIndication={I("No data to display")}
                columns={columns}
                bordered={false}
                condensed={false}
                defaultSorted={defaultSorted}
                pagination={paginationFactory(options)}
                hover
            />
        </ListContentWrapper>
    </Fragment>;

    return (
        <AdminListAndEditLayout
            className={"ip-blacklist-page"}
            listSection={listSection}
            editSection={null}
        />
    );
}

const ChatIPBlacklist = withAccessibility(ChatIPBlacklistBase)
export default ChatIPBlacklist;
