import React, {
	PureComponent
	, useState
    , useRef
} from 'react';
import classNames from 'classnames';
import { WidgetCarousel } from '../../components/v5/Widget';
import { I } from '../../common/v5/config';

//For default styling, refer chat widget script cention-chat.js "defaultChatConfig"
const gray = "#eee"
, grayOverlay = "#00000020"
, white = "#fff"
, black = "#000"
, lightBlue = "#7fcdffe0"
, yellow = "#ffbf19"
, wheat = "#fff7e5"
, grayDarkerOverlay = "#00000080"
, grayDefaultBtnBg = "#0000001a";

const DEFAULT_AGENT_AVATAR = process.env.CLOUDFRONT_URL + '/img/avatar-1.png';

const getWidgetClass = (position) => {
    let widgetPosition = "bottomRight";

    switch (position) {
        case "bottomLeft":
            widgetPosition = "bottomLeft";
            break;
        case "centerLeft":
            widgetPosition = "centerLeft";
            break;
        case "centerRight":
            widgetPosition = "centerRight";
            break;
    }

    return widgetPosition;

}

const CWidgetDefaultFrame1 = ({
    onGetConfig: getConfig
    , agent
    , showEULA
    , baseURL
}) => {
    const avatarRef = useRef();
    const areas = getConfig("activeAreas");
    let hideAreaSelection = true;
    if(areas && areas.split(",").length > 1) {
        hideAreaSelection = false;
    }
    let widgetPosition = getConfig("widgetPosition");
    let widgetPositionClass = getWidgetClass(widgetPosition);

    return (
        <div id="Skin-Default" className={"skin__frame " + widgetPositionClass}>
            <div id="CentionChatHeader" className={classNames('CentionChatHeader')}
                style={{
                    backgroundColor: getConfig("headerColor",gray),
                    color: getConfig("headerTextColor",black)
                }}>
                <span id="iconLogo" className="icon">
                    <i className="icon-chats"></i>
                </span>
                <span id="textTitle" className="text">
                    {getConfig("textTitle","Live Chat")}
                </span>
                <div style={{ marginLeft:"60px", marginTop:"5px" }}>
                    <span id="iconMinimize" className="icon"><i className="icon-expand"></i></span>
                </div>
            </div>
            <div id="CentionChatBody" style={{backgroundColor: getConfig("mainColor", gray)}}>
                <div id="CentionStartView" className={classNames('bottomLeft', {centerPosition: getConfig("widgetPosition") === "centerRight" || getConfig("widgetPosition") === "centerLeft"})} >
                    <div id="agentAvatarList" className="agentListChat">
						<div className="agentList">
							<p className="agentStatus">
								<span className="logged-in">● <span id="textAvailableNote" className="availableNote">{getConfig("textAvailableNote", "We're available!")}</span></span>
							</p>
							<div className="agentAvatar" hidden={getConfig("adminChatHideAgentAvatarList", false)}>
                                <img src={agent.photo} alt="Avatar" className="avatar"
                                ref={avatarRef}
                                onError={
                                    () => avatarRef.current.src = DEFAULT_AGENT_AVATAR
                                }
                                />
                            </div>
						</div>
					</div>
                    <div id="selectAreaIdOpts" hidden></div>
                    <select id="selectAreaId" defaultValue="-1" hidden={hideAreaSelection}>
                        <option value="-1">{getConfig("textNoSelectArea","Please select")}</option>
                    </select>
                    <input id="textInputName" type="text" placeholder={getConfig("textInputName","Name")+"*"}/>
                    <input id="textInputEmail" type="text" placeholder={getConfig("textInputEmail","Email")+"*"}/>
                    <input id="textInputPhone" hidden={!getConfig("adminChatEnableCallbackRequest", false)} type="text" placeholder={getConfig("textInputPhone","Phone")+"*"}/>
                    <textarea id="textInputQuestion" placeholder={getConfig("textInputQuestion","Question")+"*"}></textarea>
                    {showEULA &&
                    <div id="EULAAcceptance">
                        <input type="checkbox" id="askEULA"/>
                        <label id="textEULA" style={{color: getConfig("askEULATextColor",black)}}>
                            {getConfig("textEULA",'I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.')}
                        </label>
                    </div>
                    }
                    <button id="textButtonStart" className={classNames('startChatBtn', {text: getConfig("textButtonStart")})} type="button" style={{
                            color: getConfig("buttonStartTextColor", black),
                            backgroundColor: getConfig("buttonStartColor", grayDefaultBtnBg),
                            borderColor: getConfig("buttonStartBorderColor", "transparent"),
                    }}>
                    {
                        getConfig("textButtonStart") ? getConfig("textButtonStart") : <i className="fas fa-arrow-right"></i>
                    }
                    </button>
                </div>
                <div id="CentionWidgetFooter" hidden={features['hide-cention-logo']} style={{backgroundColor: getConfig("mainColor", gray)}}>
                    <a href="#" className="fixLeft" style={{color: getConfig("footerTextColor",black)}}>
                        {"Powered by "}
                        <img src={process.env.CLOUDFRONT_URL + "/img/cention-logo-black_smallest.png"}></img>
                    </a>
                </div>
            </div>
        </div>
    )
}

const CWidgetDefaultFrame2 = ({
    onGetConfig: getConfig
    , baseURL
}) => {

    let widgetPosition = getConfig("widgetPosition");
    let widgetPositionClass = getWidgetClass(widgetPosition);

    return (
        <div id="Skin-Default" className={"skin__frame " + widgetPositionClass}>
            <div id="CentionChatHeader" className={classNames('CentionChatHeader')} style={{
                backgroundColor: getConfig("headerColor",gray),
                color: getConfig("headerTextColor",black)
            }}>
                <span id="iconLogo" className="icon">
                    <i className="icon-chats"></i>
                </span>
                <span id="textTitle" className="text">
                    {getConfig("textTitle","Live Chat")}
                </span>
                <div style={{ marginLeft:"60px", marginTop:"5px" }}>
                    <span id="iconMinimize" className="icon"><i className="icon-expand"></i></span>
                </div>
            </div>
            <div id="CentionChatBody" className={classNames('bottomLeft', {centerPosition: getConfig("widgetPosition") === "centerRight" || getConfig("widgetPosition") === "centerLeft"})}
                style={{backgroundColor: getConfig("mainColor", gray)}}
            >
                <div id="CentionChatView" className={classNames('bottomLeft', {centerPosition: getConfig("widgetPosition") === "centerRight" || getConfig("widgetPosition") === "centerLeft"})}
                    style={{backgroundColor: getConfig("chatConversationBgColor",white), color: "rgb(0, 0, 0)"}} 
                >
                    <div id="CentionChatViewHeader" style={{backgroundColor: getConfig("chatConversationBgColor",white)}}>
                        <div id="Satisfaction">
                            <div className="satisfactionWrapper" style={{backgroundColor: getConfig("feedbackBgColor", lightBlue),color: getConfig("feedbackTextColor", white)}}>
                                <div id="SatisfactionText">
                                   {getConfig("textSatisfactionMessage","Enjoying the chat? Please rate it.")}
                                </div>
                                <span className="satisfactionMeterIcons">
                                    <i id="OngoingLikedChat" title="Rate chat good" className="icon-thumbs-up"></i>
                                    <i id="OngoingUnlikedChat" title="Rate chat bad" className="icon-thumbs-down addButtonActive"></i>
                                </span>
                                <div id="SatisfactionClose"><i className="icon-times"></i></div>
                            </div>
                        </div>
                    </div>
                    <div id="CentionChatList" style={{backgroundColor: getConfig("chatConversationBgColor",white), color: "rgb(0, 0, 0)"}} className="shrinked">
                        <div id="CentionChatConnected" className="system agentConnectedTo">
                            <span id="textStatusConnected">{getConfig("textStatusConnected","You have been connected to Agent.")}</span>
                        </div>
                        <div className="message fromClient">
                            <div className="message-content" style={{backgroundColor: getConfig("messageBackgroundColor","#def8f9")}}>
                                <div id="6523" className="text"><span className="textMsg">Hey there</span></div>
                                <div className="info" style={{color: getConfig("chatConversationInfoTextColor", gray)}}>
                                    <span className="sender">Hi how can I help you?</span> · <span className="sent">10:53</span>
                                    <span className="Centiontwoticks" style={{marginLeft: "3px"}}>
                                    <span id="textTickSent" className="Centionmessage_sent " style={{color: getConfig("tickSentColor", "green")}}>✓</span>
                                    <span id="textTickRead" className="Centionmessage_read " style={{color: getConfig("tickReadColor", "cyan"), marginLeft: "-6px"}}>✓</span></span>
                                </div>
                            </div>
                            <div className="message-avatar"><i className="icon-user-circle"></i></div>
                        </div>
                        <div className="message">
                            <div className="message-content" style={{backgroundColor: getConfig("messageBackgroundColorAgent","#f2f2f2")}}>
                                <div id="6524" className="text"><span className="textMsg"><span>Hi how can I help you?</span></span></div>
                                <div className="info" style={{color: getConfig("chatConversationInfoTextColor", gray)}}><span className="sender">Agent 1</span> · <span className="sent">10:56</span></div>
                            </div>
                            <div className="message-avatar"><i className="icon-user-circle"></i></div>
                        </div>
                    </div>

                    <div className="cookieContainer">
                        <div id="CentionCookieWarning" style={{color: getConfig("cookieWarningTextColor", black), backgroundColor: getConfig("cookieWarningBgColor", "wheat")}}>
                            <span className="cookieText">
                                {getConfig("textCookieWarning",
                                    "* Please allow cookie from site to make sure your chat continues if you browse to other pages during the chat.")}
                            </span>
                            <div id="closeCookieWarning"><i className="icon-times"></i></div>
                        </div>
                    </div>

                    <div id="CentionChatFooter" className="centionChatFooter expanded">
                        <button id="CentionToggleMenuButton" className="iconButton" style={{
                            backgroundColor: getConfig("buttonToggleMenuColor", white),
                            color: getConfig("toggleMenuTextColor", black)}}
                        >
                            <i className="icon-times" style={{margin: 'auto'}}></i>
                        </button>
                        <div id="CentionChatQuestion" placeholder="Send a message" style={{
                            backgroundColor: getConfig("chatConversationTextareaBgColor", grayOverlay),
                            color: getConfig("chatConversationTextareaColor", black)}}
                        >Send a message ..</div>
                        <button id="SubmitChat" className="iconButton" style={{color: getConfig("messageSendIconColor", black)}}><i className="icon-arrow-right"></i></button>
                    </div>

                    <div id="CentionToggleableChatMenu" className="centionChatMenu">
                        <span id="CentionChatSendFileButtonWrapper">
                            <a id="CentionChatSendFileButton" className="btn-file">
                                <i className="icon-attachment" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuSendFile" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuSendFile","Send file")}
                                </span>
                                <input id="CentionChatSendFileInput" type="file"/></a>
                        </span>
                        <span id="CentionChatFinishButtonWrapper">
                            <a id="CentionChatFinishButton">
                                <i className="icon-chat-close" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuFinishChat" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuFinishChat","Finish chat")}
                                </span>
                            </a>
                        </span>
                        <span id="CentionChatPrintButtonWrapper">
                            <a id="CentionChatPrintButton">
                                <i className="icon-file" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuPrintChat" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuPrintChat","Print")}
                                </span>
                            </a>
                        </span>
                        <span id="CentionChatSaveButtonWrapper">
                            <a id="CentionChatSaveButton">
                                <i className="icon-download" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuSaveChat" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuSaveChat","Save a copy")}
                                </span>
                            </a>
                        </span>
                        {
                            getConfig("adminChatEnableVideoChat") &&
                            <span id="CentionChatVideoCallWrapper">
                                <a id="CentionChatVideoButton">
                                    <i className="icon-video-call" style={{ color: getConfig("chatToggleMenuIconsColor", black) }}></i>
                                    <span id="textMenuVideoChat" style={{ color: getConfig("chatToggleMenuIconsTextColor", black) }}>
                                        {getConfig("textMenuVideoChat", "Video")}
                                    </span>
                                </a>
                            </span>
                        }
                    </div>

                </div>
                <div id="CentionWidgetFooter" hidden={features['hide-cention-logo']}>
                    <a href="#" className="fixLeft" style={{color: getConfig("footerTextColor",black)}}>
                        {"Powered by "}
                        <img src={process.env.CLOUDFRONT_URL + "/img/cention-logo-black_smallest.png"}></img>
                    </a>
                </div>
            </div>
        </div>
    )
}
const CWidgetDefaultFrame3 = ({
    onGetConfig: getConfig
}) => {
    const avatarRef = useRef();
    const areas = getConfig("activeAreas");
    let hideAreaSelection = true;
    if(areas && areas.split(",").length > 1) {
        hideAreaSelection = false;
    }
    let widgetPosition = getConfig("widgetPosition");
    let widgetPositionClass = getWidgetClass(widgetPosition);

    return (
        <div id="Skin-Default" className={"skin__frame " + widgetPositionClass}>
            <div id="CentionChatHeader" className={classNames('CentionChatHeader')}
                style={{
                    backgroundColor: getConfig("headerColor",gray),
                    color: getConfig("headerTextColor",black)
                }}>
                <span id="iconLogo" className="icon">
                    <i className="icon-chats"></i>
                </span>
                <span id="textTitle" className="text">
                    {getConfig("textTitle","Live Chat")}
                </span>
                <div style={{ marginLeft:"60px", marginTop:"5px" }}>
                    <span id="iconMinimize" className="icon"><i className="icon-expand"></i></span>
                </div>
            </div>
                <div id="CentionChatBody" style={{
                    backgroundColor: getConfig("mainColor", gray),
                    height: getConfig("bodyHeight", "39vh") 
                    }}
                >
                <div id="CentionStartView" className={classNames('bottomLeft', {centerPosition: getConfig("widgetPosition") === "centerRight" || getConfig("widgetPosition") === "centerLeft"})} >
                    <div class="awayMsgWrapper">
                        <p id="textMessageAway" style={{    fontFamily: 'sans-serif', fontSize: '14px', LetterSpacing: '0px', paddingTop: '18px', margin: '0px 0px 6px', color: 'rgb(0, 0, 0)',textAlign: 'center'}}> {getConfig("textMessageAway","We are sorry either the chat is closed or we don't have any agents available at the moment.")}</p>
                    </div>
                    <div id="CentionChatRetryWrapper" style={{textAlign:'center', paddingTop: '35px'}}>
                        <a id="textButtonRetry" style={{color: "#000"}}>Retry</a>
                    </div>

                </div>
            </div>
        </div>
    )
}
const CWidgetProactiveDefaultFrame = ({
    onGetConfig: getConfig
}) => {
    let popupClassType = "proactive-portrait";
    if(getConfig("proactivePopupType") != 1) {
        popupClassType = "proactive-landscape";
    }
    let widgetPosition = getConfig("widgetPosition");
    let widgetPositionClass = getWidgetClass(widgetPosition);

    return (
        <div id="CentionContainerView" className={"skin__frame proactive-container " + widgetPositionClass}>
            <div id="CentionChatProactiveDialog" className={classNames('minimal', popupClassType)} style={{backgroundColor: getConfig("proactiveDialogBgColor", '#FFF')}}>
                <div id="CentionProactiveAvatarWrapper" hidden={!getConfig("proactiveImageURL")}>
                    <img id="CentionProactiveAvatar" src={getConfig("proactiveImageURL", "")} alt="avatar" width="50px" height="50px" />
                </div>
                <div id="CentionProactiveTextWrapper">
                    <div id="CentionProactiveHeader">
                        <span className="icon"></span>
                        <span id="textTitleProactive" style={{color: getConfig("proactiveTitleTextColor", '#1f3c56')}} className="text">{getConfig("textTitleProactive", "Hello, may I help you?")}</span>
                    </div>
                    <div id="CentionProactiveBody">
                        <div id="textMessageProactive">
                            <span className="text" style={{color: getConfig("proactiveDescTextColor", '#1f3c56')}}>{getConfig("textMessageProactive", "Ask me about our products")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="CentionProactiveButton" className="widget__header proactive default"
                style={{
                    backgroundColor: getConfig("headerColor",gray),
                    color: getConfig("headerTextColor",black)
                }}>
                <span id="iconLogo" className="icon">
                    <i className="icon-chats"></i>
                </span>
                <span id="textTitle" className="text">
                    {getConfig("textTitle","Live Chat")}
                </span>
            </div>
       </div>
    )
}

const CWidgetModernFrame1 = ({
    onGetConfig: getConfig
    , showEULA
    , agent
    , baseURL
}) => {
    const avatarRef = useRef();
    const areas = getConfig("activeAreas");
    let hideAreaSelection = true;
    if(areas && areas.split(",").length > 1) {
        hideAreaSelection = false;
    }
    return (
        <div id="Skin-Modern" className="skin__frame">
            <div id="CentionChatHeader" className="bottomRight maximized" style={{
                display: "flex",
                width: "334px",
                backgroundColor: getConfig("headerColor",gray),
                color: getConfig("headerTextColor",black)
            }}>
                <span id="iconLogo" className="icon">
                    <i className="icon-chats"></i>
                </span>
                <span id="textTitle" className="text">
                    {getConfig("textTitle","Live Chat")}
                </span>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></span>
                    <span id="iconExpand" className="icon icon-expand"></span>
                </div>
            </div>
            <div id="CentionChatBody" style={{backgroundColor: getConfig("mainColor", gray)}}>
                <div id="CentionStartView" style={{ backgroundColor: getConfig("chatConversationBgColor", white) }}>
                     <div id="agentAvatarList" className="agentListChat">
                        <div className="agentList">
                            <p className="agentStatus">
                                <span className="logged-in">● <span id="textAvailableNote" className="availableNote">{getConfig("textAvailableNote", "We're available!")}</span></span>
                            </p>
                            <div className="agentAvatar" hidden={getConfig("adminChatHideAgentAvatarList", false)}>
                                <img src={agent.photo} alt="Avatar" className="avatar"
                                    ref={avatarRef}
                                    onError={
                                        () => avatarRef.current.src = DEFAULT_AGENT_AVATAR
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div id="selectAreaIdOpts" hidden></div>
                    <select id="selectAreaId" defaultValue="-1" hidden={hideAreaSelection}>
                        <option value="-1">{getConfig("textNoSelectArea","Please select")}</option>
                    </select>
                    <input id="textInputName" type="text" placeholder={getConfig("textInputName","Name")+"*"}/>
                    <input id="textInputEmail" type="text" placeholder={getConfig("textInputEmail","Email")+"*"}/>
                    <input id="textInputPhone" hidden={!getConfig("adminChatEnableCallbackRequest", false)} type="text" placeholder={getConfig("textInputPhone","Phone")+"*"}/>
                    <textarea id="textInputQuestion" placeholder={getConfig("textInputQuestion","Question")+"*"}></textarea>
                    {showEULA && 
                    <div id="EULAAcceptance">
                        <input type="checkbox" id="askEULA"/>
                        <label id="textEULA" style={{color: getConfig("askEULATextColor",black)}}>{
                            getConfig("textEULA",'I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.')
                        }</label>
                    </div>}
                    <button id="textButtonStart" className={classNames('startChatBtn', {text: getConfig("textButtonStart")})} type="button" style={{
                        color: getConfig("buttonStartTextColor", black),
                        backgroundColor: getConfig("buttonStartColor", gray),
                        borderColor: getConfig("buttonStartBorderColor", "transparent"),
                    }}>
                    {
                        getConfig("textButtonStart") ? getConfig("textButtonStart") : <i className="icon-arrow-right"></i>
                    }
                    </button>
                </div>
                <div id="CentionWidgetFooter" hidden={features['hide-cention-logo']}>
                    <a href="#" target="_blank" className="fixRight" style={{color: getConfig("footerTextColor",black)}}>
                        {"Powered by "}
                        <img src={process.env.CLOUDFRONT_URL + "/img/cention-logo-black_smallest.png"}></img>
                    </a>
                </div>
            </div>
        </div>
    )
}

const CWidgetModernFrame2 = ({
    onGetConfig: getConfig
    , baseURL
}) => {
    return (
        <div id="Skin-Modern" className="skin__frame">
            <div id="CentionChatHeader" className="bottomRight maximized" style={{
                display: "flex",
                width: "334px",
                backgroundColor: getConfig("headerColor",gray),
                color: getConfig("headerTextColor",black)
            }}>
                <span id="iconLogo" className="icon">
                    <i className="icon-chats"></i>
                </span>
                <span id="textTitle" className="text">
                    {getConfig("textTitle","Live Chat")}
                </span>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></span>
                    <span id="iconExpand" className="icon icon-expand"></span>
                </div>
            </div>
            <div id="CentionChatBody" style={{backgroundColor: getConfig("mainColor", gray)}}>
                {/* start */}
                <div id="CentionChatView" style={{backgroundColor: getConfig("chatConversationBgColor", white)}}>
                    <div id="CentionChatViewHeader" style={{backgroundColor: getConfig("chatConversationBgColor", white)}}>
                        <div id="Satisfaction">
                            <div className="satisfactionWrapper" style={{backgroundColor: getConfig("feedbackBgColor", lightBlue),color: getConfig("feedbackTextColor", white)}}>
                                <div id="SatisfactionText">{getConfig("textSatisfactionMessage","Enjoying the chat? Please rate it.")}</div>
                                <span className="satisfactionMeterIcons">
                                    <i id="OngoingLikedChat" title="Rate chat good" className="icon-thumbs-up"></i>
                                    <i id="OngoingUnlikedChat" title="Rate chat bad" className="icon-thumbs-down addButtonActive"></i>
                                </span>
                                <div id="SatisfactionClose"><i className="icon-times"></i></div>
                            </div>	
                        </div>
                    </div>
                    <div id="CentionChatList" className="shrinked" style={{
                        backgroundColor: getConfig("chatConversationBgColor", white),
                        color: getConfig("chatConversationTextColor",black)
                    }} >
                        <div id="CentionChatConnected" className="system agentConnectedTo">
                            <span id="textStatusConnected">{getConfig("textStatusConnected","You have been connected to Agent.")}</span>
                        </div>
                        <div className="message fromClient">
                            <div className="message-content" style={{backgroundColor: getConfig("messageBackgroundColor","#def8f9")}}>
                                <div id="6520" className="text">
                            <span className="textMsg">Hey there</span>
                            </div>
                            <div className="info" style={{color: getConfig("chatConversationInfoTextColor", gray)}}>
                                <span className="sender">Client</span> · <span className="sent">13:04</span>
                                <span className="Centiontwoticks" style={{marginLeft: "3px"}}>
                                    <span id="textTickSent" className="Centionmessage_sent " style={{color: getConfig("tickSentColor", "green")}}>✓</span>
                                    <span id="textTickRead" className="Centionmessage_read " style={{color: getConfig("tickReadColor", "cyan"), marginLeft: "-6px"}}>✓</span>
                                </span>
                            </div>
                            </div>
                            <div className="message-avatar"><i className="icon-user-circle"></i></div>
                        </div>
                        <div className="message ">
                            <div className="message-content" style={{backgroundColor: getConfig("messageBackgroundColorAgent","#f2f2f2")}}>
                                <div id="6521" className="text">
                                    <span className="textMsg"><span>Hi how can I help you?</span></span>
                                </div>
                                <div className="info" style={{color: getConfig("chatConversationInfoTextColor", gray)}}>
                                    <span className="sender">Agent</span> · <span className="sent">13:04</span>
                                </div>
                            </div>
                            <div className="message-avatar"><i className="icon-user-circle"></i></div>
                        </div>
                    </div>
                    <div className="cookieContainer">
                        <div id="CentionCookieWarning" style={{color: getConfig("cookieWarningTextColor", black), backgroundColor: getConfig("cookieWarningBgColor", "wheat")}}>
                            <span className="cookieText">
                                {getConfig("textCookieWarning",
                                        "* Please allow cookie from site to make sure your chat continues if you browse to other pages during the chat.")}
                            </span>
                            <div id="closeCookieWarning"><i className="icon-times"></i></div>
                        </div>
                    </div>
                    <div id="CentionChatFooter" className="centionChatFooter expanded" style={{backgroundColor: getConfig("chatConversationBgColor", white)}}>
                        <div className="textArea-wrapper">
                            <button id="CentionToggleMenuButton" className="iconButton" style={{color: getConfig("toggleMenuTextColor", black)}}><i className="icon-times" style={{margin: 'auto'}}></i></button>
                            <div id="CentionChatQuestion" placeholder="Send a message" style={{
                                backgroundColor: getConfig("chatConversationTextareaBgColor", grayOverlay),
                                color: getConfig("chatConversationTextareaColor", black)
                            }}>Send a message ..</div>
                            <button id="SubmitChat" className="iconButton" style={{color: getConfig("messageSendIconColor", black)}}><i className="icon-arrow-right"></i></button>
                        </div>
                    </div>
                    <div id="CentionToggleableChatMenu" className="centionChatMenu" style={{backgroundColor: getConfig("chatConversationBgColor", white)}}>
                        <span id="CentionChatSendFileButtonWrapper">
                            <a id="CentionChatSendFileButton" className="btn-file">
                                <i className="icon-attachment" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuSendFile" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuSendFile","Send file")}
                                </span>
                                <input id="CentionChatSendFileInput" type="file"/></a>
                        </span>
                        <span id="CentionChatFinishButtonWrapper">
                            <a id="CentionChatFinishButton">
                                <i className="icon-chat-close" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuFinishChat" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuFinishChat","Finish chat")}
                                </span>
                            </a>
                        </span>
                        <span id="CentionChatPrintButtonWrapper">
                            <a id="CentionChatPrintButton">
                                <i className="icon-file" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuPrintChat" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuPrintChat","Print")}
                                </span>
                            </a>
                        </span>
                        <span id="CentionChatSaveButtonWrapper">
                            <a id="CentionChatSaveButton">
                                <i className="icon-download" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuSaveChat" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuSaveChat","Save a copy")}
                                </span>
                            </a>
                        </span>
                        {
                            getConfig("adminChatEnableVideoChat") &&
                            <span id="CentionChatVideoCallWrapper">
                                <a id="CentionChatVideoButton">
                                    <i className="icon-video-call" style={{ color: getConfig("chatToggleMenuIconsColor", black) }}></i>
                                    <span id="textMenuVideoChat" style={{ color: getConfig("chatToggleMenuIconsTextColor", black) }}>
                                        {getConfig("textMenuVideoChat", "Video")}
                                    </span>
                                </a>
                            </span>
                        }
                    </div>
                </div>
                {/* end */}
                <div id="CentionWidgetFooter" hidden={features['hide-cention-logo']}>
                    <a href="#" target="_blank" className="fixRight" style={{color: getConfig("footerTextColor",black)}}>
                        {"Powered by "}
                        <img src={process.env.CLOUDFRONT_URL + "/img/cention-logo-black_smallest.png"}></img>
                    </a>
                </div>
            </div>
        </div>
    )
}
const CWidgetModernFrame3 = ({
    onGetConfig: getConfig
}) => {
    const avatarRef = useRef();
    const areas = getConfig("activeAreas");
    let hideAreaSelection = true;
    if(areas && areas.split(",").length > 1) {
        hideAreaSelection = false;
    }
    return (
        <div id="Skin-Modern" className="skin__frame">
            <div id="CentionChatHeader" className="bottomRight maximized" style={{
                display: "flex",
                width: "334px",
                backgroundColor: getConfig("headerColor",gray),
                color: getConfig("headerTextColor",black)
            }}>
                <span id="iconLogo" className="icon">
                    <i className="icon-chats"></i>
                </span>
                <span id="textTitle" className="text">
                    {getConfig("textTitle","Live Chat")}
                </span>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></span>
                    <span id="iconExpand" className="icon icon-expand"></span>
                </div>
            </div>
            <div id="CentionChatBody" style={{backgroundColor: getConfig("mainColor", gray)}}>
                <div id="CentionStartView" style={{ backgroundColor: getConfig("chatConversationBgColor", white) }}>
                <div class="awayMsgWrapper">
                        <p id="textMessageAway" style={{    fontFamily: 'sans-serif', fontSize: '14px', LetterSpacing: '0px', paddingTop: '10px', paddingLeft:'18px', paddingRight:'18px', margin: '0px 0px 6px', color: 'rgb(0, 0, 0)',textAlign: 'center'}}> {getConfig("textMessageAway","We are sorry either the chat is closed or we don't have any agents available at the moment.")}</p>
                    </div>
                    <div id="CentionChatRetryWrapper" style={{textAlign:'center', paddingTop: '60px'}}>
                        <a id="textButtonRetry" style={{color: "#000"}}>Retry</a>
                    </div>
                </div>

            </div>
        </div>
    )
}


//Proactive UI preview
const CWidgetProactiveFrame = ({
    onGetConfig: getConfig
}) => {
    let popupClassType = "proactive-portrait";
    if(getConfig("proactivePopupType") != 1) {
        popupClassType = "proactive-landscape";
    }
    let widgetPosition = getConfig("widgetPosition");
    let widgetPositionClass = getWidgetClass(widgetPosition);

    return (
        <div id="CentionContainerView" className={"skin__frame proactive-container " + widgetPositionClass}>
            <div id="CentionChatProactiveDialog" className={classNames('minimal', popupClassType)} style={{backgroundColor: getConfig("proactiveDialogBgColor", '#FFF')}}>
                <div id="CentionProactiveAvatarWrapper" hidden={!getConfig("proactiveImageURL")}>
                    <img id="CentionProactiveAvatar" src={getConfig("proactiveImageURL", "")} alt="avatar" width="50px" height="50px" />
                </div>
                <div id="CentionProactiveTextWrapper">
                    <div id="CentionProactiveHeader">
                        <span className="icon"></span>
                        <span id="textTitleProactive" style={{color: getConfig("proactiveTitleTextColor", '#1f3c56')}} className="text">{getConfig("textTitleProactive", "Hello, may I help you?")}</span>
                    </div>
                    <div id="CentionProactiveBody">
                        <div id="textMessageProactive">
                            <span className="text" style={{color: getConfig("proactiveDescTextColor", '#1f3c56')}}>{getConfig("textMessageProactive", "Ask me about our products")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="CentionProactiveButton" style={{backgroundColor: getConfig("headerColor", '#eeeeee')}} className={classNames(popupClassType)} hidden={getConfig("widgetPosition") === "centerRight" || getConfig("widgetPosition") === "centerLeft"}>
                <span id="iconLogo" className="icon"><i style={{color: getConfig("headerTextColor", 'black')}}  className={getConfig("logo", 'icon-chats')}></i></span>
            </div>
       </div>
    )
}

//Frictionless
const CWidgetFrictionlessFrame1 = ({
    onGetConfig: getConfig
    , showEULA
    , agent
    , baseURL
}) => {
    const avatarRef = useRef();
    const areas = getConfig("activeAreas");
    let hideAreaSelection = true;
    if(areas && areas.split(",").length > 1) {
        hideAreaSelection = false;
    }
    return (
        <div id="Skin-Frictionless" style={{position: "relative", margin: "0 auto"}}>
            <div id="CentionChatHeader" className="centionChatTrigger maximized" style={{
                backgroundColor: getConfig("headerColor",gray),
                color: getConfig("headerTextColor",black)
            }}>
                <span id="iconLogo" className="icon">
                    <i className="icon-chats"></i>
                </span>
                <div id="agentAvatarList" className="agentListChat">
					<div className="agentList">
                        <div className="agentAvatar">
                            <img src={agent.photo} alt="Avatar" className="avatar"
                                ref={avatarRef}
                                onError={
                                    () => avatarRef.current.src = DEFAULT_AGENT_AVATAR
                                }
                            />
                        </div>
						<div className="headerTitleWrapper">
							<span id="textTitle" className="text">{getConfig("textTitle","Live Chat")}</span>
							<div className="agentStatus">
								<div className="logged-in">
									<span className="online-indicator">●</span>
									<span id="textAvailableNote" className="availableNote">{getConfig("textAvailableNote", "We're available!")}</span>
								</div>
								<div className="logged-out" hidden>●</div>
							</div>
						</div>
					</div>
				</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></span>
                    <span id="iconExpand" className="icon icon-expand"></span>
                </div>
            </div>
            <div id="CentionChatBody" style={{backgroundColor: getConfig("mainColor", white)}}>
                <div id="CentionStartView">
                    <div id="selectAreaIdOpts" hidden></div>
                    <select id="selectAreaId" defaultValue="-1" hidden={hideAreaSelection}>
                        <option value="-1">{getConfig("textNoSelectArea","Please select")}</option>
                    </select>
                    <input id="textInputName" type="text" placeholder={getConfig("textInputName","Name")+"*"}/>
                    <input id="textInputEmail" type="text" placeholder={getConfig("textInputEmail","Email")+"*"}/>
                    <input id="textInputPhone" hidden={!getConfig("adminChatEnableCallbackRequest", false)} type="text" placeholder={getConfig("textInputPhone","Phone")+"*"}/>
                    <textarea id="textInputQuestion" placeholder={getConfig("textInputQuestion","Question")+"*"}></textarea>
                    {showEULA &&
                    <div id="EULAAcceptance">
                        <input type="checkbox" id="askEULA"/>
                        <label id="textEULA" style={{color: getConfig("askEULATextColor",black)}}>{
                            getConfig("textEULA",'I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.')
                        }</label>
                    </div>}
                    <button id="textButtonStart" className={classNames('startChatBtn', {text: getConfig("textButtonStart")})} type="button" style={{
                        color: getConfig("buttonStartTextColor", black),
                        backgroundColor: getConfig("buttonStartColor", gray),
                    }}>
                    {
                        getConfig("textButtonStart") ? getConfig("textButtonStart") : <i className="icon-arrow-right"></i>
                    }
                    </button>
                </div>
                <div id="CentionWidgetFooter" hidden={features['hide-cention-logo']}>
                    <a href="#" target="_blank" className="fixRight" style={{color: getConfig("footerTextColor",black)}}>
                        {"Powered by "}
                        <img src={process.env.CLOUDFRONT_URL + "/img/cention-logo-black_smallest.png"}></img>
                    </a>
                </div>
            </div>
        </div>
    )
}

//Frictionless
const CWidgetFrictionlessFrame2 = ({
    onGetConfig: getConfig
    , showEULA
    , agent
    , baseURL
}) => {
    const avatarRef = useRef();
    const areas = getConfig("activeAreas");
    let hideAreaSelection = true;
    if(areas && areas.split(",").length > 1) {
        hideAreaSelection = false;
    }
    return (
        <div id="Skin-Frictionless" style={{position: "relative", margin: "0 auto"}}>
            <div id="CentionChatHeader" className="centionChatTrigger maximized" style={{
                backgroundColor: getConfig("headerColor",gray),
                color: getConfig("headerTextColor",black)
            }}>
                <span id="iconLogo" className="icon">
                    <i className="icon-chats"></i>
                </span>
                <div id="agentAvatarList" className="agentListChat">
					<div className="agentList">
                        <div className="agentAvatar">
                            <img src={agent.photo} alt="Avatar" className="avatar"
                                ref={avatarRef}
                                onError={
                                    () => avatarRef.current.src = DEFAULT_AGENT_AVATAR
                                }
                            />
                        </div>
						<div className="headerTitleWrapper">
							<span id="textTitle" className="text">{getConfig("textTitle","Live Chat")}</span>
							<div className="agentStatus">
								<div className="logged-in">
									<span className="online-indicator">●</span>
									<span id="textAvailableNote" className="availableNote">{getConfig("textAvailableNote", "We're available!")}</span>
								</div>
								<div className="logged-out" hidden>●</div>
							</div>
						</div>
					</div>
				</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></span>
                    <span id="iconExpand" className="icon icon-expand"></span>
                </div>
            </div>
            <div id="CentionChatBody" style={{backgroundColor: getConfig("mainColor", white)}}>
                <div id="CentionChatView" style={{backgroundColor: getConfig("chatConversationBgColor", white)}}>
                    <div id="CentionChatViewHeader" style={{backgroundColor: getConfig("chatConversationBgColor", white)}}>
                        <div id="Satisfaction">
                            <div className="satisfactionWrapper" style={{backgroundColor: getConfig("feedbackBgColor", lightBlue),color: getConfig("feedbackTextColor", white)}}>
                                <div id="SatisfactionText">{getConfig("textSatisfactionMessage","Enjoying the chat? Please rate it.")}</div>
                                <span className="satisfactionMeterIcons">
                                    <i id="OngoingLikedChat" title="Rate chat good" className="icon-thumbs-up"></i>
                                    <i id="OngoingUnlikedChat" title="Rate chat bad" className="icon-thumbs-down addButtonActive"></i>
                                </span>
                                <div id="SatisfactionClose"><i className="icon-times"></i></div>
                            </div>
                        </div>
                    </div>
                    <div id="CentionChatList" className="shrinked" style={{
                        height: "300px",
                        backgroundColor: getConfig("chatConversationBgColor", white),
                        color: getConfig("chatConversationTextColor",black)
                    }} >
                        <div id="CentionChatConnected" className="system agentConnectedTo">
                            <span id="textStatusConnected">{getConfig("textStatusConnected","You have been connected to Agent.")}</span>
                        </div>
                        <div className="message fromClient">
                            <div className="message-content">
                                <div id="6520" className="text">
                            <span className="textMsg" style={{backgroundColor: getConfig("messageBackgroundColor","#def8f9")}}>Hey there</span>
                            </div>
                            <div className="info" style={{color: getConfig("chatConversationInfoTextColor", gray)}}>
                                <span className="sender">Client</span> · <span className="sent">13:04</span>
                                <span className="Centiontwoticks" style={{marginLeft: "3px"}}>
                                    <span id="textTickSent" className="Centionmessage_sent " style={{color: getConfig("tickSentColor", "green")}}>✓</span>
                                    <span id="textTickRead" className="Centionmessage_read " style={{color: getConfig("tickReadColor", "cyan"), marginLeft: "-6px"}}>✓</span>
                                </span>
                            </div>
                            </div>
                            <div className="message-avatar"><i className="icon-user-fill"></i></div>
                        </div>
                        <div className="message ">
                            <div className="message-content">
                                <div id="6521" className="text">
                                    <span className="textMsg" style={{backgroundColor: getConfig("messageBackgroundColorAgent","#f2f2f2")}}><span>Hi how can I help you?</span></span>
                                </div>
                                <div className="info" style={{color: getConfig("chatConversationInfoTextColor", gray)}}>
                                    <span className="sender">Agent</span> · <span className="sent">13:04</span>
                                </div>
                            </div>
                            <div className="message-avatar"><i className="icon-user-fill"></i></div>
                        </div>
                    </div>
                    <div className="cookieContainer">
                        <div id="CentionCookieWarning" style={{color: getConfig("cookieWarningTextColor", black), backgroundColor: getConfig("cookieWarningBgColor", "wheat")}}>
                            <span className="cookieText">
                                {getConfig("textCookieWarning",
                                        "* Please allow cookie from site to make sure your chat continues if you browse to other pages during the chat.")}
                            </span>
                            <div id="closeCookieWarning"><i className="icon-times"></i></div>
                        </div>
                    </div>
                    <div id="CentionChatFooter" className="centionChatFooter expanded">
                        <div className="textArea-wrapper">
                            <button id="CentionToggleMenuButton" className="iconButton" style={{color: getConfig("toggleMenuTextColor", black)}}><i className="icon-times" style={{margin: 'auto'}}></i></button>
                            <div id="CentionChatQuestion" placeholder="Send a message" style={{
                                color: getConfig("chatConversationTextareaColor", black)
                            }}>Send a message ..</div>
                            <button id="SubmitChat" className="iconButton" style={{color: getConfig("messageSendIconColor", black)}}><i className="icon-arrow-right"></i></button>
                        </div>
                    </div>
                    <div id="CentionToggleableChatMenu" className="centionChatMenu">
                        <span id="CentionChatSendFileButtonWrapper">
                            <a id="CentionChatSendFileButton" className="btn-file">
                                <i className="icon-attachment" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuSendFile" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuSendFile","Attach")}
                                </span>
                                <input id="CentionChatSendFileInput" type="file"/></a>
                        </span>
                        <span id="CentionChatFinishButtonWrapper">
                            <a id="CentionChatFinishButton">
                                <i className="icon-chat-close" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuFinishChat" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuFinishChat","End")}
                                </span>
                            </a>
                        </span>
                        <span id="CentionChatPrintButtonWrapper">
                            <a id="CentionChatPrintButton">
                                <i className="icon-file" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuPrintChat" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuPrintChat","Print")}
                                </span>
                            </a>
                        </span>
                        <span id="CentionChatSaveButtonWrapper">
                            <a id="CentionChatSaveButton">
                                <i className="icon-download" style={{color: getConfig("chatToggleMenuIconsColor", black)}}></i>
                                <span id="textMenuSaveChat" style={{color: getConfig("chatToggleMenuIconsTextColor", black)}}>
                                    {getConfig("textMenuSaveChat","Save")}
                                </span>
                            </a>
                        </span>
                        {
                            getConfig("adminChatEnableVideoChat") &&
                            <span id="CentionChatVideoCallWrapper">
                                <a id="CentionChatVideoButton">
                                    <i className="icon-video-call" style={{ color: getConfig("chatToggleMenuIconsColor", black) }}></i>
                                    <span id="textMenuVideoChat" style={{ color: getConfig("chatToggleMenuIconsTextColor", black) }}>
                                        {getConfig("textMenuVideoChat", "Video")}
                                    </span>
                                </a>
                            </span>
                        }
                    </div>
                </div>
                <div id="CentionWidgetFooter" hidden={features['hide-cention-logo']}>
                    <a href="#" target="_blank" style={{color: getConfig("footerTextColor",black)}}>
                        {"Powered by "}
                        <img src={process.env.CLOUDFRONT_URL + "/img/cention-logo-black_smallest.png"}></img>
                    </a>
                </div>
            </div>
        </div>
    )
}
const CWidgetFrictionlessFrame3 = ({
    onGetConfig: getConfig
    , agent
}) => {
    const avatarRef = useRef();
    const areas = getConfig("activeAreas");
    let hideAreaSelection = true;
    if(areas && areas.split(",").length > 1) {
        hideAreaSelection = false;
    }
    return (
        <div id="Skin-Frictionless" style={{position: "relative", margin: "0 auto"}}>
            <div id="CentionChatHeader" className="centionChatTrigger maximized" style={{
                backgroundColor: getConfig("headerColor",gray),
                color: getConfig("headerTextColor",black)
            }}>
                <span id="iconLogo" className="icon">
                    <i className="icon-chats"></i>
                </span>
                <div id="agentAvatarList" className="agentListChat">
					<div className="agentList">
                        <div className="agentAvatar">
                            <img src={agent.photo} alt="Avatar" className="avatar"
                                ref={avatarRef}
                                onError={
                                    () => avatarRef.current.src = DEFAULT_AGENT_AVATAR
                                }
                            />
                        </div>
						<div className="headerTitleWrapper">
							<span id="textTitle" className="text">{getConfig("textTitle","Live Chat")}</span>
							<div className="agentStatus">
								<div className="logged-in">
									<span className="online-indicator">●</span>
									<span id="textAvailableNote" className="availableNote">{getConfig("textAvailableNote", "We're available!")}</span>
								</div>
								<div className="logged-out" hidden>●</div>
							</div>
						</div>
					</div>
				</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></span>
                    <span id="iconExpand" className="icon icon-expand"></span>
                </div>
            </div>
            <div id="CentionChatBody" style={{backgroundColor: getConfig("mainColor", white)}}>
                    <div class="awayMsgWrapper">
                        <p id="textMessageAway" style={{    fontFamily: 'sans-serif', fontSize: '14px', LetterSpacing: '0px', paddingTop: '10px', margin: '0px 0px 6px', color: 'rgb(0, 0, 0)',textAlign: 'center'}}> {getConfig("textMessageAway","We are sorry either the chat is closed or we don't have any agents available at the moment.")}</p>
                    </div>
                    <div id="CentionChatRetryWrapper" style={{textAlign:'center', paddingTop: '25px'}}>
                        <a id="textButtonRetry" style={{color: "#000"}}>Retry</a>
                    </div>
            </div>
        </div>
    )
}
const FAQWidgetDefaultFrame1 = ({onGetConfig: getConfig, agent}) => {
    const avatarRef = useRef();
    return(
        <div className="widget-wrapper">
            <div id="CentionFAQ">
                <div id="CentionFAQContainer" style={{backgroundColor: getConfig("mainBgColor",white)}} className={getConfig("adminFaqEnableCallbackRequest", false) ? "withCallBack" : ""}>
                    <div id="CentionFAQHeader" style={{backgroundColor: getConfig("headerBgColor",yellow), color: getConfig("headerTextColor",black)}}>
                        <div className="FAQ-HeaderWrapper">
                            <div id="iconReturnPrevious">
                                <i className="fas fa-chevron-left"></i>
                            </div>
                            <div className="title-Row">
                                <div className="title-Col">
                                    <h2 id="textTitle-FAQ" style={{color: getConfig("headerTextColor",black)}}>
                                        {getConfig("textHeaderTitle","Hello, how can I help you?")}
                                    </h2>
                                </div>
                                <div className="title-Col">
                                    <div className="CentionFAQSearchWrapper2" style={{backgroundColor: getConfig("searchBarBgColor",wheat)}}>
                                        <label id="FAQ-search-icon" style={{color: getConfig("iconSearchColor", "grey")}}>
                                            <i className="icon-search"></i>
                                        </label>
                                        <input id="FAQ-search-input" name="FAQ-search-input" type="search"
                                            placeholder={getConfig("textInputSearch","Search for a question")}
                                            style={{color: getConfig("searchBarTextColor",grayOverlay) }} disabled="disabled"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="CentionFAQBody">
                        <div id="FAQStandardContainer">
                            <div id="ViewPage-Home" className="FAQ-SectionWrapper">
                                <div id="CentionFAQFeatured" className={getConfig("adminFaqEnableCallbackRequest", false) ? "CentionFAQQuestionsWrapper withCallback" : "CentionFAQQuestionsWrapper"}>
                                    <div id="tabFeatured" className="menuList">
                                        <div className="icons-wrapper row">
                                            <div className="column">
                                                <span id="iconFeatured" className="tab-icon icon-star-outline active-icon"
                                                    style={{
                                                        backgroundColor: getConfig("tabIconTextColor",wheat),
                                                        color: getConfig("tabIconBgColor",yellow),
                                                        borderColor: getConfig("tabIconTextColor",yellow),
                                                    }}
                                                ></span>
                                                <p className="FAQ-Text" id="FAQButton1" style={{color:getConfig("mainTextColor",black)}}>
                                                    {getConfig("textFAQButton1","Top FAQ")}
                                                </p>
                                            </div>
                                            <div className="column">
                                                <span id="iconBrowse" className="tab-icon icon-knowledgebase"
                                                    style={{
                                                        backgroundColor: getConfig("tabIconBgColor",yellow),
                                                        color: getConfig("tabIconTextColor",wheat),
                                                        borderColor: getConfig("tabIconTextColor",wheat),
                                                    }}
                                                ></span>
                                                <p className="FAQ-Text" id="FAQButton2" style={{color:getConfig("mainTextColor",black)}}>
                                                    {getConfig("textFAQButton2","Browse")}
                                                </p>
                                            </div>
                                            <div className="column">
                                                <span id="iconContact" className="tab-icon icon-chat"
                                                    style={{
                                                        backgroundColor: getConfig("tabIconBgColor",yellow),
                                                        color: getConfig("tabIconTextColor",wheat),
                                                        borderColor: getConfig("tabIconTextColor",wheat),
                                                    }}
                                                ></span>
                                                <p className="FAQ-Text" id="FAQButton3" style={{color:getConfig("mainTextColor",black)}}>
                                                    {getConfig("textFAQButton3","Chat")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                <div id="CallbackWrapper" className="card-body" hidden={!(features["sip-enabled"] && getConfig("adminFaqEnableCallbackRequest", true))}>
                                    <div className="centionCol"><i className="icon-call"></i></div>
                                    <div className="centionCol">
                                        <div id="CallbackRequestTitle">{getConfig("textCallbackTitle", "Request a callback")}</div>
                                        <div id="CallbackRequestWorkingHours">{getConfig("textCallbackWorkingHours", "Monday - Friday: 9:00am - 6:00pm")}</div>
                                    </div>
                                </div>
                                    <div id="CentionFAQFeaturedListDiv">
                                        <ul id="CentionFAQFeaturedList" className="FAQ-list">
                                            <li id="001" className="FAQ-list-questions"
                                                style={{
                                                    backgroundColor: getConfig("questionListBgColor",gray),
                                                    color: getConfig("questionListTextColor",black),
                                                }}
                                            >
                                                <div>{I("What do the different delivery methods cost?")}</div>
                                            </li>
                                            <li id="002" className="FAQ-list-questions"
                                                style={{
                                                    backgroundColor: getConfig("questionListBgColor",gray),
                                                    color: getConfig("questionListTextColor",black),
                                                }}
                                            >
                                                <div>{I("Do you accept American Express card?")}</div>
                                            </li>
                                            <li id="003" className="FAQ-list-questions"
                                                style={{
                                                    backgroundColor: getConfig("questionListBgColor",gray),
                                                    color: getConfig("questionListTextColor",black),
                                                }}
                                            >
                                                <div>{I("What is your opening hours?")}</div>
                                            </li>
                                            <li id="004" className="FAQ-list-questions"
                                                style={{
                                                    backgroundColor: getConfig("questionListBgColor",gray),
                                                    color: getConfig("questionListTextColor",black),
                                                }}
                                            >
                                               <div>{I("What is your Privacy Policy?")}</div>
                                            </li>
                                            <li id="005" className="FAQ-list-questions"
                                                style={{
                                                    backgroundColor: getConfig("questionListBgColor",gray),
                                                    color: getConfig("questionListTextColor",black),
                                                }}
                                            >
                                                <div>{I("How does it work?")}</div>
                                            </li>
                                            <li id="006" className="FAQ-list-questions"
                                                style={{
                                                    backgroundColor: getConfig("questionListBgColor",gray),
                                                    color: getConfig("questionListTextColor",black),
                                                }}
                                            >
                                                <div>{I("Help me to reset my password")}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-container">
                <div id="CentionFAQPanelTrigger" className="bottom-right" style={{backgroundColor: getConfig("triggerPanelBgColor", yellow)}}>
                    <span id="trigger-panel-close" className="bottom-icon icon-times" style={{color : getConfig("triggerPanelTextColor", white)}} ></span>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetDefaultFrame2 = ({onGetConfig: getConfig}) => {
    return(
        <div className="widget-wrapper">
            <div id="CentionFAQ">
                <div id="CentionFAQContainer" style={{backgroundColor: getConfig("mainBgColor",white)}}>
                    <div id="CentionFAQHeader" style={{backgroundColor: getConfig("headerBgColor",yellow), color: getConfig("headerTextColor",black)}}>
                        <div className="FAQ-HeaderWrapper">
                            <div id="iconReturnPrevious">
                                <i className="fas fa-chevron-left"></i>
                            </div>
                            <div className="title-Row">
                                <div className="title-Col">
                                    <h2 id="textTitle-FAQ" style={{color: getConfig("headerTextColor",black)}}>
                                        {getConfig("textHeaderTitle","Hello, how can I help you?")}
                                    </h2>
                                </div>
                                <div className="title-Col">
                                    <div className="CentionFAQSearchWrapper2" style={{backgroundColor: getConfig("searchBarBgColor",wheat)}}>
                                        <label id="FAQ-search-icon" style={{color: getConfig("iconSearchColor", "grey")}}>
                                            <i className="icon-search"></i>
                                        </label>
                                        <input id="FAQ-search-input" name="FAQ-search-input" type="search"
                                            placeholder={getConfig("textInputSearch","Search for a question")}
                                            style={{color: getConfig("searchBarTextColor",grayOverlay) }} disabled="disabled"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="CentionFAQBody">
                        <div id="FAQStandardContainer">
                            <div id="ViewPage-Home" className="FAQ-SectionWrapper">
                                <div id="CentionFAQFeatured" className="CentionFAQQuestionsWrapper">
                                    <div id="tabFeatured" className="menuList">
                                        <div className="icons-wrapper row">
                                            <div className="column">
                                                <span id="iconFeatured" className="tab-icon icon-star-outline"
                                                    style={{
                                                        backgroundColor: getConfig("tabIconBgColor",yellow),
                                                        color: getConfig("tabIconTextColor",wheat),
                                                        borderColor: getConfig("tabIconTextColor",wheat),
                                                    }}
                                                ></span>
                                                <p className="FAQ-Text" id="FAQButton1" style={{color:getConfig("mainTextColor",black)}}>
                                                    {getConfig("textFAQButton1","Top FAQ")}
                                                </p>
                                            </div>
                                            <div className="column">
                                                <span id="iconBrowse" className="tab-icon icon-knowledgebase active-icon"
                                                    style={{
                                                        backgroundColor: getConfig("tabIconTextColor",wheat),
                                                        color: getConfig("tabIconBgColor",yellow),
                                                        borderColor: getConfig("tabIconTextColor",yellow),
                                                    }}
                                                ></span>
                                                <p className="FAQ-Text" id="FAQButton2" style={{color:getConfig("mainTextColor",black)}}>
                                                    {getConfig("textFAQButton2","Browse")}
                                                </p>
                                            </div>
                                            <div className="column">
                                                <span id="iconContact" className="tab-icon icon-chat"
                                                    style={{
                                                        backgroundColor: getConfig("tabIconBgColor",yellow),
                                                        color: getConfig("tabIconTextColor",wheat),
                                                        borderColor: getConfig("tabIconTextColor",wheat),
                                                    }}
                                                ></span>
                                                <p className="FAQ-Text" id="FAQButton3" style={{color:getConfig("mainTextColor",black)}}>
                                                    {getConfig("textFAQButton3","Chat")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div id="CentionFAQFeaturedListDiv">
                                        <ul id="CentionFAQBrowseKnowledgebase" className="FAQ-list" style={{display:"block"}}>
                                            <li id="001" className="FAQ-list-libraries"
                                                style={{
                                                    backgroundColor: getConfig("libraryListBgColor",gray),
                                                    color: getConfig("libraryListTextColor",black),
                                                }}
                                            >
                                                <span>Pricing Plans</span><i className="icon-chevron-right"></i>
                                            </li>
                                            <li id="001" className="FAQ-list-libraries"
                                                style={{
                                                    backgroundColor: getConfig("libraryListBgColor",gray),
                                                    color: getConfig("libraryListTextColor",black),
                                                }}
                                            >
                                                <span>Product Tours</span><i className="icon-chevron-right"></i>
                                            </li>
                                            <li id="001" className="FAQ-list-libraries"
                                                style={{
                                                    backgroundColor: getConfig("libraryListBgColor",gray),
                                                    color: getConfig("libraryListTextColor",black),
                                                }}
                                            >
                                                <span>Integrations</span><i className="icon-chevron-right"></i>
                                            </li>
                                            <li id="001" className="FAQ-list-libraries"
                                                style={{
                                                    backgroundColor: getConfig("libraryListBgColor",gray),
                                                    color: getConfig("libraryListTextColor",black),
                                                }}
                                            >
                                                <span>FAQs</span><i className="icon-chevron-right"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-container">
                <div id="CentionFAQPanelTrigger" className="bottom-right" style={{backgroundColor: getConfig("triggerPanelBgColor", yellow)}}>
                    <span id="trigger-panel-close" className="bottom-icon icon-times" style={{color : getConfig("triggerPanelTextColor", white)}} ></span>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetDefaultFrame3 = ({onGetConfig: getConfig}) => {
    return(
        <div className="widget-wrapper">
            <div id="CentionFAQ">
                <div id="CentionFAQContainer" style={{backgroundColor: getConfig("mainBgColor",white)}}>
                    <div id="CentionFAQHeader" style={{backgroundColor: getConfig("headerBgColor",yellow)}}>
                        <div className="FAQ-HeaderWrapper">
                            <div id="iconReturnPrevious">
                                <i className="fas fa-chevron-left"></i>
                            </div>
                            <div className="title-Row">
                                <div className="title-Col">
                                    <h2 id="textTitle-FAQ" style={{color: getConfig("headerTextColor",black)}}>
                                       {getConfig("textHeaderTitle","Hello, how can I help you?")}
                                    </h2>
                                </div>
                                <div className="title-Col">
                                    <div className="CentionFAQSearchWrapper2" style={{backgroundColor: getConfig("searchBarBgColor",wheat)}}>
                                        <label id="FAQ-search-icon" style={{color: getConfig("iconSearchColor", "grey")}}>
                                            <i className="icon-search"></i>
                                        </label>
                                        <input id="FAQ-search-input" name="FAQ-search-input" type="search"
                                            placeholder={getConfig("textInputSearch","Search for a question")}
                                            style={{color: getConfig("searchBarTextColor",grayOverlay) }} disabled="disabled"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="CentionFAQBody">
                        <div id="FAQBreadcrumbs" style={{paddingBottom: "4px", height:"7%"}}>
                            <span title="Back to home page" id="iconReturnHome" className="icon-home"></span>
                            <ul className="breadcrumb">
                                <span></span>
                                <li id="2" className="FAQ-list-libraries">Knowledgebase</li>
                                <li id="3" className="FAQ-list-categories active-breadcrumb">
                                    <i className="icon-chevron-right"></i>
                                    <span>Categories</span>
                                </li>
                                <li id="7" className="FAQ-breadcrumb-questions active-breadcrumb">
                                    <i className="icon-chevron-right"></i>
                                    <span>Question</span>
                                </li>
                            </ul>
                        </div>
                        <div id="FAQStandardContainer">
                            <div id="ViewPage-Answer" className="FAQ-SectionWrapper">
                                <div id="CentionFAQAnswer" className="CentionFAQAnswerWrapper">
                                    <br/>
                                    <h3 id="currentSelectedQuestion" style={{color: getConfig("mainTextColor",black)}}>How does it work?</h3>
                                    <div id="currentSelectedAnswer" style={{color: getConfig("mainTextColor",black)}}>
                                        <br/>
                                        <div>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod aliqua.</div>
                                        <ul style={{listStyle: "disc",padding: "10px",fontSize: "12px"}}>
                                            <li style={{listStyle: "disc",color: getConfig("mainTextColor",black)}}>
                                                Ut enim ad minim veniam, quis nostrud exercitation ullamco</li>
                                            <li style={{listStyle: "disc",color: getConfig("mainTextColor",black)}}>
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse</li>
                                        </ul>
                                        <br/>
                                    </div>
                                    <p id="currentSelectedAnswerFiles"></p>
                                    <div className="CentionFAQFeedback" style={{marginTop: "0px", padding:"0 15px"}}>
                                        <h6 className="textFeedbackHeading"
                                          style={{marginBottom:"10px",color: getConfig("mainTextColor",black)}}
                                        >Did you get the answer to your question?
                                        </h6>
                                        <button className="FeedbackUpvote FAQ-Button-feedback"
                                            style={{
                                                backgroundColor: getConfig("btnFeedbackBgColor",yellow),
                                                color: getConfig("btnFeedbackTextColor",white),
                                                display: "inline-flex"
                                            }}
                                        >
                                            <span>Yes</span>&nbsp;
                                            <i className="icon-thumbs-up"></i>
                                        </button>
                                        <button className="FeedbackDownvote FAQ-Button-feedback"
                                            style={{
                                                backgroundColor: getConfig("btnFeedbackBgColor",yellow),
                                                color: getConfig("btnFeedbackTextColor",white),
                                                display: "inline-flex"
                                            }}
                                        >
                                            <span>No - Contact Us</span>
                                            &nbsp;<i className="icon-thumbs-down"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-container">
                <div id="CentionFAQPanelTrigger" className="bottom-right" style={{backgroundColor: getConfig("triggerPanelBgColor", yellow)}}>
                    <span id="trigger-panel-close" className="bottom-icon icon-times" style={{color : getConfig("triggerPanelTextColor", white)}} ></span>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetDefaultFrame4 = ({onGetConfig: getConfig}) => {
    return(
        <div className="widget-wrapper">
            <div id="CentionFAQ">
                <div id="CentionFAQContainer" style={{backgroundColor: getConfig("mainBgColor",white)}}>
                    <div id="CentionFAQHeader" style={{backgroundColor: getConfig("headerBgColor",yellow)}}>
                        <div className="FAQ-HeaderWrapper">
                            <div id="iconReturnPrevious">
                                <i className="fas fa-chevron-left"></i>
                            </div>
                            <div className="title-Row">
                                <div className="title-Col">
                                    <h2 id="textTitle-FAQ" style={{color: getConfig("headerTextColor",black)}}>
                                       {getConfig("textHeaderTitle","Hello, how can I help you?")}
                                    </h2>
                                </div>
                                <div className="title-Col">
                                    <div className="CentionFAQSearchWrapper2" style={{backgroundColor: getConfig("searchBarBgColor",wheat)}}>
                                        <label id="FAQ-search-icon" style={{color: getConfig("iconSearchColor", "grey")}}>
                                            <i className="icon-search"></i>
                                        </label>
                                        <input id="FAQ-search-input" name="FAQ-search-input" type="search"
                                            placeholder={getConfig("textInputSearch","Search for a question")}
                                            style={{color: getConfig("searchBarTextColor",grayOverlay) }} disabled="disabled"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="CentionFAQBody">
                        <div id="FAQStandardContainer">
                            <div id="ViewPage-Contact" className="FAQ-SectionWrapper">
                                <div className="CentionFAQContactWrapper">
                                    <div className="contactHeader">
                                        <span title="Back to home page" id="contactBackHeader" className="icon-home"></span>
                                        <span className="contact-header-text">Talk to Us</span>
                                    </div>
                                    <ul className="FAQ-list">
                                        <li id="ContactLine" style={{color:getConfig("mainTextColor",black)}}>
                                            <div className="FAQ-icon-contact" style={{color: getConfig("iconContactColor",grayDarkerOverlay)}}><i className="icon-call"></i></div>
                                            <div id="ContactNumTitle" className="FAQ-contact-title">{getConfig("textContactNumTitle","Contact Us")}</div>
                                            <div id="ContactNumDetails">
                                                <br/>
                                                <div id="ContactNumLine1">{getConfig("textContactNumLine1","tel-no +60 12345789")}</div>
                                            </div>
                                        </li>
                                        <li id="ContactHours" style={{color:getConfig("mainTextColor",black)}}>
                                            <div className="FAQ-icon-contact" style={{color: getConfig("iconContactColor",grayDarkerOverlay)}}><i className="icon-clock"></i></div>
                                            <div id="ContactHoursTitle" className="FAQ-contact-title">{getConfig("textContactHoursTitle","Operating Hours")}</div>
                                            <div id="ContactHoursDetails">
                                                <br/>
                                                <div id="ContactHoursLine1">{getConfig("textContactHoursLine1","Monday-Friday: 08:00-22:00")}</div>
                                                <br/>
                                                <div id="ContactHoursLine2">{getConfig("textContactHoursLine2","Saturday-Sunday: 10:00-17:00")}</div>
                                            </div>
                                        </li>
                                        <li id="ContactChat" style={{color:getConfig("mainTextColor",black)}}>
                                            <div className="FAQ-icon-contact" style={{color: getConfig("iconContactColor",grayDarkerOverlay)}}><i className="icon-chats"></i></div>
                                            <div id="ContactChatTitle" className="FAQ-contact-title">{getConfig("textContactChatTitle","Chat with us")}</div>
                                            <div id="ContactChatIcon">
                                                <span id="FAQChatLauncher" className="contact-chat-icon icon-chat buttonLaunchChat"
                                                title={getConfig("textFAQChatLauncher","Open Chat")}
                                                style={{
                                                    backgroundColor: getConfig("btnLaunchChatBgColor",yellow),
                                                    color: getConfig("btnLaunchChatTextColor",white)
                                                }}
                                                ></span>
                                            </div>
                                            <div id="ContactChatDetails">
                                                <br/>
                                                <div id="ContactChatLine1">{getConfig("textContactChatLine1","Weekdays: 08:00-17:00")}</div>
                                                <br/>
                                                <div id="ContactChatLine2">{getConfig("textContactChatLine2","Saturday-Sunday: 10:00-17:00")}</div>
                                            </div>
                                        </li>
                                        <li id="ContactEmail" style={{color:getConfig("mainTextColor",black)}}>
                                            <div className="FAQ-icon-contact" style={{color: getConfig("iconContactColor",grayDarkerOverlay)}}><i className="icon-mail"></i></div>
                                            <div id="ContactEmailTitle" className="FAQ-contact-title">{getConfig("textContactEmailTitle","Email us")}</div>
                                            <div id="ContactEmailDetails">
                                                <br/>
                                                <a id="ContactEmailLine1">{getConfig("textContactEmailLine1","info@cention.com")}</a>
                                                <br/>
                                                <div id="ContactEmailLine2">{getConfig("textContactEmailLine2","We will get to you as soon as we can in 24 hours")}</div>
                                                <br/>
                                                <div id="ContactEmailLine3">{getConfig("textContactEmailLine3", "")}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-container">
                <div id="CentionFAQPanelTrigger" className="bottom-right" style={{backgroundColor: getConfig("triggerPanelBgColor", yellow)}}>
                    <span id="trigger-panel-close" className="bottom-icon icon-times" style={{color : getConfig("triggerPanelTextColor", white)}} ></span>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetDefaultFrame5 = ({onGetConfig: getConfig, agent}) => {
    const avatarRef = useRef();
    return(
        <div className="widget-wrapper">
            <div id="CentionFAQ">
                <div id="CentionFAQContainer" style={{backgroundColor: getConfig("mainBgColor",white)}}>
                    <div id="CentionFAQHeader" style={{backgroundColor: getConfig("headerBgColor",yellow)}}>
                        <div className="FAQ-HeaderWrapper">
                            <div id="iconReturnPrevious">
                                <i className="fas fa-chevron-left"></i>
                            </div>
                            <div className="title-Row">
                                <div className="title-Col">
                                    <h2 id="textTitle-FAQ" style={{color: getConfig("headerTextColor",black)}}>
                                       {getConfig("textHeaderTitle","Hello, how can I help you?")}
                                    </h2>
                                </div>
                                <div className="title-Col">
                                    <div className="CentionFAQSearchWrapper2" style={{backgroundColor: getConfig("searchBarBgColor",wheat)}}>
                                        <label id="FAQ-search-icon" style={{color: getConfig("iconSearchColor", "grey")}}>
                                            <i className="icon-search"></i>
                                        </label>
                                        <input id="FAQ-search-input" name="FAQ-search-input" type="search"
                                            placeholder={getConfig("textInputSearch","Search for a question")}
                                            style={{color: getConfig("searchBarTextColor",grayOverlay) }} disabled="disabled"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="CentionFAQBody">
                        <div id="FAQBreadcrumbs">
                            <span title="Back to home page" id="iconReturnHome" className="icon-home"></span>
                            <ul className="breadcrumb"><span></span></ul>
                        </div>
                        <div id="CentionCallbackBody">
                            <div className="inner-container">
                                <div id="CentionCallbackContactForm">
                                        <div id="agentAvatarList" className="agentListChat">
                                            <div className="agentList">
                                                <p className="agentStatus">
                                                    <span className="logged-in">● <span id="textAvailableNote" className="availableNote">{getConfig("textAvailableNote", "We're available!")}</span></span>
                                                </p>
                                                <div className="agentAvatar" hidden={getConfig("adminFaqHideAgentAvatarList", false)}>
                                                    <img src={agent.photo} alt="Avatar" className="avatar"
                                                    ref={avatarRef}
                                                    onError={
                                                        () => avatarRef.current.src = DEFAULT_AGENT_AVATAR
                                                    }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <input id="callbackInputName" type="text" placeholder={getConfig("textCallbackInputName",I("Name"))} />
                                        <input id="callbackInputEmail" type="text" placeholder={getConfig("textCallbackInputEmail",I("Email"))}/>
                                        <input id="callbackInputPhone" type="text" placeholder={getConfig("textCallbackInputPhone",I("Phone"))} />
                                        <textarea id="callbackInputQuestion" placeholder={getConfig("textCallbackInputQuestion",I("Question"))} />
                                        <div id="callbackFormInfo"></div>
                                        <div id="EULAAcceptance">
                                            <input type="checkbox" id="askEULAInput" />
                                            <label htmlFor="askEULAInput" id="textEULA">
                                                {getConfig("textEULAFAQ", 'I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.')}
                                            </label>
                                        </div>
                                    <button id="callbackButtonSubmit" type="button">
                                        <i className="icon-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-container">
                <div id="CentionFAQPanelTrigger" className="bottom-right" style={{backgroundColor: getConfig("triggerPanelBgColor", yellow)}}>
                    <span id="trigger-panel-close" className="bottom-icon icon-times" style={{color : getConfig("triggerPanelTextColor", white)}} ></span>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetModernFrame1 = ({onGetConfig: getConfig}) => {
    return (
        <div className="widget-wrapper">
            <div id="Skin-Modern" className="skin preview-container">
                <div id="CentionFAQ">
                    <div id="CentionFAQContainer" style={{backgroundColor: getConfig("mainBgColor",white)}} className={getConfig("adminFaqEnableCallbackRequest", false) ? "withCallBack" : ""}>
                        <div id="CentionFAQHeader" style={{
                            backgroundColor: getConfig("headerBgColor",yellow),
                            color: getConfig("headerTextColor",black)
                        }}>
                            <div className="FAQ-HeaderWrapper">
                               <div id="BackHome" className="backHome"><i className="icon-chevron-left"></i></div>
                                <div id="HeaderInnerContent" className="title-Row">
                                    <div className="row-header">
                                        <div id="textTitle-FAQ">{getConfig("textHeaderTitle","Hello, how can I help you?")}</div>
                                    </div>
                                </div>
                                <div id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></div>
                            </div>
                        </div>
                        <div id="CentionFAQBody" style={{color: getConfig("mainTextColor",black)}}>
                            <div id="FAQStandardContainer">
                                <div id="ViewPage-Home" className="FAQ-SectionWrapper">
                                    <div id="CentionFAQFeatured" className={getConfig("adminFaqEnableCallbackRequest", false) ? "CentionFAQQuestionsWrapper withCallback" : "CentionFAQQuestionsWrapper"}>
                                        <div className="chat-banner card-wrapper" style={{height: getConfig("adminFaqEnableCallbackRequest", false) ? "" : "auto"}}>
                                            <div id="NavTextChat" className="card-header">{getConfig("textFAQButton3","Talk to Us")}</div>
                                            <div id="BrowseContact" className="card-body" style={{padding:"0"}}>
                                                <div className="col-0"><i className="icon-chat"></i></div>
                                                <div className="col-0">
                                                    <div id="ContactChatTitle">{getConfig("textContactChatTitle","Chat with us")}</div>
                                                    <div id="ContactChatLine1">{getConfig("textContactChatLine1","Weekdays: 08:00-17:00")}</div>
                                                    <div id="ContactChatLine2">{getConfig("textContactChatLine2","Saturday-Sunday: 10:00-17:00")}</div>
                                                </div>
                                            </div>
                                            <div id="CallbackWrapper" className="card-body" hidden={!getConfig("adminFaqEnableCallbackRequest", false)}>
                                                <div className="centionCol"><i className="icon-call"></i></div>
                                                <div className="centionCol">
                                                    <div id="CallbackRequestTitle">{getConfig("textCallbackTitle", "Request a callback")}</div>
                                                    <div id="CallbackRequestWorkingHours">{getConfig("textCallbackWorkingHours", "Monday - Friday: 9:00am - 6:00pm")}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-wrapper">
                                            <div id="TopListHeader" className="card-header">
                                                <div id="NavTextTopList">{getConfig("textFAQButton1","Top FAQ")}</div>
                                                <div className="browseSearch" title="Search" id="FAQ-search-icon" ><i className="icon-search"></i></div>
                                            </div>
                                            <div id="CentionFAQFeaturedListDiv">
                                                <ul id="CentionFAQFeaturedList" className="FAQ-list">
                                                    <li id="8" className="FAQ-list-questions" style={{color: getConfig("questionListTextColor",black)}}>
                                                        <div>{I("What do the different delivery methods cost?")}</div>
                                                    </li>
                                                    <li id="13" className="FAQ-list-questions" style={{color: getConfig("questionListTextColor",black)}}>
                                                        <div>{I("Do you accept American Express card?")}</div>
                                                    </li>
                                                    <li id="12" className="FAQ-list-questions" style={{color: getConfig("questionListTextColor",black)}}>
                                                        <div>{I("What is your opening hours?")}</div>
                                                    </li>
                                                    <li id="19" className="FAQ-list-questions" style={{color: getConfig("questionListTextColor",black)}}>
                                                        <div>{I("What is your Privacy Policy?")}</div>
                                                    </li>
                                                    <li id="16" className="FAQ-list-questions" style={{color: getConfig("questionListTextColor",black)}}>
                                                        <div>{I("How does it work?")}</div>
                                                    </li>
                                                    <li id="9" className="FAQ-list-questions" style={{color: getConfig("questionListTextColor",black)}}>
                                                        <div>{I("Help me to reset my password")}</div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div id="BrowseLibrary">
                                                <div className="FAQ-Text" id="FAQButton2">{getConfig("textFAQButton2","Browse")}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-container right-align">
                    <div id="CentionFAQPanelTrigger" className="bottom-right" style={{
                        backgroundColor: getConfig("triggerPanelBgColor", yellow), marginLeft: "276px"}}>
                        <span id="trigger-panel-close" className="bottom-icon icon-times" style={{color : getConfig("triggerPanelTextColor", white)}} ></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetModernFrame2 = ({onGetConfig: getConfig}) => {
    return (
        <div className="widget-wrapper">
            <div id="Skin-Modern" className="skin preview-container">
                <div id="CentionFAQ">
                    <div id="CentionFAQContainer" style={{backgroundColor: getConfig("mainBgColor",white)}}>
                        <div id="CentionFAQHeader" style={{
                            backgroundColor: getConfig("headerBgColor",yellow),
                            color: getConfig("headerTextColor",black)
                        }}>
                            <div className="FAQ-HeaderWrapper">
                               <div id="BackHome" className="backHome"><i className="icon-chevron-left"></i></div>
                                <div id="HeaderInnerContent" className="title-Row">
                                    <div className="row-header">
                                        <div id="textTitle-FAQ">{getConfig("textHeaderTitle","Hello, how can I help you?")}</div>
                                    </div>
                                    <div className="row-search">
                                        <div id="FAQSearchBar" className="CentionFAQSearchWrapper2" style={{
                                            backgroundColor:getConfig("searchBarBgColor","white")
                                        }}
                                        >
                                            <label title="Search" id="FAQ-search-icon" style={{color: getConfig("iconSearchColor", "grey")}}><i className="icon-search"></i></label>
                                            <input id="FAQ-search-input" name="FAQ-search-input" type="search" placeholder={getConfig("textInputSearch","Label for search input")}/>
                                        </div>
                                    </div>
                                </div>
                                <div id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></div>
                            </div>
                        </div>
                        <div id="CentionFAQBody">
                            <div id="FAQStandardContainer">
                                <div id="ViewPage-BrowseLibrary" className="FAQ-SectionWrapper">
                                    <div id="CentionFAQCategory" className="category-container">
                                        <div className="category-heading" style={{color: getConfig("mainTextColor",black)}}>
                                            {getConfig("textCategoryHeading", "Categories")}
                                        </div>
                                        <ul id="CentionFAQBrowseKnowledgebase" className="FAQ-list">
                                            <li id="1" className="FAQ-list-libraries" style={{
                                                backgroundColor:getConfig("libraryListBgColor",gray),
                                                color:getConfig("libraryListTextColor",black),
                                                width: getConfig("featuredFirstLibrary") ? "200px" : "",
                                                margin: getConfig("featuredFirstLibrary") ? "auto" : "",
                                            }}>
                                                <div className="library-icon-wrap"><div className="alphabet-icon">P</div></div>
                                                <div className="library-text">Pricing Plans</div>
                                            </li>
                                            <li id="2" className="FAQ-list-libraries" style={{
                                                backgroundColor:getConfig("libraryListBgColor",gray),
                                                color:getConfig("libraryListTextColor",black)
                                            }}>
                                                <div className="library-icon-wrap"><div className="alphabet-icon">P</div></div>
                                                <div className="library-text">Product Tours</div>
                                            </li>
                                            <li id="3" className="FAQ-list-libraries" style={{
                                                backgroundColor:getConfig("libraryListBgColor",gray),
                                                color:getConfig("libraryListTextColor",black)
                                            }}>
                                                <div className="library-icon-wrap"><div className="alphabet-icon">I</div></div>
                                                <div className="library-text">Integrations</div>
                                            </li>
                                            <li id="4" className="FAQ-list-libraries" style={{
                                                backgroundColor:getConfig("libraryListBgColor",gray),
                                                color:getConfig("libraryListTextColor",black)
                                            }}>
                                                <div className="library-icon-wrap"><div className="alphabet-icon">F</div></div>
                                                <div className="library-text">FAQs</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-container right-align">
                    <div id="CentionFAQPanelTrigger" className="bottom-right" style={{
                        backgroundColor: getConfig("triggerPanelBgColor", yellow), marginLeft: "276px"}}>
                        <span id="trigger-panel-close" className="bottom-icon icon-times" style={{color : getConfig("triggerPanelTextColor", white)}} ></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetModernFrame3 = ({onGetConfig: getConfig}) => {
    return (
        <div className="widget-wrapper">
            <div id="Skin-Modern" className="skin preview-container">
                <div id="CentionFAQ">
                    <div id="CentionFAQContainer" style={{backgroundColor: getConfig("mainBgColor",white)}}>
                        <div id="CentionFAQHeader" style={{
                            backgroundColor: getConfig("headerBgColor",yellow),
                            color: getConfig("headerTextColor",black)
                        }}>
                            <div className="FAQ-HeaderWrapper">
                               <div id="BackHome" className="backHome"><i className="icon-chevron-left"></i></div>
                                <div id="HeaderInnerContent" className="title-Row">
                                    <div className="row-header">
                                        <div id="textTitle-FAQ">{getConfig("textHeaderTitle","Hello, how can I help you?")}</div>
                                    </div>
                                </div>
                                <div id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></div>
                            </div>
                        </div>
                        <div id="CentionFAQBody">
                            <div id="FAQStandardContainer">
                                <div id="ViewPage-Answer" className="FAQ-SectionWrapper">
                                    <div id="CentionFAQAnswer" className="card-wrapper CentionFAQAnswerWrapper">
                                        <div className="question-answer-wrap">
                                            <h3 id="currentSelectedQuestion" style={{color: getConfig("mainTextColor",black)}}
                                            >How does it work?</h3>
                                            <div className="content-wrapper">
                                                <br/>
                                                <div id="currentSelectedAnswer">
                                                    <p>Lorem ipsum dolor sit amet, adipiscing elit,
                                                        sed do eiusmod aliqua.</p>
                                                    <br/>
                                                    <ul style={{padding:"0 24px"}}>
                                                        <li style={{listStyle:"disc"}}>
                                                            <span>Ut enim ad minim veniam, quis nostrud exercitation ullamco</span>
                                                        </li>
                                                        <li style={{listStyle:"disc"}}>
                                                            <span>Duis aute irure dolor in reprehenderit in voluptate velit esse</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="CentionFAQFeedback">
                                            <div className="textFeedbackHeading" style={{color: getConfig("mainTextColor",black)}}
                                            >{getConfig("textFeedbackQuestion","Did you get the answer to your question?")}</div>
                                            <div id="FeedbackButtons" className="feedback-button-wrapper" style={{display: "inline-flex"}}>
                                                <button className="FeedbackUpvote FAQ-Button-feedback"
                                                    style={{
                                                        backgroundColor: getConfig("btnFeedbackBgColor",yellow),
                                                        color: getConfig("btnFeedbackTextColor",white)
                                                    }}
                                                >
                                                    <span>{getConfig("textBtnUpvote","Yes")}</span>&nbsp;
                                                    <i className="icon-thumbs-up"></i>
                                                </button>
                                                <button className="FeedbackDownvote FAQ-Button-feedback"
                                                    style={{
                                                        backgroundColor: getConfig("btnFeedbackBgColor",yellow),
                                                        color: getConfig("btnFeedbackTextColor",white)
                                                    }}
                                                >
                                                    <span>{getConfig("textBtnDownvote","No - Contact Us")}</span>
                                                    &nbsp;<i className="icon-thumbs-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-container right-align">
                    <div id="CentionFAQPanelTrigger" className="bottom-right" style={{
                        backgroundColor: getConfig("triggerPanelBgColor", yellow), marginLeft: "276px"}}>
                        <span id="trigger-panel-close" className="bottom-icon icon-times" style={{color : getConfig("triggerPanelTextColor", white)}} ></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetModernFrame4 = ({onGetConfig: getConfig}) => {
    return (
        <div className="widget-wrapper">
            <div id="Skin-Modern" className="skin preview-container">
                <div id="CentionFAQ">
                    <div id="CentionFAQContainer" style={{backgroundColor: getConfig("mainBgColor",white)}}>
                        <div id="CentionFAQHeader" style={{
                            backgroundColor: getConfig("headerBgColor",yellow),
                            color: getConfig("headerTextColor",black)
                        }}>
                            <div className="FAQ-HeaderWrapper">
                               <div id="BackHome" className="backHome"><i className="icon-chevron-left"></i></div>
                                <div id="HeaderInnerContent" className="title-Row">
                                    <div className="row-header">
                                        <div id="textTitle-FAQ">{getConfig("textHeaderTitle","Hello, how can I help you?")}</div>
                                    </div>
                                </div>
                                <div id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></div>
                            </div>
                        </div>
                        <div id="CentionFAQBody">
                            <div id="FAQStandardContainer">
                                <div id="ViewPage-Contact" className="FAQ-SectionWrapper">
                                    <div className="card-wrapper CentionFAQContactWrapper">
                                        <ul className="FAQ-contact-list">
                                            <li id="ContactLine" style={{color:getConfig("mainTextColor",black)}}>
                                                <div className="FAQ-icon-contact" style={{color: getConfig("iconContactColor",grayDarkerOverlay)}}><i className="icon-call"></i></div>
                                                <div className="details-wrapper">
                                                    <div id="ContactNumTitle" className="FAQ-contact-title">{getConfig("textContactNumTitle","Contact Us")}</div>
                                                    <div id="ContactNumDetails">
                                                        <div id="ContactNumLine1">{getConfig("textContactNumLine1","tel-no +60 12345789")}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li id="ContactHours" style={{color:getConfig("mainTextColor",black)}}>
                                                <div className="FAQ-icon-contact" style={{color: getConfig("iconContactColor",grayDarkerOverlay)}}><i className="icon-clock"></i></div>
                                                <div className="details-wrapper">
                                                    <div id="ContactHoursTitle" className="FAQ-contact-title">{getConfig("textContactHoursTitle","Operating Hours")}</div>
                                                    <div id="ContactHoursDetails">
                                                        <div id="ContactHoursLine1">{getConfig("textContactHoursLine1","Monday-Friday: 08:00-22:00")}</div>
                                                        <div id="ContactHoursLine2">{getConfig("textContactHoursLine2","Saturday-Sunday: 10:00-17:00")}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li id="ContactChat" style={{color:getConfig("mainTextColor",black)}}>
                                                <div className="FAQ-icon-contact" style={{color: getConfig("iconContactColor",grayDarkerOverlay)}}><i className="icon-chats"></i></div>
                                                <div className="details-wrapper">
                                                    <div id="ContactChatTitle" className="FAQ-contact-title">{getConfig("textContactChatTitle","Chat with us")}</div>
                                                    <div id="ContactChatIcon">
                                                        <span id="FAQChatLauncher" className="contact-chat-icon icon-chat buttonLaunchChat"
                                                        title={getConfig("textFAQChatLauncher","Open Chat")}
                                                        style={{
                                                            backgroundColor: getConfig("btnLaunchChatBgColor",yellow),
                                                            color: getConfig("btnLaunchChatTextColor",white)
                                                        }}
                                                        ></span>
                                                    </div>
                                                    <div id="ContactChatDetails">
                                                        <div id="ContactChatLine1">{getConfig("textContactChatLine1","Weekdays: 08:00-17:00")}</div>
                                                        <div id="ContactChatLine2">{getConfig("textContactChatLine2","Saturday-Sunday: 10:00-17:00")}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li id="ContactEmail" style={{color:getConfig("mainTextColor",black)}}>
                                                <div className="FAQ-icon-contact" style={{color: getConfig("iconContactColor",grayDarkerOverlay)}}><i className="icon-mail"></i></div>
                                                <div className="details-wrapper">
                                                    <div id="ContactEmailTitle" className="FAQ-contact-title">{getConfig("textContactEmailTitle","Email us")}</div>
                                                    <div id="ContactEmailDetails">
                                                        <a id="ContactEmailLine1">{getConfig("textContactEmailLine1","info@cention.com")}</a>
                                                        <div id="ContactEmailLine2">{getConfig("textContactEmailLine2","We will get to you as soon as we can in 24 hours")}</div>
                                                        <div id="ContactEmailLine3">{getConfig("textContactEmailLine3", "")}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-container right-align">
                    <div id="CentionFAQPanelTrigger" className="bottom-right" style={{
                        backgroundColor: getConfig("triggerPanelBgColor", yellow), marginLeft: "276px"}}>
                        <span id="trigger-panel-close" className="bottom-icon icon-times" style={{color : getConfig("triggerPanelTextColor", white)}} ></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetModernFrame5 = ({onGetConfig: getConfig, agent}) => {
    const avatarRef = useRef();
    return (
        <div className="widget-wrapper">
            <div id="Skin-Modern" className="skin preview-container">
                <div id="CentionFAQ">
                    <div id="CentionFAQContainer" style={{backgroundColor: getConfig("mainBgColor",white)}}>
                        <div id="CentionFAQHeader" style={{
                            backgroundColor: getConfig("headerBgColor",yellow),
                            color: getConfig("headerTextColor",black)
                        }}>
                            <div className="FAQ-HeaderWrapper">
                               <div id="BackHome" className="backHome"><i className="icon-chevron-left"></i></div>
                                <div id="HeaderInnerContent" className="title-Row">
                                    <div className="row-header">
                                        <div id="textTitle-FAQ">{getConfig("textHeaderTitle","Hello, how can I help you?")}</div>
                                    </div>
                                </div>
                                <div id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></div>
                            </div>
                        </div>
                        <div id="CentionFAQBody">
                            <div id="CentionCallbackBody" className='card-wrapper' hidden={false}>
                                <div className="inner-container">
                                    <div id="CentionCallbackContactForm">
                                        {/* TOFIX: form looks like classic skin,so should follow modern skin? */}
                                        <div id="agentAvatarList" className="agentListChat">
                                            <div className="agentList">
                                                <p className="agentStatus">
                                                    <span className="logged-in">● <span id="textAvailableNote" className="availableNote">{getConfig("textAvailableNote", "We're available!")}</span></span>
                                                </p>
                                                <div className="agentAvatar" hidden={getConfig("adminFaqHideAgentAvatarList", false)}>
                                                    <img src={agent.photo} alt="Avatar" className="avatar"
                                                    ref={avatarRef}
                                                    onError={
                                                        () => avatarRef.current.src = DEFAULT_AGENT_AVATAR
                                                    }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <input id="callbackInputName" type="text" placeholder={getConfig("textCallbackInputName",I("Name"))} />
                                        <input id="callbackInputEmail" type="text" placeholder={getConfig("textCallbackInputEmail",I("Email"))}/>
                                        <input id="callbackInputPhone" type="text" placeholder={getConfig("textCallbackInputPhone",I("Phone"))} />
                                        <textarea id="callbackInputQuestion" placeholder={getConfig("textCallbackInputQuestion",I("Question"))} />
                                        <div id="callbackFormInfo"></div>
                                        <div id="EULAAcceptance">
                                            <input type="checkbox" id="askEULAInput" style={{display: "block", width: "auto"}}/>
                                            <label htmlFor="askEULAInput" id="textEULA">
                                                {getConfig("textEULAFAQ", 'I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.')}
                                            </label>
                                        </div>
                                        <button id="callbackButtonSubmit" type="button">
                                            <i className="icon-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-container right-align">
                    <div id="CentionFAQPanelTrigger" className="bottom-right" style={{
                        backgroundColor: getConfig("triggerPanelBgColor", yellow), marginLeft: "276px"}}>
                        <span id="trigger-panel-close" className="bottom-icon icon-times" style={{color : getConfig("triggerPanelTextColor", white)}} ></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetFrictionlessFrame1 = ({onGetConfig: getConfig}) => {
    const gradientHeaderBg = "linear-gradient(0deg, rgba(255,255,255,1) 0%, "+ getConfig("headerBgColor") +" 62%)";
    const customBg = {
        background: gradientHeaderBg,
        color: getConfig("headerTextColor")
    }
    return (
        <div id="CentionFAQ" className="widget-wrapper">
            <div id="Skin-Frictionless" className="skin preview-container skin-container">
                <div id="CentionFAQContainer" className={getConfig("adminFaqEnableCallbackRequest") ? "with-callback" : ""}>
                    <div id="CentionFAQHeader" style={customBg}>
                        <div className="FAQ-HeaderWrapper">
                            <header className="frictionless-header">
                                <div id="FAQ-HomeLogo">
                                    <img src={process.env.CLOUDFRONT_URL + "/chat/images/cention-mini-o-white.svg"} alt="Logo" />
                                </div>
                                <div className="contactHeader" style={{ display: 'none' }}>
                                    <span title="Back to home page" id="contactBackHeader" className="icon-knowledgebase"></span>
                                    <span className="contact-header-text" id="FAQButton3">Talk to Us</span>
                                </div>
                                <div id="agentAvatarList" className="agentListChat" style={{ display: getConfig("adminFaqHideAgentAvatarList") ? 'none' : 'block' }}>
                                    <div className="agentAvatar agentChat">
                                        <img src={process.env.CLOUDFRONT_URL + "/img/avatar-1.png"} className="avatar" alt="User 1" />
                                        <img src={process.env.CLOUDFRONT_URL + "/img/avatar-2.png"} className="avatar" alt="User 2" />
                                        <img src={process.env.CLOUDFRONT_URL + "/img/avatar-3.png"} className="avatar" alt="User 3" />
                                    </div>
                                </div>
                                <div id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></div>
                            </header>
                            <div className="title-Row">
                                <div className="title-Col">
                                    <h2 id="textTitle-FAQ">{getConfig("textHeaderTitle")}</h2>
                                </div>
                                <div id="BrowseContact" className="card-body">
                                    <div className="centionCol card-icon"><i className="icon-chat"></i></div>
                                    <div className="centionCol card-content">
                                        <div id="ContactChatTitle">{getConfig("textContactChatTitle")}</div>
                                        <div id="ContactChatLine1">{getConfig("textContactChatLine1")}</div>
                                        <div id="ContactChatLine2">{getConfig("textContactChatLine2")}</div>
                                    </div>
                                </div>
                                <div id="CallbackWrapper" className="card-body" style={{ display: getConfig("adminFaqEnableCallbackRequest") ? 'flex' : 'none' }}>
                                    <div className="centionCol card-icon"><i className="icon-call"></i></div>
                                    <div className="centionCol card-content">
                                        <div id="CallbackRequestTitle">
                                            {getConfig("textCallbackTitle")}
                                        </div>
                                        <div id="CallbackRequestWorkingHours">
                                            {getConfig("textCallbackWorkingHours")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="CentionFAQBody">
                        <div id="FAQStandardContainer">
                            <div id="ViewPage-Home" className="FAQ-SectionWrapper">
                                <div id="CentionFAQHome-search" className="title-Col">
                                    <div className="CentionFAQSearchWrapper2 search-section" style={{backgroundColor: getConfig("searchBarBgColor")}}>
                                        <label title="Search" id="FAQ-search-icon" htmlFor="FAQ-search" ><i className="icon-search"></i></label>
                                        <input id="FAQ-search-input" name="FAQ-search-input" type="search" style={{color: getConfig("searchBarTextColor")}}
                                            placeholder={getConfig("textInputSearch","Label for search input")} />
                                        <label title="Clear Search" id="FAQ-search-close" htmlFor="FAQ-search" style={{color: getConfig("searchBarTextColor")}} >
                                            <i className="icon-close"></i>
                                        </label>
                                    </div>
                                </div>
                                <div id="CentionFAQFeatured" className="CentionFAQQuestionsWrapper">
                                    <div id="CentionFAQFeaturedListDiv">
                                        <ul id="CentionFAQFeaturedList" className="FAQ-list">
                                            <li id="8" className="FAQ-list-questions" style={{ color: getConfig("questionListTextColor", black) }}>
                                                <div>{I("What do the different delivery methods cost?")}</div>
                                            </li>
                                            <li id="13" className="FAQ-list-questions" style={{ color: getConfig("questionListTextColor", black) }}>
                                                <div>{I("Do you accept American Express card?")}</div>
                                            </li>
                                            <li id="12" className="FAQ-list-questions" style={{ color: getConfig("questionListTextColor", black) }}>
                                                <div>{I("What is your opening hours?")}</div>
                                            </li>
                                            <li id="19" className="FAQ-list-questions" style={{ color: getConfig("questionListTextColor", black) }}>
                                                <div>{I("What is your Privacy Policy?")}</div>
                                            </li>
                                            <li id="16" className="FAQ-list-questions" style={{ color: getConfig("questionListTextColor", black) }}>
                                                <div>{I("How does it work?")}</div>
                                            </li>
                                            <li id="9" className="FAQ-list-questions" style={{ color: getConfig("questionListTextColor", black) }}>
                                                <div>{I("Help me to reset my password")}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FAQFooterContainer footer">
                        <div id="tabFeatured" className="menuList">
                            <div className="icons-wrapper row nav-icons">
                                <div className="column nav-icon">
                                    <span title="Faq list" id="iconFeatured" className="tab-icon icon-blur"></span>
                                    <p className="FAQ-Text" id="FAQButtonHome">{getConfig("textFAQButton1","Home")}</p>
                                </div>
                                <div className="column nav-icon">
                                    <span title="Contact page" id="iconContact" className="tab-icon icon-chat"></span>
                                    <p className="FAQ-Text" id="FAQButton3">{getConfig("textFAQButton3","Chat")}</p>
                                </div>
                                <div id="tabCallbackWrapper" className="column nav-icon" hidden={!getConfig("adminFaqEnableCallbackRequest")}>
                                    <span title="Contact page" id="iconContact" className="tab-icon icon-call"></span>
                                    <p className="FAQ-Text" id="FAQButtonCallback">{getConfig("textContactNumTitle", "Callback")}</p>
                                </div>
                                <div className="column nav-icon">
                                    <span title="Library list" id="iconBrowse" className="tab-icon icon-knowledgebase"></span>
                                    <p className="FAQ-Text" id="FAQButton2">{getConfig("textFAQButton2","Browse")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
			<div id="CentionFAQFooterLogo">
				<a href="#" target="_blank"></a>
			</div>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetFrictionlessFrame2 = ({onGetConfig: getConfig}) => {
    const solidHeaderBgColor = getConfig("headerBgColor");
    const customBg = {
        background: solidHeaderBgColor,
        color: getConfig("headerTextColor")
    }
    return (
        <div id="CentionFAQ" className="widget-wrapper">
            <div id="Skin-Frictionless" className="skin preview-container skin-container faq-browse">
                <div id="CentionFAQContainer">
                    <div id="CentionFAQHeader" className="header-minimized" style={customBg}>
                        <div className="FAQ-HeaderWrapper">
                            <header className="frictionless-header">
                                <div id="FAQ-HomeLogo">
                                    <img src={process.env.CLOUDFRONT_URL + "/chat/images/cention-mini-o-white.svg"} alt="Logo" />
                                </div>
                                <div id="FAQHeaderPath" className="contactHeader" style={{display: 'flex', color: getConfig("headerTextColor")}}>
                                    <span title="Back to home page" id="contactBackHeader" className="icon-chevron-left"></span>
                                    <span id="FAQHeaderPathIcon"><i className="icon-knowledgebase"></i></span>
                                    <span className="contact-header-text" id="FAQButton2">{getConfig("textFAQButton2","Browse")}</span>
                                </div>
                            </header>
                        </div>
                        <div id="CentionFAQHeader-search" className="title-Col">
                            <div className="CentionFAQSearchWrapper2 search-section">
                                <label title="Search" id="FAQ-search-icon" htmlFor="FAQ-search" ><i className="icon-search"></i></label>
                                <input id="FAQ-search-input" name="FAQ-search-input" type="search" placeholder={getConfig("textInputSearch","Label for search input")}/>
                                <label title="Clear Search" id="FAQ-search-close" htmlFor="FAQ-search" ><i className="icon-close"></i></label>
                            </div>
                        </div>
                    </div>
                    <div id="CentionFAQBody">
                        <div id="FAQStandardContainer">
                            <div id="ViewPage-Home" className="FAQ-SectionWrapper">
                                <div id="CentionFAQFeatured" className="CentionFAQQuestionsWrapper">
                                 <div id="textCategoryHeading" className="category-heading" style={{display: 'block'}}>{getConfig("textCategoryHeading")}</div>
                                    <ul id="CentionFAQBrowseKnowledgebase" className="FAQ-list" style={{display: 'block'}}>
                                        <li id="1" className="FAQ-list-questions" style={{
                                            color: getConfig("libraryListTextColor", black),
                                            borderRadius: "0px"
                                        }}>
                                            <div className="library-text">Pricing Plans</div>
                                        </li>
                                        <li id="2" className="FAQ-list-questions" style={{
                                            color: getConfig("libraryListTextColor", black)
                                        }}>
                                            <div className="library-text">Product Tours</div>
                                        </li>
                                        <li id="3" className="FAQ-list-questions" style={{
                                            color: getConfig("libraryListTextColor", black)
                                        }}>
                                            <div className="library-text">Integrations</div>
                                        </li>
                                        <li id="4" className="FAQ-list-questions" style={{
                                            color: getConfig("libraryListTextColor", black)
                                        }}>
                                            <div className="library-text">FAQs</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FAQFooterContainer footer">
                        <div id="tabFeatured" className="menuList">
                            <div className="icons-wrapper row nav-icons">
                                <div className="column nav-icon">
                                    <span title="Faq list" id="iconFeatured" className="tab-icon icon-blur"></span>
                                    <p className="FAQ-Text" id="FAQButtonHome">{getConfig("textFAQButton1","Home")}</p>
                                </div>
                                <div className="column nav-icon">
                                    <span title="Contact page" id="iconContact" className="tab-icon icon-chat"></span>
                                    <p className="FAQ-Text" id="FAQButton3">{getConfig("textFAQButton3","Chat")}</p>
                                </div>
                                <div id="tabCallbackWrapper" className="column nav-icon" hidden={!getConfig("adminFaqEnableCallbackRequest")}>
                                    <span title="Contact page" id="iconContact" className="tab-icon icon-call"></span>
                                    <p className="FAQ-Text" id="FAQButtonCallback">{getConfig("textContactNumTitle", "Callback")}</p>
                                </div>
                                <div className="column nav-icon">
                                    <span title="Library list" id="iconBrowse" className="tab-icon icon-knowledgebase"></span>
                                    <p className="FAQ-Text" id="FAQButton2">{getConfig("textFAQButton2","Browse")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
			<div id="CentionFAQFooterLogo">
				<a href="#" target="_blank"></a>
			</div>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetFrictionlessFrame3 = ({onGetConfig: getConfig}) => {
    const solidHeaderBgColor = getConfig("headerBgColor");
    const customBg = {
        background: solidHeaderBgColor,
        color: getConfig("headerTextColor")
    }
    return (
        <div id="CentionFAQ" className="widget-wrapper">
            <div id="Skin-Frictionless" className="skin preview-container skin-container faq-browse">
                <div id="CentionFAQContainer">
                    <div id="CentionFAQHeader" className="header-shrink" style={customBg}>
                        <div id="FAQBreadcrumbs" style={{display: 'flex', color: getConfig('headerTextColor'), gap: '5px'}}>
                            <span title="Return" id="ReturnToPrevious" className="icon-chevron-left"></span>
                            <span title="Back to home page" id="iconReturnHome" className="icon-knowledgebase"></span>
                            <ul className="breadcrumb" style={{color: getConfig("headerTextColor")}}>
                                <span> {getConfig("textFAQButton2")} </span>
                            </ul>
                            <div id="FAQResizeWrapper" style={{display: 'flex', marginLeft: 'auto'}}>
                                <span title="expand" id="iconExpandFAQ" className="icon-expand"></span>
                                <div id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></div>
                            </div>
                        </div>
                    </div>
                    <div id="CentionFAQBody">
                        <div id="FAQStandardContainer">
                            <div id="FAQBreadcrumbs" style={{margin: '10px'}}>
                                <ul className="breadcrumb frictionless-breadcrumb">
                                    <li id="11" className="FAQ-list-libraries active-breadcrumb">FAQs</li>
                                    <li id="0" className="FAQ-breadcrumb-questions active-breadcrumb">
                                        <i className="icon-chevron-right"></i>How do I log in?
                                    </li>
                                </ul>
                            </div>
                            <div id="ViewPage-Answer" className="FAQ-SectionWrapper">
                                <div id="CentionFAQAnswer" className="CentionFAQAnswerWrapper">
                                    <h3 id="currentSelectedQuestion">How do I log in?</h3>
                                    <p id="currentSelectedAnswer">
                                        <div className="content-wrapper">
                                            <br />
                                            <div id="currentSelectedAnswer">
                                                <p>Lorem ipsum dolor sit amet, adipiscing elit,
                                                    sed do eiusmod aliqua.</p>
                                                <br />
                                                <ul style={{ padding: "0 24px" }}>
                                                    <li style={{ listStyle: "disc" }}>
                                                        <span>Ut enim ad minim veniam, quis nostrud exercitation ullamco</span>
                                                    </li>
                                                    <li style={{ listStyle: "disc" }}>
                                                        <span>Duis aute irure dolor in reprehenderit in voluptate velit esse</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </p>
                                    <p id="currentSelectedAnswerFiles"></p>
                                    <div className="CentionFAQFeedback">
                                        <h4 className="textFeedbackHeading">Did you get the answer to your question?</h4>
                                        <div className="FeedbackUpvote FAQ-Button-feedback">
                                            <img src={process.env.CLOUDFRONT_URL + "/chat/images/c3-faq-upvote-emoji.png"} title={getConfig("textBtnUpvote")}></img>
                                        </div>
                                        <div className="FeedbackDownvote FAQ-Button-feedback">
                                            <img src={process.env.CLOUDFRONT_URL + "/chat/images/c3-faq-downvote-emoji.png"} title={getConfig("textBtnDownvote")}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FAQFooterContainer footer">
                        <div id="tabFeatured" className="menuList">
                            <div className="icons-wrapper row nav-icons">
                                <div className="column nav-icon">
                                    <span title="Faq list" id="iconFeatured" className="tab-icon icon-blur"></span>
                                    <p className="FAQ-Text" id="FAQButtonHome">{getConfig("textFAQButton1","Home")}</p>
                                </div>
                                <div className="column nav-icon">
                                    <span title="Contact page" id="iconContact" className="tab-icon icon-chat"></span>
                                    <p className="FAQ-Text" id="FAQButton3">{getConfig("textFAQButton3","Chat")}</p>
                                </div>
                                <div id="tabCallbackWrapper" className="column nav-icon" hidden={!getConfig("adminFaqEnableCallbackRequest")}>
                                    <span title="Contact page" id="iconContact" className="tab-icon icon-call"></span>
                                    <p className="FAQ-Text" id="FAQButtonCallback">{getConfig("textContactNumTitle", "Callback")}</p>
                                </div>
                                <div className="column nav-icon">
                                    <span title="Library list" id="iconBrowse" className="tab-icon icon-knowledgebase"></span>
                                    <p className="FAQ-Text" id="FAQButton2">{getConfig("textFAQButton2","Browse")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
			<div id="CentionFAQFooterLogo">
				<a href="#" target="_blank"></a>
			</div>
                </div>
            </div>
        </div>
    )
}

const FAQWidgetFrictionlessFrame4 = ({onGetConfig: getConfig}) => {
    const solidHeaderBgColor = getConfig("headerBgColor");
    const customBg = {
        background: solidHeaderBgColor,
        color: getConfig("headerTextColor")
    }
    return (
        <div id="CentionFAQ" className="widget-wrapper">
            <div id="Skin-Frictionless" className="skin preview-container skin-container faq-browse">
                <div id="CentionFAQContainer">
                    <div id="CentionFAQHeader" className="header-shrink" style={customBg}>
                        <div id="FAQBreadcrumbs" style={{display: 'flex', gap: '5px'}}>
                            <span title="Return" id="ReturnToPrevious" className="icon-chevron-left"></span>
                            <span title="" id="iconReturnHome" className="icon-call" style={{pointerEvent: 'none'}}></span>
                            <ul className="breadcrumb" style={{ color: getConfig("headerTextColor") }}>
                                <span> {getConfig("textFAQButton3")} </span>
                            </ul>
                            <div id="iconMinimize" className="icon" style={{marginLeft: 'auto'}}><i className="icon-minimize-window"></i></div>
                        </div>
                    </div>
                    <div id="CentionFAQBody">
                        <div id="FAQStandardContainer">
                            <div id="ViewPage-Contact" className="FAQ-SectionWrapper">
                                <div className="CentionFAQContactWrapper">
                                    <ul className="FAQ-list">
                                        <li id="ContactLine">
                                            <div className="FAQ-icon-contact"><i className="icon-call"></i></div>
                                            <div id="ContactInfoWrapper">
                                                <div id="ContactNumTitle" className="FAQ-contact-title">Contact Us</div>
                                                <div id="ContactNumDetails">
                                                    <div id="ContactNumLine1">{getConfig("textContactNumLine1","tel-no +60 12345789")}</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li id="ContactHours">
                                            <div className="FAQ-icon-contact"><i className="icon-clock"></i></div>
                                            <div id="ContactInfoWrapper">
                                                <div id="ContactHoursTitle" className="FAQ-contact-title">Operating Hours</div>
                                                <div id="ContactHoursDetails">
                                                    <div id="ContactHoursLine1">{getConfig("textContactHoursLine1","Monday-Friday: 08:00-22:00")}</div>
                                                    <div id="ContactHoursLine2">{getConfig("textContactHoursLine2","Saturday-Sunday: 10:00-17:00")}</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li id="ContactChat">
                                            <div className="FAQ-icon-contact"><i className="icon-chats"></i></div>
                                            <div id="ContactInfoWrapper">
                                                <div id="ContactChatTitle" className="FAQ-contact-title"><div id="ContactChatTitle">{getConfig("textContactChatTitle")}</div></div>
                                                <div id="ContactChatIcon">
                                                    <span title="Open Chat" id="FAQChatLauncher" className="contact-chat-icon icon-sent buttonLaunchChat"
                                                        style={{backgroundColor: getConfig("btnLaunchChatBgColor"), color: getConfig("btnLaunchChatTextColor")}}
                                                    ></span>
                                                </div>
                                                <div id="ContactChatDetails">
                                                     <div id="ContactChatLine1">{getConfig("textContactChatLine1")}</div>
                                                    <div id="ContactChatLine2">{getConfig("textContactChatLine2")}</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li id="ContactEmail">
                                            <div className="FAQ-icon-contact"><i className="icon-mail"></i></div>
                                            <div id="ContactInfoWrapper">
                                                <div id="ContactEmailTitle" className="FAQ-contact-title">{getConfig("textContactEmailTitle","Email us")}</div>
                                                <div id="ContactEmailDetails">
                                                    <a id="ContactEmailLine1">{getConfig("textContactEmailLine1","info@cention.com")}</a>
                                                    <div id="ContactEmailLine2">{getConfig("textContactEmailLine2","We will get to you as soon as we can in 24 hours")}</div>
                                                    <div id="ContactEmailLine3">{getConfig("textContactEmailLine3", "")}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FAQFooterContainer footer">
                        <div id="tabFeatured" className="menuList">
                            <div className="icons-wrapper row nav-icons">
                                <div className="column nav-icon">
                                    <span title="Faq list" id="iconFeatured" className="tab-icon icon-blur" style={{color: getConfig("tabIconTextColor")}}></span>
                                    <p className="FAQ-Text" id="FAQButtonHome">{getConfig("textFAQButton1","Home")}</p>
                                </div>
                                <div className="column nav-icon">
                                    <span title="Contact page" id="iconContact" className="tab-icon icon-chat" style={{color: getConfig("tabIconTextColor")}}></span>
                                    <p className="FAQ-Text" id="FAQButton3">{getConfig("textFAQButton3","Chat")}</p>
                                </div>
                                <div id="tabCallbackWrapper" className="column nav-icon" hidden={!getConfig("adminFaqEnableCallbackRequest")}>
                                    <span title="Contact page" id="iconContact" className="tab-icon icon-call" style={{color: getConfig("tabIconTextColor")}}></span>
                                    <p className="FAQ-Text" id="FAQButtonCallback">{getConfig("textContactNumTitle", "Callback")}</p>
                                </div>
                                <div className="column nav-icon">
                                    <span title="Library list" id="iconBrowse" className="tab-icon icon-knowledgebase" style={{color: getConfig("tabIconTextColor")}}></span>
                                    <p className="FAQ-Text" id="FAQButton2">{getConfig("textFAQButton2","Browse")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
			<div id="CentionFAQFooterLogo">
				<a href="#" target="_blank"></a>
			</div>
                </div>
            </div>
        </div>
    )
}

const CallbackWidgetModernFrame1 = ({
    onGetConfig: getConfig
    , showEULA
    , baseURL
}) => {
    return (
        <div className="widget-wrapper">
            <div id="Skin-Modern" className="skin preview-container">
                <div id="CentionCallbackHeader" className="bottomRight maximized" style={{
                    display: "flex",
                    width: "334px",
                    backgroundColor: getConfig("headerColor", gray),
                    color: getConfig("headerTextColor", black)
                }}>
                    <span id="iconLogo" className="icon">
                        <i className="icon-call"></i>
                    </span>
                    <span id="textTitle" className="text">
                        {getConfig("textTitle", "Request a call back")}
                    </span>
                    <span id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></span>
                </div>
                <div id="CentionCallbackBody" style={{ backgroundColor: getConfig("mainColor", gray) }}>
                    <div className="inner-container">
                        <div id="CentionCallbackContactForm">
                            <input id="textInputName" type="text" placeholder={getConfig("textInputName", "Name") + "*"} />
                            <input id="textInputEmail" type="text" placeholder={getConfig("textInputEmail", "Email") + "*"} />
                            <input id="textInputPhone" type="text" placeholder={getConfig("textInputPhone", "Phone") + "*"} />
                            <textarea id="contactInputQuestion" placeholder={getConfig("textInputQuestion", "Question") + "*"}></textarea>
                            {showEULA &&
                                <div id="EULAAcceptance">
                                    <input type="checkbox" id="askEULA" style={{ display: 'none' }} />
                                    <label id="textEULAVoice" style={{ color: getConfig("askEULATextColor", black), display: 'block', width: '90%' }}>
                                        {getConfig("textEULAVoice", 'I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.')}
                                    </label>
                                </div>
                            }
                            <div id="contactFormInfo"></div>
                            <button id="contactButtonSubmit" type="button">
                                <i className="icon-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    <div id="CentionWidgetFooter" hidden={features['hide-cention-logo']}>
                        <a href="#" target="_blank" className="fixRight" style={{ color: getConfig("footerTextColor", black) }}>
                            {"Powered by "}
                            <img src={process.env.CLOUDFRONT_URL + "/img/cention-logo-black_smallest.png"}></img>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CallbackWidgetModernFrame2 = ({
    onGetConfig: getConfig
    , showEULA
    , baseURL
}) => {
    return (
        <div className="widget-wrapper">
            <div id="Skin-Modern" className="skin preview-container">
                <div id="CentionCallbackHeader" className="bottomRight maximized" style={{
                    display: "flex",
                    width: "334px",
                    backgroundColor: getConfig("headerColor", gray),
                    color: getConfig("headerTextColor", black)
                }}>
                    <span id="iconLogo" className="icon">
                        <i className="icon-call"></i>
                    </span>
                    <span id="textTitle" className="text">
                        {getConfig("textTitle", "Request a call back")}
                    </span>
                    <span id="iconMinimize" className="icon"><i className="icon-minimize-window"></i></span>
                </div>
                <div id="CentionCallbackBody" style={{ backgroundColor: getConfig("mainColor", gray) }}>
                    <div className="inner-container">
                        <div id="CentionCallbackView">
                            <div id="CentionCallbackList">
                                <div className="message">
                                    <div className="message-content">
                                        <div className="text">
                                            <span className="textMsg" id="CentionMsgToCall">
                                                {getConfig("textMsgToCall", "When to call")}
                                            </span>
                                            <div className="message-date-input">
                                                <input type="text" id="dateTimePicker" />
                                                <i className="icon-chevron-down"> </i>
                                            </div>
                                            <div className="message-date-btns">
                                                <input type="button" className="message-sent" id="CentionMsgSend" value={getConfig("textBtnSend", "Send request")}></input>
                                                <input type="button" className="message-cancel" id="CentionMsgCancel" value={getConfig("textBtnCancel", "Cancel")}></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="message-avatar"><i className="icon-user-circle"></i></div>
                                </div>
                                <div className="message">
                                    <div className="message-content">
                                        <div className="text">
                                            <span className="textMsg">{getConfig("textMsgSendSuccess", "You have requested a callback through phone number at {SCHEDULE_TIME}, we will reach you at the appointed time.")}</span>
                                        </div>
                                    </div>
                                    <div className="message-avatar"><i className="icon-user-circle"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="CentionWidgetFooter" hidden={features['hide-cention-logo']}>
                        <a href="#" target="_blank" className="fixRight" style={{ color: getConfig("footerTextColor", black) }}>
                            {"Powered by "}
                            <img src={process.env.CLOUDFRONT_URL + "/img/cention-logo-black_smallest.png"}></img>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default class WidgetPreview extends PureComponent {
    constructor(props) {
        super(props);
    }

    getConfig = (config, defaultConfig) => {
		let input = this.props.input;
        //Showing default config for each if there's no custom config specified
		return input.customize ? (input[config] ? input[config] : defaultConfig) : defaultConfig;
	}

    render() {
        const {
            view
            , input
            , agent
            , previewType
        } = this.props
        ;

        const skinSetDefault = [
            {
                page: 1,
                view: <CWidgetDefaultFrame1
                        showEULA={input.customize && input.adminChatAskEULA}
                        baseURL={input.baseURL}
                        agent={agent}
                        onGetConfig={this.getConfig}/>
            },
            {
                page: 2,
                view: <CWidgetDefaultFrame2
                        baseURL={input.baseURL}
                        onGetConfig={this.getConfig}/>
            },
            {
                page: 3,
                view: <CWidgetDefaultFrame3
                        onGetConfig={this.getConfig}/>
            },
            {
                page: 4,
                view: <CWidgetProactiveDefaultFrame
                        onGetConfig={this.getConfig} />
            }
        ];
        const skinSetModern = [
            {
                page: 1,
                view: <CWidgetModernFrame1
                        showEULA={input.customize && input.adminChatAskEULA}
                        baseURL={input.baseURL}
                        agent={agent}
                        onGetConfig={this.getConfig} />
            },
            {
                page: 2,
                view: <CWidgetModernFrame2
                        baseURL={input.baseURL}
                        onGetConfig={this.getConfig} />
            },
            {
                page: 3,
                view: <CWidgetModernFrame3
                        onGetConfig={this.getConfig} />
            },
            {
                page: 4,
                view: <CWidgetProactiveFrame
                        onGetConfig={this.getConfig} />
            }
        ];

        const skinSetFrictionless = [
            {
                page: 1,
                view: <CWidgetFrictionlessFrame1
                        showEULA={input.customize && input.adminChatAskEULA}
                        baseURL={input.baseURL}
                        agent={agent}
                        onGetConfig={this.getConfig}/>
            },
            {
                page: 2,
                view: <CWidgetFrictionlessFrame2
                        baseURL={input.baseURL}
                        agent={agent}
                        onGetConfig={this.getConfig}/>
            },
            {
                page: 3,
                view: <CWidgetFrictionlessFrame3
                        agent={agent}
                        onGetConfig={this.getConfig}/>
            },
            {
                page: 4,
                view: <CWidgetProactiveFrame
                        onGetConfig={this.getConfig} />
            }
        ];

        const skinSetFAQDefault = [
            {
                page: 1,
                view: <FAQWidgetDefaultFrame1 onGetConfig={this.getConfig} agent={agent}/>
            },
            {
                page: 2,
                view: <FAQWidgetDefaultFrame2 onGetConfig={this.getConfig}/>
            },
            {
                page: 3,
                view: <FAQWidgetDefaultFrame3 onGetConfig={this.getConfig}/>
            },
            {
                page: 4,
                view: <FAQWidgetDefaultFrame4 onGetConfig={this.getConfig}/>
            }
        ];

        if(this.getConfig("adminFaqEnableCallbackRequest", false)) {
            skinSetFAQDefault.push(
                {
                    page: 5,
                    view: <FAQWidgetDefaultFrame5 onGetConfig={this.getConfig} agent={agent}/>
                }
            )
        }

        const skinSetFAQModern = [
            {
                page: 1,
                view: <FAQWidgetModernFrame1 onGetConfig={this.getConfig}/>
            },
            {
                page: 2,
                view: <FAQWidgetModernFrame2 onGetConfig={this.getConfig}/>
            },
            {
                page: 3,
                view: <FAQWidgetModernFrame3 onGetConfig={this.getConfig}/>
            },
            {
                page: 4,
                view: <FAQWidgetModernFrame4 onGetConfig={this.getConfig}/>
            }
        ];

        const skinSetFAQFrictionless = [
            {
                page: 1,
                view: <FAQWidgetFrictionlessFrame1 onGetConfig={this.getConfig}/>
            },
            {
                page: 2,
                view: <FAQWidgetFrictionlessFrame2 onGetConfig={this.getConfig}/>
            },
            {
                page: 3,
                view: <FAQWidgetFrictionlessFrame3 onGetConfig={this.getConfig}/>
            },
            {
                page: 4,
                view: <FAQWidgetFrictionlessFrame4 onGetConfig={this.getConfig}/>
            }
        ];

        if(this.getConfig("adminFaqEnableCallbackRequest", false)) {
            skinSetFAQModern.push(
                {
                    page: 5,
                    view: <FAQWidgetModernFrame5 onGetConfig={this.getConfig} agent={agent}/>
                }
            )
        }

        const skinSetCallbackModern = [
            {
                page: 1,
                view: <CallbackWidgetModernFrame1 onGetConfig={this.getConfig} baseURL={input.baseURL} showEULA={input.customize && input.askEULA} />
            },
            {
                page: 2,
                view: <CallbackWidgetModernFrame2 onGetConfig={this.getConfig} baseURL={input.baseURL}/>
            },
        ]

        let renderedSkin = skinSetDefault;
        if(input.skin == "modern"){
            renderedSkin = skinSetModern;
        } else if(input.skin == "frictionless") {
            renderedSkin = skinSetFrictionless;
        }
        if(previewType == "faq") {
            if(input.skin == "modern"){
                renderedSkin = skinSetFAQModern;
            } else if(input.skin == "frictionless") {
                renderedSkin = skinSetFAQFrictionless;
            } else {
                renderedSkin = skinSetFAQDefault;
            }
        } else if(previewType == "callback") {
            renderedSkin = skinSetCallbackModern;
        }

        return (
            <WidgetCarousel skinSet={renderedSkin} />
        )
    }
}
