import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';
//vendor
import 'jquery-spellchecker';
import 'ckeditor-core';
import { DEFAULT_CKEDITOR_HEIGHT_CHAT } from '../common/v5/constants';

CKEDITOR.env.isCompatible = true;

//TODO(mujibur): Keep it for unit testing
// if(process.env.NODE==0){
// 	require('jquery-spellchecker');
// 	require('ckeditor-core');
// }

//TODO: This "CkeditorNew" is a starter to refactor the ckeditor component
export class CkeditorNew extends React.Component {
	constructor(props) {
		super(props);
		let top;
		if(this.props.toolbarID) {
			top = this.props.toolbarID;
		}
		this.setDataInProgress = false; // control Ckeditor destroy properly (requestDestroy)
		this.requestDestroy = false;
	  	this.state = {
			isReadOnly: false,
			readOnlyOnUnmount: true,
			wrapImageInContainer: false,
			placeHolderText: "", // this is 'default' props mean un-affected by render
			config: {
				placeholder: this.props.placeHolderText,
				skin: 'cention',
				enterMode: CKEDITOR.ENTER_DIV,
				shiftEnterMode: CKEDITOR.ENTER_DIV,
				startupFocus: false,
				forcePasteAsPlainText: false,
				allowedContent: {
					$1:{
						elements: CKEDITOR.dtd,
						attributes: true,
						styles: true,
						classes: true
					}
				},
				disallowedContent: 'style; div(modal*); *{position*}',
				ignoreEmptyParagraph: false,
				pasteFromWordPromptCleanup: false,
				pasteFromWordRemoveFontStyles: false,
				pasteFromWordRemoveStyles: false,
				removeFormatAttributes: false,
				basicEntities: true,
				entities: true,
				entities_latin: false,
				entities_greek: false,
				entities_processNumerical: false,
				fillEmptyBlocks: function (element) {
					return true;
				},
				disableNativeSpellChecker: false,
				browserContextMenuOnCtrl: true,
				colorButton_enableMore: false,
				resize_dir: 'vertical',
				/*resize_enabled: true,*/
				fontSize_sizes:
					'8/8pt;' +
					'9/9pt;' +
					'10/10pt;' +
					'11/11pt;' +
					'12/12pt;' +
					'14/14pt;' +
					'16/16pt;' +
					'18/18pt;' +
					'20/20pt;' +
					'22/22pt;' +
					'24/24pt;' +
					'26/26pt;' +
					'28/28pt;' +
					'36/36pt;' +
					'48/48pt',
				font_names:
					'Arial/Arial, Helvetica, sans-serif;' +
					'Arial black;' +
					'Averta/Averta, Verdana, sans-serif;' +
					'Calibri/Calibri, sans-serif;' +
					'Comic Sans MS/Comic Sans MS, cursive;' +
					'Courier New/Courier New, Courier, monospace;' +
					'Georgia/Georgia, serif;' +
					'Lucida Console/Lucida Console, monospace;' +
					'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' +
					'Montserrat/Montserrat, sans-serif;' +
					'Myriad Pro/Myriad Pro, Lucida Grande, sans-serif;' +
					'Tahoma/Tahoma, Geneva, sans-serif;' +
					'Times New Roman/Times New Roman, Times, serif;' +
					'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' +
					'Verdana/Verdana, Geneva, sans-serif',
				sharedSpaces: {top},
				toolbar_Empty: [],
				toolbar_SingleRow: [
					[ 'Cention_emoji' ], [ 'SpellCheckerLanguage' ],
					[ 'SpellChecker' ], ['Library']
				],
				toolbar_OneRow: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'NumberedList', 'BulletedList' ],
					[ 'Indent', 'Outdent', 'Blockquote' ],
					[ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ],
					[ 'Link', 'Image', 'Youtube', 'HorizontalRule' ], [ 'PasteFromWord' ],
					[ 'Font', 'Cention_emoji' ], [ 'FontSize' ],
					[ 'TextColor', 'BGColor' ],
					[ 'SpellCheckerLanguage' ], [ 'SpellChecker' ], ['Library'], [ 'Resize' ],
					[ 'PasteText'], [ 'Cention_avatar' ]
				],
				toolbar_TwoRow: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'NumberedList', 'BulletedList' ],
					[ 'Indent', 'Outdent', 'Blockquote' ],
					[ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ], [ 'PasteFromWord' ],
					[ 'Link', 'Image', 'Youtube', 'HorizontalRule' ],
					[ 'Font', 'Cention_emoji' ], [ 'FontSize' ],'/',
					[ 'TextColor', 'BGColor' ],
					[ 'SpellCheckerLanguage' ], [ 'SpellChecker' ], ['Library'],  ['Resize'],
					[ 'PasteText']
				],
				toolbar: 'OneRow',
				toolbar_ChatOneRow: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'Image' ],
					[ 'Cention_emoji' ], [ 'SpellCheckerLanguage' ], [ 'SpellChecker' ]
				],
				toolbar_ChatAgent2Agent: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'Cention_emoji' ], [ 'SpellCheckerLanguage' ], [ 'SpellChecker' ]
				],
				toolbar_SimpleEditor: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'NumberedList', 'BulletedList' ],
					[ 'Indent', 'Outdent', 'Blockquote' ],
					[ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ],
					[ 'Link', 'Youtube', 'HorizontalRule' ], [ 'PasteFromWord' ],
					[ 'Font', 'Cention_emoji' ], [ 'FontSize' ],
					[ 'TextColor', 'BGColor' ],
					[ 'Resize' ],
					[ 'SpellCheckerLanguage' ], [ 'SpellChecker' ]
				],
				toolbar_TextFormattng: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'NumberedList', 'BulletedList' ],
					[ 'Indent', 'Outdent', 'Blockquote' ],
					[ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ],
					[ 'Link', 'Image', 'Youtube', 'HorizontalRule' ], [ 'PasteFromWord' ],
					[ 'Font', 'Cention_emoji' ], [ 'FontSize' ],
					[ 'TextColor', 'BGColor' ],
					['Library'], [ 'Resize' ],
					['PasteText'], [ 'Table' ]
				],
				simpleChatToobar: false,
				plugins:
					'basicstyles,' +
					'blockquote,' +
					'clipboard,' +
					'colorbutton,' +
					'contextmenu,' +
					'enterkey,' +
					'font,' +
					'horizontalrule,' +
					'htmlwriter,' +
					'indent,' +
					'justify,' +
					'link,' +
					'list,' +
					'magicline,' +
					'pastetext,' +
					/* 'removeformat,' + */
					'sharedspace,' +
					'showborders,' +
					'tab,' +
					/*'table,' + */
					/*'tabletools,' + */
					'toolbar,' +
					'undo,' +
					'wysiwygarea,' +
					/* 'cention_pasteimage,' + */
					'cention_dragimage,' +
					/*'cention_spellcheck,' + //Mujibur: adding it classified plugins, depend on props to show!*/
					'cention_image,' +
					'pastefromword,' +
					'resize',
				contentsCss: [
					process.env.CLOUDFRONT_URL + "/fonts/custom-fonts/style.css"
				],
				language: 'en',
				suggestBoxScrollBar: [],
				spellCheckLanguages: [],
				bodyId: "QA_replyEditor_"+this.props.id
			}
		};
	}
	getInstance =()=> {
		return CKEDITOR.instances[this.props.id];
	}
	handleCustomEvent =(event)=> {}
	setFontSize =( size )=>{
		if( size ){
			//this.state.config.fontSize_defaultLabel = '' + size;
			this.setState({config: {fontSize_defaultLabel: '' + size}});
			CKEDITOR.addCss('.cke_editable { font-size: '+ size +'pt; }');
		}
	}
	setFontFamily=( family )=>{
		if( family ){
			//this.state.config.font_defaultLabel = '' + family;
			this.setState({config: {font_defaultLabel: '' + family}});
			if(family === "Calibri" || family === "calibri"){
				family = "Calibri,sans-serif,Arial";
			}
			CKEDITOR.addCss('.cke_editable { font-family: '+ family +'; }');
		}
	}
	setSpellLanguages =( list )=>{
		//this.state.config.spellCheckLanguages = list;
		this.setState({config: {spellCheckLanguages: list}});
	}
	setItemHeight =( height )=>{
		if( !isNaN(height) ) {
			//this.state.config.height = height;
			this.setState({config: {height: height}});
		}
	}
	isChat=(p)=>{
		return (p.simpleChatToobar);
	}
	getToolbar=(p)=>{
		if(this.isChat(p)) {
			return 'ChatOneRow';
		} else if(p.agentGroupChatToolbar){
			return 'ChatAgent2Agent';
		} else {
			if(p.simpleEditor) {
				return 'SimpleEditor';
			} else if(p.simpleToolbar) {
				return 'SingleRow';
			} else if(p.hideSpellchecker) {
				return 'TextFormattng';
			} else {
				return 'OneRow';
			}
		}
	}
	setSimpleToolbar=()=>{
		//this.state.config.toolbar = this.getToolbar(this.props);
		this.setState({config: {toolbar: this.getToolbar(this.props)}});
		if(this.isChat(this.props)) {
			//this.state.config.plugins = this.state.config.plugins.replace(',link,', ',');
			let toolbar = this.state.config.plugins.replace(',link,', ',');
			this.setState({config: {plugins: toolbar}});
		}
	}

	setTwoRowToolbar=( value )=>{
		if( value ) {
			//this.state.config.toolbar = 'TwoRow';
			this.setState({config: {toolbar: 'TwoRow'}});
		}
	}
	showExtraPlugins=()=>{
		//this.state.config.extraPlugins = 'librarybar';
		this.setState({config: {extraPlugins: 'librarybar'}});
	}
	setLanguage =( language )=>{
		//this.state.config.language = language;
		this.setState({config: {language: language}});
	}
	addBase64Image =()=>{
		if(typeof this.state.config.extraPlugins !== 'undefined'){
			let ep = this.state.config.extraPlugins;
			ep += ',base64image,imageresize';
			this.setState({config: {extraPlugins: ep}});
		} else {
			//this.state.config.extraPlugins = 'base64image,imageresize';
			this.setState({config: {extraPlugins: 'base64image,imageresize'}});
		}
	}
	addXtraPlugin =(plugin)=>{
		if(!plugin) return;
		if(typeof this.state.config.extraPlugins !== 'undefined') {
			//this.state.config.extraPlugins += ',' + plugin;
			let ep = this.state.config.extraPlugins;
			ep += ',' + plugin;
			this.setState({config: {extraPlugins: ep}});
		} else {
			//this.state.config.extraPlugins = plugin;
			this.setState({config: {extraPlugins: plugin}});
			
		}
	}
	removePlugin =(plugin)=>{
		if(!plugin) return;
		if(typeof this.state.config.removePlugins !== 'undefined') {
			//this.state.config.removePlugins += ',' + plugin;
			let ep = this.state.config.removePlugins;
			ep += ',' + plugin;
			this.setState({config: {removePlugins: ep}});
		} else {
			//this.state.config.removePlugins = plugin;
			this.setState({config: {removePlugins: plugin}});
		}
	}
	getPlainText=()=>{
		if(this.isReady()) {
			return this.state.editor.document.getBody().getText();
		}
		return "";
	}
	getHtml=()=>{
		if(this.isReady()) {
			return this.state.editor.document.getBody().getHtml();
		}
		return "";
	}
	getData=()=>{
		if(this.isReady()) {
			return this.state.editor.getData();
		}
		return "";
	}
	rememberLastCursor =(editor)=>{
		if (!this.props.control) {
			return;
		}
		if(editor){
			const select = editor.getSelection();
			if (select) {
				const ranges = select.getRanges();
				if (ranges[0]) {
					const {
						startContainer
						, endContainer
						, startOffset
						, endOffset
						} = ranges[0]
						, startAddress = startContainer.getAddress()
						, endAddress = endContainer.getAddress()
						;
					this.selectionRanges = {
						startAddress
						, endAddress
						, startOffset
						, endOffset
					};
					console.log("dbg: range data:", this.selectionRanges);
				}
			}
		}
	}
	retrieveLastCursor =(editor)=>{
		if (!this.props.control) {
			return;
		}
		const { selectionRanges } = this;
		if (selectionRanges) {
			const range = editor.createRange()
				, {
				startAddress
				, endAddress
				, startOffset
				, endOffset
				} = selectionRanges
				, top = editor.document.getDocumentElement()
				;
			let startContainer = top.getChild(startAddress)
				, endContainer = top.getChild(endAddress)
				;
			if (startContainer || endContainer) {
				if (!startContainer) {
					startContainer = endContainer;
				} else if (!endContainer) {
					endContainer = startContainer;
				}
				range.setStart(startContainer, startOffset);
				range.setEnd(endContainer, endOffset);
				try {
					range.select();
				} catch (e) {
					console.log && console.log("dbg: catched select range:", e);
				}
			}
			this.selectionRanges = null;
		}
	}

	setData=(msg)=>{
		var editor = CKEDITOR.instances[this.props.id];
		if (this.isReady()) {
			if (this.setDataInProgress) {
				this.requestSetData = true;
				this.requestSetDataData = msg;
				return;
			}
			if (editor && editor.readOnly) {
				return;
			}
			this.setDataInProgress = true;
			this.settingData = msg;
			this.rememberLastCursor(editor);
			//window.setTimeout(function() {
				this.state.editor.setData(msg, {
					callback: function() {
						this.state.editor.updateElement();
						this.setDataInProgress = false;
						if(!this.props.startupFocus){
							this.retrieveLastCursor(this.state.editor);
						}
						if(this.requestDestroy) {
							this.requestSetReadOnly = false;
							this.destroyThenRecreate();
						} else {
							if(this.requestSetReadOnly) {
								this.setReadOnly(this.requestSetReadOnlyData);
							}
							if(this.requestSetData) { // must be last
								this.requestSetData = false;
								this.setData(this.requestSetDataData);
							}
						}
					}.bind(this)
				});
			//}.bind(this), 0 /* This is not a typo. Workaround for bug in IE when setting data to quickly */);
		} else {
			this.requestSetData = true;
			this.requestSetDataData = msg;
		}
	}
	appendImage=(msg)=>{
		if(this.isReady()) {
			if(this.state.editor.document.getBody().getText().lastIndexOf( this.props.placeHolderText,0) == 0){
				this.setData(msg);
			} else {
				this.state.editor.insertHtml(msg);
				//this.setState({editor: msg});
			}
		}
	}
	updateTxt =(txt)=>{
		if(this.isReady() && !this.requestDestroy) {
			this.setData(txt);
		} else {
			this.requestSetData = true;
			this.requestSetDataData = txt;
		}
	}
	destroyThenRecreate =()=>{
		if (!this.setDataInProgress) {
			if (this.state.editorIsReady) {
				let editor = this.state.editor;
				editor.destroy();
			}
			this.setState({editor: null, editorIsReady: false}, function() {
				// recreate Ckeditor editor after confirm it is destroyed
				clearTimeout(this.pid);
				this.replaceCkeditor(0);
				this.requestDestroy = false;
			}.bind(this));
		}
	}
	reload=()=>{
		if(!this.requestDestroy && this.isReady()) {
			this.requestDestroy = true;
			this.destroyThenRecreate();
		}
	}
	isReady=()=>{
		return (
			typeof this.state.editor !== 'undefined' &&
			this.state.editor != null &&
			!$.isEmptyObject(this.state.editor) &&
			this.state.editorIsReady
		);
	}
	removeImage =(id) =>{
		if(this.isReady()) {
			$( this.state.editor.editable().$ ).find(id).remove();
		}
	}
	onFocus =(state)=>{
		if(this.props.onFocus){
			this.props.onFocus();
		}
	}
	componentDidMount =()=>{
		window.CKEDITOR_BASEPATH = location.origin + '/ng' + "/vendor/ckeditor/";
		if(typeof this.props.fileArchiveImages !== "undefined") {
			this.imgs = this.props.fileArchiveImages;
		} else {
			this.imgs = null;
		}
		if(typeof this.props.defaultContent !== "undefined") {
			this.txt = this.props.defaultContent;
		} else {
			this.txt = "";
		}
		if(this.props.avatarTemplate) {
				this.avatarTemplate = process.env.CLOUDFRONT_URL + "/img/cention-custom-avatar-template-image-should-not-use-for-another-purpose.svg";
		} else {
			this.avatarTemplate = null;
		}
		this.replaceCkeditor(100);
	}
	setReadOnly=(readOnly)=>{
		if (this.isReady()) {
			if (this.setDataInProgress) {
				this.requestSetReadOnly = true;
				this.requestSetReadOnlyData = readOnly;
				return;
			}
			this.requestSetReadOnly = false;
			var ckeditorInstances = CKEDITOR.instances[this.props.id];
			if(typeof ckeditorInstances !== "undefined" && ckeditorInstances !== null)ckeditorInstances.setReadOnly(readOnly);
		}
	}
	flagChangesNotFromProp =()=>{
		if(this.props.control && this.directMutate) {
			this.directMutate = undefined;
		}
	}

	handleChange =(e)=>{
		const txt = e.editor.getData();
		if(!this.props.control || txt !== this.props.defaultContent) {
			this.txt = txt;
			this.props.onChange(e, this.directMutate);
			this.flagChangesNotFromProp();
		}
	}
	handleBlur=(e)=>{
		this.handleChange(e);
		if (typeof this.props.onBlur === 'function') {
			this.props.onBlur(e);
		}
	}
	handleDirectMutate=(e)=>{
		this.flagChangesNotFromProp();
	}
	onDoneResizing =(size)=>{
		if(this.props.onRecordEditorSize){
			this.props.onRecordEditorSize(size);
		}
	}
	replaceCkeditor=(t)=>{
		this.pid = setTimeout(function() {
			(typeof currentInterface !== 'undefined' &&
				currentInterface != "" ? this.setLanguage( currentInterface ) : this.setLanguage('en'));
			this.setSpellLanguages(this.props.spellLanguages);
			this.setFontFamily((this.props.defaultFontFamily != undefined ? this.props.defaultFontFamily : 'Verdana'));
			this.setFontSize((this.props.defaultFontSize != undefined ? this.props.defaultFontSize : 12));
			this.state.config.resize_enabled = this.props.showResize;
			if(this.props.showResize) {
				if(this.props.resizeMaxHeight){
					this.state.config.resize_maxHeight = this.props.resizeMaxHeight;
				}
				if(this.props.resizeMinHeight){
					this.state.config.resize_minHeight = this.props.resizeMinHeight;
				}
			}else{
				this.state.config.resize_enabled = false;
			}
			if(this.props.startupFocus) {
				this.state.config.startupFocus = true;
			}
			this.setSimpleToolbar();
			if(this.props.twoRowToolbar != "undefined")
				this.setTwoRowToolbar(this.props.twoRowToolbar);
			this.setItemHeight(this.props.height);
			if(typeof this.props.suggestBoxScrollBar !== 'undefined'){
				this.state.config.suggestBoxScrollBar =
					this.props.suggestBoxScrollBar;
			} else{
				this.state.config.suggestBoxScrollBar =
					{overflowY:false, maxHeight:"100px"}
			}
			// if(this.props.showLibrary != undefined && this.props.showLibrary){
			// 	if( this.props.libraryId != undefined && this.props.libraryId != 0)
			// 		this.showExtraPlugins();
			// }
			if(this.props.defShowYoutube) {
				if(typeof this.state.config.extraPlugins !== "undefined") {
					this.state.config.extraPlugins += ',youtube';
				} else {
					this.state.config.extraPlugins = 'youtube';
				}
			}
			if( typeof this.props.defShowSpellchk === "undefined" ){
				if(typeof this.state.config.extraPlugins !== "undefined" && this.state.config.extraPlugins !== "") {
					this.state.config.extraPlugins += ',cention_spellcheck';
				} else {
					this.state.config.extraPlugins = 'cention_spellcheck';
				}
			}
			if((typeof this.props.showBase64Image !== 'undefined') &&
				(this.props.showBase64Image == true)){
				this.addBase64Image();
			}
			this.addXtraPlugin('cention_emoji');
			if(this.props.avatarTemplate){
				this.addXtraPlugin('cention_avatar');
			}
			if(this.props.tableTools){
				this.addXtraPlugin('tableresize');
			}
			this.removePlugin('magicline');
			if(this.props.placeHolderText) {
				this.addXtraPlugin('confighelper');
			}
			if(this.props.autoGrow) {
				this.addXtraPlugin('autogrow');
				if(this.props.autoGrowMaxHeight){
					this.state.config.autoGrow_maxHeight = this.props.autoGrowMaxHeight;
				}
				if(this.props.autoGrowMinHeight){
					this.state.config.autoGrow_minHeight = this.props.autoGrowMinHeight;
				}
			}
			if(typeof this.props.setHeight !== 'undefined'){
				CKEDITOR.config.height = this.props.setHeight;
			}
			CKEDITOR.disableAutoInline = true;
			if(this.props.toolbarID) {
				// sharedspace plugin don't support resize plugin
				// this.removePlugin('resize');
				// not removing 'Resize' plugin seems no issue on using 'Sharedspace' plugin
				// issue seems only happens since CKEditor 4.5.5
			}
			if(typeof this.props.imageResize !== 'undefined'){
				CKEDITOR.config.imageResize = this.props.imageResize;
			}
			if(typeof this.props.imageMaxSize !== 'undefined'){
				CKEDITOR.config.imageMaxSize = this.props.imageMaxSize;
			}
			var editor = CKEDITOR.replace(this.props.id, this.state.config);
			var ckeditorInstance = CKEDITOR.instances[this.props.id];
			ckeditorInstance.on('destroy', () => {
				if (process.env.NODE_ENV !== 'production') {
					console.log("dbg: ckeditor destroyed:", this.props.id);
				}
			});
			ckeditorInstance.on('instanceReady', function() {
				if (this.props.callbackParent) {
					this.props.callbackParent(true);
				}
				if (this.props.bgColor) {
					const localTheme = localStorage.getItem('theme');
					if (localTheme=="true"){
						if (this.props.bgColor == "#FFF9EC") {
							editor.document.getBody().setStyle('background-color',
							'#302A0B');
							editor.document.getBody().setStyle('color',
							'#FEF8F0');
						} else {
							editor.document.getBody().setStyle('background-color',
							'#1B1C1C');
							editor.document.getBody().setStyle('color',
							'#B9BBBD');
						}
					} else {
						editor.document.getBody().setStyle('background-color',
						this.props.bgColor);
					}
				}
				// Set default font family and size to content
				let fontFamily = 'Verdana', fontSize = 12;
				if (this.props.defaultFontFamily) {
					fontFamily = this.props.defaultFontFamily;
					if(fontFamily === "Calibri" || fontFamily === "calibri"){
						fontFamily = "Calibri,sans-serif,Arial";
					}
				}
				if (this.props.defaultFontSize) {
					fontSize = this.props.defaultFontSize;
				}
				const editorChildNode = editor.document.getBody().getChild(0);
				if (editorChildNode.$.nodeType !== 3) {
					editorChildNode.setStyle('font-family', fontFamily);
					editorChildNode.setStyle('font-size', fontSize+"pt");
				}
				this.setState({editor, editorIsReady: true}, () => {
					// editor have been renew reset single access mutexes.
					this.resetMutex();
					// avoid losing any setData request
					if (this.requestSetData) {
						this.requestSetData = false;
						this.setData(this.requestSetDataData);
					}
				});
			}.bind(this));
			editor.___fileArchiveImages = this.imgs;
			editor.___avatarTemplate = this.avatarTemplate;
			editor.wrapImageInContainer = this.props.wrapImageInContainer;
			editor.setData(this.txt);
			editor.updateElement();
			CKEDITOR.addCss('body { margin-left: 5px; margin-right: 5px; margin-top: 3px; margin-bottom: 3px; }');
			CKEDITOR.addCss('p { margin-top: 0; }');
			CKEDITOR.addCss('.spellchecker-word-highlight { color: red; cursor: pointer; border-bottom: 1px dotted red; }');
			if(localStorage.getItem('theme') == "true"){
				CKEDITOR.addCss('body {color: #B9BBBD; }');
			}
			CKEDITOR.instances[this.props.id].on("key", function( event ){
				if (this.props.block) {
					event.cancel();
					return false;
				}
				this.handleDirectMutate(event);
				this.props.onKeydown(event);
				var selection = editor.getSelection();
				if(selection){
					var selectedElement = selection.getSelectedElement();
					if(selectedElement != null) {
						var elementType = selectedElement.$.nodeName.toLowerCase();
						if(elementType == "img") {
							if(this.props.onUndoImage) {
								this.props.onUndoImage(editor,event,selectedElement);
							}
						}
					}
				}
			}.bind(this));
			CKEDITOR.instances[this.props.id].on("click",
				this.handleDirectMutate);
			CKEDITOR.instances[this.props.id].on("change", this.handleChange);
			CKEDITOR.instances[this.props.id].on("libraryClick", function( event ){
				this.props.onLibraryClick(event);
			}.bind(this));
			CKEDITOR.instances[this.props.id].on("drop", function( event ){
				if(this.props.onDragnDropFiles){
					this.props.onDragnDropFiles(event);
					if(this.props.resizeMaxHeight){
						editor.resize( '100%', this.props.resizeMaxHeight );
					}
				}
			}.bind(this));
			if(typeof this.props.onPaste !== 'undefined'){
				CKEDITOR.instances[this.props.id].on("paste", function( event ){
					this.props.onPaste(event);
				}.bind(this));
			}
			CKEDITOR.instances[this.props.id].on("blur", this.handleBlur);
			CKEDITOR.instances[this.props.id].on("focus", function( event ){
				if(this.onFocus){
					this.onFocus(true);
				}
			}.bind(this));

			//Resize
			var answerHeight = this.props.height;
			if(this.props.showResize) {
				var resizeId;
				CKEDITOR.instances[this.props.id].on("resize", function( event ){
					if(this.props.recordSize && this.props.onRecordEditorSize){
						clearTimeout(resizeId);
						answerHeight = event.editor.ui.space( 'contents' ).getStyle( 'height' );
						if(!isNaN(parseFloat(answerHeight)) == true ){
							resizeId = setTimeout(function() {
								this.onDoneResizing(parseFloat(answerHeight));
							}.bind(this), 500);
						}
					}
				}
				.bind(this));
			}
		}.bind(this), t);

	}
	componentWillUnmount =()=>{
		if (CKEDITOR.instances[this.props.id]) {
			if(typeof this.props.readOnlyOnUnmount == 'undefined' ||
				((typeof this.props.readOnlyOnUnmount !== 'undefined') &&
				this.props.readOnlyOnUnmount == true)){
				this.setReadOnly(false);
			}
			CKEDITOR.instances[this.props.id].destroy(true);
		}
		clearTimeout(this.pid);
	}
	isEmpty=(obj)=>{
		for(var key in obj) {
			if(obj.hasOwnProperty(key))
			return false;
		}
		return true;
	}
	applyStyle =(style, value)=>{
		if(this.isReady()) {
			if(this.state.editor && !this.isEmpty(this.state.editor.document.getBody())) {
				if(localStorage.getItem('theme')=="true"){
					console.log(value);
					if (value == "#EAF8FE"){
						this.state.editor.document.getBody().setStyle(style, '#0B1E26');
						this.state.editor.document.getBody().setStyle('color', '#B9BBBD');
					} else if (value == "#FFF9EC") {
						this.state.editor.document.getBody().setStyle(style, '#302A0B');
						this.state.editor.document.getBody().setStyle('color', '#FEF8F0');
					} else {
						this.state.editor.document.getBody().setStyle(style, '#1B1C1C');
						this.state.editor.document.getBody().setStyle('color', '#B9BBBD');
					}
				}
				if(localStorage.getItem('theme')=="false"){
					this.state.editor.document.getBody().setStyle(style, value);
				}
			}
		}
	}
	componentDidUpdate =() => {
		if (this.props.hide || !this.isReady()) {
			return;
		}
		let toReload = false;
		if (typeof this.props.fileArchiveImages !== "undefined"
			&& this.props.fileArchiveImages != this.imgs) {
			if (!($.isArray(this.imgs)
				&& this.imgs.length == 0
				&& $.isArray(this.props.fileArchiveImages)
				&& this.props.fileArchiveImages.length == 0)) {
				this.imgs = this.props.fileArchiveImages;
				toReload = true;
			}
		}
		const nextToolbar = this.getToolbar(this.props);
		if (nextToolbar != this.state.config.toolbar) {
			this.state.config.toolbar = nextToolbar;
			toReload = true;
		}
		if (toReload) {
			this.reload();
		}
		//on new V5 chat errand replyPanel,
		//instead of hiding the editor, we minimize the height when collapsing the replyPanel
		if(this.props.isChat){
			let newHeight;
			if (!this.props.showReplyPanel) {
				newHeight = DEFAULT_CKEDITOR_HEIGHT_CHAT;
			} else {
				newHeight = this.props.height;
			};
			let ckContents = this.state.editor.ui.space( 'contents' );
			if(this.state.editor && ckContents) {
				ckContents.setStyle( 'height', (newHeight + "px") );
			}
		}
	}
	static getDerivedStateFromProps =(nextProps, nextState)=>{
		if (this.props.hide) {
			return;
		}
		if(this.props.isReadOnly !== nextProps.isReadOnly) {
			// must come first before setData
			this.setReadOnly(nextProps.isReadOnly);
		}
		if(typeof this.props.defaultContent !== "undefined" &&
			nextProps.defaultContent != this.txt) {
				if(this.props.control && !this.directMutate) {
					this.directMutate = true;
				}
				this.txt = nextProps.defaultContent;
				this.updateTxt(this.txt);
		}
		let color = '#fff';
		if(nextProps.placeHolderText != this.props.placeHolderText) {
			this.state.config.placeholder = nextProps.placeHolderText;
			if(nextProps.bgColor != this.props.bgColor) {
				this.applyStyle('background-color', nextProps.bgColor);
			}
			this.reload();
		}else{
			if(nextProps.bgColor != this.props.bgColor) {
				if(nextProps.bgColor) {
					color = nextProps.bgColor
				}
				this.applyStyle('background-color', color);
			}
		}
	}
	render =()=>{
		let hide, myClass = 'form-control';
		if(this.props.hide) {
			hide = true;
		}
		if(this.props.myClass) {
			myClass = this.props.myClass;
		}
		let customStyle = {};
		if(this.props.verticalView && !this.props.isChat) {
			customStyle = {
				maxHeight: this.props.editorMaxHeight+"px",
				overflow: "auto"
			}
		}
		return (
			<div
				className={classNames('c3-ckeditor', this.props.className)}
				hidden={hide}
				style={customStyle}
			>
				<textarea
					className={myClass}
					id={this.props.id}
					name={this.props.id}
					ref={this.props.id}
					onChange={this.handleCustomEvent} />
			</div>
		);
	}

}

var Ckeditor = createReactClass({
	setDataInProgress: false, // control Ckeditor destroy properly (requestDestroy)
	requestDestroy: false, // control Ckeditor destroy properly (setDataInProgress)
	resetMutex: function() {
		this.setDataInProgress = false;
	},
	detectSelection : function (editor)  {
        try {
            const selection = editor.getSelection();
            const ranges = selection.getRanges();
            const selectedText = selection.getSelectedText();

            if (selectedText && ranges.length > 0) {
                const range = ranges[0]; 
				// To get position, create a temporary marker span and use it to get the position
                const markerSpan = editor.document.createElement('span');
                markerSpan.setStyles({
                    position: 'absolute',
                    visibility: 'hidden',
                });
				// Collapse the range to the endpoint and insert the marker
                range.clone().insertNode(markerSpan);
				// Get the position of the marker in the viewport
				const position = markerSpan.$.getBoundingClientRect();
              
                if (this.props.onSelectText) {
                    this.props.onSelectText(selectedText, position);
                }
				// Clean up the temporary marker
                markerSpan.remove();
            } else {
				this.props.onSelectText(selectedText, "");
                console.log("No text selected or range not valid.");
            }
        } catch (error) {
            console.error("Error in detectSelection:", error);
        }
    },
	getInitialState: function(){
		let top;
		if(this.props.toolbarID) {
			top = this.props.toolbarID;
		}
		return{
			config:{
				placeholder: this.props.placeHolderText,
				skin: 'cention',
				enterMode: CKEDITOR.ENTER_DIV,
				shiftEnterMode: CKEDITOR.ENTER_DIV,
				startupFocus: false,
				forcePasteAsPlainText: false,
				allowedContent: {
					$1:{
						elements: CKEDITOR.dtd,
						attributes: true,
						styles: true,
						classes: true
					}
				},
				disallowedContent: 'style; div(modal*); *{position*}',
				ignoreEmptyParagraph: false,
				pasteFromWordPromptCleanup: false,
				pasteFromWordRemoveFontStyles: false,
				pasteFromWordRemoveStyles: false,
				removeFormatAttributes: false,
				basicEntities: true,
				entities: true,
				entities_latin: false,
				entities_greek: false,
				entities_processNumerical: false,
				fillEmptyBlocks: function (element) {
					return true;
				},
				disableNativeSpellChecker: false,
				browserContextMenuOnCtrl: true,
				colorButton_enableMore: false,
				resize_dir: 'vertical',
				/*resize_enabled: true,*/
				fontSize_sizes:
					'8/8pt;' +
					'9/9pt;' +
					'10/10pt;' +
					'11/11pt;' +
					'12/12pt;' +
					'14/14pt;' +
					'16/16pt;' +
					'18/18pt;' +
					'20/20pt;' +
					'22/22pt;' +
					'24/24pt;' +
					'26/26pt;' +
					'28/28pt;' +
					'36/36pt;' +
					'48/48pt',
				font_names:
					'Arial/Arial, Helvetica, sans-serif;' +
					'Arial black;' +
					'Averta/Averta, Verdana, sans-serif;' +
					'Calibri/Calibri, sans-serif;' +
					'Comic Sans MS/Comic Sans MS, cursive;' +
					'Courier New/Courier New, Courier, monospace;' +
					'Georgia/Georgia, serif;' +
					'Lucida Console/Lucida Console, monospace;' +
					'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' +
					'Montserrat/Montserrat, sans-serif;' +
					'Myriad Pro/Myriad Pro, Lucida Grande, sans-serif;' +
					'Tahoma/Tahoma, Geneva, sans-serif;' +
					'Times New Roman/Times New Roman, Times, serif;' +
					'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' +
					'Verdana/Verdana, Geneva, sans-serif',
				sharedSpaces: {top},
				toolbar_Empty: [],
				toolbar_SingleRow: [
					[ 'Cention_emoji' ], [ 'SpellCheckerLanguage' ],
					[ 'SpellChecker' ], ['Library']
				],
				toolbar_OneRow: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'NumberedList', 'BulletedList' ],
					[ 'Indent', 'Outdent', 'Blockquote' ],
					[ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ],
					[ 'Link', 'Image', 'Youtube', 'HorizontalRule' ], [ 'PasteFromWord' ],
					[ 'Font', 'Cention_emoji' ], [ 'FontSize' ],
					[ 'TextColor', 'BGColor' ],
					[ 'SpellCheckerLanguage' ], [ 'SpellChecker' ], ['Library'], [ 'Resize' ],
					[ 'PasteText'], [ 'Cention_avatar' ]
				],
				toolbar_TwoRow: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'NumberedList', 'BulletedList' ],
					[ 'Indent', 'Outdent', 'Blockquote' ],
					[ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ], [ 'PasteFromWord' ],
					[ 'Link', 'Image', 'Youtube', 'HorizontalRule' ],
					[ 'Font', 'Cention_emoji' ], [ 'FontSize' ],'/',
					[ 'TextColor', 'BGColor' ],
					[ 'SpellCheckerLanguage' ], [ 'SpellChecker' ], ['Library'],  ['Resize'],
					[ 'PasteText']
				],
				toolbar: 'OneRow',
				toolbar_ChatOneRow: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'Image' ],
					[ 'Cention_emoji' ], [ 'SpellCheckerLanguage' ], [ 'SpellChecker' ]
				],
				toolbar_ChatAgent2Agent: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'Cention_emoji' ], [ 'SpellCheckerLanguage' ], [ 'SpellChecker' ]
				],
				toolbar_SimpleEditor: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'NumberedList', 'BulletedList' ],
					[ 'Indent', 'Outdent', 'Blockquote' ],
					[ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ],
					[ 'Link', 'Youtube', 'HorizontalRule' ], [ 'PasteFromWord' ],
					[ 'Font', 'Cention_emoji' ], [ 'FontSize' ],
					[ 'TextColor', 'BGColor' ],
					[ 'Resize' ],
					[ 'SpellCheckerLanguage' ], [ 'SpellChecker' ]
				],
				toolbar_TextFormattng: [
					[ 'Bold', 'Italic', 'Underline', 'Strike' ],
					[ 'NumberedList', 'BulletedList' ],
					[ 'Indent', 'Outdent', 'Blockquote' ],
					[ 'JustifyLeft', 'JustifyCenter', 'JustifyRight' ],
					[ 'Link', 'Image', 'Youtube', 'HorizontalRule' ], [ 'PasteFromWord' ],
					[ 'Font', 'Cention_emoji' ], [ 'FontSize' ],
					[ 'TextColor', 'BGColor' ],
					['Library'], [ 'Resize' ],
					['PasteText'], [ 'Table' ]
				],
				simpleChatToobar: false,
				plugins:
					'basicstyles,' +
					'blockquote,' +
					'clipboard,' +
					'colorbutton,' +
					'contextmenu,' +
					'enterkey,' +
					'font,' +
					'horizontalrule,' +
					'htmlwriter,' +
					'indent,' +
					'justify,' +
					'link,' +
					'list,' +
					'magicline,' +
					'pastetext,' +
					/* 'removeformat,' + */
					'sharedspace,' +
					'showborders,' +
					'tab,' +
					/*'table,' + */
					/*'tabletools,' + */
					'toolbar,' +
					'undo,' +
					'wysiwygarea,' +
					/* 'cention_pasteimage,' + */
					'cention_dragimage,' +
					/*'cention_spellcheck,' + //Mujibur: adding it classified plugins, depend on props to show!*/
					'cention_image,' +
					'pastefromword,' +
					'resize',
				contentsCss: [
					process.env.CLOUDFRONT_URL + "/fonts/custom-fonts/style.css"
				],
				language: 'en',
				suggestBoxScrollBar: [],
				spellCheckLanguages: [],
				bodyId: "QA_replyEditor_"+this.props.id
			},
			value: "",
			editor: {},
			editorIsReady: false
		}
	},
	getDefaultProps: function() {
		return {
			isReadOnly: false,
			readOnlyOnUnmount: true,
			wrapImageInContainer: false,
			placeHolderText: "" // this is 'default' props mean un-affected by render
		}
	},
	getInstance: function() {
		return CKEDITOR.instances[this.props.id];
	},
	handleCustomEvent: function(event) {},
	setFontSize: function( size ){
		if( size ){
			this.state.config.fontSize_defaultLabel = '' + size;
			CKEDITOR.addCss('.cke_editable { font-size: '+ size +'pt; }');
		}
	},
	setFontFamily: function( family ){
		if( family ){
			this.state.config.font_defaultLabel = '' + family;
			if(family === "Calibri" || family === "calibri"){
				family = "Calibri,sans-serif,Arial";
			}
			CKEDITOR.addCss('.cke_editable { font-family: '+ family +'; }');
		}
	},
	setEditableHeight: function( height ){
		if( !isNaN( height) ) {
			CKEDITOR.addCss('.cke_editable { height: '+ height +'; }');
		}
	},
	setSpellLanguages: function( list ) {
		this.state.config.spellCheckLanguages = list;
	},
	setItemHeight: function( height ){
		if( !isNaN(height) ) {
			this.state.config.height = height;
		}
	},
	isChat: function(p) {
		return (p.simpleChatToobar);
	},
	getToolbar: function(p) {
		if(this.isChat(p)) {
			return 'ChatOneRow';
		} else if(p.agentGroupChatToolbar){
			return 'ChatAgent2Agent';
		} else {
			if(p.simpleEditor) {
				return 'SimpleEditor';
			} else if(p.simpleToolbar) {
				return 'SingleRow';
			} else if(p.hideSpellchecker) {
				return 'TextFormattng';
			} else {
				return 'OneRow';
			}
		}
	},
	setSimpleToolbar: function() {
		this.state.config.toolbar = this.getToolbar(this.props);
		if(this.isChat(this.props)) {
			this.state.config.plugins = this.state.config.plugins.replace(',link,', ',');
		}
	},
	//TODO: Is TwoRowToolbar still used? Else we can remove this to focus on one toolbar style only
	setTwoRowToolbar: function( value ) {
		if( value ) {
			this.state.config.toolbar = 'TwoRow';
		}
	},
	showExtraPlugins: function(){
		this.state.config.extraPlugins = 'librarybar';
	},
	setLanguage: function( language ) {
		this.state.config.language = language;
	},
	addBase64Image: function(){
		if(typeof this.state.config.extraPlugins !== 'undefined'){
			this.state.config.extraPlugins += ',base64image,imageresize';
		} else {
			this.state.config.extraPlugins = 'base64image,imageresize';
		}
	},
	addXtraPlugin: function(plugin) {
		if(!plugin) return;
		if(typeof this.state.config.extraPlugins !== 'undefined') {
			this.state.config.extraPlugins += ',' + plugin;
		} else {
			this.state.config.extraPlugins = plugin;
		}
	},
	removePlugin: function(plugin) {
		if(!plugin) return;
		if(typeof this.state.config.removePlugins !== 'undefined') {
			this.state.config.removePlugins += ',' + plugin;
		} else {
			this.state.config.removePlugins = plugin;
		}
	},
	getPlainText: function() {
		if(this.isReady()) {
			return this.state.editor.document.getBody().getText();
		}
		return "";
	},
	getHtml: function() {
		if(this.isReady()) {
			return this.state.editor.document.getBody().getHtml();
		}
		return "";
	},
	getData: function() {
		if(this.isReady()) {
			return this.state.editor.getData();
		}
		return "";
	},
	rememberLastCursor: function(editor) {
		if (!this.props.control) {
			return;
		}
		if(editor){
			const select = editor.getSelection();
			if (select) {
				const ranges = select.getRanges();
				if (ranges[0]) {
					const {
							startContainer
							, endContainer
							, startOffset
							, endOffset
						} = ranges[0]
						, startAddress = startContainer.getAddress()
						, endAddress = endContainer.getAddress()
						;
					this.selectionRanges = {
						startAddress
						, endAddress
						, startOffset
						, endOffset
					};
					console.log("dbg: range data:", this.selectionRanges);
				}
			}
		}
	},
	retrieveLastCursor: function(editor) {
		if (!this.props.control) {
			return;
		}
		const { selectionRanges } = this;
		if (selectionRanges) {
			const range = editor.createRange()
				, {
					startAddress
					, endAddress
					, startOffset
					, endOffset
				} = selectionRanges
				, top = editor.document.getDocumentElement()
				;
			let startContainer = top.getChild(startAddress)
				, endContainer = top.getChild(endAddress)
				;
			if (startContainer || endContainer) {
				if (!startContainer) {
					startContainer = endContainer;
				} else if (!endContainer) {
					endContainer = startContainer;
				}
				range.setStart(startContainer, startOffset);
				range.setEnd(endContainer, endOffset);
				try {
					range.select();
				} catch (e) {
					console.log && console.log("dbg: catched select range:", e);
				}
			}
			this.selectionRanges = null;
		}
	},
	setData: function(msg) {
		var editor = CKEDITOR.instances[this.props.id];
		if (this.isReady()) {
			if (this.setDataInProgress) {
				this.requestSetData = true;
				this.requestSetDataData = msg;
				return;
			}
			if (editor && editor.readOnly) {
				return;
			}
			this.setDataInProgress = true;
			this.settingData = msg;
			this.rememberLastCursor(editor);
			//window.setTimeout(function() {
				this.state.editor.setData(msg, {
					callback: function() {
						this.state.editor.updateElement();
						this.setDataInProgress = false;
						if(!this.props.startupFocus){
							this.retrieveLastCursor(this.state.editor);
						}
						if(this.requestDestroy) {
							this.requestSetReadOnly = false;
							this.destroyThenRecreate();
						} else {
							if(this.requestSetReadOnly) {
								this.setReadOnly(this.requestSetReadOnlyData);
							}
							if(this.requestSetData) { // must be last
								this.requestSetData = false;
								this.setData(this.requestSetDataData);
							}
						}
					}.bind(this)
				});
			//}.bind(this), 0 /* This is not a typo. Workaround for bug in IE when setting data to quickly */);
		} else {
			this.requestSetData = true;
			this.requestSetDataData = msg;
		}
	},
	appendImage: function(msg){
		if(this.isReady()) {
			if(this.state.editor.document.getBody().getText().lastIndexOf( this.props.placeHolderText,0) == 0){
				this.setData(msg);
			} else {
				this.state.editor.insertHtml(msg);
			}
		}
	},
	updateTxt: function(txt) {
		if(this.isReady() && !this.requestDestroy) {
			this.setData(txt);
		} else {
			this.requestSetData = true;
			this.requestSetDataData = txt;
		}
	},
	destroyThenRecreate: function() {
		if (!this.setDataInProgress) {
			if (this.state.editorIsReady) {
				this.state.editor.destroy();
			}
			this.setState({editor: null, editorIsReady: false}, function() {
				// recreate Ckeditor editor after confirm it is destroyed
				clearTimeout(this.pid);
				this.replaceCkeditor(0);
				this.requestDestroy = false;
			}.bind(this));
		}
	},
	reload: function() {
		if(!this.requestDestroy && this.isReady()) {
			this.requestDestroy = true;
			this.destroyThenRecreate();
		}
	},
	isReady: function() {
		return (
			typeof this.state.editor !== 'undefined' &&
			this.state.editor != null &&
			!$.isEmptyObject(this.state.editor) &&
			this.state.editorIsReady
		);
	},
	removeImage: function(id){
		if(this.isReady()) {
			$( this.state.editor.editable().$ ).find(id).remove();
		}
	},
	onFocus: function(state) {
		if(this.props.onFocus){
			this.props.onFocus();
		}
		/*var s = this.txt;
		if(state) {
			if(s) {
				s = s.replace(this.props.placeHolderText, "")
				if(!s || /^(<div>[\s\xA0]*<\/div>\s*)+$/.test(s)) {
					this.setData("");
				}
			}
		} else {
			if(this.props.placeHolderText !== "") {
				s = s.replace(/(\r\n|\n|\r)/gm,"");
				if(!s || /^(<div>[\s\xA0]*<\/div>\s*)+$/.test(s)) {
					this.setData(this.props.placeHolderText);
				}
			}
		}*/
	},
	componentDidMount: function(){
		window.CKEDITOR_BASEPATH = location.origin + '/ng' + "/vendor/ckeditor/";
		if(typeof this.props.fileArchiveImages !== "undefined") {
			this.imgs = this.props.fileArchiveImages;
		} else {
			this.imgs = null;
		}
		if(typeof this.props.defaultContent !== "undefined") {
			this.txt = this.props.defaultContent;
		} else {
			this.txt = "";
		}
		if(this.props.avatarTemplate) {
				this.avatarTemplate = process.env.CLOUDFRONT_URL + "/img/cention-custom-avatar-template-image-should-not-use-for-another-purpose.svg";
		} else {
			this.avatarTemplate = null;
		}
		this.replaceCkeditor(100);
	},
	setReadOnly: function(readOnly) {
		if (this.isReady()) {
			if (this.setDataInProgress) {
				this.requestSetReadOnly = true;
				this.requestSetReadOnlyData = readOnly;
				return;
			}
			this.requestSetReadOnly = false;
			var ckeditorInstances = CKEDITOR.instances[this.props.id];
			if(typeof ckeditorInstances !== "undefined" && ckeditorInstances !== null)ckeditorInstances.setReadOnly(readOnly);
		}
	},
	flagChangesNotFromProp: function() {
		if(this.props.control && this.directMutate) {
			this.directMutate = undefined;
		}
	},
	handleChange: function(e) {
		const txt = e.editor.getData();
		if(!this.props.control || txt !== this.props.defaultContent) {
			this.txt = txt;
			this.props.onChange(e, this.directMutate);
			this.flagChangesNotFromProp();
		}
	},
	handleBlur: function(e) {
		this.handleChange(e);
		if (typeof this.props.onBlur === 'function') {
			this.props.onBlur(e);
		}
	},
	handleDirectMutate: function(e) {
		this.flagChangesNotFromProp();
	},
	detectSelection : function (editor)  {
		try {
		    const selection = editor.getSelection();
		    const ranges = selection.getRanges();
		    const selectedText = selection.getSelectedText();

		    if (selectedText && ranges.length > 0) {
		        const range = ranges[0]; // First range of the selection
		        const markerSpan = editor.document.createElement('span');
		        markerSpan.setStyles({
		            position: 'absolute',
		            visibility: 'hidden',
		        });
		
		        // Clone range and insert marker
		        range.clone().insertNode(markerSpan);
		
		        // Get position in the viewport
		        const position = markerSpan.$.getBoundingClientRect();
		
		        // Call the callback if provided
		        if (this.props.onSelectText) {
		           this.props.onSelectText(selectedText, position);
		        }
		
		        // Clean up marker
		           markerSpan.remove();
		    } else {
		        console.log("No text selected or range not valid.");
		    }
		} catch (error) {
		    console.error("Error in detectSelection:", error);
		}
	},
	onDoneResizing: function(size) {
		if(this.props.onRecordEditorSize){
			this.props.onRecordEditorSize(size);
		}
	},
	replaceCkeditor: function(t) {
		this.pid = setTimeout(function() {
			(typeof currentInterface !== 'undefined' &&
				currentInterface != "" ? this.setLanguage( currentInterface ) : this.setLanguage('en'));
			this.setSpellLanguages(this.props.spellLanguages);
			this.setFontFamily((this.props.defaultFontFamily != undefined ? this.props.defaultFontFamily : 'Verdana'));
			this.setFontSize((this.props.defaultFontSize != undefined ? this.props.defaultFontSize : 12));
			/**
			 * NOTES: setEditableHeight
			 * set the height of the editable area to be same with the height of the editor
			 * the purpose is so that the 'mouse up' event on the editable area will be triggered
			 * when mouse up way below the text content
			 * simply remove this line below if it causes issue as it is not a must
			 */
			this.setEditableHeight(this.props.height);
			this.state.config.resize_enabled = this.props.showResize;
			if(this.props.showResize) {
				if(this.props.resizeMaxHeight){
					this.state.config.resize_maxHeight = this.props.resizeMaxHeight;
				}
				if(this.props.resizeMinHeight){
					this.state.config.resize_minHeight = this.props.resizeMinHeight;
				}
			}else{
				this.state.config.resize_enabled = false;
			}
			if(this.props.startupFocus) {
				this.state.config.startupFocus = true;
			}
			this.setSimpleToolbar();
			if(this.props.twoRowToolbar != "undefined")
				this.setTwoRowToolbar(this.props.twoRowToolbar);
			this.setItemHeight(this.props.height);
			if(typeof this.props.suggestBoxScrollBar !== 'undefined'){
				this.state.config.suggestBoxScrollBar =
					this.props.suggestBoxScrollBar;
			} else{
				this.state.config.suggestBoxScrollBar =
					{overflowY:false, maxHeight:"100px"}
			}
			// if(this.props.showLibrary != undefined && this.props.showLibrary){
			// 	if( this.props.libraryId != undefined && this.props.libraryId != 0)
			// 		this.showExtraPlugins();
			// }
			if(this.props.defShowYoutube) {
				if(typeof this.state.config.extraPlugins !== "undefined") {
					this.state.config.extraPlugins += ',youtube';
				} else {
					this.state.config.extraPlugins = 'youtube';
				}
			}
			if( typeof this.props.defShowSpellchk === "undefined" ){
				if(typeof this.state.config.extraPlugins !== "undefined" && this.state.config.extraPlugins !== "") {
					this.state.config.extraPlugins += ',cention_spellcheck';
				} else {
					this.state.config.extraPlugins = 'cention_spellcheck';
				}
			}
			if((typeof this.props.showBase64Image !== 'undefined') &&
				(this.props.showBase64Image == true)){
				this.addBase64Image();
			}
			this.addXtraPlugin('cention_emoji');
			if(this.props.avatarTemplate){
				this.addXtraPlugin('cention_avatar');
			}
			if(this.props.tableTools){
				this.addXtraPlugin('tableresize');
			}
			this.removePlugin('magicline');
			if(this.props.placeHolderText) {
				this.addXtraPlugin('confighelper');
			}
			if(this.props.autoGrow) {
				this.addXtraPlugin('autogrow');
				if(this.props.autoGrowMaxHeight){
					this.state.config.autoGrow_maxHeight = this.props.autoGrowMaxHeight;
				}
				if(this.props.autoGrowMinHeight){
					this.state.config.autoGrow_minHeight = this.props.autoGrowMinHeight;
				}
			}
			if(typeof this.props.setHeight !== 'undefined'){
				CKEDITOR.config.height = this.props.setHeight;
			}
			CKEDITOR.disableAutoInline = true;
			if(this.props.toolbarID) {
				// sharedspace plugin don't support resize plugin
				// this.removePlugin('resize');
				// not removing 'Resize' plugin seems no issue on using 'Sharedspace' plugin
				// issue seems only happens since CKEditor 4.5.5
			}
			if(typeof this.props.imageResize !== 'undefined'){
				CKEDITOR.config.imageResize = this.props.imageResize;
			}
			if(typeof this.props.imageMaxSize !== 'undefined'){
				CKEDITOR.config.imageMaxSize = this.props.imageMaxSize;
			}
			var editor = CKEDITOR.replace(this.props.id, this.state.config);
			var ckeditorInstance = CKEDITOR.instances[this.props.id];
			ckeditorInstance.on('destroy', () => {
				if (process.env.NODE_ENV !== 'production') {
					console.log("dbg: ckeditor destroyed:", this.props.id);
				}
			});
			ckeditorInstance.on('instanceReady', function() {
				if (this.props.callbackParent) {
					this.props.callbackParent(true);
				}
				if (this.props.bgColor) {
					editor.document.getBody().setStyle('background-color', this.props.bgColor);
					editor.document.getBody().setStyle('color', this.props.txtColor);
				}
				// Set default font family and size to content
				let fontFamily = 'Verdana', fontSize = 12;
				if (this.props.defaultFontFamily) {
					fontFamily = this.props.defaultFontFamily;
					if(fontFamily === "Calibri" || fontFamily === "calibri"){
						fontFamily = "Calibri,sans-serif,Arial";
					}
				}
				if (this.props.defaultFontSize) {
					fontSize = this.props.defaultFontSize;
				}
				const editorChildNode = editor.document.getBody().getChild(0);
				if (editorChildNode.$.nodeType !== 3) {
					editorChildNode.setStyle('font-family', fontFamily);
					editorChildNode.setStyle('font-size', fontSize+"pt");
				}
				this.setState({editor, editorIsReady: true}, () => {
					// editor have been renew reset single access mutexes.
					this.resetMutex();
					// avoid losing any setData request
					if (this.requestSetData) {
						this.requestSetData = false;
						this.setData(this.requestSetDataData);
					}
				});
			}.bind(this));

			ckeditorInstance.on('contentDom', function () {
				const editable = editor.editable();
				editable.attachListener(editable, 'mouseup', (e) => {
					this.detectSelection(editor);
				});
				editable.attachListener(editable, 'keyup', (e) => {
					this.detectSelection(editor);
				});
				editable.attachListener(editable, 'mousedown', (e) => {
					if(this.props.onFocus){
						this.onFocus(true);
					}
				});
				editable.attachListener(editable, 'focus', (e) => {
					if(this.props.onFocus){
						this.onFocus(true);
					}
				});
			}.bind(this));

			editor.___fileArchiveImages = this.imgs;
			editor.___avatarTemplate = this.avatarTemplate;
			editor.wrapImageInContainer = this.props.wrapImageInContainer;
			editor.setData(this.txt);
			editor.updateElement();
			CKEDITOR.addCss('body { margin-left: 5px; margin-right: 5px; margin-top: 3px; margin-bottom: 3px; }');
			CKEDITOR.addCss('p { margin-top: 0; }');
			CKEDITOR.addCss('.spellchecker-word-highlight { color: red; cursor: pointer; border-bottom: 1px dotted red; }');
			if(localStorage.getItem('theme') == "true"){
				CKEDITOR.addCss('body {color: #B9BBBD; }');
			}
			CKEDITOR.instances[this.props.id].on("key", function( event ){
				if (this.props.block) {
					event.cancel();
					return false;
				}
				this.handleDirectMutate(event);
				this.props.onKeydown(event);
				var selection = editor.getSelection();
				if(selection){
					var selectedElement = selection.getSelectedElement();
					if(selectedElement != null) {
						var elementType = selectedElement.$.nodeName.toLowerCase();
						if(elementType == "img") {
							if(this.props.onUndoImage) {
								this.props.onUndoImage(editor,event,selectedElement);
							}
						}
					}
				}
			}.bind(this));
			CKEDITOR.instances[this.props.id].on("click",
				this.handleDirectMutate);
			CKEDITOR.instances[this.props.id].on("change", this.handleChange);
			CKEDITOR.instances[this.props.id].on("libraryClick", function( event ){
				this.props.onLibraryClick(event);
			}.bind(this));
			CKEDITOR.instances[this.props.id].on("drop", function( event ){
				if(this.props.onDragnDropFiles){
					this.props.onDragnDropFiles(event);
					if(this.props.resizeMaxHeight){
						editor.resize( '100%', this.props.resizeMaxHeight );
					}
				}
			}.bind(this));
			if(typeof this.props.onPaste !== 'undefined'){
				CKEDITOR.instances[this.props.id].on("paste", function( event ){
					this.props.onPaste(event);
				}.bind(this));
			}
			CKEDITOR.instances[this.props.id].on("blur", this.handleBlur);
			// Removing this below as it conflicted with the one controlled in the contentDom
			/* CKEDITOR.instances[this.props.id].on("focus", function( event ){
				if(this.onFocus){
					this.onFocus(true);
				}
			}.bind(this)); */

			//Resize
			var answerHeight = this.props.height;
			if(this.props.showResize) {
				var resizeId;
				CKEDITOR.instances[this.props.id].on("resize", function( event ){
					if(this.props.recordSize && this.props.onRecordEditorSize){
						clearTimeout(resizeId);
						answerHeight = event.editor.ui.space( 'contents' ).getStyle( 'height' );
						if(!isNaN(parseFloat(answerHeight)) == true ){
							resizeId = setTimeout(function() {
								this.onDoneResizing(parseFloat(answerHeight));
							}.bind(this), 500);
						}
					}
				}
				.bind(this));
			}
		}.bind(this), t);

	},
	componentWillUnmount: function(){
		if (CKEDITOR.instances[this.props.id]) {
			if(typeof this.props.readOnlyOnUnmount == 'undefined' ||
				((typeof this.props.readOnlyOnUnmount !== 'undefined') &&
				this.props.readOnlyOnUnmount == true)){
				this.setReadOnly(false);
			}
			CKEDITOR.instances[this.props.id].destroy(true);
		}
		clearTimeout(this.pid);
	},
	UNSAFE_componentWillReceiveProps: function(nextProps) {
		if (this.props.hide) {
			return;
		}
		if(this.props.isReadOnly !== nextProps.isReadOnly) {
			// must come first before setData
			this.setReadOnly(nextProps.isReadOnly);
		}
		if(typeof this.props.defaultContent !== "undefined" &&
			nextProps.defaultContent != this.txt) {
				if(this.props.control && !this.directMutate) {
					this.directMutate = true;
				}
				this.txt = nextProps.defaultContent;
				this.updateTxt(this.txt);
		}
		let color = '#fff';
		if(nextProps.placeHolderText != this.props.placeHolderText) {
			this.state.config.placeholder = nextProps.placeHolderText;
			if(nextProps.bgColor != this.props.bgColor) {
				this.applyStyle('background-color', nextProps.bgColor, nextProps.txtColor);
			}
			this.reload();
		}else{
			if(nextProps.bgColor != this.props.bgColor) {
				if(nextProps.bgColor) {
					color = nextProps.bgColor
				}
				this.applyStyle('background-color', color, nextProps.txtColor);
			}
		}
	},
	isEmpty: function(obj) {
		for(var key in obj) {
			if(obj.hasOwnProperty(key))
			return false;
		}
		return true;
	},
	applyStyle: function(style, value, txtColor) {
		if(this.isReady()) {
			if(this.state.editor && !this.isEmpty(this.state.editor.document.getBody())) {
				this.setStyle(style, value, txtColor);
			}
		}
	},
	setStyle: function(style, value, txtColor) {
		this.state.editor.document.getBody().setStyle(style, value);
		this.state.editor.document.getBody().setStyle('color', txtColor);
	},
	componentDidUpdate: function() {
		if (this.props.hide || !this.isReady()) {
			return;
		}
		let toReload = false;
		if (typeof this.props.fileArchiveImages !== "undefined"
			&& this.props.fileArchiveImages != this.imgs) {
			if (!($.isArray(this.imgs)
				&& this.imgs.length == 0
				&& $.isArray(this.props.fileArchiveImages)
				&& this.props.fileArchiveImages.length == 0)) {
				this.imgs = this.props.fileArchiveImages;
				toReload = true;
			}
		}
		const nextToolbar = this.getToolbar(this.props);
		if (nextToolbar != this.state.config.toolbar) {
			this.state.config.toolbar = nextToolbar;
			toReload = true;
		}
		if (toReload) {
			this.reload();
		}
		//on new V5 chat errand replyPanel,
		//instead of hiding the editor, we minimize the height when collapsing the replyPanel
		if(this.props.isChat){
			let newHeight;
			if (!this.props.showReplyPanel) {
				newHeight = DEFAULT_CKEDITOR_HEIGHT_CHAT;
			} else {
				newHeight = this.props.height;
			};
			let ckContents = this.state.editor.ui.space( 'contents' );
			if(this.state.editor && ckContents) {
				ckContents.setStyle( 'height', (newHeight + "px") );
			}
		}
	},
	render: function() {
		let hide, myClass = 'form-control';
		if(this.props.hide) {
			hide = true;
		}
		if(this.props.myClass) {
			myClass = this.props.myClass;
		}
		let customStyle = {};
		if(this.props.verticalView && !this.props.isChat) {
			customStyle = {
				maxHeight: this.props.editorMaxHeight+"px",
				overflow: "auto"
			}
		}
		let wrapperClass = myClass+"-wrapper";
		let themeClass = "light";
		if(localStorage.getItem('theme')=="true") {
			wrapperClass = myClass+"-wrapper-dark";
			themeClass = "dark";
		}
		return (
			<div
				className={classNames('c3-ckeditor', wrapperClass, this.props.className, themeClass)}
				hidden={hide}
				style={customStyle}
			>
				<textarea
					className={myClass}
					id={this.props.id}
					name={this.props.id}
					ref={this.props.id}
					onChange={this.handleCustomEvent} />
				{
					this.props.children ? this.props.children : null
				}
			</div>
		);
	}
});

export default Ckeditor;
