import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { I } from '../../common/v5/config.js';
import ErrandBoxHeaderInfo from '../../components/v5/ErrandBoxHeaderInfo';
import ReplyToolbar from '../../components/v5/ReplyToolbar';
import { getErrandCloseTimer } from '../../common/v5/indexeddb';
import {
	withAcquireBox
	, withErrandBoxHeader
	, withHeaderLeftBox
	, withUserInfo
} from '../../components/v5/ErrandBoxHeader';
import {
	ErrandHeader,
	ErrandOpened,
	MessageInternalComment,
	InternalCommentContents,
	CountdownTimer,
	ReplyForm,
	ReplyNav,
	ErrandReplyHeader,
	Errand,
	VisibleContentBorder,
	ScrollToBottom
} from './errand';
import AcquireErrands, { AcquireErrandsModal } from '../../components/v5/AcquireErrands';
import Classification from '../../components/v5/Classification';
import PopupNotification from '../../components/v5/PopupNotification';
import PostponeErrand from '../../components/v5/PostponeErrand';
import { CollaborateMsgCtnr } from '../../containers/collaborate';
import { toggleAcquireErrand, withAcquireErrands } from '../../containers/oce';
import { showInsertProtect } from '../../redux/actions/common';
import { resetEdit } from '../../redux/actions/knowledgebase';
import {
	activateAnsweredView,
	agentIsTyping,
	cancelScrollToQuestion,
	changeExternalForwardAll,
	changeIncludeCollabHistory,
	changeExternalForwardQuestionAllAttachmentsSelection,
	clearCollaborateAnsAttachment,
	clearInputText,
	resetInputText,
	deleteSavedAnswerAttachment,
	deleteSavedColAttachment,
	deleteSavedAttachment,
	deleteNoteAttachment,
	expandHideOption,
	insertCollaborateAnsAttachment,
	removeSelectedArchive,
	selectReplyChannel,
	selectShowReply,
	showHideReplyChannel,
	selectShowReplyAction,
	showErrandAttachmentList,
	toggleAllErrandHistories,
	updateReformatAnswer,
	updateReplyCheckboxes,
	inputTextChanges,
	inputTextChangesForCall,
	tagsOperation,
	areaOperation,
	acquireOperations,
	selectForwardHistory,
	toggleErrandHeaderMobile,
	toggleErrandOptionMobile,
	classificationTagging,
	recipientsChange,
	controlActionForward,
	dueDateOperation,
	selectExistAttachment,
	selectExternalForwardAttachment,
	selectQuestionAttachment,
	selectAllQuestionAttachment,
	selectHistoryAttachment,
	toggleSocialMediaUI,
	messageSocialMedia,
	handleErrorNotifPopup,
	selectErrandLibrary,
	setErrandLibrarySearch,
	clearLibrarySearchTimeout,
	showErrandLibraryPopup,
	showErrandLibraryList,
	showLibraryAnswer,
	setKnowledgebaseQuestionNode,
	setSearchText,
	setPersonalization,
	toggleAttachmentList,
	showUploadAttachment,
	handleFileArchives,
	handleLibraryAttachments,
	selectedLibraryQuestion,
	toggleFileArchive,
	toggleVisitorPath,
	clearSelectedArchive,
	handleAttachmentToggleBox,
	clearSelectedLibrary,
	resetErrandLibrarySearch,
	setAnswerTranslation,
	showContactBook,
	showContactCard,
	showContactCardHistory,
	showContactCardChannel,
	setContactCard,
	selectContactCardAnsweredHistory,
	selectContactCardUnansweredHistory,
	appendReplyAddress,
	setReplyAddressType,
	setSelectedTZ,
	setSelectedLang,
	togglePopupFilterByElem,
	updateReplyToAndChannel,
	updateTweetWarning,
	togglePostponeErrand,
	setContactBookUI,
	changeErrandHistorySelections,
	updateUserVoteComment,
	updateUserVoteVote,
	updateUserVoteEdit,
	updateUserVoteWarning,
	selectAllHistoryAttachemnt,
	updateContactCard,
	updateCustomerNote,
	showCompanyInfo,
	toggleChatNavDD,
	changeIncludeErrandHistory,
	showVideoCallFrame,
	showSearchShortcutDropdown,
	showSearchInput,
	handleReplySearchHide,
	toggleErrandChatSidePanel,
	toggleReplyBoxErrandMenu,
	resetAgentSatisfaction,
	resetCK,
	toggleWaTemplPopup,
	setWaTemplContent,
	saveWATemplateCode,
	setQuickReplyContent,
	showErrandRewriteAnswerPopup,
	setSetRAQuestion,
	updateAIAnswerstate,
	resetAIAnswer,
	doSetAIAnswerPanel,
	toggleRightSidePanel,
	expandCollabQueryThread,
	doSetKnowledgeBasePanel,
	doExpandKnowledgeBasePanel,
	selectManualReply,
	fetchSummarizedHistoryRequest,
	fetchSummarizedHistorySuccess,
	fetchSummarizedHistoryFailure,
	setSuggestedAnswerUsed,
	fetchAgentAssistRequest
} from '../../redux/actions/errand';
import { dialBack } from '../../redux/actions/async/call';
import {
	clearInsertionContent
	, insertContent
} from '../../redux/actions/async/cke';
import {
	changeReply,
	createExpertQueryThread,
	submitCollaboration,
	toggleCollaborationLight,
	enableAutoSaveCollab,
	stopAutoSaveCollab,
	multiDispatchesEE,
	fetchEEThread
} from '../../redux/actions/async/collaborate';
import {
	fetchCompanyList
	, sstAsyncs
} from '../../redux/actions/async/admin';
import {
	askTrainChatbotsWithLibraryQuestion
} from '../../redux/actions/async/chatbot';
import { async } from '../../redux/util';
import { postAddEntryToContactCard, deleteContactCard,getSummaryHistory,getEmailAndThreadSummary } from '../../redux/actions/async/ajax';
import { getTokenStreamXHRData, getStreamXHRDataWithJSON } from '../../redux/actions/async/xhrHandler';
import {
	buttonClickSaveErrand,
	cancelTagging,
	changeArea,
	changeErrandInternalState,
	closeErrandViewAction,
	confirmTagging,
	createOrEditNote,
	deleteUploadedAttachment,
	enableAutoSave,
	forceCloseErrandView,
	openPreviousErrand,
	historyByErrandAndThread,
	historyErrandContacts,
	loadMoreOtherContacts,
	ErrandMyErrandsAsync,
	FetchLinkedErrandsAsync,
	prepareCreateNewInternalComment,
	previewEmail,
	previewEmailWithPost,
	promiseProcessEmail,
	saveAsEml,
	showOtherContactErrand,
	resendErrand,
	sendReply,
	stopAutoSave,
	editErrandNotes,
	moveErrandToFolder,
	forwardErrandToArea,
	forwardErrandToAgent,
	fetchPrintErrand,
	loadKnowledgeBase,
	doDeleteErrand,
	doCloseErrand,
	socialMediaReplyAction,
	deliverMessageToSocialMedia,
	uploadAgentAttachment,
	uploadOneAgentAttachment,
	uploadClientNoteAttachment,
	deleteAnswerAttachment,
	fetchFileFromURL,
	deleteAllAgentAttachment,
	putBackToInbox,
	queueToMe,
	translation,
	detectLanguage,
	getContactCard,
	removeContactFromCard,
	addContactIntoCard,
	fetchCustomerNotes,
	addCustomerNote,
	removeCustomerNote,
	fetchContactBook,
	getCustomerContacts,
	saveCustomerAvatar,
	delCustomerAvatar,
	getAnonymizeData,
	anonymizeData,
	getExportLog,
	delExportContact,
	exportContact,
	handleReopenErrand,
	doCheckMembershipStatus,
	recordEditorSize,
	updateShowReplyToolbarPref,
	updateShowReplyAssistPref,
	updateShowRecipientsPref,
	updateShowSubjectPref,
	updateSelectedTabPref,
	setVerticalViewPref,
	toggleHeaderPanel,
	updateLockToMe,
	fetchVoteFeaturedQuestion,
	setErrandPostponeDate,
	setVoteFeaturedQuestion,
	fetchUserVote,
	setUserVote,
	acquireErrand,
	acquireFromOtherAgentErrand,
	removeAgentWorkingOnErrand,
	companyErrandHistory,
	companyErrandOtherContacts,
	submitSipManualErrand,
	transferSipErrand,
	openErrandFromList,
	askForClassification,
	reloadBasicErrand,
	addSuggestContactIntoCard,
	createOneErrandNotes,
	refreshContactCard,
	postCustomerNoteAsync,
	generateAIAnswer,
	loadAndOpenErrand,
	fetchAgentWorkingOnSelectedErrands,
	generateAIAnswerStreaming,
	setAgentAssistTogglePref
} from '../../redux/actions/async/errand';
import { pushReviewErrandURL } from '../../redux/actions/async/review';
import { isFromSearch} from '../../redux/selectors/search';
import {
	setErrandPinToTop,
	setErrandsPriority,
	workflow as workflowMap,
	agentSetErrandView,
	agentSetErrandMsgTruncate,
	fetchWorkflowExpertQueries,
	errandPreview,
	periodicDueList,
	periodicCollabsList,
	periodicForwardedList,
	periodicExpiringList,
	stopPeriodicDueList,
	stopPeriodicCollabsList,
	stopPeriodicForwardedList,
	stopPeriodicExpiringList,
	deleteLaunchpadWidgetAction,
	addLaunchpadWidgetAction
} from '../../redux/actions/async/workflow';
import {
	sipColdTransfer
	, sipWarmTransfer
	, showSipAgentList
	, showExtTransferKeypad
	, sipCloseManualErrandAfterColdTransfer
	, toggleSipOutgoingPopup
} from '../../redux/actions/async/sippRtc';
import {
	selectToggleSideBar,
	selectCollapseSideBar,
	selectErrandFromList,
	toggleWorkflowPopup,
	selectLaunchpadLayout,
	setLaunchpadWidgets,
	setLaunchpadGrid,
	addingLaunchpadWidget,
	deleteLaunchpadWidget
} from '../../redux/actions/workflow';
import {
	customConfirm
	, enableConfirm
	, fetchAgentFavourite
	, updateAgentFavourite
	, updateFTAFavourite
	, updateFTA
} from '../../redux/actions/async/hmf';
import {
	selectManualErrand
} from '../../redux/actions/manual';
import { updateShareParam } from '../../redux/actions/statistics';
import { showMergeContactCardPopup } from '../../redux/actions/admin';
import { getActiveMainView } from '../../redux/selectors/hmf';
import { sipGetCurrentEid
	, sipCallStatus
	, sipCallShowAgentList
	, sipCallConn
	, sipMakeCallCurrentErrand
} from '../../redux/selectors/call';
import { noForBotQuestionMemo } from '../../redux/selectors/chatbot';
import {
	getCollaborationListSelector,
	collaborationGroupName
} from '../../redux/selectors/collaborate';
import { totalQueueChats, isScreenSharingMode, isCoBrowsingMode } from '../../redux/selectors/chat';
import { openLibraryIdSelector, openQuestionIdSelector } from '../../redux/selectors/library';
import {
	currentActiveReply,
	currentErrandReplyChannel,
	currentCollabChannel,
	needSaveMemoize,
	archiveAttachmentsSelector,
	libraryAttachmentsSelector,
	associatedErrandListString,
	linkedErrandsListString,
	contactCardOtherContactSelector,
	collaborateAnsAttachmentsSelector,
	currentStateMemoize,
	getAreaTags,
	getBothAreaTags,
	getCollabAttachments,
	getErrandDataAndClientById,
	getHistoriesAndMy,
	getHistoriesSelection,
	getChannelsSelector,
	getCollabChannelsSelector,
	hasOtherErrandsSelector,
	incomingAttachmentList,
	incomingAttachmentsSize,
	internalCommentAttachments,
	internalCommentStateSelector,
	isAcquireReady,
	isClassificationFetchMultiAreasTagReady,
	isErrandActionInProgressSelector,
	isHistoryReady,
	isMultiSelectionErrandList,
	isPreviewDisabledSelector,
	isSaveAsEmlDisabledSelector,
	isSaving,
	isVoiceChannelLike,
	agentTimezoneOffsetSelector,
	attachmentNotReady,
	buttonStateSelector,
	getAgentsWithoutMeSelector,
	getIsCurrentErrandSelector,
	getClassificationReadinessSelector,
	getClassificationTagsSelector,
	getClassificationSelectedTagsSelector,
	getClassificationAreaNameSelector,
	getClassificationDisplayIdSelector,
	getClassificationSubjectSelector,
	getCurrentErrandAreaData,
	getSuggestedAnswer,
	getCurrentOpenErrandID,
	getCurrentReply,
	getCurrentTaggingErrand,
	getInternalCommentsSelector,
	needAutoSaveSelector,
	notesAttachmentsSelector,
	notMineSelector,
	openErrandAreaLibraryMemo,
	questionAttachmentsSelector,
	recipientsOptionsSelector,
	getEmailRecipients,
	getCollabOpts,
	getChannelOpts,
	replyAnswerSelector,
	replyCheckboxesSelector,
	replyCheckboxStatesSelector,
	replyNavInfoSelector,
	replyNavOptionsSelector,
	replySubjectSelector,
	errandQuestionSelector,
	savedAttachmentsSelector,
	scrollToQuestionSelector,
	selectedAttachmentList,
	serializedAttachmentsSelector,
	serializedAttachmentSizeSelector,
	showPreviewSelector,
	showSaveAsEmlSelector,
	totalAttachmentsSize,
	uploadedAttachmentsSelector,
	isCollaborationSelector,
	isManualErrandClassification,
	hideSubjectbarSelector,
	previewPersonalization,
	fileArchivesSelector,
	replyPlainAnswerSelector,
	contactCardValidation,
	getClientAvatarByErrand,
	libraryShortcutsSelector,
	customerNoteAttachments,
	getAreaSignatures,
	getAreaSalutations,
	knowledgeBaseDataSelector,
	knowledgeBaseListSelector,
	getContactCardStatus,
	getClientNotesStatus,
	getSuggestAddTo,
	currentPersonDetailsSelector,
	getCurrentContactSelector,
	errandServiceTypeSelector,
	contactBookPersonListSelector,
	contactCardContactSelector,
	isAgentsSelector,
	isContactCardSelector,
	isCollaboratorSelector,
	isShareStatistic,
	isAddToContactCard,
	getClientAvatars,
	inputsReplyCheckboxes,
	historyAttachmentListSelector,
	selectedHistoryAttachmentList,
	contactCardCustomerSelector,
	isAllCurrentFilesAttached,
	getAgentWorking,
	getCurrentWorkingErrands,
	companyListSelector,
	getCurrentChannel,
	getCurrentOpenErrandSelector,
	checkCollabHistory,
	ratingsDataList,
	reactionsDataList,
	getAvailableReplyPanel,
	getSelectedReplyPanel,
	currentCipherKeyMemoize,
	getAreaQuickReplies,
	shouldHideQuickReply,
	getCRMEmailTrigger,
	getCurrentErrand,
	getCurrentErrandAreaId,
	getClientContactCardName,
	getErrandOutgoingDialplanSelector,
	rewriteAnsBaseDataSelector,
	getAreaReWriteAnswerSetupMemo,
	getRewriteAnswerBaseQuestionMemo,
	getHistoryIdSelector,
	getLlmSessionIdSelector
} from '../../redux/selectors/errand';
import {
	ckeditorInsertionTextMemoize
} from '../../redux/selectors/cke';
import {
	onlyActiveAreasSelector,
	attachFileOptionsSelector,
	contextMemo,
	getChatErrandSelected,
	getHasDueDateSelected,
	myDefaultFolderMemoize,
	searchListSelector,
	getAllowLightBulbOff,
	getCollaborationInfoSelector,
	getPreviewFeatures,
	totalSearchResult,
	allConnectedAreasSelector,
	dueErrandsSelector,
	incomingCollabsSelector,
	forwardedErrandsSelector,
	expiringErrandsSelector
} from '../../redux/selectors/workflow';
import {
	canTranslateSelector,
	translateToSelector
} from '../../redux/selectors/translation';
import {
	selectAreaRecipient,
	addColFileArchives,
	colClearSelectedArchive,
	colClearSelectedLibrary,
	clearInternalCollaborators,
	setCollaborationTranslateTo,
	updateCollaborationCheckboxes,
	updateCollabReformatAnswer,
	agentEEIsTyping,
	setupCollaborationQuery
} from '../../redux/actions/collaborate';
import {
	handleSearchTextChange,
	doGlobalSearchByWS,
	handleReplySearchResultHide,
	handleReplySearchSpinner
} from '../../redux/actions/search';
import {
	GLOBAL_SEARCH_FROM_BODY,
	SAVE_WHATSAPP_TEMPLATE_ID,
	SEARCH_ERRAND
} from '../../redux/constants/constants';
import {
	ContactBook,
	ContactCardRenderer,
	ContactCardv5,
	ContactCard
} from '../../components/v5/ContactBook';
import { KBPopupBase } from '../../components/v5/KnowledgeBasePopup';
import DataProtection from '../../components/v5/DataProtection';
import {
	mobileStateSelector
} from '../../redux/selectors/mobile';
import { keyErrandPreview, keyAddEntryToContactCard, keyDeleteContactCard } from '../../redux/constants/keys';
import {
	getAppState,
	getStateName
} from '../../redux/util';
import {
	AT_ARCHIVE,
	AT_BROWSE,
	AT_COL_ANS,
	AT_QUESTION,
	AT_SAVED,
	AT_LIBRARY,
	AT_UPLOADED,
	ECB_ALL_CURR_Q_AT,
	ECB_FWD_ALL_HISTORIES,
	ECB_INC_COLLAB_HISTORY,
	ECB_INC_HISTORIES,
	ICCB_NEW,
	MM_STATISTICS,
	COL_NEW_THREAD,
	EMAIL_TYPE_FROM_REPLY,
	RC_EMAIL,
	RPLY_COLLABORATE,
	RPLY_COMMENT,
	RPLY_ERRAND,
	RPLY_QUESTION,
	RPLY_EXT_FWD,
	TAG_ADD,
	TAG_DELETE,
	BTN_CALL,
	BTN_CLEAR,
	BTN_PREVIEW,
	BTN_SAVE,
	BTN_SAVE_AS_EML,
	BTN_SEND,
	BTN_SEND_ALL,
	BTN_RESEND,
	BTN_RESET,
	MEDIA_ACTION,
	CTX_MANUAL,
	CB_ALL_CONTACT,
	CB_EXPERT_IN_AREA,
	CB_CONTACT_CARD,
	CB_AGENTS,
	ADD_TO_CONTACT_CARD,
	INC_ALL_HISTORY_ATTACHMNET,
	IS_HISTORY_ATTACHMENT,
	TEMPLATE_USED_FOR_ERRAND,
	TEMPLATE_USED_FOR_WHATSAPP,
	M_CONTACT_CARDS,
	ME_CREATE,
	ME_CREATE_AS_MY,
	MP_BASIC_CALL,
	MP_MINIMIZE,
	AGENT_FAV_FORWARD_TO_AREA,
	AGENT_FAV_AI_TRANSLATE_LANGUAGES,
	RPLY_MANUAL,
	CHAT_CKE_PLACEHOLDER_TEXT,
	RPLY_CHAT_SUMMARY,
	ME_START,
	WFP_BULK_SEND_ERRAND
} from '../../common/v5/constants';
import {
	chatHasExternalCollaboration,
	getCurrentChatErrand,
	getChatTagLevels,
	flattenTagLevels,
	pleaseWaitString,
	checkIfChatNotEmpty,
	isEmptyRichText,
	stripElements
} from '../../common/v5/helpers';
import {
	copyTextToClipboard,
	isEmptyString
} from '../../common/v5/utils';
import AgentSocket from '../../common/v5/agentsocket';
import {
	CHAT_ADD_FILE_ARCHIVE,
	CHAT_ADD_KB_FILE,
	CHAT_UPDATE_TAGS,
	evtCHAT_SET_TAGS,
	GLOBAL_SEARCH_FROM_REPLY
} from '../../redux/constants/constants';
import {
	popErrorOnly
	, togglePopAlert
	, togglePopWaiting
	, clearPopWaiting
	, toggleToastAlert
} from '../../redux/actions/hmf';
import {
	closeVideoFrames
} from '../../common/v5/webRtcCall.js';
import { stateName } from '../../redux/reducers/statistics.js';
import {
	isCallMemoize
	, manualCallMinimize
} from '../../redux/selectors/manual.js';
import { clearInsertionText } from '../../redux/actions/cke.js';
import { errand, fetchClientsAddressList } from '../../redux/actions/async/errand';
import { selectEmailChannel } from '../../redux/selectors/admin.js';
import { RewriteAnswerPopup, RewriteAnswerBoxContent } from '../../components/v5/rewriteAnswerBase.js';
import { TXT_FETCHING_DATA, TXT_DELETING } from '../../common/v5/chatbotConstants.js';
import Launchpad from '../../components/v5/Launchpad';
import { TOAST_POSITION, TOAST_TYPE } from '../../reactcomponents/Modal.js';

const wfAsyncState = (state, key) => getAppState(
	state
	, 'workflow'
)[getStateName(workflowMap[key])];

const mapClassify = (state, props) => {
	const e = state.app.errand, inputs = e.inputs, c = inputs.classification
	, wfui = state.app.workflow.ui;
	if(!c) {
		return {enable: false};
	}
	// FIXME: Setup proper subject for tagging multiple errands
	const subject = getClassificationSubjectSelector(state, props), s = {
			enable: true,
			show: true,
			condition: c.condition,
			eid: getCurrentOpenErrandID(state, props),
			taggingErrandId: getCurrentTaggingErrand(state, props),
			manual: isManualErrandClassification(state, props),
			isSameCurrentErrand: getIsCurrentErrandSelector(state, props),
			multi: isMultiSelectionErrandList(state, props),
			isReady: getClassificationReadinessSelector(state, props),
			tags: getClassificationTagsSelector(state, props),
			tagsFetchReady: isClassificationFetchMultiAreasTagReady(state, props),
			selectedTags: getClassificationSelectedTagsSelector(state, props),
			areaName: getClassificationAreaNameSelector(state, props),
			displayId: getClassificationDisplayIdSelector(state, props),
			iconicSubject: subject.iconic,
			subject: subject.subject,
			backDropDisable: (wfui.showManualCall === MP_BASIC_CALL ? true : false)
		};
	return s;
};

const mapClassifyCallbacks = (dispatch, props) => {
	return {
		onAddTag: (value, id, isCurrentErrand, manual) => {
			if(isCurrentErrand || manual) {
				dispatch(tagsOperation(TAG_ADD, value, manual));
				return;
			}
			dispatch(classificationTagging(TAG_ADD, value, id));
		},
		onDeleteTag: (index, id, isCurrentErrand, manual) => {
			if(isCurrentErrand || manual) {
				dispatch(tagsOperation(TAG_DELETE, index, manual));
				return;
			}
			dispatch(classificationTagging(TAG_DELETE, index, id));
		},
		onCancel: () => {
			dispatch(cancelTagging());
		},
		onConfirm: () => {
			dispatch(confirmTagging());
		}
	};
};

export const ClassificationCtnr = connect(mapClassify,
	mapClassifyCallbacks)(Classification);

const mapPopupNotif = (state, props) => {
	const e = state.app.errand;
	const s = {
		message: e.basic.data ? e.basic.data.data.statusMessage : '',
		type: 'error',
		display: e.ui.showErrorNotifPopup
	};
	return s;
};

const mapPopupNotifCallbacks = (dispatch, props) => {
	return {
		onClose: () => {
			dispatch(handleErrorNotifPopup(false));
		}
	};
};

export const PopupNotificationCtnr = connect(mapPopupNotif,
	mapPopupNotifCallbacks)(PopupNotification);

const mapMoment = (state, props) => ({
	timezoneOffset: agentTimezoneOffsetSelector(state, props)
});

export function withAgentTimezonOffset(Component) {
	return connect(mapMoment)(Component);
}

/* Contact Book is Address book explorer */
const mapContactBook = (state, props) => {
	const e = state.app.errand, ui = e.ui,
		cb = ui.contactBook,
		m = ((isCallMemoize(state)|| manualCallMinimize(state)) ? state.app.errand.ui.manualCall : state.app.errand.ui.manual),
		wfs = state.app.workflow.fetchWfSettings,
		s = {
			activeMainView: getActiveMainView(state),
			show: cb.show,
			replyType: cb.replyType,
			currentTab: cb.currentTab,
			selectedPerson: cb.selectedPerson,
			searchText: cb.searchText,
			serviceType: errandServiceTypeSelector(state, props),
			personList: contactBookPersonListSelector(state, props),
			personDetails: currentPersonDetailsSelector(state),
			contactList: contactCardContactSelector(state),
			selectedContact: getCurrentContactSelector(state, props),
			isAgents: isAgentsSelector(state, props),
			isContactCard: isContactCardSelector(state, props),
			isCollaborator: isCollaboratorSelector(state, props),
			isAddToContactCard: isAddToContactCard(state, props),
			isShare : isShareStatistic(state, props),
			isSIPForward: sipCallShowAgentList(state, props),
			mCipherKey: m.cipherKey,
			extRefId: m.extRefId,
			sipCallConn: sipCallConn(state),
			sipCallStatus: sipCallStatus(state),
			sipServerName: (wfs.data ? wfs.data["sipServerName"] : ""),
			sipHideColdTransfer: (wfs.data ? wfs.data["sipHideColdTransfer"] : false),
			sipHideWarmTransfer: (wfs.data ? wfs.data["sipHideWarmTransfer"] : false),
			sipMakeCallCurrentErrand: sipMakeCallCurrentErrand(state),
			currentCipherKey: currentCipherKeyMemoize(state),
			currentSipErrandId: sipGetCurrentEid(state),
			manualEid: m.newErrandId,
			manualDispId: m.createdId,
			errandId: e.currentErrand.id,
			areaDialPlan: getErrandOutgoingDialplanSelector(state, props),
			displayId: e.currentErrand.errand ? e.currentErrand.errand.data.displayId : "",
			error: cb.error
		};
	return s;
};

const mapContactBookCallbacks = (dispatch, props) => {
	return {
		onToggle: (toggle) => {
			dispatch(showContactBook(toggle));
			if(!toggle) {
				dispatch(showSipAgentList(false));
			}
		},
		onShowPersonDatails: (tab, id) => {
			if(tab === CB_CONTACT_CARD) {
				dispatch(getCustomerContacts(id));
			}
			dispatch(setContactBookUI("selectedPerson", id));
		},
		onAddContact: () => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(addContactIntoCard("contactBook"));
		},
		onHandleSearch: (toggle, tab, search) => {
			if(tab === CB_CONTACT_CARD) {
				dispatch(fetchContactBook(search));
			}
		},
		onReplyAdddress: (id, email, activeMainView) => {
			if (activeMainView === MM_STATISTICS) {
				dispatch(updateShareParam("selectedAgent", id));
			} else {
				dispatch(appendReplyAddress(id, email));
			}
		},
		onHandleCurrentTab: type => {
			dispatch(setContactBookUI("currentTab", type));
		},
		onSelectedContact: id => {
			dispatch(setContactBookUI("selectedContact", id));
		},
		onSetSearchText: text => {
			dispatch(setContactBookUI("searchText", text));
		},
		handleColdTransferToAgent: (eid, targetAgentId, sipId, conn,
			displayId, mCipherKey, isManual, isExternalForward, extRefId,
			areaDialPlan) => {
			let dispatchee;
			if(eid != 0){
				//outgoing call
				let isClose = false;
				if(isManual == true){
					if(isExternalForward) {
						isClose = true;
					}
					dispatch(submitSipManualErrand(conn, eid, sipId,
						displayId, targetAgentId, extRefId, isExternalForward,
						mCipherKey, isClose));
				} else {
					//incoming call
					dispatch(transferSipErrand(eid, sipId, targetAgentId,
					displayId, extRefId, isExternalForward));
				}
			} else {
				//when no errand created
				if(isExternalForward && isManual) {
					dispatch(sipCloseManualErrandAfterColdTransfer(eid,
						mCipherKey, extRefId))
				}
				dispatch(sipColdTransfer(conn, sipId, targetAgentId,
					displayId, isExternalForward, isManual, areaDialPlan));
			}
			dispatch(showSipAgentList(false));
		},
		handleWarmTransferToAgent: (eid, targetAgentId, sipId, conn,
            displayId, mCipherKey, isManual, isExternalForward,
			extRefId, dialPlanId) => {
            dispatch(sipWarmTransfer(conn, sipId, targetAgentId,
                displayId, eid, mCipherKey, isManual, isExternalForward,
				extRefId, dialPlanId));
				dispatch(showSipAgentList(false));
        },
		onCloseExternalKeypad: () => {
			dispatch(showExtTransferKeypad(false));
		},
	};
};

export const ContactBookModalCtnr = connect(mapContactBook,
	mapContactBookCallbacks)(ContactBook);

const mapContactCard = (state, props) => {
	const e = state.app.errand, ui = e.ui, cc = ui.contactCard,
		cerd = e.currentErrand, cus = cerd.contactCard,
		notes = cerd.customerNotes,
		admn = state.app.admin,
		wf = state.app.workflow, wfs = wf.fetchWfSettings;
	const s = {
			isChat: (e.chat ? true : false),
			currentErrandId: cerd.id,
			contactCard: cc,
			defaultService: selectEmailChannel(state),
			noteAttachment: customerNoteAttachments(state),
			addButton: contactCardValidation(state, props),
			customer: update(cus.customer, {
				$merge: {
					photo: getClientAvatarByErrand(state),
					currentErrandId: cerd.id,
				}
			}),
			channel: cus.channel,
			contactList: cus.contactList,
			compList: companyListSelector(state, props),
			customerNotes: notes,
			otherContacts: contactCardOtherContactSelector(state, props),
			agentAnonymize: (wfs.data ? wfs.data["data-protection.anonymize-by-agent"] : false),
			dataPortabilityExport: (wfs.data ? wfs.data["data-protection.export"] : false),
			show: (cc.show ? cc.show : false),
			showMergeContactPopup: admn.admin.showMergeContactPopup,
			showCompanyInfo: cc.showCompanyInfo
		};
	return s;
};

export function confirmDeleteNoteAttachment(dispatch, type, attachmentID, attachmentFileName) {
	new Promise((resolve, reject) => {
		let warnBttns = warnYesOrNoButtons;
		resolve(dispatch(customConfirm(
			warnDeleteAttachment + attachmentFileName + "?"
			, warnBttns,
		))
		.then(({ button }) => {
			if (button === warnBttnYes) {
				return shouldDeleteAttachment;
			}
		}));
	})
	.then(shouldDeleteAttachment => {
		if(shouldDeleteAttachment) {
			dispatch(deleteNoteAttachment(attachmentID));
		}
	});
}

//test contact methods
const addContactCardEntry = (p) => async(postAddEntryToContactCard(p),
	errand[keyAddEntryToContactCard]
);

const addClientNotes = (p) => (dispatch, getState) => {
	const state = getState(), errand = state.app.errand, cerd = errand.currentErrand,
	cc = errand.ui.contactCard;

	let params = {
		errand: cerd.id,
		type: "client",
		text: p.text,
		attachments: p.attachments,
	};
	return dispatch(postCustomerNoteAsync(params))
};

const deleteContactCardErrand = id => async(deleteContactCard(id),
	errand[keyDeleteContactCard]
);

const contactCardMethods = {
    save: (p) => addContactCardEntry(p)
	// , addNotes: p => createOneErrandNotes(p)
	, addNotes: p => addClientNotes(p)
	, deleteNote: p => removeContactNotes(p)
	, deleteContactCard: p => deleteContactCardErrand(p)
}


const mapContactCardCallbacks = (dispatch, props) => {
	return {
		onToggle: toggle => {
			dispatch(getContactCard(toggle));
		},
		onToggleMergePopup: (toggle) => {
			dispatch(showMergeContactCardPopup(toggle));
		},
		onMergeContactCards: (view, params, id, sourceName, targetName) => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(sstAsyncs[M_CONTACT_CARDS].merge(params))
			.then((rs)=> {
				dispatch(getContactCard(true));
				dispatch(fetchCustomerNotes());
				dispatch(fetchCompanyList());
				dispatch(showMergeContactCardPopup(false));
				dispatch(toggleToastAlert(TOAST_TYPE.success, TOAST_POSITION.topRight, I(`Contact card ${sourceName} merged into ${targetName} successfully`)))
			})
			.catch(err => {
				console.log("error merging contact (errand page):", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onShowHistory: (toggle) => {
			dispatch(showContactCardHistory(toggle));
		},
		onShowChannelOptions: toggle => {
			dispatch(showContactCardChannel(toggle));
		},
		onSetContactCard: (field, value) => {
			dispatch(setContactCard(field, value));
		},
		onSelectAnsweredErrands: toggle => {
			dispatch(selectContactCardAnsweredHistory(toggle));
		},
		onSelectUnansweredErrands: toggle => {
			dispatch(selectContactCardUnansweredHistory(toggle));
		},
		onRemoveContact: id => {
			dispatch(removeContactFromCard(id));
		},
		onAddContact: () => {
			dispatch(addContactIntoCard("contactCard"));
		},
		onAddContactAccount: (view, params) => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(contactCardMethods.save(params))
				.then((res) => {
					const obj = res;
					dispatch(updateContactCard("add", obj));
					dispatch(getContactCard(true))
					dispatch(clearPopWaiting())
				})
				.catch(err => {
                    console.log("error saving :", err)
                    dispatch(clearPopWaiting());
                    return dispatch(popErrorOnly(err));
                });
		},
		onDeleteContactAccount: (accountId, id, list) => {
			dispatch(togglePopWaiting(pleaseWaitString(TXT_DELETING)));
			dispatch(removeContactFromCard(accountId, list))
		},
		onDeleteContactCard: (id, view, popout, list) => {
			dispatch(togglePopWaiting(pleaseWaitString(TXT_DELETING)));
			dispatch(contactCardMethods.deleteContactCard(id))
			.then((rs) => {
				dispatch(showContactCard(false))
				dispatch(clearPopWaiting());
			})
			.catch(err => {
				console.log("error deleting contact :", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onSaveContact: (view, params,) => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(contactCardMethods.save(params))
				.then((res) => {
					// dispatch(updateContactCard("list", obj)); //tofix- this resets avatar
					dispatch(refreshContactCard("contactCard", res));
					dispatch(getContactCard(true))
				})
				.catch(err => {
                    console.log("error saving :", err)
                    dispatch(clearPopWaiting());
                    return dispatch(popErrorOnly(err));
                });
		},
		onRemoveNote: id => {
			dispatch(removeCustomerNote(id));
		},
		onRemoveNoteV5: p => {
			// dispatch(contactCardMethods.notes(params))
			dispatch(removeCustomerNote(p.id))
		},
		onAddNote: () => {
			dispatch(addCustomerNote());
		},
		onAddNoteV5: (params, id) => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(contactCardMethods.addNotes(params))
			.then((rs) => {
				if(params.note === "") {
					dispatch(updateCustomerNote("add", rs.note));
				} else {
					dispatch(updateCustomerNote("edit", rs.note));
				}
				dispatch(clearPopWaiting());
			})
			.catch(err => {
				console.log("error saving notes:", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onCloseContactCard: () => {
			dispatch(showMergeContactCardPopup(false));
			dispatch(showContactCard(false))
		},
		onAddToExistingCard: (toggle) => {
			dispatch(showContactBook(toggle));
			dispatch(setReplyAddressType("", ADD_TO_CONTACT_CARD));
			dispatch(fetchContactBook(""));
		},
		onSaveAvatar: () => {
			dispatch(saveCustomerAvatar());
		},
		onRemoveAvatar: () => {
			dispatch(delCustomerAvatar());
		},
		onFileupload: (file, route) => {
			dispatch(uploadClientNoteAttachment(file, route));
		},
		onAttachmentDelete: (type, attachmentID, index, e, attachmentFileName) => {
			confirmDeleteNoteAttachment(dispatch, type, attachmentID, attachmentFileName);
		},
		onShowOtherContact: (id, threadId) => {
			dispatch(showOtherContactErrand(id, threadId));
		},
		onShowAlert: (msg) => {
			dispatch(togglePopAlert(msg));
		},
		refreshOtherContacts: (id) => {
			dispatch(historyErrandContacts(id, 0));
			dispatch(loadMoreOtherContacts(id, 0));
			if (features['link-errand']) {
				dispatch(ErrandMyErrandsAsync(id,0)) ;
				dispatch(FetchLinkedErrandsAsync(id, 0)) ;
			}
		},
		onChangeCompany: (field, obj) => {
			dispatch(updateContactCard(field, obj));
		},
		onShowCompanyInfo: (tgl) => {
			dispatch(showCompanyInfo(tgl));
		},
		onClearAttachments: () => {
			dispatch(setContactCard("noteAttachment", []));
		},
		onGetCompanyHistory: (id) => {
			dispatch(companyErrandHistory(id, 0));
			dispatch(companyErrandOtherContacts(id, 0));
		}
	}
};


export const ContactCardModalCntr = connect(mapContactCard,
	mapContactCardCallbacks)(ContactCardRenderer);

////// GDPR

const mapDataProtection = (state, props) => {
	const e = state.app.errand, ui = e.ui, cc = ui.contactCard,
		wf = state.app.workflow, wfs = wf.fetchWfSettings,
		anon = e.dataAnonymize, exl = e.dataExportLog, revoke = e.dataRevokeAccess, gdprUi = ui.gdpr;
	let anonymizeTaskId = 0, anonymizeTaskStatus = 0, anonymizeError = "",
	ready = false, working=true, path = "", secret = "", timestampEnabled = 0,
	expirySecs = 0, error = "", timezone, logs = [], revokeWIP = true;

	if(anon.data){
		let data = anon.data;
		if(data.result){
			anonymizeTaskId = data.result.id;
			anonymizeTaskStatus = data.result.status;
			anonymizeError = data.result.error;
		}
	}
	if(exl.data){
		let r = exl.data;
		if(r){
			ready = true;
			working = false;
			path = r.Path;
			secret = r.Secret;
			timestampEnabled = r.TimestampEnabled;
			expirySecs = r.ExpirySecs;
			error = r.Error;
			timezone = r.TimeZones;
			logs= r.Logs;
		}
	}
	if(revoke.data){
		revokeWIP = false;
	}
	const s = {
			agentAnonymize: (wfs.data ? wfs.data["data-protection.anonymize-by-agent"] : false),
			dataPortabilityExport: (wfs.data ? wfs.data["data-protection.export"] : false),
			errandId: e.currentErrand.id,
			anonymizeTaskId,
			anonymizeTaskStatus,
			anonymizeError,
			ready,
			working,
			secret: gdprUi.exportSecret,
			timestampEnabled: gdprUi.exportTimestampEnabled,
			expirySecs: gdprUi.exportExpirySecs,
			error: gdprUi.exportError,
			timezone: gdprUi.exportTimeZones,
			logs,
			exportPath: gdprUi.exportPath,
			exportLogs: gdprUi.exportLogs,
			exportError: gdprUi.exportError,
			exportTimestampEnabled: gdprUi.exportTimestampEnabled,
			exportExpirySecs: gdprUi.exportExpirySecs,
			selectedLang: gdprUi.selectedLang,
			selectedTZ: gdprUi.selectedTZ
		};
	return s;
};

const mapDataProtectionCallbacks = (dispatch, props) => {
	return {
		onGetAnonymize: (id, tz) => {
			dispatch(getAnonymizeData(id, tz));
		},
		onDoAnonymize: (id, tz) => {
			dispatch(anonymizeData(id, tz));
		},
		onGetExportLog: (id, tz) => {
			dispatch(getExportLog(id, tz));
		},
		onRevokeAccess: (id, secret) => {
			dispatch(delExportContact(id, secret));
		},
		onExportContact: (id, lang, tz) => {
			dispatch(exportContact(id, lang, tz));
		},
		onSelectedTZ: (tz) => {
			dispatch(setSelectedTZ(tz));
		},
		onSelectedLang: (lang) => {
			dispatch(setSelectedLang(lang));
		}
	}
};

export const DataProtectionCtnr = connect(mapDataProtection,
	mapDataProtectionCallbacks)(DataProtection);

///// END GDPR

const mapKnowledgeBasePopup = (state, props) => {
	const { chat, ui } = state.app.errand;
	return {
			ui: ui.knowledgeBase,
			searchSource: 0, // 0-either, 1-both, 2-question, 3-answer
			defaultLibrary: openErrandAreaLibraryMemo(state),
			currentReply: currentActiveReply(state),
			dataSrc: knowledgeBaseDataSelector(state, props),
			kbList: knowledgeBaseListSelector(state, props),
			chat,
			noForBotQuestion: noForBotQuestionMemo(state),
			userVote: ui.knowledgeBase.userVote,
			expandKnowledgeBasePanel: ui.expandKnowledgeBasePanel,
		};
};

const mapKnowledgeBasePopupCallbacks = (dispatch, props) => {
	return {
		handleToggle: toggle => {
			dispatch(showErrandLibraryPopup(toggle, RPLY_ERRAND));
		},
		getKnowledgeBase: (id, param) =>{
			dispatch(loadKnowledgeBase(id, param, true));
		},
		onRequestTrainChatbots: (id, chatbots) => {
			if (process.env.NODE_ENV !== 'production') {
				console.log(`dbg: request train chatbots intent library ${id}`);
			}
			return dispatch(askTrainChatbotsWithLibraryQuestion(id, chatbots));
		},
		onSelectLibrary: id =>{
			let param = {"hideTimeControl":true}
			dispatch(loadKnowledgeBase(id, param,true));
			dispatch(selectErrandLibrary(id));
		},
		onUpdateSelectedLibrary: id => {
			dispatch(selectErrandLibrary(id));
		},
		onSearchLibrary: isSearch =>{
			dispatch(setErrandLibrarySearch(isSearch));
		},
		onResetLibrarySearch: resetAttachment =>{
			dispatch(resetErrandLibrarySearch(resetAttachment));
		},
		onShowLibraryList: toggle =>{
			dispatch(showErrandLibraryList(toggle));
		},
		onShowAnswer: (p, node) => {
			dispatch(showLibraryAnswer(p));
			dispatch(setKnowledgebaseQuestionNode(node));
			// dispatch(fetchVoteFeaturedQuestion(p.id));
			dispatch(fetchUserVote(p.id));
		},
		fetchForFeaturedQuestion: p => {
			dispatch(fetchVoteFeaturedQuestion(p.id))
		},
		onUpdateFeaturedQuestion: p => {
			dispatch(setVoteFeaturedQuestion(p));
		},
		onSetSearchText: text => {
			dispatch(setSearchText(text));
		},
		handleAppendKnowledgeBase: (value, plain, attachments, reply, chat, qid) => {
			if(chat) {
				dispatch({type:CHAT_ADD_KB_FILE,chat,attachments});
				dispatch(showErrandLibraryPopup(false, ""));
				//Append chat was handled on the previous operation
				return;
			}
			console.log("add knowledge into errand start.");
			dispatch(insertContent(value))
				.then(() => {
					console.log("add knowledge base into errand done.");
				})
			.catch((error) => {
				console.error("Error inserting content:", error);
			});
			if (reply == RPLY_ERRAND) {
				dispatch(selectedLibraryQuestion(qid, reply))
			}
			dispatch(handleLibraryAttachments(attachments, reply));
			dispatch(showErrandLibraryPopup(false, ""));
		},
		onClearSearchTimeout: () =>{
			dispatch(clearLibrarySearchTimeout());
		},
		onChangeComment: (value) => {
			dispatch(updateUserVoteComment(value))
		},
		onChangeVote: (value) => {
			dispatch(updateUserVoteVote(value))
		},
		onUpdateUserVote: (p) => {
			dispatch(setUserVote(p))
		},
		onEditUserVote: (t) => {
			dispatch(updateUserVoteEdit(t))
		},
		onUpdateUserVoteWarning: (t) => {
			dispatch(updateUserVoteWarning(t))
		},
		onToggleKnowledgeBase: (tgl) =>{
			if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui") && cflag.IsActive("2024-05-23.CEN-2090.new-errand-knowledgebase-gui")){
				dispatch(doExpandKnowledgeBasePanel(tgl));
			} else {
				dispatch(showErrandLibraryPopup(tgl, RPLY_MANUAL));
			}
		},
		onCloseKnowledgeBasePopup: () =>{
			dispatch(doExpandKnowledgeBasePanel(false));
			dispatch(resetErrandLibrarySearch());
			dispatch(resetEdit());
		},
		onMinimizeKnowledgePopup: () => {
			dispatch(toggleRightSidePanel(true));
			dispatch(expandHideOption('KnowledgeBasePanel', 0, true));
			dispatch(doExpandKnowledgeBasePanel(false));
		}
	}
};

export const KnowledgeBasePopupCntr = connect(
	mapKnowledgeBasePopup,
	mapKnowledgeBasePopupCallbacks
)(KBPopupBase);

const mapRewriteBasePopup = (state, props) => {
	const { ui } = state.app.errand;
	return {
		showPopup: ui.rewriteAnswerBase.showPopup,
		currentQues: ui.rewriteAnswerBase.currentQues,
		newAIContext: ui.rewriteAnswerBase.newContext,
		AISession: getLlmSessionIdSelector(state, props),
		generatingAns: ui.rewriteAnswerBase.generatingAns,
		dataSrc: rewriteAnsBaseDataSelector(state, props),
		eid: getCurrentOpenErrandID(state, props),
		isChat: (state.app.errand.chat ? true : false),
		libraryId: openLibraryIdSelector(state, props),
		selectedLibraryQuestion: openQuestionIdSelector(state, props),
	};
};

const mapRewriteBasePopupCallbacks = (dispatch, props) => {
	return {
		handleToggle: toggle => {
			dispatch(showErrandRewriteAnswerPopup(toggle, RPLY_ERRAND));
		},
		generateAnswer: p => {
			dispatch(updateAIAnswerstate(true));
		},
		onUpdateQuestion: p => {
			dispatch(setSetRAQuestion(p));
		},
		onResetAIAnswer: text => {
			dispatch(resetAIAnswer(text, false, false));
		},
		handleCloseAIAnswer: (eid) => {
			dispatch(showErrandRewriteAnswerPopup(false, RPLY_ERRAND));
			dispatch(expandHideOption('AIAnswerPanel', eid, false));
			dispatch(toggleRightSidePanel(false));
		},
	}
};

export const RewriteAnswerPopupCntr = connect(
	mapRewriteBasePopup,
	mapRewriteBasePopupCallbacks
)(RewriteAnswerPopup);

const mapReWriteAnswer = (state, props) => {
	const wf = state.app.workflow, wfs = wf.fetchWfSettings
	, ui = state.app.errand.ui;
	return {
		show: ui.showAIAnswerPanel,
		area: getCurrentErrandAreaId(state, props),
		areaCustomAIInstructions: getAreaReWriteAnswerSetupMemo(state, props),
		currentQues: ui.rewriteAnswerBase.currentQues,
		newAIContext: ui.rewriteAnswerBase.newContext,
		AISession: getLlmSessionIdSelector(state, props),
		generatingAns: ui.rewriteAnswerBase.generatingAns,
		dataSrc: rewriteAnsBaseDataSelector(state, props),
		verticalView: wfs.data.verticalView,
		currentAnswer: replyAnswerSelector(state, props),
		agentFavouriteAITranslateLangs: state.app.header.agentFavourite[AGENT_FAV_AI_TRANSLATE_LANGUAGES],
		eid: getCurrentOpenErrandID(state, props),
		libraryId: openLibraryIdSelector(state, props),
		src: getCurrentOpenErrandID(state, props) ? "ERRAND" : "KNOWLEDGE_BASE",
		currentAgentAssistContext: ui.rewriteAnswerBase.agentAssistContext,
		selectedLibraryQuestion: openQuestionIdSelector(state, props),
	};
}

const mapReWriteAnswerCallbacks = dispatch => {
	return {
		onLoad: () => {
			dispatch(fetchAgentFavourite(AGENT_FAV_AI_TRANSLATE_LANGUAGES));
		},
		generateAnswer: p => {
			dispatch(updateAIAnswerstate(true));
		},
		onUpdateQuestion: p => {
			dispatch(setSetRAQuestion(p));
		},
		onSend: (action, p) => {
			//unique id per stream to avoid mixing up the responses
			const streamId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
			if (action === "start-over") {
				//clear all AI answer history and reset the question to use current answer
				dispatch(resetAIAnswer(p.content, false, true));
				dispatch(setSetRAQuestion(p.content));
				dispatch(updateAIAnswerstate(true));
				if (cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response") &&
				!features["openai-agent-assist-uses-openai"]) {
					dispatch(fetchAgentAssistRequest(p.errandId, p.context, p.content, streamId));
					dispatch(generateAIAnswerStreaming(p, streamId));
				} else {
					dispatch(generateAIAnswer(p));
				}
			} else {
				let answer = p.content;
				if (isEmptyString(answer)) {
					answer = answer.replace(/\s/g, '');
				}
				if(answer !== ""){
					dispatch(setSetRAQuestion(answer));
					dispatch(updateAIAnswerstate(true));
					if (cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response") && action !== "translate" &&
						!features["openai-agent-assist-uses-openai"]) {
						dispatch(fetchAgentAssistRequest(p.errandId, p.context, p.content, streamId));
						dispatch(generateAIAnswerStreaming(p, streamId));
					} else {
						dispatch(generateAIAnswer(p));
					}
				}
			}
		},
		onResetAIAnswer: text => {
			dispatch(resetAIAnswer(text, false, false));
		},
		onCopy: (p) => {
			const isFormatted = true;
			copyTextToClipboard(p, isFormatted);
		},
		onInsertAIAnswer: (value, isChat, src, isPopup, usePartial) => {
			if(!isChat) {
				const kbEditor = CKEDITOR.instances["Library_answer"];
				if (kbEditor) {
					kbEditor.setData(value);
				} else {
					const errandEditor = CKEDITOR.instances["ckeditorv5"];
					var selection = errandEditor.getSelection();
					var ranges = selection.getRanges();
					if (ranges.length > 0) {
						const range = ranges[0];
						// Split the value into multiple paragraphs if plain text
						const paragraphs = value.includes('<') 
							? value 
							: value.split('\n\n').map(para => `<p>${para}</p>`).join('');
						// Create a CKEditor document fragment
						const fragment = new CKEDITOR.dom.documentFragment(errandEditor.document);
						// Parse and append each paragraph to the fragment
						const tempContainer = CKEDITOR.dom.element.createFromHtml(`<div>${paragraphs}</div>`);
						while (tempContainer.getFirst()) {
							fragment.append(tempContainer.getFirst());
						}
						range.deleteContents();
						range.insertNode(fragment);
					} else {
						dispatch(inputTextChanges('update_answer', value, true));
					}
				}
				if(isPopup) {
					dispatch(showErrandRewriteAnswerPopup(false));
				}
			} else {
				//direct into current chat editor as this doesnt use state at all !
				const editor = CKEDITOR.instances["chatEditor"];
				if (editor) {
					editor.setData(value);
				}
			}
			if(isPopup) {
				dispatch(showErrandRewriteAnswerPopup(false, RPLY_ERRAND));
			}
		},
		onUpdateLangFavourites: (code) => {
			dispatch(updateAgentFavourite(AGENT_FAV_AI_TRANSLATE_LANGUAGES, code))
		},
	}
}

export const RewriteAnswerBoxContentCtnr = connect(mapReWriteAnswer, mapReWriteAnswerCallbacks)(RewriteAnswerBoxContent);

const AcquireErrandsCtnr = withAcquireErrands(AcquireErrands);
export const AcquireErrandsModalCtnr = withAcquireErrands(AcquireErrandsModal);

const mapHeaderInfo = (state, props) => {
	const top = state.app.errand,
		wf = state.app.workflow,
		wfs = wf.fetchWfSettings,
		selectedTags = top.inputs.tags,
		ui = top.ui.reply,
		currentArea = top.inputs.area,
		currentDueDate = top.inputs.done_date,
		doneDateFeature = wfs.data.doneDateFeature,
		showErrandState = wfs.data.showErrandState,
		errandStates = wfs.data.errandStates,
		showErrandReturnPath = wfs.data.showErrandReturnPath,
		autoPickupFeature = wfs.data["pickUpNext"],
		activeUserId = wfs.data.activeUserId,
		currentAgentStatus = (state.app.header.currentStatus.data ? state.app.header.currentStatus.data.status : ""),
		serviceConts = state.server.services.constants,
		errandAreaData = getCurrentErrandAreaData(state),
		historyAttachment = historyAttachmentListSelector(state, props),
		currentContext = contextMemo(state),
		errandData = getErrandDataAndClientById(state, props),
		s = {
			isChat: state.app.errand.chat != null,
			chat: state.app.errand.chat,
			attachmentNotReady: attachmentNotReady(state, props),
			attachmentSize: incomingAttachmentsSize(state, props)+historyAttachment.size,
			totalAttachmentsSize: totalAttachmentsSize(state, props)+historyAttachment.size,
			selectedTags,
			errand: errandData.errand,
			errandActionWip: isErrandActionInProgressSelector(state),
			isCollaboration: isCollaborationSelector(state, props),
			manualServices: state.server.services.manual,
			collaborateLibraries: top.collaborationInputs.library_attachments,
			tags: getAreaTags(state, props),
			allTags: getBothAreaTags(state, props),
			ui,
			currentArea,
			doneDateFeature,
			showErrandReturnPath,
			errandAreaData,
			currentDueDate,
			showErrandState,
			errandStates,
			style: errandData.style,
			toggleAttachBox: top.ui.toggleAttachBox,
			showAttachment: top.ui.showAttachment,
			showReplyBox: ui.show,
			selectedReplyTab: ui.selectedReplyTab,
			hasAgentAttached: top.ui.isAgentAttached,
			currentContext: currentContext ? currentContext : CTX_MANUAL,
			acquiredData: top.acquireErrand.data.acquire,
			autoPickupFeature,
			activeUserId,
			currentAgentStatus,
			Workflow: serviceConts.Workflow,
			toggleableErrandLink: true,
			filterPopup: top.ui.filterPopup,
			areas: allConnectedAreasSelector(state, props),
			forwardAreaInOrg: wfs.data['agent.only-forward-to-area-in-organisation'],
			nameOncontactCard: contactCardCustomerSelector(state, props),
			myErrandsNorm: state.app.errand.myErrands.data.norm,
			isPostponed: errandData.errand.state === Workflow.ErrandStates.STATE_POSTPONED
		};
	if (s.chat) {
		s.selectedTags = getChatTagLevels(s.chat);
	}

	return s;
};

const mapHeaderInfoCallbacks = (dispatch, props) => {
	return {
		onToggleTagsSelection: toggleState => {
			if(toggleState) {
				props.onShowSelection();
			}
			dispatch(tagsOperation('show', toggleState));
		},
		onToggleArea: toggleState => {
			if(toggleState) {
				props.onShowSelection();
			}
			dispatch(areaOperation('show', toggleState));
		},
		onChangeArea: value => {
			dispatch(changeArea(value));
		},
		onChangeState: (id, promptAgent, name) => {
			dispatch(changeErrandInternalState(id, promptAgent, name));
		},
		onAddTag: (value, chat) => {
			let p = props;
			if (chat) {
				let newTagLevels = [...getChatTagLevels(chat)];
				newTagLevels.push(value);
				let  tags = flattenTagLevels(newTagLevels);
				AgentSocket.SendEvent(evtCHAT_SET_TAGS, {sessionId: chat.sessionId, tags: tags}, (ack) => {
					if (ack.error) {
						let msg = I("{CHAT_ID} An error occurred when adding the tag. Please contact support. {ERROR}")
							.replace('{CHAT_ID}', 'CHAT#'+chat.errand.data.id)
							.replace('{ERROR}', ack.error)
							;
						dispatch(togglePopAlert(msg));
					} else {
						dispatch({type:CHAT_UPDATE_TAGS, chat, Tags: ack.Tags});
					}
				});
			} else {
				dispatch(tagsOperation(TAG_ADD, value));
			}
		},
		onDeleteTag: (value, chat) => {
			if (chat) {
				let newTagLevels = [...getChatTagLevels(chat)];
				newTagLevels.splice(value, 1)
				let tags = flattenTagLevels(newTagLevels);
				AgentSocket.SendEvent(evtCHAT_SET_TAGS, {sessionId: chat.sessionId, tags: tags}, (ack) => {
					if (ack.error) {
						let msg = I("{CHAT_ID} An error occurred when removing the tag. Please contact support. {ERROR}")
							.replace('{CHAT_ID}', 'CHAT#'+chat.errand.data.id)
							.replace('{ERROR}', ack.error)
							;
						dispatch(togglePopAlert(msg));
					} else {
						dispatch({type:CHAT_UPDATE_TAGS, chat, Tags: ack.Tags});
						dispatch(tagsOperation(TAG_DELETE, value));
					}
				});
			} else {
			      dispatch(tagsOperation(TAG_DELETE, value));
			}
		},
		onChangeDoneDate: value => {
			dispatch(dueDateOperation(value));
		},
		onToggleReplyBoxErrandMenu: (toggle) => {
			dispatch(toggleReplyBoxErrandMenu(toggle));
		},
		onSwitchAcquire: type => {
			dispatch(acquireOperations('switch', type));
		},
		handleAttachmentToggleBox:(tgl, showAttachment) =>{
			dispatch(handleAttachmentToggleBox(tgl));
			dispatch(toggleAttachmentList(showAttachment));
		}
	};
};

export const ErrandBoxHeaderInfoCtnr = connect(
	mapHeaderInfo
	, mapHeaderInfoCallbacks
)(ErrandBoxHeaderInfo);

ErrandBoxHeaderInfoCtnr.displayName = "ErrandBoxHeaderInfoCtnr";

const mapComment = (state, props) => {
	const wf = state.app.workflow;
	let ui = state.app.errand.ui;
	let lockEditErrandNote = wf.fetchWfSettings.data["notes.errand.edit"];
	return {
		state: internalCommentStateSelector(state, props),
		isAgent: true,
		show: ui.showComment,
		noteList: getInternalCommentsSelector(state, props),
		attachments: internalCommentAttachments(state, props),
		filterPopup: ui.filterPopup,
		showExactDayAndTime: wf.fetchWfSettings.data["show-exact-day-and-time"],
		lockEditErrandNote: lockEditErrandNote,
		currentEditNote: state.app.errand.inputs.current_edit_note.note
	};
};

const mapCommentCallbacks = dispatch => {
	return {
		onToggleShow: (eid, toggleState) => {
			dispatch(expandHideOption('note', eid, toggleState));
		},
		onDeleteNoteClick: (note, errand) => {
			const text = I('Are you sure delete note {NOTE_ID}?')
				.replace('{NOTE_ID}', note);
			let isChat=false;
			dispatch(enableConfirm('delete_note', text, {note, errand, isChat}))
			.then((rs)=> {
				dispatch(reloadBasicErrand(errand));
			})
			.catch(error => {
				console.log('error saving note', error);
			});
		},
		onEditNoteClick: (noteId, errandId) => {
			dispatch(editErrandNotes(noteId, errandId));
		},
		onSaveNote: noteId => {
			dispatch(notesOperation('save', noteId));
		},
		onCopyNote: (noteId, errandId) => {
			//dispatch(notesOperation('copy', noteId));
		}
	};
};

const withCommentCtnr = connect(mapComment, mapCommentCallbacks);

const CommentCtnr = withCommentCtnr(MessageInternalComment);

export const InternalCommentContentsCtnr = withCommentCtnr(InternalCommentContents);

const mapCountdownTimer = (state, props) => {
	const wf = state.app.workflow,
		e = state.app.errand;
	const s = {
			aventaEnabled: wf.fetchWfSettings.data.aventaEnabled,
			sipEnabled: wf.fetchWfSettings.data.sipEnabled,
			e: e
		};
	return s;
};

const mapCountdownTimerCallbacks = dispatch => {
	return {
		handleAutoClose: id => {
			let option = {
				isCall: true
				, manual: false
				, isSip: true
				, isClose: true
			};
			let param = {
				update_id: id
			}
			dispatch(askForClassification(
				param
				, option
				, false
				, false
				, false
			)).then((data) => {
				dispatch(doCloseErrand(id, false, true));
			})
		},
	};
};

export const CountdownTimerCtnr = connect(mapCountdownTimer,
	mapCountdownTimerCallbacks)(CountdownTimer);

const messageType = {
	note: (key, eid, innerRef) => <CommentCtnr key={key} eid={eid} innerRef={innerRef} />
	, collaborate: (key, eid, innerRef) => <CollaborateMsgCtnr key={key} eid={eid} innerRef={innerRef} />
};

export const messages = (type, eid, innerRef) => {
	const key = type+eid;

	return messageType[type](key, eid, innerRef);
};

const mapReplyHeader = (state, props) => {
	const e = state.app.errand, inpts = e.inputs, app = state.app,
		ui = e.ui.reply, collaInputs = e.collaborationInputs,
		eArea = getCurrentErrandAreaData(state, props),
		wf = state.app.workflow, wfs = wf.fetchWfSettings;
	let library=0;
	if(typeof  e.errandAreaData.data !== "undefined" &&
		e.errandAreaData.data != null){
		library = e.errandAreaData.data.library;
	}
	let isCollaboration = isCollaborationSelector(state, props);
	const s = {
		chat: e.chat,
		errandId: e.currentErrand.id,
		previewDisabled: isPreviewDisabledSelector(state) || isErrandActionInProgressSelector(state),
		showPreview: showPreviewSelector(state),
		signatures: getAreaSignatures(state),
		salutations: getAreaSalutations(state),
		templates: templateForAnswer(eArea.template_contents, e, getCurrentChannel(state)),
		selectedSig: (isCollaboration ? collaInputs.update_signature : inpts.update_signature),
		selectedSal: inpts.update_salutation,
		answer: replyAnswerSelector(state, props),
		plain: replyPlainAnswerSelector(state, props),
		currentChannel: currentErrandReplyChannel(state),
		defaultLibrary: library,
		knowledgeBase: props.knowledgeBase,
		currentSelected: getCurrentReply(state, props),
		isCollaboration: isCollaboration,
		openKnowledgeBase: e.ui.knowledgeBase.showPopup,
		openRewriteAnswerBase: e.ui.rewriteAnswerBase.showPopup,
		attachmentSize: serializedAttachmentSizeSelector(state, props),
		showUploadAttachmentBox: ui.showUploadAttachmentBox,
		canTranslate: canTranslateSelector(state, props),
		toLang: translateToSelector(state, props),
		filterPopup: e.ui.filterPopup,
		onlyAllowAdminSetSignature: eArea.area_admin_tick_sign,
		onlyAllowAdminSetSalutation: eArea.area_admin_tick_sal,
		agentEnforceSignatureAnswerErrand: wfs.data.agentEnforceSignatureAnswerErrand,
		suggestedAnswers: getSuggestedAnswer(state),
		quickReplies: getAreaQuickReplies(state),
		displayQuickReply: shouldHideQuickReply(state)
	};
	return s;
};

const mapReplyHeaderDispatch = (dispatch, props) => {
	return {
		getKnowledgeBase: (id, param) =>{
			dispatch(loadKnowledgeBase(id, param,true));
		},
		onOpenInsertProtectedContentReply: () => dispatch(showInsertProtect(true)),
		onToggleKnowledgeBase: (tgl) =>{
			dispatch(showErrandLibraryPopup(tgl, RPLY_ERRAND));
		},
		onClickPreview: reply => {
			promiseProcessEmail(dispatch, reply, previewEmailWithPost);
		},
		onSelectLibrary: id =>{
			let param = {}
			dispatch(loadKnowledgeBase(id, param,true));
			dispatch(selectErrandLibrary(id));
		},
		onSearchLibrary: isSearch =>{
			dispatch(setErrandLibrarySearch(isSearch));
		},
		onShowLibraryList: toggle =>{
			dispatch(showErrandLibraryList(toggle));
		},
		setSignature: (id, currentReply) => {
			dispatch(setPersonalization("update_signature", id, currentReply))
		},
		setSalutation: (id, currentReply) => {
			dispatch(setPersonalization("update_salutation", id, currentReply))
		},
		appendTemplate: (value) => {
			dispatch(insertContent(value))
		},
		appendSuggestedAnswer: (answer, attachments, reply) => {
			dispatch(insertContent(answer))
			dispatch(handleLibraryAttachments(attachments, reply));
			dispatch(setSuggestedAnswerUsed(true));
		},
		toggleUploadAttachment: currentShowState => {
			dispatch(showUploadAttachment(!currentShowState));
		},
		onSelectedToLang: (l, reply) => {
			if (reply === RPLY_ERRAND || reply === RPLY_EXT_FWD) {
				dispatch(setAnswerTranslation('to', l));
			} else if (reply === RPLY_COLLABORATE) {
				dispatch(setCollaborationTranslateTo(l));
			}
		},
		onShowTemplateCustom: (tgl, wc, id) => {
			dispatch(toggleWaTemplPopup(tgl));
			dispatch(setWaTemplContent(wc, id));
		},
		onInsertQuickReply: (id, wc, code) => {
			//updateReformatAnswer expects html and plain text
			//for this plain text no need
			dispatch(updateReformatAnswer(wc, ""));
			dispatch(setQuickReplyContent(id, code));
		},
	};
};

export const ReplyHeaderCtnr = connect(
	mapReplyHeader
	, mapReplyHeaderDispatch
)(ErrandReplyHeader);

const mapReply = (state, props) => {
	const e = state.app.errand;
	const search = state.app.search;
	let answerBoxHeight = state.server.features["workflow.answerbox-height"];
	if(e.recordEditorSize && e.recordEditorSize.data){
		if(typeof e.recordEditorSize.data.answer !== 'undefined'){
			answerBoxHeight = e.recordEditorSize.data.answer;
		}
	}
	const eArea = getCurrentErrandAreaData(state, props),
		ui = e.ui.reply, inpts = e.inputs,
		collaInputs = e.collaborationInputs,
		wf = state.app.workflow, wfs = wf.fetchWfSettings,
		chn = getChannelsSelector(state, props),
		colChn = getCollabChannelsSelector(state, props),
		isCollaboration = isCollaborationSelector(state, props),
		ckConf = wfs.data.answerWysiwyg,
		d = state.domain,
		currentContext = contextMemo(state),
		s = {
			block: isSaving(state),
			chat: e.chat,
			isChat: e.errand.isChat,
			attachments: serializedAttachmentsSelector(state, props),
			attachmentSize: serializedAttachmentSizeSelector(state, props),
			lastSavedTimestamp: e.ui.lastSave.time,
			lastSavedTimestampEE: e.ui.lastSaveEE.time,
			timezone: agentTimezoneOffsetSelector(state, props),
			selectedAgents: collaborationGroupName(state, props),
			selectedAreas: collaInputs.selectedAreas,
			areas: onlyActiveAreasSelector(state),
			errandId: e.currentErrand.id,
			areaId: getCurrentErrandAreaId(state, props),
			hideFooterCheckboxes: isVoiceChannelLike(state),
			hideSubjectbar: hideSubjectbarSelector(state, props),
			insertionText: ckeditorInsertionTextMemoize(state),
			replyCheckboxes: replyCheckboxesSelector(state, props),
			replyCheckboxStates: replyCheckboxStatesSelector(state, props),
			availableReplyPanel: getAvailableReplyPanel(state),
			selectedReplyPanel: getSelectedReplyPanel(state),
			selectedReplyTab: ui.selectedReplyTab,
			showCollaboratorList: ui.showCollaboratorList,
			showRecipients: ui.showRecipients,
			showReplyToolbar: ui.showReplyToolbar,
			showReplyAssist: ui.showReplyAssist,
			showSubject: ui.showSubject,
			showChannelSelection: ui.showChannel,
			showActionSelection: ui.showAction,
			buttonCondition: buttonStateSelector(state, props),
			currentChannel: currentErrandReplyChannel(state),
			selectedCollabChannel: currentCollabChannel(state),
			currentReply: getCurrentReply(state, props),
			classification: inpts.classification ? true : false,
			channels: chn,
			collabArchiveAttachment: collaInputs.archive_attachments,
			collabChannels: colChn,
			subject: replySubjectSelector(state, props), //inpts.update_subject,
			emailRecipients: getEmailRecipients(state, props),
			recipientsOptions: recipientsOptionsSelector(state, props),
			addressBook: eArea.addressbook_addresses,
			answer: replyAnswerSelector(state, props), //inpts.update_answer,
			question: errandQuestionSelector(state, props),
			comment: inpts.internal_comment,
			ckEditorBox: wfs.data.answerWysiwyg,
			//archiveImgs: eArea.file_archive_images, /*its not only area's archive, extended errands file also be used*/
			collabOpts: getCollabOpts(state),
			channelOpts: getChannelOpts(state),
			showRatingPopup: ui.showRatingPopup,
			showSendPMPopup: ui.showSendPMPopup,
			showReactionPopup: ui.showReactionPopup,
			showUpdateAnswerPopup: ui.showUpdateAnswerPopup,
			ratings: ratingsDataList(state),
			reactions: reactionsDataList(state),
			pms_body: inpts.pms_body,
			showAttachment: ui.showAttachment,
			showAttachmentPanel: e.ui.showAttachment,
			showUploadAttachmentBox: ui.showUploadAttachmentBox,
			isUploading: e.uploadAnswerAttachment.wip,
			attachFile: attachFileOptionsSelector(state, props),
			archiveImgs: fileArchivesSelector(state, props),
			showArchiveDD: ui.showArchiveDD,
			preference: previewPersonalization(state, props),
			previewDisabled: isPreviewDisabledSelector(state) || isErrandActionInProgressSelector(state),
			canTranslate: canTranslateSelector(state, props),
			toLang: translateToSelector(state, props),
			knowledgeBase: e.ui.knowledgeBase,
			errandKnowledgeBaseShortcuts: libraryShortcutsSelector(state, props),
			selectedSig: (isCollaboration ?
				collaInputs.update_signature : inpts.update_signature),
			selectedSal: inpts.update_salutation,
			showContactBook: e.ui.contactBook.show,
			allowChangeChannelFeature: wfs.data["change-channel"],
			agentStore: state.app.agentStore,
			openKnowledgeBase: e.ui.knowledgeBase.showPopup,
			openRewriteAnswerBase: e.ui.rewriteAnswerBase.showPopup,
			shouldShowOpenAI: wfs.data["openai-rewrite-answer"],
			templates: eArea.template_contents,
			hasQuickReply: eArea.quickReplies,
			quickReplies: eArea.quickreply_contents,
			preferredAnswerBoxHeight: answerBoxHeight,
			filterPopup: e.ui.filterPopup,
			shouldShowTweetWarning: ui.shouldShowTweetWarning,
			numberOfTweet: ui.numberOfTweet,
			twtChannelInfo: e.currentErrand.smData,
			errandFrom: (e.basic.data !== null ? e.basic.data.data.fromName : ""),
			verticalView: wfs.data.verticalView,
			preferredSpellcheckLang: wfs.data["spell"],
			spellcheckLangs: ckConf.languageSrc ? ckConf.languageSrc : "",
			check: checkCollabHistory(state),
			canInsertIncomingAttachments: wfs.data.attachmentListErrandShowStatus,
			selectAllQuestionFiles: isAllCurrentFilesAttached(state, props),
			agentWorking: getAgentWorking(state),
			openSearchShortcut: e.ui.searchShortcut.showDropdown,
			openSearchInput: e.ui.searchShortcut.showSearch,
			plainAnswer: inpts.plain_answer,
			isSearch:  isFromSearch(state),
			searchText: search.inputs.replyText,
			showReplyResultBox: search.inputs.showReplyResultBox,
			searchList: searchListSelector(state, props),
			searchResults: search.results,
			isSearching: search.inputs.replySearchOngoing,
			disableActiveSearch: wfs.data.disableActiveSearch,
			clientAvatar: state.domain.clientAvatar,
			currentOpened: getCurrentOpenErrandSelector(state, props),
			showExactDayAndTime: wfs.data["show-exact-day-and-time"],
			currentContext: contextMemo(state),
			collaborationInfo: getCollaborationInfoSelector(state, props),
			canOffCollaborationLight: getAllowLightBulbOff(state),
			previewActivated: getPreviewFeatures(wfs.data ? wfs.data : {}, currentContext),
			errandPreview: d.previewData.byId[wf.ui.currentPreviewId] ? d.previewData.byId[wf.ui.currentPreviewId] : "",
			totalSearchFiltered: totalSearchResult(state),
			searchOffset: search.ui.bodySearch.topResults,
			ui: wf.ui,
			wfs: wfs.data,
			showWATemplatePopup: ui.showWATemplatePopup,
			templContent: inpts.templContent,
			templCode: inpts.templCode,
			waTemplId: inpts.templId,
			sipMakeCallCurrentErrand: sipMakeCallCurrentErrand(state),
			collaborationAttachments: getCollabAttachments(state),
			areaCustomAIInstructions: getAreaReWriteAnswerSetupMemo(state, props),
			haveAIAnswerHistory: getRewriteAnswerBaseQuestionMemo(state,props),
			newAIContext: e.ui.rewriteAnswerBase.newContext,
			AISession: getLlmSessionIdSelector(state, props),
		};
	return s;
};

//TODO(Mujibur): Unused at the moment, as the idea might include both template either whatsapp or normal
//to support the cention system template as a support
function templateForAnswer(tmplContents, e, currentService){
	let errandTemplates = tmplContents && tmplContents.filter(tmpl =>{
		if(tmpl.UsedFor === TEMPLATE_USED_FOR_ERRAND){
			return tmpl
		}
	});
	let waTemplates = tmplContents && tmplContents.filter(tmpl =>{
		if(tmpl.UsedFor === TEMPLATE_USED_FOR_WHATSAPP){
			return tmpl
		}
	});
	if(!e.errand.isChat){
		if(currentService !== Workflow.Errand.SERVICE_WHATSAPP){
			return errandTemplates;
		}
		return waTemplates;
	}
	if(e.chat.errand.data.sourceId === Workflow.Errand.SERVICE_WHATSAPP){
		return waTemplates;
	}
	return errandTemplates;
}

function confirmDeleteAttachment(dispatch, reply, type, attachmentID, attachmentFileName) {
	new Promise((resolve, reject) => {
		let warnBttns = warnYesOrNoButtons;
		resolve(dispatch(customConfirm(
			warnDeleteAttachment + attachmentFileName + "?"
			, warnBttns,
		))
		.then(({ button }) => {
			if (button === warnBttnYes) {
				return shouldDeleteAttachment;
			}
		}));
	})
	.then(shouldDeleteAttachment => {
		if(shouldDeleteAttachment) {
			if(type === AT_SAVED) {
				dispatch(deleteSavedAttachment(reply, attachmentID))
			} else if(type === AT_UPLOADED) {
				dispatch(deleteUploadedAttachment(reply, attachmentID));
			} else if(type === AT_ARCHIVE) {
				dispatch(removeSelectedArchive(reply, attachmentID));
			}
		}
	});
}

var typingTimer;
var doneTypingInterval = 500;
const mapErrandReplyDispatch = (dispatch, props) => {
	return {
		dispatch,
		onAttachmentDelete: (reply, type, attachmentID, attachmentFileName) => {
			confirmDeleteAttachment(dispatch, reply, type, attachmentID, attachmentFileName);
		},
		onInsertCollabAnswerAttachment: attachment => {
			dispatch(insertCollaborateAnsAttachment(attachment))
		},
		onClickPreview: reply => {
			promiseProcessEmail(dispatch, reply, previewEmailWithPost);
		},
		onDragnDropFile: (reply, chat, data, info) => {
			if (reply !== RPLY_COMMENT) {
				dispatch(showErrandAttachmentList(true));
			}
			return dispatch(uploadOneAgentAttachment(
				{data, info}
				, reply
				, chat
			));
		},
		onResetInsertionText: () => {
			dispatch(clearInsertionContent());
		},
		onToggleChannel: toggleState => {
			dispatch(showHideReplyChannel(toggleState));
		},
		onToggleAction: toggleState => {
			dispatch(selectShowReplyAction(toggleState));
		},
		onSelectAction: (eid, action, channel) => {
			dispatch(socialMediaReplyAction(eid, action, channel));
		},
		socialMediaUI:(toggle, whom) =>{
			dispatch(toggleSocialMediaUI(toggle, whom));
		},
		onToggleShowCollaboratorList: toggledState => {
		},
		handleOnChangeMsg: (actType, msg, channel) =>{ //actType is either sendPM/update post
			dispatch(messageSocialMedia(actType, msg, channel));
		},
		handleSendMessage: (eId, actType, channel) =>{
			dispatch(deliverMessageToSocialMedia(eId, actType, channel));
		},
		//not used ?
		onSelectChannel: channel => {
			dispatch(selectReplyChannel(channel));
			dispatch(updateReplyToAndChannel(channel));
		},
		onToggleRecipients: toggleState => {
			dispatch(updateShowRecipientsPref(toggleState))
		},
		onToggleReplyToolbar: toggleState => {
			dispatch(updateShowReplyToolbarPref(toggleState));
		},
		onToggleReplyAssist: toggleState => {
			dispatch(updateShowReplyAssistPref(toggleState))
		},
		onToggleSubject : toggleState => {
			dispatch(updateShowSubjectPref(toggleState));
		},
		onSelectTab: tab => {
			dispatch(updateSelectedTabPref(tab));
		},
		onAnswerSubjectChange: value => {
			if(props.currentChecked !== RPLY_COLLABORATE) {
				dispatch(inputTextChanges('update_subject', value));
			} else {
				dispatch(inputTextChanges('update_subject', value,
					RPLY_COLLABORATE));
			}
		},
		onQuestionChange: (value, plain, reformat) =>{
			if(props.currentChecked == RPLY_QUESTION){
				dispatch(inputTextChanges('update_question', value, plain,
					RPLY_QUESTION));
			}
		},
		onAnswerChange: (value, plain, reformat, updateManualCall) => {
			if(props.currentChecked === RPLY_COLLABORATE) {
				if(!reformat) {
					dispatch(inputTextChanges('update_answer', value, plain,
					RPLY_COLLABORATE));
					dispatch(agentEEIsTyping());
				} else {
					dispatch(updateCollabReformatAnswer(value, plain, RPLY_COLLABORATE));
				}
			} else if(props.currentChecked === RPLY_COMMENT) {
				dispatch(inputTextChanges('internal_comment', value, plain));
			} else {
				if(!reformat) {
					dispatch(inputTextChanges('update_answer', value, plain));
					if(updateManualCall) {
						dispatch(inputTextChangesForCall("update_answer", value, plain, RPLY_MANUAL));
					}
					dispatch(agentIsTyping());
				} else {
					dispatch(updateReformatAnswer(value, plain));
				}
			}
		},
		onRecipientsChange: (recipients, which) => {
			if(props.currentChecked !== RPLY_COLLABORATE) {
				dispatch(recipientsChange(recipients, which));
			} else {
				dispatch(recipientsChange(recipients, which, RPLY_COLLABORATE));
			}
		},
		onReplyCheckboxesClick: (isChat, reply, type, state, check) => {
			// console.log('dbg: reply check:', {reply, type, state, check});
			if (reply === RPLY_COLLABORATE) {
				if (type === ECB_FWD_ALL_HISTORIES) {
					if (isChat) {
						dispatch(toggleAllErrandHistories(state));
					} else {
						dispatch(changeExternalForwardAll(state));
					}
					dispatch(changeIncludeErrandHistory(state)); // TODO: seem useless as old code
				} else if (type === COL_NEW_THREAD) {
					if(state) {
						dispatch(createExpertQueryThread());
					} else {
						// purposely do nothing for unselection of new thread
					}
				} else if (type === ECB_INC_COLLAB_HISTORY && check.incCollabHis) {
					dispatch(changeIncludeCollabHistory(state));
				} else {
					dispatch(updateCollaborationCheckboxes(type, state));
				}
			} else if (reply === RPLY_COMMENT) {
				if(type === ICCB_NEW) {
					if(state) {
						// console.log('dbg: check new internal comment');
						dispatch(prepareCreateNewInternalComment());
					} else {
						// can not uncheck as non-new mean editing existing
						// internal comment which require specific edit which
						// internal comment.
					}
				} else {
					// do nothing
				}
			} else if (reply === RPLY_EXT_FWD
				&& type === ECB_FWD_ALL_HISTORIES) {
				dispatch(changeExternalForwardAll(state));
			} else if (reply === RPLY_EXT_FWD && type === ECB_ALL_CURR_Q_AT) {
				dispatch(changeExternalForwardQuestionAllAttachmentsSelection(state));
			} else if (reply === RPLY_ERRAND
				&& type === ECB_INC_HISTORIES) {
					dispatch(changeExternalForwardAll(state));
					dispatch(updateReplyCheckboxes(type, state));
			} else {
				dispatch(updateReplyCheckboxes(type, state));
			}
		},
		onButtonClick: type => {
			if (type === BTN_CLEAR) {
				switch(props.currentChecked) {
					case RPLY_ERRAND:
						dispatch(clearInputText('update_answer'));
						break;
					case RPLY_EXT_FWD:
						dispatch(clearInputText('update_answer'));
						break;
					case RPLY_COLLABORATE:
						dispatch(clearInputText('update_answer',
							RPLY_COLLABORATE));
						break;
					case RPLY_COMMENT:
						dispatch(clearInputText('internal_comment'));
				}
			} else if(type === BTN_SEND) {
				const reply = props.currentChecked;
				switch(reply) {
					case RPLY_ERRAND:
						dispatch(sendReply({all: false, reply}));
						break;
					case RPLY_EXT_FWD:
						dispatch(sendReply({all: false, reply}));
						break;
					case RPLY_COLLABORATE:
						// dispatch(sendExpertQuery());
						dispatch(submitCollaboration());
						break;
					case RPLY_COMMENT:
						dispatch(createOrEditNote(false));
				}
			} else if (type === BTN_SAVE) {
				const reply = props.currentChecked;
				if (reply == RPLY_COMMENT) {
					dispatch(createOrEditNote(false));
				} else {
					dispatch(buttonClickSaveErrand());
				}
			} else if(type === BTN_SEND_ALL) {
				const reply = props.currentChecked;
				if (reply == RPLY_ERRAND) {
					dispatch(sendReply({all: true, reply}));
				} else {
					console.log('dbg: unexpected reply result:', reply);
				}
			} else if(type === BTN_RESEND) {
				const reply = props.currentChecked;
				dispatch(resendErrand(reply));
			} else if(type == BTN_RESET) {
				dispatch(resetInputText(RPLY_QUESTION));
			} else if(type === BTN_PREVIEW || type === BTN_SAVE_AS_EML) {
				const reply = props.currentChecked
					, emailType = EMAIL_TYPE_FROM_REPLY[reply]
					;
				if (typeof emailType === "undefined") {
					console.log("dbg: preview N/A:", {reply});
					return;
				}
				if (type === BTN_PREVIEW) {
					// dispatch(previewEmail(emailType, {reply}));
					dispatch(previewEmailWithPost(emailType, {reply}));
				} else {
					dispatch(saveAsEml(emailType, {reply}));
				}
			} else if (type === BTN_CALL) {
				console.log('dbg: call button type', type);
				dispatch(dialBack());
			} else {
				console.log('dbg: unknown button type', type);
			}
		},
		onFileupload: (file, route, showAttachment, reply, chat) => {
			dispatch(uploadAgentAttachment(file, route, reply, chat));
			if(reply !== RPLY_COMMENT && !showAttachment) {
				dispatch(toggleAttachmentList(showAttachment));
			}
		},
		toggleUploadAttachment: currentShowState => {
			dispatch(showUploadAttachment(!currentShowState));
		},
		onToggleAttachmentPanel: (toggle) => {
			dispatch(toggleAttachmentList(toggle));
		},
		onUploadArchive: (id, files, showAttachment, reply, chat) => {
			if (chat && !chatHasExternalCollaboration(reply)) {
				dispatch({ type: CHAT_ADD_FILE_ARCHIVE, chat, id, files });
			} else if (reply !== RPLY_COLLABORATE) {
				dispatch(handleFileArchives(id, files));
			} else {
				dispatch(addColFileArchives(id, files));
			}
			if(!showAttachment) {
				dispatch(toggleAttachmentList(showAttachment));
			}
		},
		showArchiveAttch: tgl =>{
			dispatch(toggleFileArchive(tgl));
		},
		onSelectedToLang: (l, reply) => {
			if (reply === RPLY_ERRAND || reply === RPLY_EXT_FWD) {
				dispatch(setAnswerTranslation('to', l));
			} else if (reply === RPLY_COLLABORATE) {
				dispatch(setCollaborationTranslateTo(l));
			}
		},
		onToggleContactBook: (tgl, replyType) =>{
			dispatch(showContactBook(tgl));
			dispatch(setReplyAddressType(replyType, props.currentChecked));
			dispatch(fetchContactBook(""));
		},
		onToggleKnowledgeBase: (tgl) =>{
			dispatch(showErrandLibraryPopup(tgl, RPLY_ERRAND));
		},
		onToggleRewritePanelAnswer: (tgl, answer, area, withCustomAIIns, haveAIAnswerHistory, isChat, newAIContext, AISession, errandId, action) =>{
			dispatch(toggleRightSidePanel(tgl));
			dispatch(doSetAIAnswerPanel(tgl));

			if (isEmptyString(answer)) {
				answer = answer.replace(/\s/g, '');
			}
			if (isChat) {
				const chatIsNotEmpty = checkIfChatNotEmpty();
				if (!chatIsNotEmpty) {
					answer = "";
				}
			}
			let isEmpty = false;
			if (answer !== "") {
				let parser = new DOMParser();
				let doc = parser.parseFromString(answer, 'text/html');
				if (doc.body.innerText === "") {
					isEmpty = true;
				}
			}
			if(!isEmpty) {
				dispatch(setSetRAQuestion(answer));
			}
			if(action) {
				if(action == "proof-read" || action == "summarize") {
					const streamId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
					const p = {
						area,
						content: answer,
						context: action,
						newAIContext,
						errandId: errandId.toString(),
						sessionId: AISession
					}
					dispatch(updateAIAnswerstate(true));
					if (cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response") &&
						!features["openai-agent-assist-uses-openai"]) {
						dispatch(fetchAgentAssistRequest(p.errandId, p.context, p.content, streamId));
						dispatch(generateAIAnswerStreaming(p, streamId));
					} else {
						dispatch(generateAIAnswer(p));
					}
				} else {
					//TODO: To support other task shortcuts straight from the editor
				}
			}
		},
		onToggleKBPanel: (tgl, reply) =>{
			dispatch(doSetKnowledgeBasePanel(tgl));
		},
		onRecordEditorSizes: (height) => {
			dispatch(recordEditorSize(height));
		},
		showTweetWarning: (toggle, nt) =>{
			dispatch(updateTweetWarning(toggle, nt));
		},
		onClearCollaborators: () => {
			dispatch(clearInternalCollaborators());
		},
		onSelectAllCurrentAttachment: (toggle, reply) => {
			dispatch(selectAllQuestionAttachment(toggle, reply));
		},
		onAddSuggestedAnswerAttachment: (attachments, reply) => {
			dispatch(handleLibraryAttachments(attachments, reply));
		},
		onToggleSearchShortcutDropdown: toggle => {
			dispatch(showSearchShortcutDropdown(toggle));
		},
		onToggleSearchInput: toggle => {
			dispatch(showSearchShortcutDropdown(false));
			dispatch(clearInputText('update_answer'));
			dispatch(resetCK());
			dispatch(showSearchInput(toggle));
		},
		onHideReplySearch: val => {
			dispatch(clearInputText('update_answer'));
			dispatch(resetCK());
			dispatch(handleReplySearchHide(val));
			dispatch(handleSearchTextChange('reply', ""));
			dispatch(handleReplySearchResultHide());
		},
		handleChangeSearchInput: (e) =>{
			e.preventDefault();
			let val = e.target.value;
			dispatch(handleSearchTextChange('reply',val));
		},
		handleSearchButton: (e, val) =>{
			console.log('button',e);
			dispatch(handleReplySearchSpinner(true));
			dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_REPLY, true));
		},
		handleKeyUpSearchBox: (e, setHeaderDropdown)=>{
			if (typingTimer) clearTimeout(typingTimer);
			if(e.target.value !== ""){
				dispatch(handleReplySearchSpinner(true));
				typingTimer = setTimeout(()=>{
					dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_REPLY,
						setHeaderDropdown));
				}, doneTypingInterval);
			}
		},
		handleKeyDownSearchBox: (e) =>{
			clearTimeout(typingTimer);
			if(e.keyCode === 8 || e.keyCode === 46){ //backspace, delete
				dispatch(handleSearchTextChange('reply', e.target.value));
			}else if(e.keyCode === 27){ //esc
				dispatch(handleSearchTextChange('reply', ""));
				dispatch(handleReplySearchResultHide());
			}
		},
		onCloseErrandChatSidePanel: toggleState => {
			dispatch(toggleErrandChatSidePanel(toggleState));
			dispatch(resetAgentSatisfaction());
		},
		onClickReview: (id, cipherKey) => dispatch(pushReviewErrandURL(id, cipherKey)),
		onSetCurrentErrand: (id, chat, extra, search) => {
			dispatch(openErrandFromList(id, chat, extra, search));
		},
		onSelectErrandFromList: (id, select) => {
			dispatch(selectErrandFromList(id, select));
		},
		onGetCollaborationInfo: (id) => {
			dispatch(fetchWorkflowExpertQueries(id));
		},
		onToggleLight: errandId => {
			dispatch(toggleCollaborationLight(errandId));
		},
		onLoadPreview: (id) => {
			if (id > 0) {
				dispatch(errandPreview(id));
			}
		},
		handleWATemplToggle: tgl => {
			dispatch(toggleWaTemplPopup(tgl));
		},
		appendTemplate: (value) => {
			dispatch(insertContent(value));
		},
		saveWaTemplateCode: (value) => {
			dispatch(saveWATemplateCode(value));
		},
		onSetAreaRecipient: (v) => {
			dispatch(selectAreaRecipient(v));
		}
	};
};

export const ReplyFormCtnr = connect(
	mapReply
	, mapErrandReplyDispatch
)(ReplyForm);

const mapPostponeErrand = (state, props) =>{
	const e = state.app.errand
	, extData = getErrandDataAndClientById(state, props).extendedData
	, currentErrand = getErrandDataAndClientById(state, props).errand
	, currentArea = getCurrentErrandAreaData(state, props)
	, inputs = e.inputs
	, data = e.basic.data
	, wf = state.app.workflow
	, s = {
		inputs: inputs
		, extData: extData
		, currentArea: currentArea
		, wf: wf
		, data: data
		, currentErrand: e.currentErrand
	};
	return s;
}

const mapPostponeErrandDispatch = (dispatch, props) => {
	return{
		handleTogglePostponeErrand: toggle => {
			dispatch(togglePostponeErrand(toggle));
		}
		, handleSetPostponeDate: (id, date, areaId, areaToReload) => {
			dispatch(setErrandPostponeDate(id, date, areaId, areaToReload));
		}
	};
};

export const PostponeErrandCtnr = connect(mapPostponeErrand,
	mapPostponeErrandDispatch)(PostponeErrand);

const mapReplyNav = (state, props) => ({
	currentReply: getCurrentReply(state, props)
	, info: replyNavInfoSelector(state, props)
	, chat: state.app.errand.chat
	, options: replyNavOptionsSelector(state, props)
	, showChatReplyNavDD: state.app.errand.ui.reply.showChatReplyNavDD
});

const mapReplyNavDispatch = (dispatch, props) => ({
	onSelectReply: (which, show) => {
		dispatch(changeReply(which, show));
	}
	, onClickInfo: (action, source, errandID) => {
		if (action === "delete") {
			toggleAcquireErrand(dispatch, source, errandID, false);
		}
	}
	, onOpenAcquiredErrand: (id, threadId) => {
		dispatch(showOtherContactErrand(id, threadId));
	}
	, onDefaultReply: () => {
		dispatch(changeErrandHistorySelections());
	}
	, onToggleChatNavDD: (toggle) => {
		dispatch(toggleChatNavDD(toggle));
	}
});

export const ReplyNavCtnr = connect(mapReplyNav, mapReplyNavDispatch)(ReplyNav);

const ErrandBoxHeaderUserInfo = compose(
	withUserInfo
	, withAcquireBox
)(AcquireErrandsCtnr);

ErrandBoxHeaderUserInfo.displayName = "ErrandBoxHeaderUserInfo";

function withErrandBoxHeaderBase(LeftBox) {
	return withErrandBoxHeader(LeftBox, ErrandBoxHeaderInfoCtnr);
}

const ErrandBoxHeader = compose(
	withErrandBoxHeaderBase
	, withHeaderLeftBox
)(ErrandBoxHeaderUserInfo);

ErrandBoxHeader.displayName = "ErrandBoxHeader"

const getErrandHistoryAgents = (histories, agentId) =>{
	let totalAgent = 1;
	histories.forEach((h)=>{
		if(h.userId !== agentId){
			totalAgent++;
		}
	});
	return totalAgent;
}

const mapErrandBoxHeader = (state, props) => {
	const errandAndClient = getErrandDataAndClientById(state, props)
		, app = state.app
		, e = app.errand
		, ui =  e.ui
		, serviceConts = state.server.services.constants
		, reply = ui.reply
		, historyAttachment = historyAttachmentListSelector(state, props)
		, s = {
			currentReply: getCurrentReply(state, props),
			errand: errandAndClient.errand,
			extendedData: errandAndClient.extendedData,
			chat: e.chat,
			client: errandAndClient.client,
			eid: getCurrentErrand(state),
			selectedReplyTab: reply.selectedReplyTab,
			showReplyBox: reply.show,
			canInsertIncomingAttachments: app.workflow.fetchWfSettings.data['attachmentListErrandShowStatus'],
			hasOpenErrand: e.contacts.data.hasOpen,
			hasOtherErrands: hasOtherErrandsSelector(state, props),
			isCollaboration: isCollaborationSelector(state, props),
			attachmentList: incomingAttachmentList(state, props),
			attachmentSize: incomingAttachmentsSize(state, props)+historyAttachment.size,
			totalAttachmentsSize: totalAttachmentsSize(state, props)+historyAttachment.size,
			notesAttachmentList: notesAttachmentsSelector(state, props),
			historyAttachmentList: historyAttachment.list,
			selectedHistoryAttachmentList: selectedHistoryAttachmentList(state, props),
			selectedList: selectedAttachmentList(state, props),
			serverServices: state.server.services,
			showAcquire: reply.showAcquire,
			showArea: reply.showArea,
			showTags: reply.showTags,
			showVisitorPath: ui.showVisitorPath,
			history: errandAndClient.history,
			showAttachment: ui.showAttachment,
			isAgentAttached: ui.isAgentAttached,
			savedAttachments: savedAttachmentsSelector(state, props),
			questionAttachments: questionAttachmentsSelector(state, props),
			uploadedAttachments: uploadedAttachmentsSelector(state, props),
			archiveAttachments: archiveAttachmentsSelector(state, props),
			libraryAttachments: libraryAttachmentsSelector(state, props),
			collaborateAnsAttachments: collaborateAnsAttachmentsSelector(state, props),
			textFilePreview: ui.textFilePreview,
			csvFilePreview: ui.csvFilePreview,
			collaborationList: getCollaborationListSelector(state, props),
			internalComments: (e.chat && e.chat.errand &&  e.chat.errand.notes) ? e.chat.errand.notes.length : errandAndClient.extendedData.data.errand_notes,
			customers: 1,/*Assume that one errand has one sender, so theoritically communication is one to one! */
			agents: getErrandHistoryAgents(errandAndClient.history.data, errandAndClient.errand.agentId),
			Workflow: serviceConts.Workflow,
			hasMembershipStatusFeature: app.workflow.fetchWfSettings.data['check-membership-status'],
			threadId: state?.app?.errand?.basic?.param?.threadId,
			membershipStatus: (e.userMembership.data ? e.userMembership.data : null),
			filterPopup: ui.filterPopup,
			showHeader: app.workflow.fetchWfSettings.data.showErrandHeader,
			hasContactCard: getContactCardStatus(state, props),
			nameOncontactCard: getClientContactCardName(state, props),
			hasClientNotes: getClientNotesStatus(state, props),
			suggestAddTo: getSuggestAddTo(state),
			includeHistory: inputsReplyCheckboxes(state, props)[ECB_INC_HISTORIES],
			extForwardHistory: replyCheckboxStatesSelector(state, props)[ECB_FWD_ALL_HISTORIES],
			allAttachmentChecked: inputsReplyCheckboxes(state, props)[INC_ALL_HISTORY_ATTACHMNET]
		};
	return s;
};

const warnDeleteAttachment = I("Are you sure you want to delete attachment ")
	, warnDeleteAllAttachment = I("Are you sure you want to delete all attachments?")
	, warnBttnYes = 1
	, warnBttnNo = 2
	, yesBttnValue = {
		type: warnBttnYes
		, color: 'grey'
		, text: I('Yes')
	}
	, noBttnValue = {
		type: warnBttnNo
		, color: 'blue'
		, text: I('No')
	}
	, warnYesOrNoButtons = [
		yesBttnValue
		, noBttnValue
	]
	, shouldDeleteAttachment = {shouldDeleteAttachment: true}
	;

function confirmDeleteAnswerAttachment(dispatch, actionFor, atId, type, reply, fileName) {
	new Promise((resolve, reject) => {
		let warnBttns = warnYesOrNoButtons;
		resolve(dispatch(customConfirm(
			warnDeleteAttachment + fileName + "?"
			, warnBttns,
		))
		.then(({ button }) => {
			if (button === warnBttnYes) {
				return shouldDeleteAttachment;
			}
		}));
	})
	.then(shouldDeleteAttachment => {
		if(shouldDeleteAttachment) {
			if(type === AT_BROWSE) {
				dispatch(deleteAnswerAttachment(actionFor, atId, reply));
			} else if(type === AT_ARCHIVE) {
				if(reply !== RPLY_COLLABORATE) {
					dispatch(clearSelectedArchive(actionFor, atId));
				} else {
					dispatch(colClearSelectedArchive(actionFor, atId));
				}
			} else if(type === AT_SAVED) {
				// NOTE: saved attachment is only being deleted from front-end
				// RAM and no interaction with backend as the backend only
				// search for temporary upload attachments for deletion.
				// However, once it is removed, and save/update errand endpoint
				// will handle this deleted saved attachment properly.

				if(reply == RPLY_COLLABORATE) {
					dispatch(deleteSavedColAttachment(atId, actionFor));
				} else {
					dispatch(deleteSavedAnswerAttachment(atId, actionFor));
				}
			} else if(type === AT_QUESTION) {
				dispatch(selectQuestionAttachment(atId, false));
			} else if(type === AT_COL_ANS) {
				dispatch(clearCollaborateAnsAttachment(atId));
			} else if(type === AT_LIBRARY) {
				if(reply !== RPLY_COLLABORATE) {
					dispatch(clearSelectedLibrary(actionFor, atId));
				} else {
					dispatch(colClearSelectedLibrary(actionFor, atId));
				}
			}
		}
	});
}

function confirmDeleteAllAgentAttachment(dispatch, eid) {
	new Promise((resolve, reject) => {
		let warnBttns = warnYesOrNoButtons;
		resolve(dispatch(customConfirm(
			warnDeleteAllAttachment
			, warnBttns,
		))
		.then(({ button }) => {
			if (button === warnBttnYes) {
				return shouldDeleteAttachment;
			}
		}));
	})
	.then(shouldDeleteAttachment => {
		if(shouldDeleteAttachment) {
			dispatch(deleteAllAgentAttachment(eid));
		}
	});
}

const mapErrandBoxHeaderCallbacks = dispatch => {
	return {
		onSelectAttachment: (attachmentID, value, currentReply, history) => {
			if (currentReply === RPLY_COLLABORATE) {
				dispatch(selectExistAttachment(attachmentID, value));
			} else if (currentReply === RPLY_EXT_FWD) {
				if(history === IS_HISTORY_ATTACHMENT) {
					dispatch(selectHistoryAttachment(attachmentID, value));
				} else {
					dispatch(selectExternalForwardAttachment(attachmentID, value));
				}
			} else {
				// only allow insertion from question attachments.
				if (history === IS_HISTORY_ATTACHMENT) {
					dispatch(selectHistoryAttachment(attachmentID, value));
				} else {
					dispatch(selectQuestionAttachment(attachmentID, true));
				}
			}
		},
		onToggleAcquire: (toggleState) => {
			dispatch(acquireOperations('show', toggleState));
		},
		onToggleAttachmentList: (toggleState) => {
			dispatch(toggleAttachmentList(toggleState));
		},
		onDeleteAnswerAttachment: (actionFor, atId, type, reply, fileName) => {
			confirmDeleteAnswerAttachment(dispatch, actionFor, atId, type, reply, fileName);
		},
		onGetAttachmentContent: (file, type) => {
			dispatch(fetchFileFromURL(file, type));
		},
		deleteAllAgentAttachment: eid => {
			confirmDeleteAllAgentAttachment(dispatch, eid);
		},
		onSelectTab: tab => {
			dispatch(updateSelectedTabPref(tab));
		},
		onToggleReply: () => {
			dispatch(selectShowReply());
		},
		onToggleVisitorPath: (toggleState) => {
			dispatch(toggleVisitorPath(toggleState));
		},
		onToggleContactCard: tgl => {
			dispatch(getContactCard(tgl));
			dispatch(fetchCustomerNotes());
			dispatch(fetchCompanyList());
		},
		onCheckingMembershipStatus: email => {
			dispatch(doCheckMembershipStatus({email: email}));
		},
		onTogglePopupHistoryElem: (elem, tgl) =>{
			dispatch(togglePopupFilterByElem(elem, tgl));
		},
		onClickHeaderPanel: (toggle) => {
			dispatch(toggleHeaderPanel(toggle));
		},
		onCheckAllAttachment: (value) => {
			let type = INC_ALL_HISTORY_ATTACHMNET;
			dispatch(updateReplyCheckboxes(type, value));
			dispatch(selectAllHistoryAttachemnt());
		},
		onAddSuggestContact: (groupId, serviceType, contact) => {
			dispatch(addSuggestContactIntoCard(groupId, serviceType, contact));
		},
		onSummarizedHistory: (params, callback) => {
			dispatch(fetchSummarizedHistorySuccess("loading ..."));
			dispatch(fetchSummarizedHistoryRequest());
			// First, call the getEmailAndThreadSummary to check if a summary exists
			getEmailAndThreadSummary(params.threadId)
				.then((summaryResponse) => {
					if (summaryResponse && summaryResponse.summaryContent) {
						// If there is a summary, dispatch it and stop further execution
						dispatch(fetchSummarizedHistorySuccess(summaryResponse.summaryContent));
					} else {
						// If no summary is found, continue with the existing logic
						if (cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response")) {
							// Use the streaming approach if the feature flag is active
							// Determine the function dynamically based on the cflag
							
							let fetchDataFunction;
								if (cflag.IsActive("2024-12-31.CEN-2751.Control.token.stream") == false) {
									fetchDataFunction = getStreamXHRDataWithJSON;
								} else {
									fetchDataFunction = getTokenStreamXHRData;
								}
								
							fetchDataFunction('/errand/getSummarizedHistory', params, (chunk) => {
							dispatch(fetchSummarizedHistorySuccess(chunk));
							})
							.then((finalOutput) => {
								if (finalOutput) {
									dispatch(fetchSummarizedHistorySuccess(finalOutput));
								}
							})
							.catch((error) => {
								console.error('Error:', error);
								dispatch(fetchSummarizedHistoryFailure(error));
							});
						} else {
							// Use the non-streaming approach if the feature flag is not active
							getSummaryHistory(JSON.stringify(params))()
								.then((data) => {
									if (data.summary) {
										dispatch(fetchSummarizedHistorySuccess(data.summary));
									}
								})
								.catch((error) => {
									console.error("Error:", error);
									dispatch(fetchSummarizedHistoryFailure(error));
								});
						}
					}
				})
				.catch((error) => {
					console.error('Error fetching email and thread summary:', error);
					dispatch(fetchSummarizedHistoryFailure(error));
				});
		}
		
	};
};

export const ErrandBoxHeaderCtnr = connect(mapErrandBoxHeader, mapErrandBoxHeaderCallbacks)(ErrandBoxHeader);

ErrandBoxHeaderCtnr.displayName = "ErrandBoxHeaderCtnr";

export const withErrandInfo = connect(mapErrandBoxHeader, mapErrandBoxHeaderCallbacks);

const mapStateToErrandHeaderProps = (state, props) => ({
	chatWindowActivated: state.app.footer.uiData.activateChatWindow
	, chat: getCurrentChatErrand(state, props)
	, currentReply: getCurrentReply(state, props)
	, errandActionWip: isErrandActionInProgressSelector(state)
	, currentSipErrandId: sipGetCurrentEid(state)
	, currentSipCallStatus: sipCallStatus(state)
	, saveAsEmlDisabled: isSaveAsEmlDisabledSelector(state, props)
	, showSaveAsEml: showSaveAsEmlSelector(state, props)
	, agentWorking: getAgentWorking(state)
	, agentFavouriteForwardToArea: state.app.header.agentFavourite[AGENT_FAV_FORWARD_TO_AREA]
	, isVideoCallClientReady: (state.app.errand.chat ? state.app.errand.chat.videoCallClientReady : false)
	, isAgentVideoCallShowing: (state.app.errand.chat ? state.app.errand.chat.videoCall : false)
	, isVideoCallInProgress: (state.app.errand.chat ? state.app.errand.chat.videoCallInProgress: false)
	, isVideoRecording: (state.app.errand.chat ? state.app.errand.chat.videoIsRecording: false)
	, isOnVideoCall: (state.chat.socket.onVideoCall)
	, isOnScreenSharing: isScreenSharingMode(state, props)
	, isOnCoBrowseMode: isCoBrowsingMode(state, props)
	, isAgentScreenSharing: (state.chat.socket.agentSharingScreen)
	, totalQueueChats: totalQueueChats(state)
	, toggleForwardToAreaFavourites: state.app.workflow.ui.toggleForwardToAreaFavourites
	, toggleForwardToArea: state.app.workflow.ui.toggleForwardToArea
	, toggleTEST: state.app.workflow.ui.toggleTEST
	, isPostponed: getErrandDataAndClientById(state, props).errand.state === Workflow.ErrandStates.STATE_POSTPONED
	, allowCloseWithoutAnswer: state.app.workflow.fetchWfSettings.data['close-errand-without-answering']
});

const mapCallbackErrandHeader = (dispatch, props) => ({
	onSaveAsEml: reply => {
		promiseProcessEmail(dispatch, reply, saveAsEml);
	},
	onAgentStartWebCam: (sid, clientId, agentId) => {
		dispatch(showVideoCallFrame(sid, true, true));
	},
	onAgentStopWebCam: (sid, clientId, agentId) => {
		closeVideoFrames(sid);
	},
	onGetForwardAreaFavourites: () => {
		dispatch(fetchAgentFavourite(AGENT_FAV_FORWARD_TO_AREA))
	},
	onToggleForwardAreaFavourites: (id) => {
		dispatch(updateAgentFavourite(AGENT_FAV_FORWARD_TO_AREA, id))
	},
	onToggleFavouriteFTADropdown: (p) => {
		dispatch(updateFTAFavourite(p))
	},
	onToggleFTADropdown: (p) => {
		dispatch(updateFTA(p))
	}
});

export const ErrandHeaderCtnr = connect(
	mapStateToErrandHeaderProps
	, mapCallbackErrandHeader
)(ErrandHeader);

const mapStateToContentBorderProps = (state) => {
	return {
		chat: getCurrentChatErrand(state)
	};
};

export const ContentBorder = connect(mapStateToContentBorderProps)(VisibleContentBorder);

ContentBorder.displayName = "ContentBorder";

const mapStateToScrollToBottom = (state) => {
	const e = state.app.errand;
	const wfs = state.app.workflow.fetchWfSettings;
	let answerBoxHeight = state.server.features["workflow.answerbox-height"];
	if(e.recordEditorSize && e.recordEditorSize.data){
		if(typeof e.recordEditorSize.data.answer !== 'undefined'){
			answerBoxHeight = e.recordEditorSize.data.answer;
		}
	}
	return {
		preferredAnswerBoxHeight: answerBoxHeight,
		showReply: e.ui.reply.show,
		verticalView: wfs.data.verticalView
	};
}

export const ScrollToBottomCtnr = connect(mapStateToScrollToBottom)(ScrollToBottom)

const contentBorderPropTypes = {
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.element
	]),
	currentReply: PropTypes.string,
	expandReply: PropTypes.bool,
	hideReply: PropTypes.bool,
	onToggleExpand: PropTypes.func,
	state: PropTypes.number
};

ContentBorder.propTypes = contentBorderPropTypes;

const mapStateLaunchpad = (state) => {
	const wfui = state.app.workflow.ui, wfs = state.app.workflow.fetchWfSettings;
	let launchpadGridLayout = {};
	if(wfui.launchpadGridLayout){
		launchpadGridLayout.lg = JSON.parse(wfui.launchpadGridLayout);
		launchpadGridLayout.md = JSON.parse(wfui.launchpadGridLayout);
	}
	return {
		defaultLaunchpadWidgets: wfs.data.defaultLaunchpadWidgets,
		dueErrands: dueErrandsSelector(state),
		forwardedErrands: forwardedErrandsSelector(state),
		incomingCollabs: incomingCollabsSelector(state),
		expiringErrands: expiringErrandsSelector(state),
		launchpadLayout: wfui.launchpadLayout,
		launchpadWidgets: wfui.launchpadWidgets,
		launchpadGridLayout: launchpadGridLayout,
		collapseSideBar: wfui.collapseSideBar,
	}
}

const mapLaunchpadCallbacks= (dispatch, props) => ({
	onLoad: () => {
		dispatch(periodicDueList());
		dispatch(periodicCollabsList());
		dispatch(periodicForwardedList());
		dispatch(periodicExpiringList());
	},
	onUnload: () => {
		dispatch(stopPeriodicDueList());
		dispatch(stopPeriodicCollabsList());
		dispatch(stopPeriodicForwardedList());
		dispatch(stopPeriodicExpiringList());
	},
	onChangeLayout: (layoutType, save) => {
		dispatch(selectLaunchpadLayout(layoutType, save));
	},
	onSetCurrentErrand: (eId, tId, qId) => {
		if(qId) {
			dispatch(loadAndOpenErrand(eId))
			.then(() => {
				dispatch(expandHideOption('collaboration', eId, true));
				dispatch(multiDispatchesEE(eId))
				.then(() => {
					dispatch(fetchEEThread(tId, eId)).then(() => {
						dispatch(expandCollabQueryThread(tId, true));
						dispatch(setupCollaborationQuery(tId, qId));
					});
				})
			});
		} else {
			dispatch(openErrandFromList(eId));
		}
	},
	onSaveWidgetPreference: (preference) => {
		dispatch(setLaunchpadWidgets(preference));
	},
	onSaveChangeLayout: (layoutType, layoutGrid, save, tobeDeleted, toBeAdded) => {
		dispatch(setLaunchpadGrid(layoutType, layoutGrid, true));
		if(save) {
			if(tobeDeleted && tobeDeleted.length > 0) {
				const toDeleteStr = tobeDeleted.join(",");
				dispatch(deleteLaunchpadWidgetAction(toDeleteStr));
			}
			if(toBeAdded && toBeAdded.length > 0) {
				const widgetSet = JSON.stringify(toBeAdded);
				dispatch(addLaunchpadWidgetAction({launchpadWidgets: widgetSet}));
			}
		}
	},
	onAddWidgetGUI: (widget) => {
		dispatch(addingLaunchpadWidget(widget));
	},
	onDeleteWidgetGUI: (id) => {
		dispatch(deleteLaunchpadWidget(id));
	}
});

export const LaunchpadCntr = connect(mapStateLaunchpad, mapLaunchpadCallbacks)(Launchpad);

const mapErrandOpenedState = (state, props) => ({
	needAutoSave: needAutoSaveSelector(state, props)
	, needFullSave: needSaveMemoize(state)
	, currentWorkingErrands: getCurrentWorkingErrands(state)
});

const mapErrandOpened = (dispatch, props) => {
	return {
		onErrandOpened: () => {
			dispatch(enableAutoSave());
			dispatch(enableAutoSaveCollab());
		}
		, onErrandWillClose: (ids) => {
			// Update redis to indicates agent no longer working on this errand
			dispatch(removeAgentWorkingOnErrand(ids.toString()));
			dispatch(forceCloseErrandView())
			.then(rs=>{
				dispatch(openPreviousErrand());
			});
			dispatch(stopAutoSave());
			dispatch(stopAutoSaveCollab());
			dispatch(doSetKnowledgeBasePanel(false));
		}
		, onWindowBeforeUnload: (needSave, e) => {
			if (!needSave) {
				return;
			}
			// NOTE: custom message no longer work in many of the modern
			// browsers. Refer to: https://stackoverflow.com/a/7317311/1778714
			const msg = I("There are unsaved changes, do you want to proceed?");
			e = e || window.event;
			if (e) {
				e.returnValue = msg;
			}
			return msg;
		}
	};
};

export const ErrandOpenedCtnr = connect(
	mapErrandOpenedState
	, mapErrandOpened
)(ErrandOpened);

const mapErrand = (state, props) => {
	const { inputs } = state.app.errand
		, wf = state.app.workflow
		, e = state.app.errand
		, ce = e.currentErrand
		, currChat = e.chat
		, areaData = getCurrentErrandAreaData(state, props)
		, ui = state.app.errand.ui;
	return {
			associatedErrands: associatedErrandListString(state, props),
			linkedErrands: linkedErrandsListString(state, props),
			ui,
			wfSettings: wf.fetchWfSettings,
			eid: ce.id,
			chat: currChat,
			currentState: currentStateMemoize(state),
			errand: e.errand,
			mobile: mobileStateSelector(state, props),
			data: getHistoriesAndMy(state, props),
			sumHistory: e.sumHistory,
			area: getCurrentErrandAreaData(state, props),
			forwardSelection: getHistoriesSelection(state, props),
			constants: state.server.constants,
			historyReady: isHistoryReady(state),
			myErrandReady: isAcquireReady(state),
			notMine: notMineSelector(state, props),
			errandInputs: inputs,
			forwardToArea: areaData.forward_to_areas,
			forwardToAgent: areaData.agents,
			printContent: e.printContent.data,
			errandAreaData: areaData,
			chatErrandSelected: getChatErrandSelected(state),
			hasDueDateSelected: getHasDueDateSelected(state),
			showMultipleActions: wf.ui.showMultipleActions,
			collapsedSideBar: wf.ui.collapseSideBar,
			scrollToQuestion: scrollToQuestionSelector(state, props),
			filterPopup: ui.filterPopup,
			basic: e.basic.data,
			truncateErrandMessage: wf.ui.errandMessageTruncateByDefault,
			defaultFolderId: myDefaultFolderMemoize(state),
			acquireErrand: e.acquireErrand,
			viewSingleErrandOnly: wf.ui.viewSingleErrandOnly,
			agentWorking: getAgentWorking(state),
			noteList: getInternalCommentsSelector(state, props),
			sipCallStatus: sipCallStatus(state),
			isCallMinimized: manualCallMinimize(state),
			crmData: getCRMEmailTrigger(state),
			showAcquire: ui.reply.showAcquire,
			hasOtherErrands: hasOtherErrandsSelector(state, props),
			hasOpenErrand: e.contacts.data.hasOpen,
			showContactCard: ui.contactCard.show,
			historyIds: getHistoryIdSelector(state),
			pinnedShowAIAnswerPanel: wf.fetchWfSettings.data.showAgentAssistPanel,
	};
};

const mapErrandCallbacks = dispatch => {
	return {
		onActivateView: () => {
			dispatch(activateAnsweredView());
		},
		onCancelScrollToQuestion: currentValue => {
			dispatch(cancelScrollToQuestion());
		},
		onCloseErrandHeaderView: id => {
			dispatch(closeErrandViewAction(id))
				.catch(() => {});
		},
		onFetchHistory: (errandId, threadId) => {
			return dispatch(historyByErrandAndThread(errandId, threadId))
		},
		onPopAlert: (...args) => dispatch(popErrorOnly(...args)),
		onToggleReply: () => {
			dispatch(selectShowReply());
		},
		onToggleErrandHeaderMobile: toggleState => {
			dispatch(toggleErrandHeaderMobile(toggleState));
		},
		onToggleErrandOptionMobile: toggleState => {
			dispatch(toggleErrandOptionMobile(toggleState));
		},
		onSelectForward: (eid, history, value) => {
			dispatch(selectForwardHistory(history, value));
		},
		showPostponeErrand: toggle => {
			dispatch(togglePostponeErrand(toggle));
		},
		handleLockToMe: (id, cipherKey, lock) =>{
			dispatch(updateLockToMe(id, cipherKey, lock));
		},
		handleErrandPriority: (id, cipherKey, highPriority) =>{
			dispatch(setErrandsPriority({list: id, cipher_keys: cipherKey, highPriority: highPriority}, false));
		},
		handleActionShowForwardTo: (actionFor, toggle) =>{
			dispatch(controlActionForward(actionFor, toggle));
		},
		handleMoveToFolder: (errandId, fid) =>{
			dispatch(moveErrandToFolder(errandId, fid));
		},
		handleForwardToArea: (errandId, aid) =>{
			dispatch(forwardErrandToArea(errandId, aid));
		},
		handleForwardToAgent: (errandId, aid) =>{
			dispatch(forwardErrandToAgent(errandId, aid));
		},
		handleActionPrint: (doPrint,errandId, withHistory) =>{
			dispatch(fetchPrintErrand(errandId, doPrint, withHistory));
		},
		deleteErrand: id => {
			dispatch(doDeleteErrand(id, false));
		},
		closeErrand: id => {
			dispatch(doCloseErrand(id, false, false));
		},
		handleCloseChatWithSummary: () => {
			dispatch(selectShowReply(RPLY_CHAT_SUMMARY, true));
		},
		handlePintop: (id, cipherKey, toggle) => {
			dispatch(setErrandPinToTop(id, cipherKey, toggle));
		},
		handleReturnBackToInbox: id => {
			dispatch(putBackToInbox(id, false));
		},
		onHandleSendingBulkErrand: (ids) => {
			dispatch(fetchAgentWorkingOnSelectedErrands())
			.then((data) => {
				if(!data.agent_working_on_errand) {
					dispatch(selectManualErrand(ME_START));
					dispatch(selectManualReply(ME_START));
					dispatch(toggleWorkflowPopup(WFP_BULK_SEND_ERRAND, true));
				}
			});
		},
		handleReopen: (id, cipherKey, channel) => {
			dispatch(handleReopenErrand(id, cipherKey, channel));
		},
		handleQueueToMe: id => {
			dispatch(queueToMe(id));
		},
		onTranslation: (field, html, from) => {
			if(field === "detect") {
				return dispatch(detectLanguage(html));
			}
			if(field === "translate") {
				return dispatch(translation(html, from));
			}
		},
		handleCopyErrandMessage: (value, plain) => {
			dispatch(inputTextChanges('update_answer', value, plain));
			dispatch(selectShowReply(RPLY_ERRAND, true));
		},
		handleEditErrandAnswer: () => {
			dispatch(selectShowReply(RPLY_ERRAND, true));
		},
		handleEditErrandQuestion: () => {
			dispatch(selectShowReply(RPLY_QUESTION, true));
		},
		handleAcquireFromOtherAgentErrand: (...args) => {
			dispatch(acquireFromOtherAgentErrand(...args));
		},
		onSetErrandView: listView => {
			dispatch(agentSetErrandView({preferredErrandsView: listView}));
		},
		onSetErrandMessageTruncatePref: truncate => {
			dispatch(agentSetErrandMsgTruncate({errandMsgTruncate: truncate}))
		},
		onClickAgentAssistTrigger: (toggle) => {
			dispatch(expandHideOption('AIAnswerPanel', 0, toggle));
		},
		onSend: (action, p) => {1
			//unique id per stream to avoid mixing up the responses
			const streamId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
			if (action === "start-over") {
				//clear all AI answer history and reset the question to use current answer
				dispatch(resetAIAnswer(p.content, false, true));
				dispatch(setSetRAQuestion(p.content));
				dispatch(updateAIAnswerstate(true));
				if (cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response") &&
				!features["openai-agent-assist-uses-openai"]) {
					dispatch(fetchAgentAssistRequest(p.errandId, p.context, p.content, streamId));
					dispatch(generateAIAnswerStreaming(p, streamId));
				} else {
					dispatch(generateAIAnswer(p));
				}
			} else {
				let answer = p.content;
				if (isEmptyString(answer)) {
					answer = answer.replace(/\s/g, '');
				}
				if(answer !== ""){
					dispatch(setSetRAQuestion(answer));
					dispatch(updateAIAnswerstate(true));
					if (cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response") && action !== "translate" &&
						!features["openai-agent-assist-uses-openai"]) {
						dispatch(fetchAgentAssistRequest(p.errandId, p.context, p.content, streamId));
						dispatch(generateAIAnswerStreaming(p, streamId));
					} else {
						dispatch(generateAIAnswer(p));
					}
				}
			}
		},
		onChangeReplyView: verticalView => {
			dispatch(setVerticalViewPref(verticalView));
		},
		socialMediaUI:(toggle, whom, eid, action, channel, status) =>{
			dispatch(socialMediaReplyAction(eid, action, channel, status));
			dispatch(toggleSocialMediaUI(toggle, whom));
		},
		onToggleSideBar: (toggle) => {
			dispatch(selectToggleSideBar(toggle));
		},
		onCollapseSideBar: (toggle) => {
			dispatch(selectCollapseSideBar(toggle));
		},
		onMakeSIPCall: (contact) => {
			dispatch(toggleWorkflowPopup(MP_MINIMIZE));
			dispatch(toggleSipOutgoingPopup(true, true, contact));
		},
		onCloseKnowledgeBasePopup: (minimize) => {
			if(minimize) {
				dispatch(toggleRightSidePanel(true));
				dispatch(expandHideOption('KnowledgeBasePanel', 0, true));
			}
			dispatch(doExpandKnowledgeBasePanel(false));
		},
		onPinAgentAssistPanel: (toggle) => {
			dispatch(setAgentAssistTogglePref(toggle));
		}
	};
};

const ErrandCtnr = connect(mapErrand, mapErrandCallbacks)(Errand);

export default ErrandCtnr;
