import React, {
	Fragment
	, PureComponent
	, memo
	, useEffect
	, useState
} from 'react';
import styled from 'styled-components'
import { branch, renameProps, renderComponent, withProps } from 'recompose'
import classNames from 'classnames';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactPaginate from 'react-paginate';
import {
	TABLE_BASE_CLASSNAME,
} from '../../../reactcomponents/common';
import { I, L, webRoot } from '../../../common/v5/config';
import {
	composeWithDisplayName,
	createWithMountCondition,
	omitProps,
	withUnmountWhenHidden
} from '../../../reactcomponents/hocs'
import {
	renderEditButton as renderActionButtons
	, compositeFileActionProps as compositeActionProps
} from "../admin"
import {
	ListHeaderWrapper,
	ListContentWrapper,
	StandardEditorHeader,
	// TableHeader,
	Skeleton,
	editorTitleByView,
	LabeledDelete,
	ActionsWrapper,
	Delete as ActionDelete,
	Download as ActionDownload,
	Edit as ActionEdit,
	EditorBox,
	EditorFooter,
	EditorHeader,
	SaveButton,
	CopyButton,
	CancelButton,
	SingleListSekeleton,
} from '../../../reactcomponents/Admin'
import { TableIconicButton } from '../../../reactcomponents/Form';
import withRoutingGroups from './routinggroupsCtnr'
import {
	ADMIN_VIEW_MAP,
	ADMIN_TITLE,
	ADMINISTRATION,
	INPUT_EMPTY_WARNING,
	INPUT_SPACE_WARNING,
	M_ACCOUNTS,
	M_ROUTING_GROUPS,
	SOCIAL_CHANNEL_VIEW_ICONS as channelListIcons,
	BTN_TXT_SAVE,
	BTN_TXT_SAVING
} from '../../../common/v5/constants';
import {
	BoolYesNoSelect,
	CommonList,
	OrgsAreasSelection,
	orgsAreasColumn,
	useNoOrgOrAreaSelection
} from '../../receiptgreetings/close'
import {
	Div,
	actionEditAndDelete,
	useInvalidEmptyDataField,
	useOnEditField
} from '../../receiptgreetings/receive'
import {
	CELL_ACTION,
	CELL_BOOL,
	Sort,
	useExternalSort,
	NormalizedTable,
	TableBase,
	withExpandedRow
} from '../../../reactcomponents/Table'
import { AEO_MANUAL, TXT_ACTION } from '../../../common/v5/chatbotConstants'
import { useSortBy, useTable, usePagination } from 'react-table'
import memoizeOne from 'memoize-one';
import {
	useCallbackMultiValues,
	useCallbackWithValue,
	useIgnorePromiseCatch
} from '../../../hooks/callback'
import RoutingGroupsForm from "./routinggroupsEdit"
import { tintDarkGrey } from '../../../styles/_variables'

const TableHeader = ({
	onHandleOpenNew,
	showInline,
	title,
}) => {
	return (
		<div className='admin-top-header'>
			<div className='upper-content'>
				<h2 className='title'>{L(title)}</h2>
				<div className='tab-options-wrapper'>
					<TableIconicButton
						type='button'
						className={classNames("btn bg-transparent btn-round", { "active": showInline })}
						iconClass='icon-add'
						title={I('Create New')}
						onClick={onHandleOpenNew}
						// value={2}
						hide={false}
						active={showInline}
					/>
				</div>
			</div>
		</div>
	)
};

//table//
const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
	width: 100%;

	thead{
		border-bottom: 1px solid ${tintDarkGrey};
		th {
			border: none;
			color: rgb(111, 111, 111);
			font-size: 15px;
			padding: 10px;
			text-align: left;
			font-size: 14px;
   		 	font-weight: 900;
		  }
	}
	tbody {
		font-size: 12px;
		tr {
			position: relative;
			padding: 10px;
			border-bottom: 1px solid ${tintDarkGrey};
			line-height: 1em;
			margin: 0 10px;
			transition: all .5s;
			text-align: left;
			.table-btns {
				text-align: right;
			}
		}
		tr:hover {
			background-color: rgba(0,0,0,.075);
		}
		tr.active {
			background-color: #7ac0ff4f;
			border-radius: 5px;
			box-shadow: 0 3px 10px 3px #7ac0ff82;
			transition: all .3s;
		}
	}
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 10px;

      :last-child {
        border-right: 0;
		text-align: right;
      }
	  .table-btns button {
		  padding: 2px 0 2px 6px;
	  }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`
const PaginationBtn = ({ className, disabled, label, onClick }) => {
	return (
		<button
			className={classNames(className, { "disabled": disabled })}
			onClick={onClick}
			disabled={disabled}
		>{label}
		</button>
	)
}

function Table({ columns, data, activeId }) {
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		// rows,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 }
		},
		useSortBy,
		usePagination
	)

	const handlePageClick = (event) => {
		gotoPage(event.selected);
	};

	// Render the UI for your table
	return (
		<div>
			<table {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps(column.getSortByToggleProps())}>
									{column.render('Header')}
									<span>
										{column.isSorted
											? column.isSortedDesc
												? ' ▼'
												: ' ▲'
											: ''}
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row)
						return (
							<tr
								className={classNames({ "active": row.values.id === activeId })}
								{...row.getRowProps()}>
								{row.cells.map(cell => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
			{/* pagination */}
			<div className="pagination">
				<div className="page-indicator">
					{(I("Showing"))} {pageIndex + 1} {I(" of ")} {pageOptions.length}
				</div>
				<ReactPaginate
					previousLabel="<"
					breakLabel="..."
					nextLabel=">"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={1}
					pageCount={pageCount}
					renderOnZeroPageCount={null}
					containerClassName="page-numbers"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item previous"
					previousLinkClassName="page-link"
					nextClassName="page-item next"
					nextLinkClassName="page-link"
					breakClassName="page-item break"
					breakLinkClassName="page-link"
					activeClassName="active"
				/>
			</div>
		</div>
	)
}


const SubmitButton = ({ className, text, disabled  = false}) => {
	return (<button
		type="submit"
		className={className}
		title={text}
		data-qa-id="btn-Save"
		disabled={disabled}
	>{text}</button>
	)
}

const EditFormButtons = ({
	disableSave = false,
	hideDelete,
	onCancel,
	onDelete,
	onSave,
	saveTxt = BTN_TXT_SAVE,
}) => (
	<EditorFooter
		left={<LabeledDelete hidden={hideDelete} onClick={onDelete} />}
	>
		<CancelButton onClick={onCancel} />
		{/* <SaveButton disabled={disableSave} onClick={onSave} type="submit" /> */}
		<SubmitButton className={"btn-blue"} text={saveTxt} disabled={disableSave} />
	</EditorFooter>
)

const EditFormBase = ({
	// areaList,
	activeId,
	input,
	view,
	onHandleTextInputChange,
	onHandleTextInputBlur,
	onChangeAdminInput,
	isNew,
	onSave,
	onCancel,
	onDelete,
	hidden,
	adminStatus,
	...props
}) => {
	let buttonSaveTxt = BTN_TXT_SAVE, disableSave = false;
	if(adminStatus && adminStatus.status === 1) {
		buttonSaveTxt = BTN_TXT_SAVING;
		disableSave = true;
	}

	const baseButtons = <EditFormButtons
		hideDelete={isNew}
		onCancel={onCancel}
		onDelete={useCallbackMultiValues(onDelete, activeId)}
		onSave={onSave}
		saveTxt={buttonSaveTxt}
		disableSave={disableSave}
	/>
	return (
		<EditorBox>
			<RoutingGroupsForm
				activeId={activeId}
				input={input}
				view={view}
				hidden={hidden}
				onChangeAdminInput={onChangeAdminInput}
				isNew={isNew}
				baseButtons={baseButtons}
				onSave={onSave}
			/>
		</EditorBox>
	)
}

const EditForm = composeWithDisplayName(
	'EditForm',
	memo,
	withProps(({ activeId }) => {
		if (!activeId) {
			return { isNew: true }
		}
		return { isNew: false }
	}),
	withUnmountWhenHidden
)(EditFormBase);

const EditFormSection = (
	{ view
		, isNew
		, title
		, onLoad
		, showInline
		, list
		, onLoadToEdit
		, onChangeAdminInput
		, onSaveAdmin
		, onCancel
		, onDeleteFromList
		, activeId
		, input
		, hidden
		, adminStatus
	}) => {
	return (
		<div className='form-container'>
			<EditorHeader
				hidden={!showInline}
				isNew={isNew}
				onClickClose={onCancel}
				title={title}
			/>
			<EditForm
				// areaList={areaList}
				// onHandleChangeData={onHandleChangeData}
				// oauthConstants={oauthConstants}
				activeId={activeId}
				input={input}
				view={view}
				// onHandleTextInputChange={onChangeAdminInput}
				onChangeAdminInput={onChangeAdminInput}
				// onHandleTextInputBlur={onHandleTextInputBlur}
				onSave={onSaveAdmin}
				onCancel={onCancel}
				onDelete={onDeleteFromList}
				isNew={isNew}
				hidden={!showInline}
				adminStatus={adminStatus}
			/>
		</div>
	)
}

const RoutingGroupsBase = ({
	view
	, onLoad
	, showInline
	, list
	, onLoadToEdit
	, onChangeAdminInput
	, onSaveAdmin
	, onDeleteFromList
	, onCloseForm
	, activeId
	, input
	, adminStatus
}) => {
	useEffect(() => {
		if (onLoad) {
			onLoad(M_ROUTING_GROUPS, false);
		}
	}, []);

	const handleDelete = (id) => {
		let confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			onDeleteFromList(id);
		}
	}

	const actionHandlers = { onDelete: handleDelete, onEdit: onLoadToEdit }

	const columns2 = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				Footer: "footer"
			},
			{
				Header: 'Action',
				accessor: 'id',
				disableSortBy: true,
				// Cell: iconicBtn
				Cell: (instances) => {
					return (
						renderActionButtons(instances, instances.row.values, actionHandlers)
					)
				}
			},
		],
		[]
	)

	return (
		<Skeleton
			className={"routing-groups-page"}
			lists={
				<Fragment>
					<ListHeaderWrapper>
						<TableHeader
							title={ADMIN_TITLE[view]}
							type='button'
							// className='plus-btn btn bg-transparent'
							iconClass='icon-add'
							onHandleOpenNew={e => onLoadToEdit(0)}
							// onHandleOpenNew={onLoadToEdit}
							hide={false}
							showInline={showInline}
						/>
					</ListHeaderWrapper>
					<ListContentWrapper>
						<Styles>
							<Table columns={columns2} data={list} activeId={activeId} />
						</Styles>
					</ListContentWrapper>
				</Fragment>
			}
		// extraComponent={extraComponent}
		>
			<EditFormSection
				activeId={activeId}
				title={editorTitleByView(activeId <= 0, view)}
				isNew={activeId <= 0}
				view={view}
				onLoad={onLoad}
				showInline={showInline}
				list={list}
				input={input}
				onLoadToEdit={onLoadToEdit}
				onChangeAdminInput={onChangeAdminInput}
				onSaveAdmin={onSaveAdmin}
				onDeleteFromList={handleDelete}
				onCancel={onCloseForm}
				adminStatus={adminStatus}
			/>
		</Skeleton>
	)
}

// const RoutingGroups = withRoutingGroups(RoutingGroupsBase);

// export default RoutingGroups;
export default RoutingGroupsBase;